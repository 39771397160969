import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TextField, Grid, Checkbox } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Link } from 'react-router-dom';

import Button from '../common/Button';
import MetricsMenu from './MetricsMenu';

import './CreateTestView.css';

const CreateTestView = (props) => (
  <section className="create-test-view modal-page">
    <div className={`viewport ${props.isSubmitting ? 'viewport-submitting' : ''}`}>
      <header>
        <h1>Metrics</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="content">
        <MetricsMenu {...props} />
        <div className="modal-activity">
          <div className="activity-area">
            <header>
              <h3>Create Test Form</h3>
            </header>
            <div className="inner-activity">
              <Grid container spacing={5}>
                <Grid item xs={7}>
                  <h4>1.) Input Title</h4>
                  <TextField
                    className="bsf-text"
                    value={_.get(props.form, 'title') || ''}
                    placeholder="Title of the metric test you're running"
                    fullWidth
                    onChange={props.handleChange('title')}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <h4>2.) Choose Projection</h4>
                  <Autocomplete
                    id="combo-box-demo"
                    options={props.spatialReferences}
                    getOptionLabel={(option) => `${option.auth_name}:${option.srid} - ${option.name}`}
                    style={{ width: '100%' }}
                    value={_.get(props.form, 'spatialReference')}
                    onChange={(event, value) => {
                      props.handleChange('spatialReference')({ target: { value } });
                    }}
                    getOptionSelected={(option, value) => option.srid === value.srid
                      && option.auth_name === value.auth_name
                      && option.name === value.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Title of the metric test you're running"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <h4>3.) Choose Compactness Metrics</h4>
                  <Grid container spacing={2}>
                    {
                    props.metrics.map((metric, i) => (
                      <Grid item xs={12} md={4} className="mtc-option" key={`ctv_g${i}`}>
                        <Checkbox
                          onChange={() => props.handleCheck(metric.id)}
                        />
                        <div className="mtc-item">
                          <h4>{metric.name}</h4>
                          <p className="metric-description">
                            {metric.description}
                          </p>
                        </div>
                      </Grid>
                    ))
                  }
                  </Grid>
                </Grid>
              </Grid>

            </div>
            <footer>
              <Button primary onClick={() => props.handleSubmit()}>Generate Test</Button>
            </footer>
          </div>
        </div>
      </section>
    </div>
  </section>
);

CreateTestView.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  form: PropTypes.object.isRequired,
  planId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  metrics: PropTypes.array,
  handleCheck: PropTypes.func.isRequired,
  spatialReferences: PropTypes.array.isRequired,
};

CreateTestView.defaultProps = {
  metrics: [],
};

export default CreateTestView;
