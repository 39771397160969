import React from 'react';
import PropTypes from 'prop-types';
import Button from '../common/Button';

import ImportedDistrictLibraryItem from './ImportedDistrictLibraryItem';

import './ImportedDistrictLibrary.css';

const ImportDistrictLibrary = (props) => (
  <section className="import-district-library activity-area">
    <header>
      <h3>District Library: Choose a Plan</h3>
    </header>
    <div className="idu-inner-activity-area inner-activity">
      <ul>
        {
          props.importedDistricts.map((importedDistrict) => (
            <ImportedDistrictLibraryItem
              key={importedDistrict.id}
              importedDistrict={importedDistrict}
              {...props}
            />
          ))
        }
      </ul>
    </div>
    <footer>
      <Button
        primary={props.isValid}
        grayed
        to={`/editor/${props.planId}`}
      >
        Cancel
      </Button>
      <Button
        primary={props.selectedLibraryItem}
        disabled={!props.selectedLibraryItem}
        onClick={props.handleSelect}
      >
        Select
      </Button>
    </footer>
  </section>
);

ImportDistrictLibrary.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  importedDistricts: PropTypes.array.isRequired,
  isValid: PropTypes.bool,
  planId: PropTypes.any.isRequired,
  selectedLibraryItem: PropTypes.object,
};

ImportDistrictLibrary.defaultProps = {
  selectedLibraryItem: null,
  isValid: false,
};

export default ImportDistrictLibrary;
