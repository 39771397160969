import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const ClickOutside = (props) => {
  const node = useRef();

  useEffect(() => {
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      props.handleClickOutside();
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [props]);

  return (
    <div style={props.style} className={`${props.className}`} ref={node}>
      {props.children}
    </div>
  );
};

ClickOutside.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  handleClickOutside: PropTypes.func.isRequired,
};

ClickOutside.defaultProps = {
  style: {},
  className: '',
};

export default ClickOutside;
