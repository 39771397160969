import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';

import EditorNotificationItem from './EditorNotificationItem';
import Button from '../../common/Button';
import ClickOutside from '../../common/ClickOutside';
import './EditorNotifications.css';

const EditorNotifications = (props) => (
  <ClickOutside
    handleClickOutside={props.handleClickOutsideNotifications}
  >
    <div className="editor-notifications">
      <Button onClick={props.handleNotificationClick}>
        <Icon icon={faBell} size="2x" color="gray" />
        {
        props.unseenNotifications.length > 0
        && (
          <div className="en-badge">
            <span>
              {_.get(props.unseenNotifications, 'length', 0) > 99
                ? '99+'
                : _.get(props.unseenNotifications, 'length', 0)}
            </span>
          </div>
        )
      }
      </Button>
      {
      props.showNotifications
      && (
        <div className="en-notification-list">
          <ul>
            {
              props.notifications.map((notif) => (
                <EditorNotificationItem
                  {...notif}
                  handleClickOutsideNotifications={props.handleClickOutsideNotifications}
                />
              ))
            }
          </ul>
        </div>
      )
    }
    </div>
  </ClickOutside>
);

EditorNotifications.propTypes = {
  handleClickOutsideNotifications: PropTypes.func.isRequired,
  handleNotificationClick: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  showNotifications: PropTypes.bool.isRequired,
  unseenNotifications: PropTypes.array.isRequired,
};

export default EditorNotifications;
