import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import './Button.css';

const getClass = (props) => {
  let primary = _.get(props, 'primary') || '';
  if (primary === true) {
    primary = 'true';
  }
  let grayed = _.get(props, 'grayed') || '';
  if (grayed === true) {
    grayed = 'true';
  }
  if (primary) { return 'btn btn-primary'; }
  if (grayed) { return 'btn btn-grayed'; }
  return 'btn';
};
const Button = (props) => {
  let primary = _.get(props, 'primary') || '';
  if (primary === true) {
    primary = 'true';
  }
  let grayed = _.get(props, 'grayed') || '';
  if (grayed === true) {
    grayed = 'true';
  }
  return (props.to
    ? (
      <Link
        {...props}
        primary={primary}
        grayed={grayed}
        to={props.to}
        className={`${getClass(props)} ${props.disabled ? 'btn-disabled' : ''} ${props.className}`}
        onClick={props.onClick || (() => {})}
      >
        {props.children}
      </Link>
    )
    : (
      <button
        {...props}
        primary={primary}
        grayed={grayed}
        className={`${getClass(props)} ${props.disabled ? 'btn-disabled' : ''} ${props.className}`}
        onClick={props.onClick}
        type="button"
      >
        {props.children}
      </button>
    ));
};

Button.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  primary: PropTypes.bool,
  grayed: PropTypes.bool,
};

Button.defaultProps = {
  className: '',
  disabled: false,
  href: '',
  to: '',
  primary: false,
  grayed: false,
  onClick: () => {},
};

export default Button;
