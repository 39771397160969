import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import ImportedDistrictView from '../components/importedDistrict/ImportedDistrictView';

class ImportedDistrictPublicContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render = () => (
    <ImportedDistrictView
      action="public"
      planId={_.get(this.props, 'match.params.plan_id')}
    />
  )
}

const mapStateToProps = () => ({});

ImportedDistrictPublicContainer.propTypes = {};

export default connect(mapStateToProps, {})(ImportedDistrictPublicContainer);
