import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updatePlan } from '../../actions/plan';
import { getSpatialReferences } from '../../actions/metrics';
import BasicSettings from '../../components/settings/BasicSettings';

class BasicSettingsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        id: _.get(this.props.chosenPlan, 'id'),
        title: _.get(this.props.chosenPlan, 'title'),
        planState: _.get(this.props.chosenPlan, 'state', null),
        description: _.get(this.props.chosenPlan, 'description'),
        districtCount: _.get(this.props.chosenPlan, 'district_count'),
        districtType: _.get(this.props.chosenPlan, 'district_type', null),
        defaultProjection: _.get(this.props.chosenPlan, 'default_projection', null),
      },
    };
  }

  componentDidMount() {
    this.props.getSpatialReferences();
    if (_.get(this.props.chosenPlan, 'id', null) === null) {
      this.initializeForm();
    }
  }

  componentDidUpdate(prevProps) {
    if (_.get(prevProps.chosenPlan, 'id', null) === null
      && _.get(this.props.chosenPlan, 'id', null) !== null) {
      this.initializeForm();
    }
  }

  initializeForm = () => {
    this.setState({
      form: {
        id: _.get(this.props.chosenPlan, 'id'),
        title: _.get(this.props.chosenPlan, 'title'),
        planState: _.get(this.props.chosenPlan, 'state', null),
        description: _.get(this.props.chosenPlan, 'description'),
        districtCount: _.get(this.props.chosenPlan, 'district_count'),
        districtType: _.get(this.props.chosenPlan, 'district_type', null),
        defaultProjection: _.get(this.props.chosenPlan, 'default_projection', null),
      },
    });
  }

  handleRevert = () => {
    this.initializeForm();
  }

  handleSubmit = () => {

  }

  handleSubmit = () => {
    this.props.updatePlan(
      this.state.form.id,
      this.state.form.title,
      this.state.form.description,
      this.state.form.districtType,
      this.state.form.districtCount,
      this.state.form.defaultProjection.srid,
    );
  };

  handleChange = (col) => (val) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [col]: val,
      },
    }));
  }

  render = () => (
    <BasicSettings
      {...this.props}
      form={this.state.form}
      revert={this.handleRevert}
      handleSubmit={this.handleSubmit}
      handleChange={this.handleChange}
    />
  )
}

const mapStateToProps = (state) => ({
  chosenPlan: _.get(state.plan, 'chosenPlan'),
  spatialReferences: _.get(state, 'metrics.spatialReferences'),
});

BasicSettingsContainer.propTypes = {
  chosenPlan: PropTypes.object,
  updatePlan: PropTypes.func.isRequired,
  getSpatialReferences: PropTypes.func.isRequired,
};

BasicSettingsContainer.defaultProps = {
  chosenPlan: {},
};

export default connect(
  mapStateToProps, {
    updatePlan,
    getSpatialReferences,
  },
)(BasicSettingsContainer);
