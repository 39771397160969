export const getNotifications = (planId) => ({
  type: 'NOTIFICATION_GET_NOTIFICATIONS',
  payload: {
    request: {
      url: `/plan/${planId}/notifications/`,
    },
  },
});

export const updateNotification = (planId, notificationId, partialProps) => ({
  type: 'NOTIFICATION_UPDATE_NOTIFICATIONS',
  payload: {
    request: {
      url: `/plan/${planId}/notifications/${notificationId}/`,
      method: 'PATCH',
      data: partialProps,
    },
  },
});

export const receiveNotification = (notification) => ({
  type: 'NOTIFICATION_RECEIVE_PUSH_NOTIFICATION',
  payload: {
    data: {
      ...notification,
    },
  },
});

export const receiveExportPoll = (exportPoll) => ({
  type: 'NOTIFICATION_RECEIVE_EXPORT_POLL',
  payload: {
    data: {
      exportPoll,
    },
  },
});

export const receiveImportedDistrictPoll = (importedDistrictPoll) => ({
  type: 'NOTIFICATION_RECEIVE_IMPORTED_DISTRICT_POLL',
  payload: {
    data: {
      importedDistrictPoll,
    },
  },
});
