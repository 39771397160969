/**
 * We need the planId to keep users from using specific columns
 * unavailable because of their respective plans...
 * @param {number} planId
 */
export const getDataColumns = (planId) => ({
  type: 'RETRIEVE_DATA_COLUMNS',
  payload: {
    request: {
      url: `/plan/${planId}/data-sources/columns/`,
      method: 'GET',
    },
  },
});

export const activateShadedLayer = (planId, shadedLayerId) => ({
  type: 'ACTIVATE_SHADED_LAYER',
  payload: {
    request: {
      url: `/plan/${planId}/shaded-layer/${shadedLayerId}/`,
      method: 'PUT',
      data: {
        id: shadedLayerId,
        plan: planId,
        is_active: true,
      },
    },
  },
});

export const createShadedLayer = (planId, form) => ({
  type: 'CREATE_SHADED_LAYER',
  payload: {
    request: {
      url: `/plan/${planId}/shaded-layer/`,
      method: 'POST',
      data: {
        name: form.name,
        description: form.description,
        numerator: form.numerator.id,
        denominator: form.denominator.id,
        color_min: form.colorMin,
        color_max: form.colorMax,
        color_via: form.colorVia,
        steps: form.steps,
        stops: form.stops,
        breakoff_point: form.breakoffPoint,
        brush_type: 'census-group',
        plan: planId,
      },
    },
  },
});

export const updateShadedLayer = (planId, shadedLayerId, form) => ({
  type: 'UPDATE_SHADED_LAYER',
  payload: {
    request: {
      url: `/plan/${planId}/shaded-layer/${shadedLayerId}/`,
      method: 'PUT',
      data: {
        id: shadedLayerId,
        name: form.name,
        description: form.description,
        numerator: form.numerator.id,
        denominator: form.denominator.id,
        color_min: form.colorMin,
        color_max: form.colorMax,
        color_via: form.colorVia,
        steps: form.steps,
        stops: form.stops,
        breakoff_point: form.breakoffPoint,
        brush_type: 'census-group',
        plan: planId,
      },
    },
  },
});

export const deleteShadedLayer = (planId, shadedLayerId) => ({
  type: 'DELETE_SHADED_LAYER',
  shadedLayerId,
  payload: {
    request: {
      url: `/plan/${planId}/shaded-layer/${shadedLayerId}/`,
      method: 'DELETE',
    },
  },
});

export const updateShadedLayerStatus = (poll) => ({
  type: 'SHADED_LAYER_UPDATE_STATUS',
  payload: {
    data: poll,
  },
});
