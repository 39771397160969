// Reducer
import Cookies from 'universal-cookie';
import _ from 'lodash';
import { setAuthHeader } from '../requestClient';

const defaultState = {
  auth: {},
  isLoading: true,
};

const AuthReducer = (state = defaultState, action) => {
  const cookies = new Cookies();

  switch (action.type) {
    case 'AUTH_SET_AUTH': {
      return {
        ...state,
        ...action.auth,
        isLoading: false,
      };
    }
    case 'BALLISTA_LOGIN_SUCCESS':
    case 'BALLISTA_REGISTER_SUCCESS': {
      const auth = {
        id: action.payload.data.user_id,
        username: action.payload.data.username,
      };
      const bearerToken = _.get(action, 'payload.data.token');
      cookies.set('ballista_token', bearerToken, { domain: `${process.env.REACT_APP_PDI_COOKIE_DOMAIN}` });
      setAuthHeader(bearerToken);
      return {
        ...auth,
        isLoading: false,
      };
    }
    case 'PDI_GEO': {
      return {
        ...state,
        isLoading: true,
      };
    }
    // case 'PDI_GEO_SUCCESS': {
    //       return {
    //         ...state,
    //         settings: _.get(action, 'payload.data.settings'),
    //         isLoading: false,
    //       };
    //     }
    //     case 'PDI_GEO_FAIL': {
    //       return {
    //         ...state,
    //         isLoading: false,
    //       };
    //     }
    case 'PDI_LOGIN_SUCCESS': {
      return {
        ...state,
        settings: _.get(action, 'payload.data.settings'),
        isLoading: false,
      };
    }
    case 'BALLISTA_LOGOUT': {
      cookies.remove('ballista_token', { domain: `${process.env.REACT_APP_PDI_COOKIE_DOMAIN}` });
      cookies.remove('pdi_token', { domain: `${process.env.REACT_APP_PDI_COOKIE_DOMAIN}` });
      cookies.remove('pdi_api_token', { domain: `${process.env.REACT_APP_PDI_COOKIE_DOMAIN}` });
      cookies.remove('pdi_settings', { domain: `${process.env.REACT_APP_PDI_COOKIE_DOMAIN}` });
      return {
        ...defaultState,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};

export default AuthReducer;
