import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import { connect } from 'react-redux';
import PlansContainer from '../containers/PlansContainer';
import EditorPage from './EditorPage';
import ImportedDistrictPage from './ImportedDistrictPage';
import GuideLayerPage from './GuideLayerPage';
import ShadedLayerPage from './ShadedLayerPage';
import SettingsPage from './SettingsPage';
import LoginPage from './LoginPage';
import LogoutPage from './LogoutPage';
import ViewerPage from './ViewerPage';
import RegistrationPage from './RegistrationPage';
import TransparentLoginPage from './TransparentLoginPage';
import ExportArchivePage from './ExportArchivePage';
import MetricsPage from './MetricsPage';
import ReportPage from './ReportPage';
import Toast from '../components/common/Toast';

import { getPdiAuthState, getBallistaAuthState } from '../helper/auth';
import { loadAuth } from '../actions/auth';

const PrivateRoute = ({ render, ...rest }) => {
  const renderCondition = _.get(rest.auth, 'id', null) !== null || _.get(rest.auth, 'isLoading');
  return (
    <Route
      {...rest}
      render={(props) => (
        renderCondition === true
          ? render(props)
          : <Redirect to="/login" />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  auth: PropTypes.object.isRequired,
  render: PropTypes.func.isRequired,
};

class Scorpio extends React.Component {
  componentDidMount() {
    let auth = getPdiAuthState();
    if (!_.get(auth, 'id', null)) {
      auth = getBallistaAuthState();
    }
    this.props.loadAuth(auth);
  }

  render() {
    if (this.props.siteIsLoading) {
      return null;
    }

    return (
      <>
        <Toast />
        <Router>
          <div className="page">
            <PrivateRoute
              auth={this.props.auth}
              exact
              path="/"
              render={(props) => <PlansContainer {...props} />}
            />
            <Route
              auth={this.props.auth}
              exact
              path="/login"
              render={(props) => <LoginPage {...props} />}
            />
            <Route
              auth={this.props.auth}
              exact
              path="/logout"
              render={(props) => <LogoutPage {...props} />}
            />
            <Route
              auth={this.props.auth}
              exact
              path="/register"
              render={(props) => <RegistrationPage {...props} />}
            />
            <Route
              auth={this.props.auth}
              path="/tlogin"
              render={(props) => <TransparentLoginPage {...props} />}
            />
            <PrivateRoute
              auth={this.props.auth}
              path="/editor/:plan_id/report"
              render={(props) => <ReportPage {...props} action="create" />}
            />
            <PrivateRoute
              auth={this.props.auth}
              path="/editor/:plan_id/report/:action"
              render={(props) => <ReportPage {...props} action={props.match.params.action} />}
            />
            <PrivateRoute
              auth={this.props.auth}
              path="/editor/:plan_id/report/:report_id/view"
              render={(props) => <ReportPage {...props} action="view" reportId={props.match.params.report_id} />}
            />
            <PrivateRoute
              auth={this.props.auth}
              path="/editor/:plan_id/guide-layer/create"
              render={(props) => <GuideLayerPage {...props} />}
            />
            <PrivateRoute
              auth={this.props.auth}
              path="/editor/:plan_id/shaded-layer/create"
              render={(props) => <ShadedLayerPage {...props} />}
            />
            <PrivateRoute
              auth={this.props.auth}
              path="/editor/:plan_id/shaded-layer/:shaded_layer_id/edit"
              render={(props) => <ShadedLayerPage {...props} />}
            />
            <PrivateRoute
              auth={this.props.auth}
              exact
              path="/editor/:plan_id/shaded-layer/:shaded_layer_id/activate"
              render={(props) => (
                <EditorPage
                  {...props}
                  shouldActivateShadedLayer
                  shadedLayerId={props.match.params.shaded_layer_id}
                  planId={props.match.params.plan_id}
                />
              )}
            />
            <PrivateRoute
              exact
              auth={this.props.auth}
              path="/editor/:plan_id/import-district"
              render={(props) => <ImportedDistrictPage {...props} tab="upload" />}
            />
            <PrivateRoute
              auth={this.props.auth}
              path="/editor/:plan_id/import-district/:tab"
              render={(props) => <ImportedDistrictPage {...props} tab={props.match.params.tab} />}
            />
            <PrivateRoute
              exact
              auth={this.props.auth}
              path="/viewer/:plan_id"
              render={(props) => <ViewerPage {...props} />}
            />
            <PrivateRoute
              auth={this.props.auth}
              path="/editor/:plan_id/metrics/:tab"
              render={(props) => (
                <MetricsPage
                  exact
                  {...props}
                  tab={_.get(props.match, 'params.tab')}
                  planId={_.get(props.match, 'params.plan_id')}
                />
              )}
            />
            <Route
              path="/editor/:plan_id/metrics/metric-edit/:metric_id"
              exact
              auth={this.props.auth}
              render={(props) => (
                <MetricsPage
                  tab="metric-edit"
                  planId={_.get(props.match, 'params.plan_id')}
                  testSuiteId={_.get(props.match, 'params.test_id')}
                  metricId={_.get(props.match, 'params.metric_id')}
                />
              )}
            />
            <PrivateRoute
              exact
              path="/editor/:plan_id/metrics/:suite_id/results/"
              auth={this.props.auth}
              render={(props) => (
                <MetricsPage
                  {...props}
                  tab="suite"
                  planId={_.get(props.match, 'params.plan_id')}
                  testSuiteId={parseInt(_.get(props.match, 'params.suite_id'), 10)}
                />
              )}
            />
            <PrivateRoute
              exact
              auth={this.props.auth}
              path="/editor/:plan_id/metrics/:suite_id/results/:test_instance"
              render={(props) => (
                <MetricsPage
                  {...props}
                  tab="results"
                  planId={_.get(props.match, 'params.plan_id')}
                  testSuiteId={parseInt(_.get(props.match, 'params.suite_id'), 10)}
                  testInstanceId={parseInt(_.get(props.match, 'params.test_instance'), 10)}
                />
              )}
            />
            <PrivateRoute
              exact
              auth={this.props.auth}
              path="/editor/:plan_id/exports/:action"
              render={(props) => (
                <ExportArchivePage
                  {...props}
                  planId={_.get(props.match, 'params.plan_id')}
                  action={_.get(props.match, 'params.action')}
                />
              )}
            />
            <PrivateRoute
              exact
              auth={this.props.auth}
              path="/editor/:plan_id/settings"
              render={(props) => (
                <SettingsPage
                  {...props}
                  action="basic"
                  planId={_.get(props.match, 'params.plan_id')}
                />
              )}
            />
            <PrivateRoute
              auth={this.props.auth}
              path="/editor/:plan_id/settings/:action"
              render={(props) => (
                <SettingsPage
                  {...props}
                  action={props.match.params.action}
                  planId={_.get(props.match, 'params.plan_id')}
                />
              )}
            />
            <PrivateRoute
              auth={this.props.auth}
              path="/editor/:plan_id"
              render={(props) => <EditorPage {...props} />}
            />
          </div>
        </Router>
      </>
    );
  }
}

Scorpio.propTypes = {
  siteIsLoading: PropTypes.bool,
  auth: PropTypes.object.isRequired,
  loadAuth: PropTypes.func.isRequired,
};

Scorpio.defaultProps = {
  siteIsLoading: false,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  loadAuth,
})(Scorpio);
