export const BRUSH_STROKES = [
  {
    id: 'county',
    geoid: 'geoid',
    name: 'County',
    layerName: 'censuscounty',
    geoserverLayerName: 'ballista:county',
  },
  {
    id: 'tract',
    geoid: 'geoid',
    name: 'Census Tract',
    layerName: 'censustract',
    geoserverLayerName: 'ballista:tract',
  },
  {
    id: 'group',
    geoid: 'groupid',
    name: 'Census Group',
    layerName: 'censusgroup',
    geoserverLayerName: 'ballista:group',
  },
  {
    id: 'block',
    geoid: 'geoid10',
    name: 'Census Block',
    layerName: 'censustabblock',
    geoserverLayerName: 'ballista:block',
  },
];

// {
//   id: 'precinct',
//   geoid: 'name',
//   name: 'Election District',
//   layerName: 'censusprecinct',
// },

export const DEFAULT_GUIDE_LAYERS = [
  ...BRUSH_STROKES,
  {
    id: 'cd',
    name: 'Congressional District',
    layerName: 'ballista:cd',
  },
  {
    id: 'sldu',
    name: 'State Legislative District Upper',
    layerName: 'ballista:sldu',
  },
  {
    id: 'sldl',
    name: 'State Legislative District Lower',
    layerName: 'ballista:sldl',
  },
  {
    id: 'precinct',
    name: 'Precincts',
    layerName: 'ballista:precinct',
  },
];

export const TOOLBOX = [
  { name: 'Pointer' },
  { name: 'Click' },
  { name: 'Paint' },
  { name: 'Draw' },
  { name: 'Square' },
  { name: 'Freehand' },
  { name: 'Erase' },
];

export const IMPORTED_DISTRICT_MENU_LIST = [
  {
    label: 'Upload Districts',
    action: 'upload',
  },
  {
    label: 'My Districts Library',
    action: 'library',
  },
  {
    label: 'Districts Library',
    action: 'public',
  },
];

export const SETTINGS_MENU_LIST = [
  {
    label: 'Basic Settings',
    action: 'basic',
  },
  {
    label: 'Column Settings',
    action: 'columns',
  },
  {
    label: 'District Settings',
    action: 'district',
  },
];

export const STOPS_TYPE = [
  {
    label: 'Equal Number of Features',
    value: 'EQUAL_NUMBER',
  },
  {
    label: 'Equal Size Intervals',
    value: 'EQUAL_INTERVALS',
  },
  {
    label: 'Random',
    value: 'RANDOM',
  },
];

export const SEARCH_TYPE = [
  'Address',
  'County',
  // 'Tract',
  // 'Group',
  // 'Block',
];

export const DISTRICT_ALLOWED_DEVIATION_TYPES = [
  {
    value: 'allowed-deviation-percentage',
    label: 'By Percentage',
  },
  {
    value: 'allowed-deviation-person',
    label: 'By Person Count',
  },
];
export const QUERY_BUILDER_MATH_OPERATIONS = [
  { id: 'PI', display: 'PI' },
  { id: 'sqrt', display: 'sqrt' },
  { id: 'sin', display: 'sin' },
  { id: 'cos', display: 'cos' },
  { id: 'tan', display: 'tan' },
  { id: 'cot', display: 'cot' },
  { id: 'cos', display: 'cos' },
  { id: 'csc', display: 'csc' },
];
