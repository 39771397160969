import React from 'react';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { transformExtent } from 'ol/proj';
import { GeoJSON, MVT } from 'ol/format';
import { createXYZ } from 'ol/tilegrid';
import { containsExtent } from 'ol/extent';
import Feature from 'ol/Feature';
import { intersection, union, difference } from 'polygon-clipping';
import { fromExtent } from 'ol/geom/Polygon';
import MultiPolygon from 'ol/geom/MultiPolygon';

import {
  Fill, Stroke, Style as OlStyle,
} from 'ol/style';

import {
  countyStyle,
  tractStyle,
  groupStyle,
  blockStyle,
  sandboxFillStyle,
  sandboxStrokeStyle,
  highlightBlockStyle,
  districtColoredStyle,
  districtGreyedStyle,
  shadedLayerStyle,
  accessStyle,
  defaultGuideLayerStyle,
} from './EditorMapStyles';

import RedistrictingMap from '../../map/Map';
import BaseLayer, { OpenStreetMap, MapBox, StamenLayer } from '../../map/baseLayer';
import {
  VectorLayer,
  VectorSource,
  Style,
  VectorTileLayer,
  VectorTileSource,
  VectorTileLoaderSource,
} from '../../map/activeLayer';

import DrawLayer, {
  Freehand,
  Square,
  Polygon,
} from '../../map/drawLayer';

import {
  Click,
  MoveEnd,
  Paint,
  Hover,
  Erase,
} from '../../map/events';

import 'ol/ol.css';
import './MapArea.css';

import { VectorTile } from '../../../helper/-mapbox_vector-tile-1.3.1';

import { tileClient } from '../../../requestClient';

import { DEFAULT_GUIDE_LAYERS, BRUSH_STROKES } from '../../../constants';

const { removeGaps, toCamel } = require('../../../helper/district');

const Protobuf = require('../../../helper/pbf');

class EditorMapArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewport: null,
      moveEndDebounce: null,
      redrawDebounce: null,
      zoom: null,
      dataLayer: null,
      actionStack: [],
      actionStackAlt: [],
      rawActionStack: [],
      rawActionStackAlt: [],
      countStack: [],
      pendingChangesHistory: {},
      hoveredCensusArea: null,
      usedBrushes: ['county'],
    };
    this.map = null;
    this.i = 0;
    this.paintedCensusAreas = {};
    this.erasedCensusAreas = {};
    this.districtSourceRef = React.createRef();
    this.countySourceRef = React.createRef();
    this.countyLayerRef = React.createRef();
    this.tractSourceRef = React.createRef();
    this.tractLayerRef = React.createRef();
    this.groupSourceRef = React.createRef();
    this.groupLayerRef = React.createRef();
    this.blockSourceRef = React.createRef();
    this.blockLayerRef = React.createRef();
    this.censusSourceHighlightRef = React.createRef();
    this.censusHighlightLayerRef = React.createRef();
    this.districtSandboxSourceRef = React.createRef();
    this.censusTileDebugRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      viewport: this.getViewport(),
      moveEndDebounce: null,
      redrawDebounce: null,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      chosenTool,
      chosenBrush,
      chosenDistrict,
      districtDetails,
      districtDetailsSuccessFlag,
      calculateDistrictDetailsSuccessToken,
      undoLastActionFlag,
      changedDistrictId,
    } = this.props;

    const { actionStack, pendingChangesHistory } = this.state;

    // initialize District Sandbox
    if (_.get(prevProps, 'chosenDistrict.id') !== _.get(chosenDistrict, 'id')) {
      if (chosenTool !== 'Pointer') {
        // console.log('*** 1 CLEAR/INITIALIZE District Sandbox');
        this.clearDistrictSandbox(() => {
          this.initializeDistrictSandbox();
        });
      } else {
        // console.log('*** 2 CLEAR District Sandbox');
        this.clearDistrictSandbox();
      }
    }

    if (_.get(prevProps, 'chosenTool') !== chosenTool) {
      if (actionStack.length === 0) {
        if (chosenTool !== 'Pointer') {
          this.clearDistrictSandbox(() => {
            this.initializeDistrictSandbox();
          });
        } else {
          this.clearDistrictSandbox();
        }
      }
    }

    if (_.get(prevProps, 'chosenBrush') !== chosenBrush) {
      const { usedBrushes } = this.state;
      const newUsedBrushes = [...usedBrushes];
      if (!usedBrushes.includes(chosenBrush)) {
        newUsedBrushes.push(chosenBrush.id);
        this.setState({
          hoveredCensusArea: null,
          usedBrushes: newUsedBrushes,
        });
      }
    }

    // handle pending changes cancel
    if (districtDetails === null && prevProps.districtDetails !== null) {
      // console.log('*** 4 CANCEL PENDING CHANGES');
      this.cancelPendingChanges();
    }

    // district details have changed
    // note: this change flag is necessary because react is not correctly responding to
    //        deep object changes in districtDetails
    if (calculateDistrictDetailsSuccessToken !== null
      && prevProps.calculateDistrictDetailsSuccessToken === null) {
      // console.log('**** 7 DISTRICT DETAILS CHANGE', districtDetails, this.state.countStack)
      const newPendingChangesHistory = { ...pendingChangesHistory };
      newPendingChangesHistory[calculateDistrictDetailsSuccessToken] = districtDetails;
      this.setState({ pendingChangesHistory: newPendingChangesHistory }, () => {
        this.props.clearCalculateDistrictDetailsSuccessToken();
      });
    }

    // handle pending changes save success
    if (districtDetailsSuccessFlag && !prevProps.districtDetailsSuccessFlag) {
      // console.log('*** 5 PENDING CHANGES SAVE SUCCESS');
      this.handleDistrictSaveSuccess();
      this.props.clearDistrictDetails();
    }

    // handle undo last action
    if (undoLastActionFlag && !prevProps.undoLastActionFlag) {
      // console.log('*** 6 UNDO');
      this.undoLastAction();
      this.props.clearUndoLastActionFlag();
    }

    // check district locked changes
    if (changedDistrictId !== prevProps.changedDistrictId && changedDistrictId !== null) {
      this.redrawDistrictSandbox();
      this.props.clearChangedDistrictId();
    }
  }

  cancelPendingChanges = () => {
    const { chosenTool } = this.props;

    this.clearDistrictSandbox(() => {
      if (chosenTool !== 'Pointer') {
        this.initializeDistrictSandbox();
      }
    });
  }

  handleSetMap = (map) => {
    this.map = map;
  }

  undoLastAction = () => {
    const {
      actionStack, actionStackAlt, rawActionStack, rawActionStackAlt, countStack,
    } = this.state;

    if (rawActionStack.length > 0) {
      const lastRawAction = rawActionStack.pop();
      const lastRawActionAlt = rawActionStackAlt.pop();
      countStack.pop();
      const lastAction = actionStack[actionStack.length - 1];
      const lastActionAlt = actionStackAlt[actionStackAlt.length - 1];

      if (lastRawAction.type === lastAction.type) {
        if (lastAction.hasOwnProperty('add')) {
          lastAction.add = _.difference(lastAction.add, lastRawAction.add);
          lastActionAlt.add = _.difference(lastActionAlt.add, lastRawActionAlt.add);
        } else if (lastAction.hasOwnProperty('remove')) {
          lastAction.remove = _.difference(lastAction.remove, lastRawAction.remove);
          lastActionAlt.remove = _.difference(lastActionAlt.remove, lastRawActionAlt.remove);
        }
      }
      if ((lastAction.hasOwnProperty('add') && lastAction.add.length === 0) || (lastAction.hasOwnProperty('remove') && lastAction.remove.length === 0)) {
        actionStack.pop();
        actionStackAlt.pop();
      }
    }

    this.setState({
      actionStack,
      actionStackAlt,
      rawActionStack,
      rawActionStackAlt,
      countStack,
    }, () => {
      this.redrawDistrictSandbox();
      this.calculatePendingChanges();
    });
  }

  handleDistrictSaveSuccess = () => {
    const districtSource = _.get(this.districtSourceRef, 'current');
    districtSource.refresh();
  }

  clearDistrictSandbox = (cb = null) => {
    const districtSandboxSource = _.get(this.districtSandboxSourceRef, 'current');
    districtSandboxSource.clear();
    this.drawStackClear(cb);
  }

  initializeDistrictSandbox = () => {
    const { chosenDistrict } = this.props;
    const districtSandboxSource = _.get(this.districtSandboxSourceRef, 'current');
    const features = districtSandboxSource.getFeatures();
    const districtId = _.get(chosenDistrict, 'id');

    if (features.length === 0 && districtId !== -1) {
      const color = _.get(this.props, 'chosenDistrict.district_sandbox.color');
      if (districtId) {
        const feature = this.getFeatureFromDistrictIdsAndActions(color, [districtId]);
        districtSandboxSource.addFeature(feature);
      } else {
        const districtSandboxFeature = new Feature({ color });
        districtSandboxFeature.setId(-1);
        districtSandboxSource.addFeature(districtSandboxFeature);
      }
    }
  }

  debouncedRedrawDistrictSandbox = () => {
    clearInterval(this.state.redrawDebounce);
    const timeoutDebounce = setTimeout(() => {
      this.redrawDistrictSandbox();
    }, 300);
    this.setState({
      redrawDebounce: timeoutDebounce,
    });
  }

  redrawDistrictSandbox = () => {
    // const t0 = performance.now();
    const color = _.get(this.props, 'chosenDistrict.district_sandbox.color');
    const districtId = _.get(this.props, 'chosenDistrict.id');
    if (districtId) {
      const districtSandboxSource = _.get(this.districtSandboxSourceRef, 'current');
      if (districtSandboxSource !== null) {
        const feature = this.getFeatureFromDistrictIdsAndActions(color, [districtId]);
        const sandboxFeature = districtSandboxSource.getFeatureById(-1);

        if (sandboxFeature !== null) {
          sandboxFeature.setGeometry(feature.getGeometry());
        }
      } else {
        console.error('redrawDistrictSandbox districtSandboxSource is null'); // eslint-disable-line
      }
    }
    // const t1 = performance.now();
    // console.log('** redraw district sandbox', t1 - t0);
  }

  drawStackClear = (cb) => {
    this.paintedCensusAreas = {};
    this.erasedCensusAreas = {};
    this.setState({
      actionStack: [],
      actionStackAlt: [],
      rawActionStack: [],
      rawActionStackAlt: [],
      countStack: [],
    }, () => {
      if (cb !== null && typeof cb === 'function') {
        cb();
      }
    });
  }

  drawStackPush = (featureCounts = {}, intersectedDistricts = {}, removeFlag = false) => {
    const {
      actionStack, actionStackAlt, rawActionStack, rawActionStackAlt, countStack,
    } = this.state;
    const { chosenBrush } = this.props;

    // push action stack
    const featureIds = Object.keys(featureCounts).reduce((results, key) => {
      results.push(key.split('|')[0]);
      return results;
    }, []) || [];

    const censusIds = Object.keys(featureCounts).reduce((results, key) => {
      results.push(key.split('|')[1]);
      return results;
    }, []) || [];

    const actionId = uuidv4();

    if (featureIds.length > 0) {
      const action = {
        type: chosenBrush.id,
        intersectedDistricts,
        actionId,
      };
      const actionAlt = {
        type: chosenBrush.id,
        intersectedDistricts,
        actionId,
      };
      const count = {
        type: chosenBrush.id,
        intersectedDistricts,
        actionId,
      };
      if (removeFlag) {
        action.remove = featureIds;
        actionAlt.remove = censusIds;
        count.remove = featureCounts;
      } else {
        action.add = featureIds;
        actionAlt.add = censusIds;
        count.add = featureCounts;
      }

      rawActionStack.push({ ...action });
      rawActionStackAlt.push({ ...actionAlt });
      countStack.push(count);
      if (actionStack.length === 0 || actionStack[actionStack.length - 1].type !== chosenBrush.id) {
        actionStack.push(action);
        actionStackAlt.push(actionAlt);
      } else {
        const mergedAction = actionStack[actionStack.length - 1];
        const mergedActionAlt = actionStackAlt[actionStackAlt.length - 1];
        if ((removeFlag && mergedAction.hasOwnProperty('add'))
          || (!removeFlag && mergedAction.hasOwnProperty('remove'))) {
          actionStack.push(action);
          actionStackAlt.push(actionAlt);
        } else if (removeFlag) {
          mergedAction.remove = [...mergedAction.remove, ...featureIds];
          mergedActionAlt.remove = [...mergedActionAlt.remove, ...censusIds];
        } else {
          mergedAction.add = [...mergedAction.add, ...featureIds];
          mergedAction.intersectedDistricts = {
            ...mergedAction.intersectedDistricts, ...intersectedDistricts,
          };
          mergedActionAlt.add = [...mergedActionAlt.add, ...censusIds];
          mergedActionAlt.intersectedDistricts = {
            ...mergedActionAlt.intersectedDistricts, ...intersectedDistricts,
          };
        }
      }
    }

    this.setState({
      actionStack,
      actionStackAlt,
      rawActionStack,
      rawActionStackAlt,
      countStack,
    }, () => {
      this.redrawDistrictSandbox();
      this.calculatePendingChanges(intersectedDistricts);
    });
  }

  sumCountStack = (countStack, origCounts = {}) => {
    const newCounts = { ...origCounts };
    if (countStack.length > 0) {
      countStack.forEach((count) => {
        if (count.hasOwnProperty('add')) {
          Object.keys(count.add).forEach((censusArea) => {
            Object.keys(count.add[censusArea]).forEach((column) => {
              newCounts[column] += count.add[censusArea][column];
            });
          });
        }
        if (count.hasOwnProperty('remove')) {
          Object.keys(count.remove).forEach((censusArea) => {
            Object.keys(count.remove[censusArea]).forEach((column) => {
              newCounts[column] -= count.remove[censusArea][column];
            });
          });
        }
      });
    }
    return newCounts;
  }

  calculatePendingChanges = (intersectedDistricts = {}) => {
    const {
      actionStack, countStack, rawActionStack, pendingChangesHistory,
    } = this.state;
    const { chosenDistrict, districts, chosenPlan } = this.props;

    if (actionStack.length > 0) {
      const actionId = _.get(rawActionStack[rawActionStack.length - 1], 'actionId');

      if (pendingChangesHistory.hasOwnProperty(actionId)) {
        this.props.setDistrictDetails(pendingChangesHistory[actionId], actionId);
      } else {
        const orig = _.get(chosenDistrict, 'settings.counts', {
          total_population: 0,
          total_voters: 0,
          cvap_asian_2017: 0,
          cvap_black_2017: 0,
          cvap_latino_2017: 0,
        });
        const newCounts = this.sumCountStack(countStack, orig);
        const district = { ...chosenDistrict };
        // don't send settings back over the wire
        delete district.settings;
        const details = {
          orig: { counts: orig },
          final: { counts: newCounts },
          district,
          actions: actionStack,
        };

        const districtDetailsArray = [details];

        const unlockedIntersectedDistricts = Object.keys(intersectedDistricts).filter(
          (districtId) => {
            const intersectedDistrict = _.find(districts, { id: parseInt(districtId, 10) });
            return !_.get(intersectedDistrict, 'is_locked', false);
          },
        );
        unlockedIntersectedDistricts.forEach((unlockedIntersectedDistrictId) => {
          const unlockedIntersectedDistrict = _.find(
            districts, { id: parseInt(unlockedIntersectedDistrictId, 10) },
          );
          const unlockedIntersectedOrig = _.get(unlockedIntersectedDistrict, 'settings.counts', {
            total_population: 0,
            total_voters: 0,
            cvap_asian_2017: 0,
            cvap_black_2017: 0,
            cvap_latino_2017: 0,
          });
          if (_.get(unlockedIntersectedDistrict, 'id') !== _.get(chosenDistrict, 'id')) {
            // don't send settings back over the wire
            delete unlockedIntersectedDistrict.settings;
            districtDetailsArray.push({
              orig: { counts: unlockedIntersectedOrig },
              final: { counts: {} },
              district: unlockedIntersectedDistrict,
              actions: [],
              isIntersected: true,
            });
          }
        });

        this.props.setDistrictDetails(districtDetailsArray, actionId);

        // get counts from server if needed
        if (Object.keys(intersectedDistricts).length > 0) {
          const planId = _.get(chosenPlan, 'id');
          const districtId = _.get(chosenDistrict, 'id');
          this.props.calculateDistrictDetails(planId, districtId, districtDetailsArray, actionId);
        }
      }
    } else if (actionStack.length === 0) {
      this.props.setDistrictDetails(null);
    }
  }

  getViewport = () => {
    const viewport = _.get(this.props.chosenPlan, 'viewport');
    if (viewport) {
      return transformExtent(viewport, 'EPSG:4326', 'EPSG:900913');
    }
    return null;
  }

  handleSetZoom = (zoom) => {
    this.setState({ zoom });
  }

  getDataLayerFromZoomLevel = (zoom) => {
    let layerName = 'ballista:block_data';
    if (zoom <= 9) {
      layerName = 'ballista:tract_data';
    } else if (zoom > 9 && zoom <= 11) {
      layerName = 'ballista:group_data';
    }
    return layerName;
  }

  getFilteredFeaturesFromCoords = async (coords, pixel, mapExtent) => {
    let filteredFeatures = [];
    let censusSource = this.countySourceRef.current;
    let censusLayer = this.countyLayerRef.current;
    const brushType = _.get(this.props, 'chosenBrush.id');
    if (brushType === 'tract') {
      censusSource = this.tractSourceRef.current;
      censusLayer = this.tractLayerRef.current;
    } else if (brushType === 'group') {
      censusSource = this.groupSourceRef.current;
      censusLayer = this.groupLayerRef.current;
    } else if (brushType === 'block') {
      censusSource = this.blockSourceRef.current;
      censusLayer = this.blockLayerRef.current;
    }

    // const censusSourceMerged = this.censusSourceMergedRef.current;
    const zoom = Math.floor(this.state.zoom);

    if (censusLayer !== null) {
      filteredFeatures = await censusLayer.getFeatures(pixel).then((features) => {
        const returnFeatures = [];
        // collect singles
        features.forEach((feature) => {
          const properties = feature.getProperties();
          const geom = feature.getGeometry();
          if (_.get(properties, 'single') && _.get(properties, 'z') === zoom
                  && geom && geom.intersectsCoordinate(coords)) {
            returnFeatures.push(feature);
          }
        });

        // collect multiples
        if (features.length > 0 && returnFeatures.length === 0) {
          const extentFeatures = censusSource.getFeaturesInExtent(mapExtent);
          extentFeatures.forEach((feature) => {
            const properties = feature.getProperties();
            if (_.get(properties, 'z') === zoom && features[0].getId() === feature.getId()) {
              returnFeatures.push(feature);
            }
          });
        }

        return returnFeatures;
      });
    }

    return filteredFeatures;
  }

  getFilteredFeaturesFromGeoJSON = (geojson, mapExtent) => {
    let censusSource = this.countySourceRef.current;
    const brushType = _.get(this.props, 'chosenBrush.id');
    if (brushType === 'tract') {
      censusSource = this.tractSourceRef.current;
    } else if (brushType === 'group') {
      censusSource = this.groupSourceRef.current;
    } else if (brushType === 'block') {
      censusSource = this.blockSourceRef.current;
    }

    const zoom = Math.floor(this.state.zoom);

    const geoJsonFeatures = (new GeoJSON()).readFeatures(geojson);
    const extent = geoJsonFeatures[0].getGeometry().getExtent();
    const transformedExtent = transformExtent(extent, 'EPSG:4326', 'EPSG:900913');

    const testGeom = geoJsonFeatures[0].getGeometry();
    const c1 = testGeom.transform('EPSG:4326', 'EPSG:900913').getCoordinates();

    const multipleFeatureIds = {};
    const filteredFeatures = [];
    const singleFeatures = censusSource.getFeaturesInExtent(transformedExtent);
    const singleFilteredFeatures = singleFeatures.filter((feature) => {
      const properties = feature.getProperties();
      const featureZoom = _.get(properties, 'z');
      if (zoom === featureZoom) {
        if (!_.get(properties, 'single')) {
          const c2 = feature.getGeometry().getCoordinates();
          const i1 = intersection(c1, c2);
          if (i1.length > 0) {
            const id = feature.getId();
            multipleFeatureIds[id] = true;
          }
          return false;
        }
        // intersection
        const c2 = feature.getGeometry().getCoordinates();
        const i1 = intersection(c1, c2);
        return i1.length > 0;
      }
      return false;
    });

    if (Object.keys(multipleFeatureIds).length > 0) {
      const extentFeatures = censusSource.getFeaturesInExtent(mapExtent);
      const extentFeaturesMap = extentFeatures.reduce((map, feature) => {
        const newMap = { ...map };
        const id = feature.getId();
        if (multipleFeatureIds.hasOwnProperty(id)) {
          if (!newMap.hasOwnProperty(id)) {
            newMap[id] = [feature];
          } else {
            newMap[id].push(feature);
          }
        }
        return newMap;
      }, {});

      // look through all features we've collected, and if one is not single,
      // collect its siblings
      Object.keys(multipleFeatureIds).forEach((featureId) => {
        if (extentFeaturesMap.hasOwnProperty(featureId)) {
          filteredFeatures.push(...extentFeaturesMap[featureId]);
        }
      });
    }

    return [...filteredFeatures, ...singleFilteredFeatures];
  }

  combineDistrictAndActionIds = (districtIdsArray = []) => {
    const { actionStackAlt } = this.state;
    const { districts } = this.props;
    const returnIds = {};
    districtIdsArray.forEach((districtId) => {
      const districtIds = _.get(_.find(districts, { id: parseInt(districtId, 10) }), 'settings.ids', {});
      Object.keys(districtIds).forEach((censusType) => {
        if (!returnIds.hasOwnProperty(censusType)) {
          returnIds[censusType] = {};
        }
        Object.keys(districtIds[censusType]).forEach((censusId) => {
          returnIds[censusType][censusId] = true;
        });
      });
    });

    if (actionStackAlt.length > 0) {
      actionStackAlt.forEach((action) => {
        const censusType = action.type;
        if (!returnIds.hasOwnProperty(censusType)) {
          returnIds[censusType] = {};
        }
        let actionIds;
        if (action.hasOwnProperty('add')) {
          actionIds = action.add;
        } else if (action.hasOwnProperty('remove')) {
          actionIds = action.remove;
        }
        actionIds.forEach((actionId) => {
          returnIds[censusType][actionId] = true;
        });
      });
    }

    return returnIds;
  }

  getExtentFeaturesMap = (featureIds) => {
    let censusSource;
    const extentFeaturesMap = {};
    const mapExtent = this.map.getView().calculateExtent();
    const censusTypes = Object.keys(featureIds);
    if (censusTypes.length > 0) {
      censusTypes.forEach((censusType) => {
        if (censusType === 'county') {
          censusSource = this.countySourceRef.current;
        } else if (censusType === 'tract') {
          censusSource = this.tractSourceRef.current;
        } else if (censusType === 'group') {
          censusSource = this.groupSourceRef.current;
        } else if (censusType === 'block') {
          censusSource = this.blockSourceRef.current;
        }
        if (censusSource !== null) {
          const extentFeatures = censusSource.getFeaturesInExtent(mapExtent);
          extentFeaturesMap[censusType] = extentFeatures.reduce((map, feature) => {
            const newMap = { ...map };
            const id = feature.getId();
            if (featureIds[censusType].hasOwnProperty(id.split('_')[2])) {
              const geom = feature.getGeometry();
              const coords = geom.getCoordinates();
              if (!newMap.hasOwnProperty(id)) {
                newMap[id] = [coords];
              } else {
                newMap[id].push(coords);
              }
            }
            return newMap;
          }, {});
        }
      });
    }
    return extentFeaturesMap;
  }

  addDistrictFeatures = (districtIds = [], extentFeaturesMap, returnFeature) => {
    const { districts } = this.props;
    const zoom = Math.floor(this.state.zoom);
    const filteredFeatureCoords = [];
    districtIds.forEach((districtId) => {
      const ids = _.get(_.find(districts, { id: parseInt(districtId, 10) }), 'settings.ids', {});
      const censusTypes = Object.keys(ids);
      if (censusTypes.length > 0) {
        censusTypes.forEach((censusType) => {
          Object.keys(ids[censusType]).forEach((censusId) => {
            const featureId = `${zoom}_${censusType}_${censusId}`;
            if (extentFeaturesMap.hasOwnProperty(censusType)
              && extentFeaturesMap[censusType].hasOwnProperty(featureId)) {
              filteredFeatureCoords.push(...extentFeaturesMap[censusType][featureId]);
            }
          });
        });
      }
    });
    if (filteredFeatureCoords.length > 0) {
      const districtGeometry = returnFeature.getGeometry();
      try {
        const u1 = union(...filteredFeatureCoords);
        if (typeof districtGeometry === 'undefined') {
          returnFeature.setGeometry(new MultiPolygon(u1));
        } else {
          districtGeometry.setCoordinates(u1);
        }
      } catch (error) {
        console.error('error in union', error); // eslint-disable-line
      }
    }
    const returnGeometry = returnFeature.getGeometry();
    if (typeof returnGeometry !== 'undefined') {
      returnGeometry.setCoordinates(removeGaps(returnGeometry, null, zoom));
    }
    return returnFeature;
  }

  removeLockedDistrictFeatures = (lockedDistrictIds = [], extentFeaturesMap, returnFeature) => {
    const { districts } = this.props;
    const zoom = Math.floor(this.state.zoom);
    const filteredFeatureCoords = [];
    lockedDistrictIds.forEach((lockedDistrictId) => {
      const ids = _.get(_.find(districts, { id: parseInt(lockedDistrictId, 10) }), 'settings.ids', {});
      const censusTypes = Object.keys(ids);
      if (censusTypes.length > 0) {
        censusTypes.forEach((censusType) => {
          Object.keys(ids[censusType]).forEach((censusId) => {
            const featureId = `${zoom}_${censusType}_${censusId}`;
            if (extentFeaturesMap.hasOwnProperty(censusType)
              && extentFeaturesMap[censusType].hasOwnProperty(featureId)) {
              filteredFeatureCoords.push(...extentFeaturesMap[censusType][featureId]);
            }
          });
        });
      }
    });
    if (filteredFeatureCoords.length > 0) {
      const districtGeometry = returnFeature.getGeometry();
      if (typeof districtGeometry !== 'undefined') {
        const a1 = districtGeometry.getCoordinates();
        try {
          const d1 = difference(a1, ...filteredFeatureCoords);
          districtGeometry.setCoordinates(d1);
        } catch (error) {
          console.error('error in difference', error); // eslint-disable-line
        }
      }
    }
    const returnGeometry = returnFeature.getGeometry();
    if (typeof returnGeometry !== 'undefined') {
      returnGeometry.setCoordinates(removeGaps(returnGeometry, null, zoom));
    }
    return returnFeature;
  }

  addRemoveActionStackFeatures = (extentFeaturesMap, returnFeature) => {
    const zoom = Math.floor(this.state.zoom);
    const { actionStackAlt } = this.state;

    if (actionStackAlt.length > 0) {
      actionStackAlt.forEach((action) => {
        const censusType = _.get(action, 'type');
        const filteredFeatureCoords = [];

        let actionType;
        if (action.hasOwnProperty('add')) {
          actionType = 'add';
        } else if (action.hasOwnProperty('remove')) {
          actionType = 'remove';
        }

        action[actionType].forEach((censusId) => {
          const featureId = `${zoom}_${censusType}_${censusId}`;
          if (extentFeaturesMap.hasOwnProperty(censusType)
            && extentFeaturesMap[censusType].hasOwnProperty(featureId)) {
            filteredFeatureCoords.push(...extentFeaturesMap[censusType][featureId]);
          }
        });

        if (filteredFeatureCoords.length > 0) {
          const districtGeometry = returnFeature.getGeometry();
          let u1;
          if (typeof districtGeometry === 'undefined') {
            try {
              u1 = union(...filteredFeatureCoords);
              returnFeature.setGeometry(new MultiPolygon(u1));
            } catch (error) {
              console.error('error in union', error); // eslint-disable-line
            }
          } else {
            const a1 = districtGeometry.getCoordinates();
            if (actionType === 'add') {
              try {
                u1 = union(a1, ...filteredFeatureCoords);
                districtGeometry.setCoordinates(u1);
              } catch (error) {
                console.error('error in union', error); // eslint-disable-line
              }
            } else if (actionType === 'remove') {
              try {
                const d1 = difference(a1, ...filteredFeatureCoords);
                districtGeometry.setCoordinates(d1);
              } catch (error) {
                console.error('error in difference', error); // eslint-disable-line
              }
            }
          }

          // get out gaps
          const returnGeometry = returnFeature.getGeometry();
          if (typeof returnGeometry !== 'undefined') {
            returnGeometry.setCoordinates(removeGaps(returnGeometry, censusType, zoom));
          }
        }
      });
    }
    return returnFeature;
  }

  getLockedIntersectedDistrictIds = () => {
    const { rawActionStack } = this.state;
    const { districts } = this.props;
    const lockedDistrictIds = {};
    rawActionStack.forEach((action) => {
      const intersectedDistricts = _.get(action, 'intersectedDistricts', {});
      Object.keys(intersectedDistricts).forEach((districtId) => {
        const district = _.find(districts, { id: parseInt(districtId, 10) });
        const isLocked = _.get(district, 'is_locked', false);
        if (isLocked) {
          lockedDistrictIds[districtId] = true;
        }
      });
    });
    return Object.keys(lockedDistrictIds).map(Number);
  }

  getLockedIntersectedCensusTypes = () => {
    const { rawActionStack } = this.state;
    const { districts } = this.props;
    const censusTypes = {};
    rawActionStack.forEach((action) => {
      const intersectedDistricts = _.get(action, 'intersectedDistricts', {});
      Object.keys(intersectedDistricts).forEach((districtId) => {
        const district = _.find(districts, { id: parseInt(districtId, 10) });
        const isLocked = _.get(district, 'is_locked', false);
        if (isLocked) {
          const ids = _.get(district, 'settings.ids', {});
          Object.keys(ids).forEach((censusType) => {
            censusTypes[censusType] = true;
          });
        }
      });
    });
    return censusTypes;
  }

  getFeatureFromDistrictIdsAndActions = (color, districtIds = []) => {
    const { actionStack } = this.state;
    const { districts } = this.props;

    let returnFeature = new Feature({ color });
    returnFeature.setId(-1);
    let featureIds;
    let lockedIntersectedDistrictIds = [];

    if (districtIds.length === 1 && actionStack.length === 0) {
      featureIds = _.get(_.find(districts, { id: parseInt(districtIds[0], 10) }), 'settings.ids', {});
    } else {
      lockedIntersectedDistrictIds = this.getLockedIntersectedDistrictIds();
      featureIds = this.combineDistrictAndActionIds(
        [...districtIds, ...lockedIntersectedDistrictIds],
      );
    }
    const extentFeaturesMap = this.getExtentFeaturesMap(featureIds);
    returnFeature = this.addDistrictFeatures(districtIds, extentFeaturesMap, returnFeature);
    if (actionStack.length > 0) {
      returnFeature = this.addRemoveActionStackFeatures(extentFeaturesMap, returnFeature);
    }
    if (lockedIntersectedDistrictIds.length > 0) {
      returnFeature = this.removeLockedDistrictFeatures(
        lockedIntersectedDistrictIds, extentFeaturesMap, returnFeature,
      );
    }
    return returnFeature;
  }

  handleMoveEnd = (e) => {
    const newViewport = transformExtent(e.map.getView().calculateExtent(), 'EPSG:900913', 'EPSG:4326');

    clearInterval(this.state.moveEndDebounce);
    const nextZoom = e.map.getView().getZoom();
    const prevLayer = this.getDataLayerFromZoomLevel(this.state.zoom);
    const nextLayer = this.getDataLayerFromZoomLevel(nextZoom);

    if (nextLayer !== prevLayer) {
      this.setState({
        dataLayer: nextLayer,
      });
    }

    const timeoutDebounce = setTimeout(() => {
      this.props.updatePlanViewport(this.props.chosenPlan.id, newViewport);
      this.debouncedRedrawDistrictSandbox();
    }, 300);

    this.setState({
      moveEndDebounce: timeoutDebounce,
      zoom: nextZoom,
    });
  }

  handleDrawEnd = (geojson) => {
    const color = _.get(this.props, 'chosenDistrict.district_sandbox.color');

    const features = this.getFilteredFeaturesFromGeoJSON(
      geojson, this.map.getView().calculateExtent(),
    );

    const filteredFeatures = features.reduce((results, feature) => {
      feature.setProperties({ color });
      results.push(feature);
      return results;
    }, []) || [];

    if (filteredFeatures.length > 0) {
      this.addFeaturesToDistrictSandbox(filteredFeatures);
    }
  }

  handleClick = (e) => {
    e.stopPropagation();
    const color = _.get(this.props, 'chosenDistrict.district_sandbox.color');
    const coords = e.map.getCoordinateFromPixel(e.pixel);
    const self = this;

    (async function () {
      const features = await self.getFilteredFeaturesFromCoords(
        coords, e.pixel, e.map.getView().calculateExtent(),
      );

      const filteredFeatures = features.reduce((results, feature) => {
        feature.setProperties({ color });
        results.push(feature);
        return results;
      }, []) || [];

      if (filteredFeatures.length > 0) {
        self.addFeaturesToDistrictSandbox(filteredFeatures);
      }
    }());
  }

  handleEraseClick = (e) => {
    e.stopPropagation();
    const coords = e.map.getCoordinateFromPixel(e.pixel);
    const self = this;
    (async function () {
      const features = await self.getFilteredFeaturesFromCoords(
        coords, e.pixel, e.map.getView().calculateExtent(),
      );
      if (features.length > 0) {
        self.removeFeaturesFromDistrictSandbox(features);
      }
    }());
  }

  handlePaint = (e) => {
    e.stopPropagation();
    const color = _.get(this.props, 'chosenDistrict.district_sandbox.color');
    const coords = e.map.getCoordinateFromPixel(e.pixel);
    const self = this;
    (async function () {
      const features = await self.getFilteredFeaturesFromCoords(
        coords, e.pixel, e.map.getView().calculateExtent(),
      );

      const filteredFeatures = features.reduce((results, feature) => {
        if (!self.isInPaintedCensusAreas(feature)) {
          feature.setProperties({ color });
          results.push(feature);
          return results;
        }
        return results;
      }, []) || [];

      if (filteredFeatures.length > 0) {
        self.addToPaintedCensusAreas(filteredFeatures);
        self.addFeaturesToDistrictSandbox(filteredFeatures);
      }
    }());
  }

  handleErase = (e) => {
    e.stopPropagation();
    const color = _.get(this.props, 'chosenDistrict.district_sandbox.color');
    const coords = e.map.getCoordinateFromPixel(e.pixel);
    const self = this;
    (async function () {
      const features = await self.getFilteredFeaturesFromCoords(
        coords, e.pixel, e.map.getView().calculateExtent(),
      );

      const filteredFeatures = features.reduce((results, feature) => {
        if (!self.isInErasedCensusAreas(feature)) {
          feature.setProperties({ color });
          results.push(feature);
          return results;
        }
        return results;
      }, []) || [];

      if (filteredFeatures.length > 0) {
        self.addToErasedCensusAreas(filteredFeatures);
        self.removeFeaturesFromDistrictSandbox(filteredFeatures);
      }
    }());
  }

  handleHover = (e) => {
    const { hoveredCensusArea } = this.state;
    const { chosenBrush } = this.props;

    let censusLayer = this.countyLayerRef.current;
    if (_.get(chosenBrush, 'id') === 'tract') {
      censusLayer = this.tractLayerRef.current;
    } else if (_.get(chosenBrush, 'id') === 'group') {
      censusLayer = this.groupLayerRef.current;
    } else if (_.get(chosenBrush, 'id') === 'block') {
      censusLayer = this.blockLayerRef.current;
    }
    const highlightLayer = this.censusHighlightLayerRef.current;
    const self = this;

    if (censusLayer !== null) {
      censusLayer.getFeatures(e.pixel).then((features) => {
        if (!features.length && hoveredCensusArea !== null) {
          self.setState({ hoveredCensusArea: null }, () => {
            highlightLayer.changed();
          });
          highlightLayer.changed();
          return;
        }
        const feature = features[0];
        if (!feature) {
          return;
        }
        if (hoveredCensusArea === null || hoveredCensusArea.getId() !== feature.getId()) {
          self.setState({ hoveredCensusArea: feature }, () => {
            highlightLayer.changed();
          });
        }
      });
    }
  }

  addFeaturesToDistrictSandbox = (features) => {
    const { districts, accessGeography } = this.props;
    const districtSandboxSource = _.get(this.districtSandboxSourceRef, 'current');
    const {
      featureCounts,
      intersectedDistricts,
    } = districtSandboxSource.addSandboxFeatures(features, districts, accessGeography);
    if (Object.keys(featureCounts).length > 0) {
      this.drawStackPush(featureCounts, intersectedDistricts);
    }
  }

  removeFeaturesFromDistrictSandbox = (features) => {
    const { districts } = this.props;
    const districtSandboxSource = _.get(this.districtSandboxSourceRef, 'current');
    // const t0 = performance.now();
    const {
      featureCounts,
      intersectedDistricts,
    } = districtSandboxSource.removeSandboxFeatures(features, districts);
    // const t1 = performance.now();
    // console.log(`Call to removeSandboxFeatures took ${t1 - t0} milliseconds.`);
    if (Object.keys(featureCounts).length > 0) {
      this.drawStackPush(featureCounts, intersectedDistricts, true);
    }
  }

  isInPaintedCensusAreas = (feature) => {
    const brushType = _.get(feature.getProperties(), 'census_area_type');
    return _.get(this.paintedCensusAreas, brushType, {}).hasOwnProperty(feature.getId());
  }

  isInErasedCensusAreas = (feature) => {
    const brushType = _.get(feature.getProperties(), 'census_area_type');
    return _.get(this.erasedCensusAreas, brushType, {}).hasOwnProperty(feature.getId());
  }

  addToPaintedCensusAreas = (features = []) => {
    const paintedCensusAreas = { ...this.paintedCensusAreas };
    const erasedCensusAreas = { ...this.erasedCensusAreas };

    features.forEach((feature) => {
      const brushType = _.get(feature.getProperties(), 'census_area_type');
      const featureId = feature.getId();

      // add painted
      if (!paintedCensusAreas.hasOwnProperty(brushType)) {
        paintedCensusAreas[brushType] = {};
      }
      paintedCensusAreas[brushType][featureId] = true;

      // remove erased
      if (erasedCensusAreas.hasOwnProperty(brushType)) {
        if (erasedCensusAreas[brushType].hasOwnProperty(featureId)) {
          delete erasedCensusAreas[brushType][featureId];
        }
        if (Object.keys(erasedCensusAreas[brushType]).length === 0) {
          delete erasedCensusAreas[brushType];
        }
      }
    });

    this.paintedCensusAreas = paintedCensusAreas;
    this.erasedCensusAreas = erasedCensusAreas;
  }

  addToErasedCensusAreas = (features = []) => {
    const paintedCensusAreas = { ...this.paintedCensusAreas };
    const erasedCensusAreas = { ...this.erasedCensusAreas };

    features.forEach((feature) => {
      const brushType = _.get(feature.getProperties(), 'census_area_type');
      const featureId = feature.getId();

      // add erased
      if (!erasedCensusAreas.hasOwnProperty(brushType)) {
        erasedCensusAreas[brushType] = [];
      }
      erasedCensusAreas[brushType][featureId] = true;

      // remove painted
      if (paintedCensusAreas.hasOwnProperty(brushType)) {
        if (paintedCensusAreas[brushType].hasOwnProperty(featureId)) {
          delete paintedCensusAreas[brushType][featureId];
        }
        if (Object.keys(paintedCensusAreas[brushType]).length === 0) {
          delete paintedCensusAreas[brushType];
        }
      }
    });

    this.erasedCensusAreas = erasedCensusAreas;
    this.paintedCensusAreas = paintedCensusAreas;
  }

  checkIfDistrictIsColored = () => _.get(this.props.chosenPlan, 'settings.isDistrictColored', false)

  // getCensusAreaTileUrlFunction = (tileCoord) => {
  //     const geoLayerName = _.get(this.props, 'chosenBrush.layerName');
  //
  //     const url = '/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0'
  //               + `&LAYER=${geoLayerName}&STYLE=`
  //               + `&TILEMATRIX=EPSG:900913:${tileCoord[0]}&TILEMATRIXSET=EPSG:900913`
  //               + `&FORMAT=application/vnd.mapbox-vector-tile&TILECOL=${tileCoord[1]}`
  //               + `&TILEROW=${tileCoord[2]}`
  //               + `&VIEWPARAMS=stusps:${_.get(this.props.chosenPlan, 'state')}`;
  //
  //
  //     return url;
  //   };

  getDistrictMVTTileUrl = (tileCoord) => {
    const { planId } = this.props;
    const [z, x, y] = tileCoord;
    const url = `plan/${planId}/districts/mvt/?z=${z}&y=${y}&x=${x}`;
    return url;
  }

  getAccessMVTTileUrl = (tileCoord) => {
    const { planId } = this.props;
    const [z, x, y] = tileCoord;
    const url = `plan/${planId}/access/mvt/?z=${z}&y=${y}&x=${x}`;
    return url;
  }

  getCensusAreaMVTTileUrl = (tileCoord, brushSize) => {
    const brush = _.find(BRUSH_STROKES, { id: brushSize });
    const geoLayerName = _.get(brush, 'geoserverLayerName');
    const url = '/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0'
              + `&LAYER=${geoLayerName}&STYLE=`
              + `&TILEMATRIX=EPSG:900913:${tileCoord[0]}&TILEMATRIXSET=EPSG:900913`
              + `&FORMAT=application/vnd.mapbox-vector-tile&TILECOL=${tileCoord[1]}`
              + `&TILEROW=${tileCoord[2]}`
              + `&VIEWPARAMS=stusps:${_.get(this.props.chosenPlan, 'state')}`;
    return url;
  }

  getColorFromZoom = (zoom) => {
    if (zoom < 6) {
      return 'rgba(128, 128, 128)'; // gray
    } if (zoom === 6) {
      return 'rgba(255, 0, 0, 0.4)'; // red
    } if (zoom === 7) {
      return 'rgba(255, 165, 0, 0.4)'; // orange
    } if (zoom === 8) {
      return 'rgba(255, 255, 0, 0.4)'; // yellow
    } if (zoom === 9) {
      return 'rgba(0, 128, 0, 0.4)'; // green
    } if (zoom === 10) {
      return 'rgba(144, 238, 144, 0.4)'; // lightgreen
    } if (zoom === 11) {
      return 'rgba(75, 0, 130, 0.4)'; // indigo
    } if (zoom === 12) {
      return 'rgba(238, 130, 238, 0.4)'; // violet
    } if (zoom === 13) {
      return 'rgba(165, 42, 42, 0.4)'; // brown
    } if (zoom === 14) {
      return 'rgba(255, 215, 0, 0.4)'; // gold
    } if (zoom === 15) {
      return 'rgba(255, 0, 255, 0.4)'; // magenta
    } if (zoom === 16) {
      return 'rgba(127, 255, 0, 0.4)'; // chartreuse
    } if (zoom === 17) {
      return 'rgba(0, 255, 255, 0.4)'; // aqua
    } if (zoom === 18) {
      return 'rgba(255, 127, 80, 0.4)'; // coral
    } if (zoom === 19) {
      return 'rgba(255, 105, 180, 0.4)'; // hotpink
    }
    return 'rgba(128, 128, 128)';
  }

  tileDebug = (extent) => {
    const zoom = Math.floor(this.map.getView().getZoom());
    const censusTileDebugSource = this.censusTileDebugRef.current;
    const box = new Feature(fromExtent(extent));
    box.setStyle(
      new OlStyle({
        stroke: new Stroke({ color: this.getColorFromZoom(zoom) }),
        fill: new Fill({ color: [0, 0, 0, 0] }),
      }),
    );
    censusTileDebugSource.addFeature(box);
  }

  getXYZFromCensusAreaUrl = (url) => {
    const urlObj = new URL(url, 'http://example.com');
    const x = parseInt(urlObj.searchParams.get('TILECOL'), 10);
    const y = parseInt(urlObj.searchParams.get('TILEROW'), 10);
    const z = parseInt(urlObj.searchParams.get('TILEMATRIX').split(':')[2], 10);
    return { x, y, z };
  }

  getXYZFromDistrictUrl = (url) => {
    const urlObj = new URL(url, 'http://example.com');
    const x = parseInt(urlObj.searchParams.get('x'), 10);
    const y = parseInt(urlObj.searchParams.get('y'), 10);
    const z = parseInt(urlObj.searchParams.get('z'), 10);
    return { x, y, z };
  }

  // buildCensusAreaFeatureIndex = (loadedTiles) => {
  //   //const t0 = performance.now();
  //
  //   const featureIndex = {};
  //
  //   loadedTiles.forEach(tile => {
  //     const features = tile.getFeatures();
  //     const tileExtent = _.get(tile, 'extent');
  //     features.forEach(feature => {
  //       const featureExtent = feature.getGeometry().getExtent();
  //       // don't add census areas that are entirely within their tile
  //       if(!containsExtent(tileExtent, featureExtent)) {
  //         const properties = feature.getProperties();
  //         const z = _.get(properties, 'z');
  //         const id = _.get(properties, 'id');
  //         const brushType = _.get(properties, 'census_area_type');
  //         if (typeof featureIndex[z] === 'undefined') {
  //           featureIndex[z] = {};
  //         }
  //         if (typeof featureIndex[z][brushType] === 'undefined') {
  //           featureIndex[z][brushType] = {};
  //         }
  //         if (typeof featureIndex[z][brushType][id] === 'undefined') {
  //           featureIndex[z][brushType][id] = [feature];
  //         } else {
  //           featureIndex[z][brushType][id].push(feature);
  //         }
  //       }
  //     })
  //   });
  //   //const t1 = performance.now();
  //
  //   return featureIndex;
  // }

  //  mergeTilesCensusArea = (loadedTiles) => {
  //     //const t0 = performance.now();
  //     const brushType = _.get(this.props, 'chosenBrush.id');
  //     const zoom = Math.floor(this.state.zoom);
  //     const featureIndex = this.buildCensusAreaFeatureIndex(loadedTiles);
  //     const tiles = _.get(featureIndex, `${zoom}.${brushType}`);
  //     const censusSourceMerged = this.censusSourceMergedRef.current;
  //
  //     if (typeof tiles !== 'undefined') {
  //       for (const [key, features] of Object.entries(tiles)) {
  //         if (features.length > 0) {
  //           const mergedFeatureId = `${zoom}_${brushType}_${key}`;
  //           let isNewFlag = false;
  //           let a1 = [];
  //           let mergedFeature = censusSourceMerged.getFeatureById(mergedFeatureId);
  //           if (mergedFeature === null) {
  //               isNewFlag = true;
  //               mergedFeature = new Feature();
  //               mergedFeature.setId(mergedFeatureId);
  //               const properties = features[0].getProperties();
  //               //mergedFeature.setGeometry(features[0].getGeometry());
  //               //mergedFeature.setGeometry(new MultiPolygon());
  //               mergedFeature.setProperties({
  //                 namelsad: _.get(properties, 'namelsad', null),
  //                 stusps: _.get(properties, 'stusps', null),
  //                 total_population: _.get(properties, 'total_population', null),
  //                 id: _.get(properties, 'id', null),
  //                 census_area_type: _.get(properties, 'census_area_type', null),
  //                 census_code_full: _.get(properties, 'census_code_full', null),
  //                 z: _.get(properties, 'z', null),
  //                 color: this.getColorFromZoom(zoom)
  //               });
  //               //if (features.length > 0) {
  //               //  mergedFeature.setProperties(features[0].getProperties());
  //               //}
  //               //mergedFeature = features[0].clone();
  //               //mergedFeature.setId(mergedFeatureId);
  //               censusSourceMerged.addFeature(mergedFeature);
  //           } else {
  //             a1 = mergedFeature.getGeometry().getCoordinates();
  //           }
  //
  //           let mergedGeometry = mergedFeature.getGeometry();
  //
  //           //if (features.length > 1) {
  //             const b1 = [];
  //             features.forEach(feature => {
  //               const geom = feature.getGeometry();
  //               const coords = geom.getCoordinates();
  //               b1.push(coords);
  //             });
  //             try {
  //               const u1 = union(a1, ...b1);
  //
  //               if (typeof mergedGeometry === 'undefined') {
  //                 mergedFeature.setGeometry(new MultiPolygon(u1));
  //               } else {
  //                 mergedGeometry.setCoordinates(u1);
  //               }
  //             } catch (error) {
  //               console.log('** error in union', error);
  //             }
  //
  //             //if (isNewFlag) {
  //             //  censusSourceMerged.addFeature(mergedFeature);
  //             //  mergedFeature.setCoordinates(u1);
  //             //}
  //           // } else if (features.length === 1) {
  // //             // deal with this case
  // //             if (typeof mergedGeometry === 'undefined') {
  // //               const featureGeometry = features[0].getGeometry();
  // //               let coords = featureGeometry.getCoordinates();
  // //               if (featureGeometry.getType() === 'Polygon') {
  // //                 coords = [coords];
  // //               }
  // //               mergedFeature.setGeometry(new MultiPolygon(coords));
  // //             } else {
  // //               const a1 = mergedFeature.getGeometry().getCoordinates();
  // //               const b1 = features[0].getGeometry().getCoordinates();
  // //               const u1 = union(a1, b1);
  // //               mergedGeometry.setCoordinates(u1);
  // //             }
  // //             //if (isNewFlag) {
  // //             //  censusSourceMerged.addFeature(mergedFeature);
  // //             //}
  // //           }
  //           //console.log(`${key}:`, features);
  //         }
  //       }
  //     }
  //     //const t1 = performance.now();
  //     this.debouncedRedrawDistrictSandbox();
  //   }

  // buildDistrictFeatureIndex = (loadedTiles) => {
  //   //const t0 = performance.now();
  //
  //   const featureIndex = {};
  //
  //   loadedTiles.forEach(tile => {
  //     const features = tile.getFeatures();
  //     const tileExtent = _.get(tile, 'extent');
  //     features.forEach(feature => {
  //       //const featureExtent = feature.getGeometry().getExtent();
  //       // don't add census areas that are entirely within their tile
  //       //if(!containsExtent(tileExtent, featureExtent)) {
  //         const properties = feature.getProperties();
  //         const z = _.get(properties, 'z');
  //         const id = _.get(properties, 'id');
  //         //const brushType = _.get(properties, 'census_area_type');
  //         if (typeof featureIndex[z] === 'undefined') {
  //           featureIndex[z] = {};
  //         }
  //         //if (typeof featureIndex[z][brushType] === 'undefined') {
  //         //  featureIndex[z][brushType] = {};
  //         //}
  //         if (typeof featureIndex[z][id] === 'undefined') {
  //           featureIndex[z][id] = [feature];
  //         } else {
  //           featureIndex[z][id].push(feature);
  //         }
  //       //}
  //     })
  //   });
  //   //const t1 = performance.now();
  //
  //   return featureIndex;
  // }

  //   mergeTilesDistrict = (loadedTiles) => {
  //     //const t0 = performance.now();
  //     //const brushType = _.get(this.props, 'chosenBrush.id');
  //     const zoom = Math.floor(this.state.zoom);
  //
  //     const featureIndex = this.buildDistrictFeatureIndex(loadedTiles);
  //     const tiles = _.get(featureIndex, `${zoom}`);
  //     const districtSourceMerged = this.districtSourceMergedRef.current;
  //
  //     if (typeof tiles !== 'undefined') {
  //       for (const [key, features] of Object.entries(tiles)) {
  //         //if (features.length > 0) {
  //           const mergedFeatureId = `${zoom}_district_${key}`;
  //           let isNewFlag = false;
  //           let a1 = [];
  //           let mergedFeature = districtSourceMerged.getFeatureById(mergedFeatureId);
  //           if (mergedFeature === null) {
  //               isNewFlag = true;
  //               mergedFeature = new Feature();
  //               mergedFeature.setId(mergedFeatureId);
  //               const properties = features[0].getProperties();
  //               //mergedFeature.setGeometry(features[0].getGeometry());
  //               //mergedFeature.setGeometry(new MultiPolygon());
  //
  //               mergedFeature.setProperties({
  //                 alias: _.get(properties, 'alias', null),
  //                 id: _.get(properties, 'id', null),
  //                 total_population: _.get(properties, 'total_population', null),
  //                 z: _.get(properties, 'z', null),
  //                 is_initialized: _.get(properties, 'is_initialized', null),
  //                 is_locked: _.get(properties, 'is_locked', null),
  //                 name: _.get(properties, 'name', null),
  //               });
  //               //if (features.length > 0) {
  //               //  mergedFeature.setProperties(features[0].getProperties());
  //               //}
  //               //mergedFeature = features[0].clone();
  //               //mergedFeature.setId(mergedFeatureId);
  //               districtSourceMerged.addFeature(mergedFeature);
  //           } else {
  //             a1 = mergedFeature.getGeometry().getCoordinates();
  //           }
  //
  //           let mergedGeometry = mergedFeature.getGeometry();
  //
  //           //if (features.length > 1) {
  //             const b1 = [];
  //             features.forEach(feature => {
  //               const geom = feature.getGeometry();
  //               const coords = geom.getCoordinates();
  //               b1.push(coords);
  //             });
  //             try {
  //               const u1 = union(a1, ...b1);
  //
  //               if (typeof mergedGeometry === 'undefined') {
  //                 mergedFeature.setGeometry(new MultiPolygon(u1));
  //               } else {
  //                 mergedGeometry.setCoordinates(u1);
  //               }
  //             } catch (error) {
  //               console.log('** error in union', error);
  //             }
  //
  //             //if (isNewFlag) {
  //             //  censusSourceMerged.addFeature(mergedFeature);
  //             //  mergedFeature.setCoordinates(u1);
  //             //}
  //           // } else if (features.length === 1) {
  // //             // deal with this case
  // //             if (typeof mergedGeometry === 'undefined') {
  // //               const featureGeometry = features[0].getGeometry();
  // //               let coords = featureGeometry.getCoordinates();
  // //               if (featureGeometry.getType() === 'Polygon') {
  // //                 coords = [coords];
  // //               }
  // //               mergedFeature.setGeometry(new MultiPolygon(coords));
  // //             } else {
  // //               const a1 = mergedFeature.getGeometry().getCoordinates();
  // //               const b1 = features[0].getGeometry().getCoordinates();
  // //               const u1 = union(a1, b1);
  // //               mergedGeometry.setCoordinates(u1);
  // //             }
  // //             //if (isNewFlag) {
  // //             //  censusSourceMerged.addFeature(mergedFeature);
  // //             //}
  // //           }
  //           //console.log(`${key}:`, features);
  //           //}
  //       }
  //     }
  //
  //     //const t1 = performance.now();
  //     this.debouncedRedrawDistrictSandbox();
  //
  //   }

  tileLoadFunctionCensusArea = (tile, url, brushType) => {
    const self = this;
    const mapDebug = this.map && _.get(this.map.getProperties(), 'debug');
    const { x, y, z } = this.getXYZFromCensusAreaUrl(url);

    tile.setLoader((extent, resolution, projection) => {
      fetch(url).then((response) => {
        response.arrayBuffer().then((data) => {
          const format = tile.getFormat(); // ol/format/MVT configured as source format
          let vt;
          try {
            vt = new VectorTile(new Protobuf(data));
          } catch (error) {
            console.error(error); // eslint-disable-line
          }

          const layer = _.get(vt, `layers[${brushType}]`);
          const featureArray = [];

          if (typeof layer !== 'undefined') {
            for (let i = 0; i < layer.length; i++) {
              const feature = layer.feature(i);
              feature.properties.census_area_type = brushType;
              try {
                const geojson = feature.toGeoJSON(x, y, z);
                featureArray.push(geojson);
              } catch (error) {
                console.error(error); // eslint-disable-line
              }
            }
          }

          const collection = {
            type: 'FeatureCollection',
            features: featureArray,
          };

          const features = format.readFeatures(collection, {
            extent,
            featureProjection: projection,
            dataProjection: 'EPSG:4326',
          });

          features.forEach((feature) => {
            const featureGeometry = feature.getGeometry();
            const featureExtent = featureGeometry.getExtent();
            const properties = feature.getProperties();
            if (containsExtent(extent, featureExtent)) {
              feature.setProperties({ single: true });
            }
            const geometryType = featureGeometry.getType();
            if (geometryType === 'Polygon') {
              const coords = featureGeometry.getCoordinates();
              feature.setGeometry(new MultiPolygon([coords]));
            }

            feature.setProperties({ z });
            feature.setId(`${z}_${brushType}_${_.get(properties, 'census_code_full')}`);
          });

          if (mapDebug) {
            self.tileDebug(extent);
          }
          tile.setFeatures(features);
          self.debouncedRedrawDistrictSandbox();
        });
      });
    });
  }

  tileLoadFunctionAccess = (tile, url) => {
    tile.setLoader((extent, resolution, projection) => {
      tileClient.get(url, { params: {}, headers: { Accept: '*/*' } })
        .then((response) => {
          const { data } = response;
          const format = tile.getFormat(); // ol/format/MVT configured as source format
          const features = format.readFeatures(data, {
            extent,
            featureProjection: projection,
          });
          tile.setFeatures(features);
        });
    });
  }

  tileLoadFunctionDistrict = (tile, url) => {
    const { districts } = this.props;
    const { x, y, z } = this.getXYZFromDistrictUrl(url);

    tile.setLoader((extent, resolution, projection) => {
      tileClient.get(url, { params: {}, headers: { Accept: '*/*' } })
        .then((response) => {
          const { data } = response;

          const format = tile.getFormat(); // ol/format/MVT configured as source format
          let vt;
          try {
            vt = new VectorTile(new Protobuf(data));
          } catch (error) {
            console.error('district VectorTile error', error);  // eslint-disable-line
          }
          const layer = _.get(vt, 'layers.default');
          const featureArray = [];

          if (typeof layer !== 'undefined') {
            for (let i = 0; i < layer.length; i++) {
              const feature = layer.feature(i);
              try {
                const geojson = feature.toGeoJSON(x, y, z);
                featureArray.push(geojson);
              } catch (error) {
                console.error(error); // eslint-disable-line
              }
            }
          }

          const collection = {
            type: 'FeatureCollection',
            features: featureArray,
          };

          const features = format.readFeatures(collection, {
            extent,
            featureProjection: projection,
            dataProjection: 'EPSG:4326',
          });

          features.forEach((feature) => {
            const featureGeometry = feature.getGeometry();
            const featureExtent = featureGeometry.getExtent();
            const properties = feature.getProperties();
            if (containsExtent(extent, featureExtent)) {
              feature.setProperties({ single: true });
              const geometryType = featureGeometry.getType();
              if (geometryType === 'Polygon') {
                const coords = featureGeometry.getCoordinates();
                feature.setGeometry(new MultiPolygon([coords]));
              }
            }
            const districtId = _.get(properties, 'id');
            const district = _.find(districts, { id: districtId });
            const color = _.get(district, 'district_sandbox.color');
            feature.setProperties({ z, color });
            feature.setId(`${z}_district_${districtId}`);
          });

          tile.setFeatures(features);
        });
    });
  }

  // getCensusAreaTileUrl = () => {
  //   const geoLayerName = _.get(this.props, 'chosenBrush.layerName');
  //   /*
  //   const url = '/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0'
  //             + `&LAYER=${geoLayerName}&STYLE=`
  //             + `&TILEMATRIX=EPSG:900913:${tileCoord[0]}&TILEMATRIXSET=EPSG:900913`
  //             + `&FORMAT=application/vnd.mapbox-vector-tile&TILECOL=${tileCoord[1]}`
  //             + `&TILEROW=${tileCoord[2]}`
  //             + `&VIEWPARAMS=stusps:${_.get(this.props.chosenPlan, 'state')}`;
  //
  //   const url = '/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0'
  //             + `&LAYER=${geoLayerName}&STYLE=`
  //             + `&TILEMATRIX=EPSG:900913:${tileCoord[0]}&TILEMATRIXSET=EPSG:900913`
  //             + `&FORMAT=application/json;type=geojson&TILECOL=${tileCoord[1]}`
  //             + `&TILEROW=${tileCoord[2]}`
  //             + `&VIEWPARAMS=stusps:${_.get(this.props.chosenPlan, 'state')}`;
  //   */
  //
  //  const url = `census/${geoLayerName}/?stusps=${_.get(this.props.chosenPlan, 'state')}`;
  // //const x = tileCoord[2];
  // //const z = tileCoord[0];
  // //const y = Math.pow(2, z) - tileCoord[1] - 1;
  // //const ymax = 1 << z;
  // //const y = ymax - tileCoord[2] - 1;
  // //const url = `${process.env.REACT_APP_API_URL}api/v1/census/censuscounty?tile=${z}/${x}/${y}`
  // //          + `&stusps=${_.get(this.props.chosenPlan, 'state')}`;
  // //console.log(url)
  //  return url;
  // };

  getShadedLayerTileUrlFunction = (tileCoord) => {
    const shadedLayerName = this.getDataLayerFromZoomLevel(this.state.zoom);
    const numeratorColumn = this.props.activeShadedLayer.numerator.column_name;
    const numeratorModel = _.get(this.props.activeShadedLayer.numerator, `geographic_hierarchy_to_table.${shadedLayerName}`);
    const denominatorColumn = this.props.activeShadedLayer.denominator.column_name;
    const denominatorModel = _.get(this.props.activeShadedLayer.denominator, `geographic_hierarchy_to_table.${shadedLayerName}`);

    const url = '/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0'
            + `&LAYER=${shadedLayerName}&STYLE=`
            + `&TILEMATRIX=EPSG:900913:${tileCoord[0]}&TILEMATRIXSET=EPSG:900913`
            + `&FORMAT=application/vnd.mapbox-vector-tile&TILECOL=${tileCoord[1]}`
            + `&TILEROW=${tileCoord[2]}`
            + `&VIEWPARAMS=stusps:${_.get(this.props.chosenPlan, 'state')};`
            + `numerator_column:${numeratorColumn};`
            + `denominator_column:${denominatorColumn};`
            + `num_model:${numeratorModel};`
            + `denom_model:${denominatorModel}`;

    return url;
  };

  getDefaultGuideLayerTileUrlFunction = (tileCoord) => {
    const defaultGuideLayerName = (_.get(this.props, 'chosenPlan.settings.defaultGuideLayer', '') || '').toLowerCase();
    const stroke = _.find(DEFAULT_GUIDE_LAYERS, { id: defaultGuideLayerName });
    const layerName = _.get(stroke, 'geoserverLayerName') || _.get(stroke, 'layerName');

    const url = '/geoserver/gwc/service/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0'
              + `&LAYER=${layerName}&STYLE=`
              + `&TILEMATRIX=EPSG:900913:${tileCoord[0]}&TILEMATRIXSET=EPSG:900913`
              + `&FORMAT=application/vnd.mapbox-vector-tile&TILECOL=${tileCoord[1]}`
              + `&TILEROW=${tileCoord[2]}`
              + `&VIEWPARAMS=stusps:${_.get(this.props.chosenPlan, 'state')}`;

    return url;
  };

  formatCensusCodeFull = (censusAreaType, censusCodeFull) => {
    const censusCodeFullString = censusCodeFull ? censusCodeFull.toString() : '';
    let countyCode = '';
    let tractCode = '';
    let groupCode = '';
    let blockCode = '';

    if (censusAreaType === 'tract') {
      tractCode = censusCodeFullString.slice(-6);
      countyCode = censusCodeFullString.slice(-9, -6);
      return (`${parseInt(countyCode, 10)} ${parseInt(tractCode, 10)}`);
    } if (censusAreaType === 'group') {
      groupCode = censusCodeFullString.slice(-1);
      tractCode = censusCodeFullString.slice(-7, -1);
      countyCode = censusCodeFullString.slice(-10, -7);
      return (`${parseInt(countyCode, 10)} ${parseInt(tractCode, 10)} ${parseInt(groupCode, 10)}`);
    } if (censusAreaType === 'block') {
      blockCode = censusCodeFullString.slice(-3);
      groupCode = censusCodeFullString.slice(-4, -3);
      tractCode = censusCodeFullString.slice(-10, -4);
      countyCode = censusCodeFullString.slice(-13, -10);
      return (`${parseInt(countyCode, 10)} ${parseInt(tractCode, 10)} ${parseInt(groupCode, 10)} ${parseInt(blockCode, 10)}`);
    }
    return censusCodeFull;
  }

  renderBaseLayer = () => {
    const mapSource = _.get(this.props.chosenPlan, 'base_layer.map_source');
    switch (mapSource) {
      case 'MapType.OPENSTREET':
        return <OpenStreetMap />;
      case 'MapType.STAMEN':
        return (
          <StamenLayer
            baseURL={_.get(this.props.chosenPlan, 'base_layer.configuration.base_url')}
          />
        );
      case 'MapType.GMAP':
        return null;
      case 'MapType.MAPBOX':
        return (
          <MapBox
            baseURL={_.get(this.props.chosenPlan, 'base_layer.configuration.base_url')}
            accessToken={_.get(this.props.chosenPlan, 'base_layer.configuration.access_token')}
          />
        );
      default:
        return <OpenStreetMap />;
    }
  };

  render = () => {
    const { hoveredCensusArea, usedBrushes } = this.state;
    const {
      chosenBrush, chosenTool, chosenDistrict, accessGeography,
    } = this.props;

    const defaultGuideLayer = _.get(this.props, 'chosenPlan.settings.defaultGuideLayer');

    const mapDebug = this.map && _.get(this.map.getProperties(), 'debug');

    let censusHighlightSource = null;
    let censusLayer = this.countyLayerRef.current;
    let censusLayerName;
    let censusHighlightSourceName;
    if (_.get(chosenBrush, 'id') === 'tract') {
      censusLayer = this.tractLayerRef.current;
    } else if (_.get(chosenBrush, 'id') === 'group') {
      censusLayer = this.groupLayerRef.current;
    } else if (_.get(chosenBrush, 'id') === 'block') {
      censusLayer = this.blockLayerRef.current;
    }
    if (censusLayer !== null) {
      censusHighlightSource = censusLayer.getSource();
      censusLayerName = censusLayer.get('layerName');
      censusHighlightSourceName = censusHighlightSource.get('layerName');
    }

    let hoveredCensusAreaProperties;
    let hoveredCensusAreaId;
    if (hoveredCensusArea !== null) {
      hoveredCensusAreaProperties = toCamel(hoveredCensusArea.getProperties());
      hoveredCensusAreaId = hoveredCensusArea.getId();
    }

    const lockedIntersectedCensusTypes = this.getLockedIntersectedCensusTypes();

    const renderTracts = usedBrushes.includes('tract') || (chosenTool !== 'Pointer' && (Object.keys(_.get(chosenDistrict, 'settings.ids.tract', [])).length > 0)) || lockedIntersectedCensusTypes.hasOwnProperty('tract');
    const showTracts = (_.get(chosenBrush, 'id') === 'tract');

    const renderGroups = usedBrushes.includes('group') || (chosenTool !== 'Pointer' && (Object.keys(_.get(chosenDistrict, 'settings.ids.group', [])).length > 0)) || lockedIntersectedCensusTypes.hasOwnProperty('group');
    const showGroups = (_.get(chosenBrush, 'id') === 'group');

    const renderBlocks = usedBrushes.includes('block') || (chosenTool !== 'Pointer' && (Object.keys(_.get(chosenDistrict, 'settings.ids.block', [])).length > 0)) || lockedIntersectedCensusTypes.hasOwnProperty('block');
    const showBlocks = (_.get(chosenBrush, 'id') === 'block');

    // <VectorLayer
    //   title="CENSUS_AREA"
    //   committedChanges={this.props.chosenBrush.layerName}
    // >
    //   <VectorSourceTileStrategy
    //     url={this.getCensusAreaTileUrl()}
    //     format={new GeoJSON()}
    //     tileGrid={createXYZ({ maxZoom: 19 })}
    //     ref={this.censusSourceRef}
    //     brushType={_.get(this.props, 'chosenBrush.id')}
    //   />
    //   <Style
    //     featureStyle={blockStyle}
    //   />
    // </VectorLayer>

    // <VectorTileLayer
    //   title="CENSUS_AREA"
    //   committedChanges={this.props.chosenBrush.layerName}
    // >
    //   <VectorTileSource
    //     tileGrid={createXYZ({ maxZoom: 19 })}
    //     format={new MVT()}
    //     key={this.props.chosenBrush.layerName}
    //     tileUrlFunction={(coords) => this.getCensusAreaTileUrlFunction(coords)}
    //   />
    //   <Style
    //     featureStyle={blockStyle}
    //   />
    // </VectorTileLayer>

    return (
      <RedistrictingMap
        className="editor-map"
        projection="EPSG:900913"
        viewport={this.state.viewport}
        setMap={(map) => { this.handleSetMap(map); }}
        setZoom={this.handleSetZoom}
        mouseoutHandler={() => {
          this.setState({ hoveredCensusArea: null });
        }}
      >
        <BaseLayer>
          {this.renderBaseLayer()}
        </BaseLayer>

        { this.props.isInteractive
          && <MoveEnd handler={this.handleMoveEnd} />}
        { this.props.isInteractive
            && ['Paint', 'Click'].includes(this.props.chosenTool)
            && <Click handler={this.handleClick} />}
        { this.props.isInteractive
            && this.props.chosenTool === 'Paint'
            && <Paint handler={this.handlePaint} />}
        { this.props.isInteractive
            && this.props.chosenTool === 'Erase'
            && <Erase handler={this.handleErase} />}
        { this.props.isInteractive
            && this.props.chosenTool === 'Erase'
            && <Click handler={this.handleEraseClick} />}
        { this.props.isInteractive
            && ['Erase', 'Paint', 'Click', 'Pointer'].includes(this.props.chosenTool)
            && <Hover handler={this.handleHover} />}

        { renderBlocks
          && (
          <VectorTileLayer
            title="CENSUS_AREA_BLOCK"
            ref={this.blockLayerRef}
            declutter
            show={showBlocks}
            committedChanges={{ showBlocks }}
          >
            <VectorTileLoaderSource
              title="BLOCK_SOURCE"
              tileGrid={createXYZ({ maxZoom: 19 })}
              ref={this.blockSourceRef}
              key="censusarea_block"
              tileUrlFunction={(coords) => this.getCensusAreaMVTTileUrl(coords, 'block')}
              tileLoadFunction={(tile, url) => this.tileLoadFunctionCensusArea(tile, url, 'block')}
            />
            <Style
              featureStyle={blockStyle}
            />
          </VectorTileLayer>
          )}

        { renderGroups
          && (
          <VectorTileLayer
            title="CENSUS_AREA_GROUP"
            ref={this.groupLayerRef}
            declutter
            show={showGroups}
            committedChanges={{ showGroups }}
          >
            <VectorTileLoaderSource
              title="GROUP_SOURCE"
              tileGrid={createXYZ({ maxZoom: 19 })}
              ref={this.groupSourceRef}
              key="censusarea_group"
              tileUrlFunction={(coords) => this.getCensusAreaMVTTileUrl(coords, 'group')}
              tileLoadFunction={(tile, url) => this.tileLoadFunctionCensusArea(tile, url, 'group')}
            />
            <Style
              featureStyle={groupStyle}
            />
          </VectorTileLayer>
          )}

        { renderTracts
          && (
          <VectorTileLayer
            title="CENSUS_AREA_TRACT"
            ref={this.tractLayerRef}
            declutter
            show={showTracts}
            committedChanges={{ showTracts }}
          >
            <VectorTileLoaderSource
              title="TRACT_SOURCE"
              tileGrid={createXYZ({ maxZoom: 19 })}
              ref={this.tractSourceRef}
              key="censusarea_tract"
              tileUrlFunction={(coords) => this.getCensusAreaMVTTileUrl(coords, 'tract')}
              tileLoadFunction={(tile, url) => this.tileLoadFunctionCensusArea(tile, url, 'tract')}
            />
            <Style
              featureStyle={tractStyle}
            />
          </VectorTileLayer>
          )}

        <VectorTileLayer
          title="CENSUS_AREA_COUNTY"
          ref={this.countyLayerRef}
          declutter
        >
          <VectorTileLoaderSource
            title="COUNTY_SOURCE"
            tileGrid={createXYZ({ maxZoom: 19 })}
            ref={this.countySourceRef}
            key="censusarea_county"
            tileUrlFunction={(coords) => this.getCensusAreaMVTTileUrl(coords, 'county')}
            tileLoadFunction={(tile, url) => this.tileLoadFunctionCensusArea(tile, url, 'county')}
          />
          <Style
            featureStyle={countyStyle}
          />
        </VectorTileLayer>

        <VectorTileLayer
          title="CENSUS_AREA_HIGHLIGHT"
          source={censusHighlightSource}
          committedChanges={{ censusHighlightSourceName, hoveredCensusAreaId, censusLayerName }}
          ref={this.censusHighlightLayerRef}
          renderMode="vector"
          declutter
        >
          <Style
            featureStyle={
              (feature) => {
                if (hoveredCensusArea !== null
                  && hoveredCensusArea.getId() === feature.getId()) {
                  return highlightBlockStyle;
                }
                return null;
              }
            }
          />
        </VectorTileLayer>

        { mapDebug && (
        <VectorLayer
          title="CENSUS_TILE_DEBUG"
        >
          <VectorSource
            format={new GeoJSON()}
            tileGrid={createXYZ({ maxZoom: 19 })}
            ref={this.censusTileDebugRef}
          />
        </VectorLayer>
        )}

        {
        // <VectorLayer
        //   title="CENSUS_AREA_MERGED"
        //   visible={false}
        // >
        //   <VectorSource
        //     format={new GeoJSON()}
        //     tileGrid={createXYZ({ maxZoom: 19 })}
        //     ref={this.censusSourceMergedRef}
        //   />
        //   <Style
        //     featureStyle={mergedBlockStyle}
        //   />
        // </VectorLayer>

        // <VectorLayer
//           title="CENSUS_AREA_HIGHLIGHT"
//         >
//           <VectorSource
//             format={new GeoJSON()}
//             tileGrid={createXYZ({ maxZoom: 19 })}
//             ref={this.censusSourceHighlightRef}
//           />
//           <Style
//             featureStyle={hoverBlockStyle}
//           />
//         </VectorLayer>

//            getFeatureFromDistrictIdsArray = (color, districtIdsArray=[], mapExtent) => {
        }

        {
          //            mergeTileFunction={this.mergeTilesDistrict}
        //
        // <VectorLayer
        //   title="DISTRICT_LAYER_MERGED"
        //   visible={false}
        // >
        //   <VectorSource
        //     format={new GeoJSON()}
        //     tileGrid={createXYZ({ maxZoom: 19 })}
        //     ref={this.districtSourceMergedRef}
        //   />
        //   <Style
        //     featureStyle={mergedBlockStyle}
        //   />
        // </VectorLayer>

        // <VectorLayer
//           title="DISTRICT_LAYER"
//           committedChanges={this.props.committedChanges}
//         >
//           <VectorSource
//             url={`/plan/${this.props.planId}/districts/geom/`}
//             format={new GeoJSON()}
//             ref={this.districtSourceRef}
//           />
//           <Style
//             featureStyle={this.checkIfDistrictIsColored()
//               ? districtColoredStyle
//               : districtGreyedStyle}
//           />
//         </VectorLayer>

        // <VectorLayer title="UNASSIGNED_LAYER" committedChanges={this.props.committedChanges}>
        //  <VectorSource
        //    url={`/plan/${this.props.planId}/unassigned/geom/`}
        //    format={new GeoJSON()}
        //  />
        //  <Style
        //    featureStyle={unassignedDistrictStyle}
        //  />
        // </VectorLayer>
        }

        {_.get(this.props.chosenPlan, 'settings.isShadedLayerActive', false)
          && this.props.activeShadedLayer
          && (
            <VectorTileLayer title="SHADED_LAYER" commitedChanges={this.props.committedChanges}>
              <VectorTileSource
                tileGrid={createXYZ({ maxZoom: 19 })}
                format={new MVT()}
                tileUrlFunction={(coords) => this.getShadedLayerTileUrlFunction(coords)}
                shadedLayerId={this.props.activeShadedLayer.id}
                dataLayer={this.state.dataLayer}
              />
              <Style
                featureStyle={
                      (feature) => {
                        if (feature.get('statecode') === this.props.chosenPlan.state) {
                          return shadedLayerStyle(feature, this.props.activeShadedLayer);
                        }
                        return null;
                      }
                    }
              />
            </VectorTileLayer>
          )}

        {
          _.filter(
            _.get(this.props, 'publishedGuideLayers', []), { settings: { visible: true } },
          ).map((guideLayer) => (
            <VectorLayer
              key={`GUIDE_LAYER_${_.get(guideLayer, 'guide_layer.id')}`}
              title={`GUIDE_LAYER_${_.get(guideLayer, 'guide_layer.id')}`}
              committedChanges={this.props.committedChanges}
            >
              <VectorSource
                url={`/plan/${this.props.chosenPlan.id}/guide-layer/${_.get(guideLayer, 'guide_layer.id')}/geom/`}
                format={new GeoJSON()}
              />
            </VectorLayer>
          ))
        }

        { defaultGuideLayer && (
        <VectorTileLayer
          title="DEFAULT_GUIDE_LAYER"
          committedChanges={defaultGuideLayer}
        >
          <VectorTileSource
            tileGrid={createXYZ({ maxZoom: 19 })}
            format={new MVT()}
            key={`defaultGuideLayer_${defaultGuideLayer}`}
            tileUrlFunction={(coords) => this.getDefaultGuideLayerTileUrlFunction(coords)}
          />
          <Style
            featureStyle={defaultGuideLayerStyle}
          />
        </VectorTileLayer>
        )}

        <VectorTileLayer
          title="DISTRICT_LAYER"
          committedChanges={this.props.committedChanges}
        >
          <VectorTileLoaderSource
            tileGrid={createXYZ({ maxZoom: 19 })}
            ref={this.districtSourceRef}
            key="district_source"
            tileUrlFunction={(coords) => this.getDistrictMVTTileUrl(coords)}
            tileLoadFunction={this.tileLoadFunctionDistrict}
          />
          <Style
            featureStyle={this.checkIfDistrictIsColored()
              ? districtColoredStyle
              : districtGreyedStyle}
          />
        </VectorTileLayer>

        <VectorLayer title="DISTRICT_SANDBOX_LAYER" committedChanges={this.props.committedChanges}>
          <VectorSource
            format={new GeoJSON()}
            ref={this.districtSandboxSourceRef}
            url={null}
          />
          <Style fillOpacity={0.4} fill={sandboxFillStyle} stroke={sandboxStrokeStyle} />
        </VectorLayer>

        { accessGeography && (
        <VectorTileLayer
          title="ACCESS_LAYER"
          committedChanges={this.props.committedChanges}
        >
          <VectorTileSource
            tileGrid={createXYZ({ maxZoom: 19 })}
            key="access_source"
            format={new MVT()}
            tileUrlFunction={(coords) => this.getAccessMVTTileUrl(coords)}
            tileLoadFunction={this.tileLoadFunctionAccess}
          />
          <Style
            featureStyle={accessStyle}
          />
        </VectorTileLayer>
        )}

        { this.props.isInteractive
            && (
            <DrawLayer>
              { this.props.chosenTool === 'Draw' && <Polygon onDrawEnd={this.handleDrawEnd} /> }
              { this.props.chosenTool === 'Square' && <Square onDrawEnd={this.handleDrawEnd} /> }
              { this.props.chosenTool === 'Freehand' && <Freehand onDrawEnd={this.handleDrawEnd} /> }
            </DrawLayer>
            )}

        { hoveredCensusArea
              && (
              <div className="popover">
                { _.get(hoveredCensusAreaProperties, 'namelsad')
                  ? <div className="title">{ _.get(hoveredCensusAreaProperties, 'namelsad') }</div>
                  : (
                    <div className="title">
                      { _.get(hoveredCensusAreaProperties, 'stusps') }
                      {' '}
                      <span className="type">{ _.get(hoveredCensusAreaProperties, 'censusAreaType') }</span>
                      {' '}
                      { this.formatCensusCodeFull(_.get(hoveredCensusAreaProperties, 'censusAreaType'), _.get(hoveredCensusAreaProperties, 'censusCodeFull')) }
                    </div>
                  )}
                <div className="data-table">
                  <div>
                    <span className="table-header">Total Pop</span>
                    <span className="bar-cont"><span className="bar" style={{ '--data-x': '100%' }} /></span>
                    <span className="count">{ _.get(hoveredCensusAreaProperties, 'totalPopulation') }</span>
                  </div>
                  <div>
                    <span className="table-header">Total Voters</span>
                    <span className="bar-cont"><span className="bar" style={{ '--data-x': `${Math.floor((_.get(hoveredCensusAreaProperties, 'totalVoters') / _.get(hoveredCensusAreaProperties, 'totalPopulation')) * 100)}%` }} /></span>
                    <span className="count">{ _.get(hoveredCensusAreaProperties, 'totalVoters') }</span>
                  </div>
                  <div>
                    <span className="table-header">Asian</span>
                    <span className="bar-cont"><span className="bar" style={{ '--data-x': `${Math.floor((_.get(hoveredCensusAreaProperties, 'cvapAsian2017') / _.get(hoveredCensusAreaProperties, 'totalPopulation')) * 100)}%` }} /></span>
                    <span className="count">{ _.get(hoveredCensusAreaProperties, 'cvapAsian2017') }</span>
                  </div>
                  <div>
                    <span className="table-header">Black</span>
                    <span className="bar-cont"><span className="bar" style={{ '--data-x': `${Math.floor((_.get(hoveredCensusAreaProperties, 'cvapBlack2017') / _.get(hoveredCensusAreaProperties, 'totalPopulation')) * 100)}%` }} /></span>
                    <span className="count">{ _.get(hoveredCensusAreaProperties, 'cvapBlack2017') }</span>
                  </div>
                  <div>
                    <span className="table-header">Latino</span>
                    <span className="bar-cont"><span className="bar" style={{ '--data-x': `${Math.floor((_.get(hoveredCensusAreaProperties, 'cvapLatino2017') / _.get(hoveredCensusAreaProperties, 'totalPopulation')) * 100)}%` }} /></span>
                    <span className="count">{ _.get(hoveredCensusAreaProperties, 'cvapLatino2017') }</span>
                  </div>
                </div>

              </div>
              )}

      </RedistrictingMap>
    );
  }
}

EditorMapArea.propTypes = {
  chosenTool: PropTypes.any.isRequired,
  committedChanges: PropTypes.number.isRequired,
  activeShadedLayer: PropTypes.object,
  chosenPlan: PropTypes.object.isRequired,
  planId: PropTypes.any.isRequired,
  chosenBrush: PropTypes.object.isRequired,
  chosenDistrict: PropTypes.object,
  updatePlanViewport: PropTypes.func.isRequired,
  isInteractive: PropTypes.bool.isRequired,
  districts: PropTypes.array,
  setDistrictDetails: PropTypes.func.isRequired,
  calculateDistrictDetails: PropTypes.func.isRequired,
  clearChangedDistrictId: PropTypes.func.isRequired,
  clearUndoLastActionFlag: PropTypes.func.isRequired,
  changedDistrictId: PropTypes.string,
  undoLastActionFlag: PropTypes.bool,
  clearDistrictDetails: PropTypes.func.isRequired,
  districtDetailsSuccessFlag: PropTypes.bool,
  calculateDistrictDetailsSuccessToken: PropTypes.string,
  districtDetails: PropTypes.array,
  clearCalculateDistrictDetailsSuccessToken: PropTypes.func.isRequired,
  accessGeography: PropTypes.object,
};

EditorMapArea.defaultProps = {
  activeShadedLayer: null,
  chosenDistrict: null,
  districts: [],
  changedDistrictId: null,
  undoLastActionFlag: false,
  districtDetailsSuccessFlag: false,
  calculateDistrictDetailsSuccessToken: null,
  districtDetails: [],
  accessGeography: null,
};

export default EditorMapArea;
