import _ from 'lodash';

const defaultState = {
  folders: [],
};

const FolderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'BALLISTA_LOGOUT': {
      return defaultState;
    }
    case 'PLAN_GET_PLANS_SUCCESS': {
      const folders = _.get(action, 'payload.data.folders');
      const sortedFolders = _.sortBy(folders, ['lower_name', 'id']);

      return {
        ...state,
        folders: sortedFolders,
      };
    }
    case 'FOLDER_CREATE_FOLDER_SUCCESS': {
      const createdFolder = _.get(action, 'payload.data');
      const existingFolders = [...state.folders];
      existingFolders.push(createdFolder);
      const sortedFolders = _.sortBy(existingFolders, ['lower_name', 'id']);

      return {
        ...state,
        folders: sortedFolders,
      };
    }
    case 'FOLDER_UPDATE_FOLDER_SUCCESS': {
      const folders = [...state.folders];
      const newFolder = _.get(action, 'payload.data');
      folders.splice(
        _.findIndex(folders, { id: _.get(newFolder, 'id') }), 1, newFolder,
      );
      const sortedFolders = _.sortBy(folders, ['lower_name', 'id']);

      return {
        ...state,
        folders: sortedFolders,
      };
    }
    case 'FOLDER_DELETE_FOLDER_SUCCESS': {
      const folderId = _.get(action, 'meta.previousAction.folderId');
      const folders = _.clone(_.get(state, 'folders'));
      _.remove(folders, { id: folderId });

      return {
        ...state,
        folders,
      };
    }
    default:
      return state;
  }
};

export default FolderReducer;
