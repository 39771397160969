import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { IMPORTED_DISTRICT_MENU_LIST } from '../../constants';

const ImportedDistrictMenu = (props) => (
  <section className="imported-district-menu modal-menu">
    <nav>
      <ul>
        {
          IMPORTED_DISTRICT_MENU_LIST.map((menuItem) => (
            <li
              key={menuItem.action}
              className={menuItem.action === props.action ? 'active' : ''}
            >
              <Link to={`/editor/${props.planId}/import-district/${menuItem.action}`}>
                {menuItem.label}
              </Link>
            </li>
          ))
        }
      </ul>
    </nav>
  </section>
);

ImportedDistrictMenu.propTypes = {
  action: PropTypes.string.isRequired,
  planId: PropTypes.any.isRequired,
};

export default ImportedDistrictMenu;
