import React from 'react';
// import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ExportArchiveMenu = (props) => (
  <section className="modal-menu">
    <nav>
      <ul>
        <li className={props.action === 'shapefile' ? 'active' : ''}>
          <Link to={`/editor/${props.planId}/exports/shapefile`}>
            Shapefile Exports
          </Link>
        </li>
        <li
          className={props.action === 'metrics' ? 'active' : ''}
        >
          <Link to={`/editor/${props.planId}/exports/metrics`}>
            Metrics Test Exports
          </Link>
        </li>
      </ul>
    </nav>
  </section>
);

ExportArchiveMenu.propTypes = {
  planId: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
};

export default ExportArchiveMenu;
