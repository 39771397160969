import React from 'react';
import PropTypes from 'prop-types';

import Numeral from 'numeral';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import './ACSTable.css';

class EditorACSTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
    };
  }

  toggleExpand = () => {
    this.setState((prevState) => ({ isExpanded: !prevState.isExpanded }));
  }

  render = () => {
    const {
      orig, final, district, isIntersected,
    } = this.props;
    return (
      <>
        { isIntersected
        && (
        <TableRow key={`${district.id}_intersected`}>
          <TableCell component="th" scope="row">
            {district.alias}
            {' '}
            Total
          </TableCell>
          <TableCell align="right">{Numeral(orig.counts.total_population).format(',')}</TableCell>
          <TableCell align="right">{Numeral((final.counts.total_population || 0) - (orig.counts.total_population || 0)).format(',')}</TableCell>
          <TableCell align="right">{Numeral(final.counts.total_population).format(',')}</TableCell>
        </TableRow>
        )}

        { !isIntersected
        && (
        <>
          <TableRow key={`${district.id}_total_population`}>
            <TableCell component="th" scope="row" className="indented">
              Total
            </TableCell>
            <TableCell align="right">{Numeral(orig.counts.total_population).format(',')}</TableCell>
            <TableCell align="right">{Numeral((final.counts.total_population || 0) - (orig.counts.total_population || 0)).format(',')}</TableCell>
            <TableCell align="right">{Numeral(final.counts.total_population).format(',')}</TableCell>
          </TableRow>
          <TableRow key={`${district.id}_total_voters`}>
            <TableCell component="th" scope="row" className="indented">
              Total Voters
            </TableCell>
            <TableCell align="right">{Numeral(orig.counts.total_voters).format(',')}</TableCell>
            <TableCell align="right">{Numeral((final.counts.total_voters || 0) - (orig.counts.total_voters || 0)).format(',')}</TableCell>
            <TableCell align="right">{Numeral(final.counts.total_voters).format(',')}</TableCell>
          </TableRow>
          <TableRow key={`${district.id}_asian`}>
            <TableCell component="th" scope="row" className="indented">
              Asian
            </TableCell>
            <TableCell align="right">{Numeral(orig.counts.cvap_asian_2017).format(',')}</TableCell>
            <TableCell align="right">{Numeral((final.counts.cvap_asian_2017 || 0) - (orig.counts.cvap_asian_2017 || 0)).format(',')}</TableCell>
            <TableCell align="right">{Numeral(final.counts.cvap_asian_2017).format(',')}</TableCell>
          </TableRow>
          <TableRow key={`${district.id}_black`}>
            <TableCell component="th" scope="row" className="indented">
              Black
            </TableCell>
            <TableCell align="right">{Numeral(orig.counts.cvap_black_2017).format(',')}</TableCell>
            <TableCell align="right">{Numeral((final.counts.cvap_black_2017 || 0) - (orig.counts.cvap_black_2017 || 0)).format(',')}</TableCell>
            <TableCell align="right">{Numeral(final.counts.cvap_black_2017).format(',')}</TableCell>
          </TableRow>
          <TableRow key={`${district.id}_latino`}>
            <TableCell component="th" scope="row" className="indented">
              Latino
            </TableCell>
            <TableCell align="right">{Numeral(orig.counts.cvap_latino_2017).format(',')}</TableCell>
            <TableCell align="right">{Numeral((final.counts.cvap_latino_2017 || 0) - (orig.counts.cvap_latino_2017 || 0)).format(',')}</TableCell>
            <TableCell align="right">{Numeral(final.counts.cvap_latino_2017).format(',')}</TableCell>
          </TableRow>
        </>
        )}
      </>
    );
  }
}

EditorACSTable.propTypes = {
  final: PropTypes.object.isRequired,
  orig: PropTypes.object.isRequired,
  district: PropTypes.object.isRequired,
  isIntersected: PropTypes.bool,
};

EditorACSTable.defaultProps = {
  isIntersected: false,
};

export default EditorACSTable;
