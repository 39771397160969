import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { CircularProgress } from '@material-ui/core';

const ShadedLayerMenu = (props) => (
  <section className="imported-district-menu modal-menu">
    <nav>
      <ul>
        <li
          className={props.action === 'create' ? 'active' : ''}
        >
          <Link to={`/editor/${props.planId}/shaded-layer/create`}>
            Create Shaded Layer
          </Link>
        </li>
        {_.get(props, 'shadedLayers', []).length > 0 && (
          <li>
            <h4>Manage Shaded Layers</h4>
            <ul>
              {
                props.shadedLayers.map((shadedLayer) => (
                  <li
                    key={shadedLayer.id}
                    className={props.action === 'edit' && `${shadedLayer.id}` === props.shadedLayerId ? 'active' : ''}
                  >
                    <Link to={`/editor/${props.planId}/shaded-layer/${shadedLayer.id}/edit`}>
                      {shadedLayer.name}
                      {
                        shadedLayer.is_complete
                          ? (
                            shadedLayer.is_active && <Icon icon={faCheckCircle} color="green" />
                          ) : <CircularProgress size={10} />

                      }
                    </Link>
                  </li>
                ))
              }
            </ul>
          </li>
        )}
      </ul>
    </nav>
  </section>
);

ShadedLayerMenu.propTypes = {
  action: PropTypes.string.isRequired,
  planId: PropTypes.any.isRequired,
  shadedLayerId: PropTypes.number.isRequired,
  shadedLayers: PropTypes.array.isRequired,
};

export default ShadedLayerMenu;
