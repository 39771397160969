import { asArray } from 'ol/color';
import _ from 'lodash';
import { getWidth } from 'ol/extent';
import {
  Fill, Stroke, Style, Text,
} from 'ol/style';

export const defaultCountyStyle = new Style({
  fill: new Fill({
    color: [0, 0, 0, 0],
  }),
  stroke: new Stroke({
    width: 1,
    color: 'rgba(0,0,155, 0.4)',
  }),
});

export const defaultTractStyle = new Style({
  fill: new Fill({
    color: [0, 0, 0, 0],
  }),
  stroke: new Stroke({
    width: 1,
    color: 'rgba(30,144,255, 0.4)',
  }),
});

export const defaultGroupStyle = new Style({
  fill: new Fill({
    color: [0, 0, 0, 0],
  }),
  stroke: new Stroke({
    width: 1,
    color: 'rgba(30,144,255, 0.4)',
  }),
});

export const defaultBlockStyle = new Style({
  fill: new Fill({
    color: [0, 0, 0, 0],
  }),
  stroke: new Stroke({
    width: 1,
    color: 'rgba(30,144,255, 0.4)',
  }),
});

export const highlightBlockStyle = new Style({
  fill: new Fill({
    color: [0, 0, 0, 0],
  }),
  stroke: new Stroke({
    width: 1,
    color: 'rgba(0,0,155, 0.8)',
  }),
});

export const mergeBlockStyle = new Style({
  fill: new Fill({
    color: [0, 0, 0, 0],
  }),
  stroke: new Stroke({
    width: 2.5,
    color: 'rgba(245, 229, 27, 0.4)',
  }),
});

export const debugStyle = new Style({
  fill: new Fill({
    color: [0, 0, 0, 0],
  }),
  stroke: new Stroke({
    width: 1,
    color: 'rgba(155,155,155, 0.4)',
  }),
});

export const selectedBlockStyle = new Style({
  fill: new Fill({
    color: [255, 0, 0, 0.4],
  }),
  stroke: new Stroke({
    width: 0,
    color: 'rgba(255,0,0,0.4)',
  }),
});

export const defaultDefaultGuideLayerStyle = new Style({
  fill: new Fill({
    color: [255, 255, 255, 0.3],
  }),
  stroke: new Stroke({
    width: 1,
    color: 'rgba(74, 160, 205, 1)',
  }),
});

export const labelStyle = new Style({
  geometry(feature) {
    let geometry = feature.getGeometry();
    if (geometry.getType() === 'MultiPolygon') {
      // Only render label for the widest polygon of a multipolygon
      const polygons = geometry.getPolygons();
      let widest = 0;
      for (let i = 0, ii = polygons.length; i < ii; ++i) {
        const polygon = polygons[i];
        const width = getWidth(polygon.getExtent());
        if (width > widest) {
          widest = width;
          geometry = polygon;
        }
      }
    }
    return geometry;
  },
  text: new Text({
    font: '12px Calibri,sans-serif',
    overflow: true,
    fill: new Fill({
      color: '#000',
    }),
    stroke: new Stroke({
      color: '#fff',
      width: 3,
    }),
  }),
});

export const accessFillStyle = (f) => {
  const { color } = f.getProperties();
  const withOpacity = asArray(color.slice());
  withOpacity[3] = 0.4;
  return ({
    color: withOpacity,
  });
};

export const sandboxFillStyle = (f) => {
  const { color } = f.getProperties();
  const withOpacity = asArray(color.slice());
  withOpacity[3] = 0.4;
  return ({
    color: withOpacity,
  });
};

export const sandboxStrokeStyle = (f) => {
  const { color } = f.getProperties();
  return ({
    width: 4,
    color,
  });
};

export const highlightStrokeStyle = () => {
  const color = 'rgba(155,0,155,0.4)';
  return ({
    width: 2.5,
    color,
  });
};

export const highlightFillStyle = () => ({
  color: 'transparent',
});

export const defaultDistrictStyle = new Style({
  fill: new Fill({
    color: [0, 0, 0, 0.1],
  }),
  stroke: new Stroke({
    width: 4,
    color: '#333333',
    lineDash: [5, 7],
    lineDashOffset: 10,
    lineCap: 'square',
    lineJoin: 'round',
  }),
});

export const defaultAccessStyle = new Style({
  fill: new Fill({
    color: [0, 0, 0, 0.4],
  }),
  stroke: null,
});

export const defaultStrokeStyle = () => ({
  width: 4,
  color: 'blue',
  lineDash: [5, 7],
  lineDashOffset: 10,
  lineCap: 'square',
  lineJoin: 'round',
});

export const districtColoredStyle = (feature) => {
  const coloredStyle = _.cloneDeep(defaultDistrictStyle);
  let color = _.get(feature.getProperties(), 'color', '#FFFFFF');
  color = asArray(color).slice();
  color[3] = 0.7;
  coloredStyle.setFill(new Fill({ color }));

  const style = [coloredStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const districtGreyedStyle = (feature) => {
  const style = [defaultDistrictStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const accessStyle = (feature) => {
  const style = [defaultAccessStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const defaultGuideLayerStyle = (feature) => {
  const style = [defaultDefaultGuideLayerStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const countyStyle = (feature) => {
  const style = [defaultCountyStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const tractStyle = (feature) => {
  const style = [defaultTractStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const groupStyle = (feature) => {
  const style = [defaultGroupStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const blockStyle = (feature) => {
  const style = [defaultBlockStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const sandboxStyle = (feature) => {
  const style = [defaultBlockStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const hoverBlockStyle = (feature) => {
  const style = [highlightBlockStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const mergedBlockStyle = (feature) => {
  const clonedMergeBlockStyle = _.cloneDeep(mergeBlockStyle);
  const color = _.get(feature.getProperties(), 'color');
  if (color) {
    clonedMergeBlockStyle.setStroke(new Stroke({
      width: 2.5,
      color,
    }));
  }
  const style = [clonedMergeBlockStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

export const debugTileStyle = (feature) => {
  const style = [debugStyle, labelStyle];
  labelStyle.getText().setText(feature.get('alias'));

  return style;
};

const _getCentile = (value, centiles) => {
  for (let cnt = 0; cnt < centiles.length - 1; cnt++) {
    if (value >= centiles[cnt] && value < centiles[cnt + 1]) {
      return cnt;
    }
  }
  return centiles.length - 1;
};

export const shadedLayerStyle = (feature, shadedLayer) => {
  const arr = _.get(shadedLayer, 'color_range', []);
  const numerator = feature.get('numerator_column');
  const denominator = feature.get('denominator_column');

  let colorIndex = null;
  if (
    _.get(shadedLayer, 'stops') === 'EQUAL_INTERVALS'
    || _.get(shadedLayer, 'stops' === 'EQUAL_NUMBER')
  ) {
    const intervals = _.get(shadedLayer, 'choropleth_progression') || [];
    colorIndex = _getCentile(numerator / parseFloat(denominator), intervals);
  } else {
    colorIndex = parseInt((numerator * arr.length) / denominator, 10);
  }

  let color = null;
  if (denominator === 0 || !arr[colorIndex]) {
    color = 'rgba(50,50,50,0.4)';
  } else {
    color = asArray(arr[colorIndex]).slice();
    color[3] = 0.5;
  }

  return new Style({
    fill: new Fill({
      color,
    }),
    stroke: new Stroke({
      width: 0,
      color: 'rgba(0,0,0,0)',
    }),
  });
};

export const unassignedDistrictStyle = () => new Style({
  fill: new Fill({
    color: [255, 255, 255, 0.5],
  }),
  stroke: new Stroke({
    width: 4,
    color: '#FFFFFF',
    lineDash: [5, 7],
    lineDashOffset: 10,
    lineCap: 'square',
    lineJoin: 'round',
  }),
});
