import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, LinearProgress } from '@material-ui/core';

import ReportMenu from './ReportMenu';
import ReportFileView from './ReportFileView';
import ReportDetails from './ReportDetails';

import './ViewReportView.css';

const computeCompletion = (task) => {
  const done = _.get(task, 'result.done', 0);
  const total = _.get(task, 'result.total', 1);
  return (done * 100) / total;
};

const ViewReportView = (props) => (
  <section className="view-report modal-page">
    <div className={`bs-viewport viewport ${props.isSubmitting ? 'bs-submitting' : ''}`}>
      <header>
        <h1>Reports</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="bs-content content">
        <ReportMenu {...props} />
        <div className="bs-activity modal-activity">
          <div className="activity-area">
            <header>
              <h3>{ _.get(props.chosenReport, 'is_complete', false) ? 'Report Results' : 'Building Report...'}</h3>
            </header>
            <div className="vrv-inner-activity inner-activity">
              <Grid container spacing={0} className="vrv-grid">
                <Grid item xs={12} md={12}>
                  <ReportDetails {...props} />
                </Grid>
                <Grid item xs={12} md={12} style={{ flexGrow: 1 }}>
                  {
                  _.get(props.chosenReport, 'is_complete', false)
                    ? <ReportFileView {...props} />
                    : (
                      <div className="vrv-loading">
                        <p>{_.get(props.task, 'result.message', 'Processing...')}</p>
                        <LinearProgress
                          variant="determinate"
                          value={computeCompletion(props.task)}
                        />
                      </div>
                    )
                }
                </Grid>

              </Grid>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
);

ViewReportView.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  planId: PropTypes.string.isRequired,
  chosenReport: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
};

export default ViewReportView;
