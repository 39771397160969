/**
 * Pulls in the details for a selected district
 * @param {number} planId
 * @param {number} districtId
 */
// export const retrieveSandboxDistrictDetail = (planId, districtId) => ({
//  type: 'EDITOR_RETRIEVE_SANDBOX_DISTRICT_DETAIL',
//  payload: {
//    request: {
//      url: `/plan/${planId}/district/${districtId}/details/`,
//      method: 'GET',
//    },
//  },
// });

/**
 * Sends a geojson to be added to the district-sandbox. This
 * Updates the district's elements. This one saves a
 * district via geojson entry from client
 *
 * @param {number} planId
 * @param {object} geojson
 * @param {string} brushType
 */
export const submitSelection = (planId, districtId, geojson, brushType) => ({
  type: 'EDITOR_SUBMIT_SELECTION',
  payload: {
    request: {
      url: `/plan/${planId}/district/${districtId}/sandbox/`,
      method: 'POST',
      data: {
        geojson,
        submit_type: 'EDITOR_SUBMIT_SELECTION',
        brush_type: brushType,
      },
    },
  },
});

/**
 * Saves the District Sandbox into a District
 * @param {number} districtId
 */
// export const saveDistrict = (planId, districtId) => ({
//   type: 'EDITOR_SAVE_DISTRICT',
//   payload: {
//     request: {
//       url: `/plan/${planId}/district/${districtId}/commit/`,
//       method: 'POST',
//     },
//   },
// });

/**
 * Saves the District Details into a District
 * @param {number} districtId
 */
export const saveDistrictDetails = (planId, districtId, districtDetails) => ({
  type: 'EDITOR_SAVE_DISTRICT_DETAILS',
  payload: {
    request: {
      url: `/plan/${planId}/district/${districtId}/details/`,
      method: 'POST',
      data: districtDetails,
    },
  },
});

/**
 * Get the District Counts & Details by submitting action stack
 * @param {number} districtId
 */
export const calculateDistrictDetails = (planId, districtId, districtDetails, transactionToken) => (
  {
    type: 'EDITOR_CALCULATE_DISTRICT_DETAILS',
    transactionToken,
    payload: {
      request: {
        url: `/plan/${planId}/district/${districtId}/details/`,
        method: 'PUT',
        data: districtDetails,
      },
    },
  }
);

/**
 * Cancels the district saving and clears Sandbox District to BLANK
 * @param {number} districtId
 */
// export const revertDistrict = (planId, districtId) => ({
//   type: 'EDITOR_REVERT_DISTRICT',
//   payload: {
//     request: {
//       url: `/plan/${planId}/district/${districtId}/sandbox/`,
//       method: 'DELETE',
//     },
//   },
// });

/**
 * #### submitID
 * _Deprecated_
 * Sends ID of census block
 * @param {number} planId
 * @param {number} id - District ID
 * @param {string} mode
 * @param {Array} censusBlockIds
 * @param {string} brushType
 */
export function submitIDs(planId, id, mode, censusBlockIds, brushType) {
  return {
    type: 'EDITOR_SUBMIT_IDS',
    payload: {
      request: {
        url: `/plan/${planId}/district/${id}/sandbox/`,
        method: 'POST',
        data: {
          id,
          mode,
          census_block_ids: censusBlockIds,
          brush_type: brushType,
          submit_type: 'EDITOR_SUBMIT_IDS',
        },
      },
    },
  };
}

/**
 * `undoLastAction`
 *
 */
export const undoLastAction = () => (
  {
    type: 'EDITOR_UNDO_LAST_ACTION',
  }
);

/**
 * `clearUndoLastActionFlag`
 *
 */
export const clearUndoLastActionFlag = () => (
  {
    type: 'EDITOR_CLEAR_UNDO_LAST_ACTION_FLAG',
  }
);

/**
 * `clearCalculateDistrictDetailsSuccessToken`
 *
 */
export const clearCalculateDistrictDetailsSuccessToken = () => (
  {
    type: 'EDITOR_CLEAR_CALCULATE_DISTRICT_DETAILS_SUCCESS_TOKEN',
  }
);

/**
 * `clearChangedDistrictId`
 *
 */
export const clearChangedDistrictId = () => (
  {
    type: 'EDITOR_CLEAR_CHANGED_DISTRICT_ID',
  }
);

/**
 * `setChosenTool`
 *
 * @param {string} chosenTool
 */
export const setChosenTool = (chosenTool) => (
  {
    type: 'EDITOR_SET_CHOSEN_TOOL',
    data: { chosenTool },
  }
);

/**
 * `setChosenBrush`
 *
 * @param {object} brush
 */
export const setChosenBrush = (brush) => ({
  type: 'EDITOR_SET_CHOSEN_BRUSH',
  data: { brush },
});

/**
 * `setDistrictDetails`
 *
 * @param {array} districtDetails
 */
export const setDistrictDetails = (districtDetails, transactionToken = null) => ({
  type: 'EDITOR_SET_DISTRICT_DETAILS',
  data: { districtDetails, transactionToken },
});

/**
 * `clearDistrictDetails`
 *
 * @param {array} districtDetails
 */
export const clearDistrictDetails = () => ({
  type: 'EDITOR_CLEAR_DISTRICT_DETAILS',
});

/**
 * `focusDistrict`
 * with a district id, we focus on it by looking for it in openlayer...
 *
 * This will focus on districts
 * @param {number} districtId
 */
export const focusDistrict = (districtId) => ({
  type: 'EDITOR_FOCUS_DISTRICT',
  data: {
    focusedDistrict: districtId,
  },
});

/**
 * Builds up on the proposed uncommitted deletion of district blocks
 *
 * @param {number} planId
 * @param {JSON} geometry
 * @param {string} brushType
 */
// export const updateUnassignedDistrict = (planId, geojson, brushType) => ({
//   type: 'EDITOR_UPDATE_UNASSIGNED_DISTRICT',
//   payload: {
//     request: {
//       url: `/plan/${planId}/unassigned/`,
//       method: 'PUT',
//       data: {
//         geojson,
//         brush_type: brushType,
//       },
//     },
//   },
// });

/**
 * This commit unassigned districts and remove any district blocks that encompass these
 * @param {number} planId
 */
// export const commitUnassignedDistrict = (planId) => ({
//   type: 'EDITOR_COMMIT_UNASSIGNED_DISTRICT',
//   payload: {
//     request: {
//       url: `/plan/${planId}/unassigned/`,
//       method: 'POST',
//     },
//   },
// });

/**
 * Reverts the unassigned district to `empty`
 * @param {number} planId
 */
// export const revertUnassignedDistrict = (planId) => ({
//   type: 'EDITOR_REVERT_UNASSIGNED_DISTRICT',
//   payload: {
//     request: {
//       url: `/plan/${planId}/unassigned/`,
//       method: 'DELETE',
//     },
//   },
// });

/**
 * Updates alias of the district
 * @param {number} districtId
 * @param {string} alias
 */
export const submitDistrictUpdate = (planId, districtId, alias, color) => ({
  type: 'EDITOR_DISTRICT_UPDATE_ALIAS',
  payload: {
    request: {
      url: `/plan/${planId}/district/${districtId}/`,
      method: 'PATCH',
      data: {
        alias,
        district_sandbox: {
          color,
        },
      },
    },
  },
});

/**
 * Locks / Unlocks a district. Uses the same
 * endpoint as `submitDistrictUpdate`
 * @param {number} districtId
 * @param {boolean} lock
 */
export const toggleDistrictLock = (planId, districtId, lock) => ({
  type: 'EDITOR_DISTRICT_UPDATE_LOCK',
  payload: {
    request: {
      url: `/plan/${planId}/district/${districtId}/`,
      method: 'PATCH',
      data: {
        is_locked: lock,
      },
    },
  },
});

/**
 * Calls the `DELETE` method to delete District `districtId`
 * @param {*} planId
 * @param {*} districtId
 */
export const deleteDistrict = (planId, districtId) => ({
  type: 'EDITOR_DISTRICT_DELETE',
  districtId,
  payload: {
    request: {
      url: `/plan/${planId}/district/${districtId}/`,
      method: 'DELETE',
    },
  },
});

/**
 * Calls /search `GET` from site which will return
 * a list of matches along with their respective
 * extents/bounds. This will then be a marker...
 *
 * @param {string} type - Sets what kind of
 * search will happen :: `Address`, `County`, `Tract`, etc..
 * @param {string} searchQuery - Search query inputted by the user
 */
export const searchMap = (type, searchQuery) => ({
  type: 'EDITOR_SEARCH',
  payload: {
    request: {
      url: '/search/',
      method: 'POST',
      data: {
        search_type: type,
        search_query: searchQuery,
      },
    },
  },
});

/**
 * Simply clears the search result area
 */
export const clearSearch = () => ({
  type: 'EDITOR_CLEAR_SEARCH',
});

/**
 * Sets the geometry to be focused on
 * @param {geojson} geometry
 */
export const setFocusGeom = (geometry) => ({
  type: 'EDITOR_SET_FOCUS_GEOMETRY',
  payload: {
    data: {
      focusedGeometry: geometry,
    },
  },
});

/**
 * Calls server to return a county that is not
 * completely redistricted
 * @param {string} planId
 */
export const gotoIncompleteArea = (planId) => ({
  type: 'EDITOR_GOTO_INCOMPLETE_AREA',
  payload: {
    request: {
      url: `/plan/${planId}/goto-incomplete/`,
      method: 'GET',
    },
  },
});

/**
 * Clears the extent chosen
 */
export const clearIncompleteAreaExtent = () => ({
  type: 'EDITOR_CLEAR_INCOMPLETE_AREA_EXTENT',
});

/**
 * Calls server to get available baselayers
 */
export const getBaseLayers = () => ({
  type: 'EDITOR_GET_BASE_LAYERS',
  payload: {
    request: {
      url: '/base-layers/',
      method: 'GET',
    },
  },
});
