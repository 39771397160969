import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import OLVectorSource from 'ol/source/Vector';
import { difference, intersection } from 'polygon-clipping';
import { intersects } from 'ol/extent';
import loaderFunction from '../../../helper/featureLoader';

/**
 * For our intents and purposes, this component are vectors being served
 * by Ballista server
 */
class VectorSource extends React.Component {
  constructor(props) {
    super(props);

    const newVectorSource = new OLVectorSource({
      format: props.format,
      features: props.features,
    });

    if (props.url !== null) {
      newVectorSource.setLoader(loaderFunction(newVectorSource, props.url));
    }

    this.state = {
      vectorSource: newVectorSource,
    };
  }

  componentDidMount() {
    this.props.layer.setSource(this.state.vectorSource);
  }

  componentDidUpdate() {
    this.state.vectorSource.refresh();
    this.state.vectorSource.clear(true);
  }

  getCountsFromFeature = (feature) => ({
    total_population: parseInt(_.get(feature.getProperties(), 'total_population', 0), 10),
    total_voters: parseInt(_.get(feature.getProperties(), 'total_voters', 0), 10),
    cvap_asian_2017: parseInt(_.get(feature.getProperties(), 'cvap_asian_2017', 0), 10),
    cvap_black_2017: parseInt(_.get(feature.getProperties(), 'cvap_black_2017', 0), 10),
    cvap_latino_2017: parseInt(_.get(feature.getProperties(), 'cvap_latino_2017', 0), 10),
  })

  testCensusAreaIdDown = (censusAreaId, censusType, districtType, ids = {}) => {
    let returnVal = false;
    const idArray = Object.keys(ids);
    for (let j = 0; j < idArray.length; j++) {
      const id = idArray[j];
      if (id.substring(0, censusAreaId.length) === censusAreaId) {
        returnVal = districtType;
        break;
      }
    }
    return returnVal;
  }

  testCensusAreaIdUp = (censusAreaId, censusType, districtType, ids = {}) => {
    // get first key
    let k;
    const idKeys = Object.keys(ids);
    if (idKeys.length > 0) {
      [k] = idKeys;
    }
    if (ids.hasOwnProperty(censusAreaId.substr(0, k.length))) {
      return districtType;
    }
    return false;
  }

  checkIntersections = (censusAreaId, censusAreaType, districts = []) => {
    const returnObj = {};
    // check which districts a census area id appears in
    districts.forEach((district) => {
      const districtIds = _.get(district, 'settings.ids', {});
      const districtId = _.get(district, 'id');
      const districtTypes = Object.keys(districtIds);
      for (let i = 0; i < districtTypes.length; i++) {
        const districtType = districtTypes[i];
        const ids = _.get(districtIds, districtType, {});
        if (censusAreaType === districtType && ids.hasOwnProperty(censusAreaId)) {
          // complete match
          returnObj[districtId] = censusAreaType;
          break;
        } else if (censusAreaType !== districtType) {
          let testDirection = 'down';
          let foundType;
          if ((censusAreaType === 'tract' && districtType === 'county')
          || (censusAreaType === 'group' && (districtType === 'county' || districtType === 'tract'))
          || (censusAreaType === 'block' && (districtType === 'county' || districtType === 'tract' || districtType === 'group'))) {
            testDirection = 'up';
          }
          if (testDirection === 'down') {
            // check down
            foundType = this.testCensusAreaIdDown(censusAreaId, censusAreaType, districtType, ids);
          } else if (testDirection === 'up') {
            // check up
            foundType = this.testCensusAreaIdUp(censusAreaId, censusAreaType, districtType, ids);
          }
          if (foundType) {
            returnObj[districtId] = foundType;
            break;
          }
        }
      }
    });
    return returnObj;
  }

  addSandboxFeatures = (features = [], districts = [], accessGeography = null) => {
    const { vectorSource } = this.state;
    const sandboxFeature = vectorSource.getFeatureById(-1);
    let sandboxGeometry;
    if (sandboxFeature !== null) {
      sandboxGeometry = sandboxFeature.getGeometry();
    }
    const featureCounts = {};
    const intersectedDistricts = {};

    let censusAreaType = _.get(features[0].getProperties(), 'census_area_type');
    // let z;
    if (features.length > 0) {
      censusAreaType = _.get(features[0].getProperties(), 'census_area_type');
      // z = _.get(features[0].getProperties(), 'z');
    }

    if (features.length === 1 && (typeof sandboxGeometry === 'undefined' || sandboxGeometry === null)) {
      const addFeature = features[0].clone();
      const censusAreaId = features[0].getId().split('_')[2];
      const properties = features[0].getProperties();
      const featureId = _.get(properties, 'id');
      const combinedId = `${featureId}|${censusAreaId}`;
      let accessDeniedFlag = false;
      // const feature_total_population = _.get(addFeature.getProperties(), 'total_population', 0);

      // check access intersections
      if (accessGeography !== null) {
        const accessObj = this.checkIntersections(censusAreaId, censusAreaType, [accessGeography]);
        if (Object.keys(accessObj).length > 0) {
          accessDeniedFlag = true;
        }
      }

      if (!accessDeniedFlag) {
        // check district intersections
        const intersectObj = this.checkIntersections(censusAreaId, censusAreaType, districts);
        Object.keys(intersectObj).forEach((districtId) => {
          intersectedDistricts[districtId] = true;
        });

        // sandboxFeature.setGeometry(addFeature.getGeometry());
        if (!featureCounts.hasOwnProperty(combinedId)) {
          featureCounts[combinedId] = this.getCountsFromFeature(addFeature);
        }
      }
    } else {
      // const addFeature = features[0].clone();
      let skipDiffFlag = false;
      let e1;
      let a1;

      if (typeof sandboxGeometry === 'undefined' || sandboxGeometry === null) {
        // sandboxFeature.setGeometry(addFeature.getGeometry());
        // sandboxGeometry = sandboxFeature.getGeometry();
        // we don't need to take the difference if it's a brand new district
        skipDiffFlag = true;
      } else {
        e1 = sandboxGeometry.getExtent();
        a1 = sandboxGeometry.getCoordinates();
      }

      // const b1 = [];
      const checkedIds = {};
      const checkedAccessDeniedIds = {};

      features.forEach((feature) => {
        const geom = feature.getGeometry();
        const coords = geom.getCoordinates();

        const censusAreaId = feature.getId().split('_')[2];
        const properties = feature.getProperties();
        const featureId = _.get(properties, 'id');
        const combinedId = `${featureId}|${censusAreaId}`;
        const skipFeatureFlag = false;

        if (accessGeography !== null) {
          if (!checkedAccessDeniedIds.hasOwnProperty(censusAreaId)) {
            // check access intersections
            const accessObj = this.checkIntersections(
              censusAreaId, censusAreaType, [accessGeography],
            );
            if (Object.keys(accessObj).length > 0) {
              checkedAccessDeniedIds[censusAreaId] = true;
            } else {
              checkedAccessDeniedIds[censusAreaId] = false;
            }
          }
        }

        if (!_.get(checkedAccessDeniedIds, censusAreaId, false)) {
          if (!checkedIds.hasOwnProperty(censusAreaId)) {
            const intersectObj = this.checkIntersections(censusAreaId, censusAreaType, districts);
            checkedIds[censusAreaId] = intersectObj;
            Object.keys(intersectObj).forEach((districtId) => {
              intersectedDistricts[districtId] = true;
            });
          }

          let d1;
          if (!skipDiffFlag) {
            const e2 = geom.getExtent();
            const i1 = intersects(e1, e2);
            // we don't need to take the difference if the feature is completely outside
            // the sandbox
            if (!i1) {
              skipDiffFlag = true;
            } else {
              try {
                d1 = difference(coords, a1);
              } catch (error) {
                console.error('error in difference', error); // eslint-disable-line
              }
            }
          }
          // only add to union and featureCounts if it is new, or if its extent doesn't intersect
          // the sandbox extent, or if at least some of it is outside the sandbox
          if (skipDiffFlag || (typeof d1 !== 'undefined' && d1.length > 0)) {
            if (!skipFeatureFlag) {
              // b1.push(coords);
              if (!featureCounts.hasOwnProperty(combinedId)) {
                featureCounts[combinedId] = this.getCountsFromFeature(feature);
              }
            }
          }
        }
      });

      // Union
      //      try {
      //
      //         //const u1 = union(a1, ...b1);
      //         // if (!_.isEmpty(diffDistricts)) {
      // //           const districtIdsArray = [];
      // //           Object.keys(diffDistricts).forEach(districtId => {
      // //             districtIdsArray.push(
      //                  _.get(_.find(districts, {id: parseInt(districtId, 10)}), 'settings.ids')
      //                );
      // //           });
      // //           const districtCoords = this.props.getDistrictsCoords(districtIdsArray)
      // //           const d2 = difference(u1, ...districtCoords);
      // //           sandboxGeometry.setCoordinates(d2);
      // //         } else {
      //         //sandboxGeometry.setCoordinates(u1);
      //         //}
      //
      //         // get out gaps
      //         //sandboxGeometry.setCoordinates(removeGaps(sandboxGeometry, censusAreaType, z));
      //
      //       } catch(error) {
      //         console.log(error);
      //       }
    }

    return { featureCounts, intersectedDistricts };
  }

  removeSandboxFeatures = (features = [], districts = []) => {
    const { vectorSource } = this.state;

    const sandboxFeature = vectorSource.getFeatureById(-1);
    let sandboxGeometry;
    if (sandboxFeature !== null) {
      sandboxGeometry = sandboxFeature.getGeometry();
    }
    const featureCounts = {};
    const intersectedDistricts = {};

    if (typeof sandboxGeometry !== 'undefined' && sandboxGeometry !== null) {
      const e1 = sandboxGeometry.getExtent();
      const a1 = sandboxGeometry.getCoordinates();
      // const b1 = [];
      const checkedIds = {};

      features.forEach((feature) => {
        const censusAreaId = feature.getId().split('_')[2];
        const properties = feature.getProperties();
        const featureId = _.get(properties, 'id');
        const combinedId = `${featureId}|${censusAreaId}`;
        const censusAreaType = _.get(properties, 'census_area_type');

        if (!checkedIds.hasOwnProperty(censusAreaId)) {
          const intersectObj = this.checkIntersections(censusAreaId, censusAreaType, districts);
          checkedIds[censusAreaId] = intersectObj;
          Object.keys(intersectObj).forEach((districtId) => {
            intersectedDistricts[districtId] = true;
          });
        }

        const geom = feature.getGeometry();
        const coords = geom.getCoordinates();
        const e2 = geom.getExtent();
        const i1 = intersects(e1, e2);
        if (i1) {
          try {
            const i2 = intersection(coords, a1);
            if (i2.length > 0) {
              // b1.push(coords);
              if (!featureCounts.hasOwnProperty(combinedId)) {
                featureCounts[combinedId] = this.getCountsFromFeature(feature);
              }
            }
          } catch (error) {
            console.error('error in intersection', error); // eslint-disable-line
          }
        }
      });

      // Difference
      // const d1 = difference(a1, ...b1);
      // sandboxGeometry.setCoordinates(d1);
      // sandboxFeature.setProperties({total_population});
    }

    return { featureCounts, intersectedDistricts };
  }

  addFeature = (feature) => this.state.vectorSource.addFeature(feature);

  getFeatures = () => this.state.vectorSource.getFeatures();

  addFeatures = (features) => this.state.vectorSource.addFeatures(features);

  clear = () => this.state.vectorSource.clear();

  getFeatureById = (id) => this.state.vectorSource.getFeatureById(id);

  getFeaturesInExtent = (extent) => this.state.vectorSource.getFeaturesInExtent(extent);

  getFeaturesAtCoordinate = (coords) => this.state.vectorSource.getFeaturesAtCoordinate(coords);

  render = () => null;
}

VectorSource.propTypes = {
  url: PropTypes.string,
  format: PropTypes.object.isRequired,
  layer: PropTypes.object,
  features: PropTypes.array,
  // tileLoadFunction: PropTypes.func,
  // getDistrictsCoords: PropTypes.func,
};

VectorSource.defaultProps = {
  layer: null,
  features: null,
  url: null,
  // tileLoadFunction: null,
  // getDistrictsCoords: () => {},
};

export default VectorSource;
