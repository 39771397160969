/** *
 *  -------------------
 *  EditorContainer.js
 *  -------------------
 *
 *  EditorContainer is where the actual editing of districts happen.
 *  This page contains the heaviest load of activitiest and will
 *  show a lot of features specified by the client. This will also
 *  manage all the interactions and changes to all the layers.
 * */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import { toast } from 'react-toastify';
import EditorToolbox from '../components/editor/toolbox/EditorToolbox';
import EditorNotificationItem from '../components/editor/toolbox/EditorNotificationItem';

import {
  // focusDistrict,
  getBaseLayers,
  // saveDistrict,
  setChosenTool,
  setChosenBrush,
  // retrieveSandboxDistrictDetail,
  // revertDistrict,
  // commitUnassignedDistrict,
  // revertUnassignedDistrict,
} from '../actions/editor';
import {
  getGuideLayers,
  updateGuideLayerSettings,
} from '../actions/guideLayer';
import {
  getPlanDetails,
  // setChosenDistrict,
  // selectUnassigned,
  updatePlanBaseLayer,
  exportPlan,
} from '../actions/plan';

import {
  getNotifications,
  receiveNotification,
  receiveExportPoll,
  receiveImportedDistrictPoll,
} from '../actions/notification';

/**
 * Editor Container provides information to mapping and editor
 * toolbox.
 */
class EditorContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNotifications: false,
      showExportPlans: true,
      showToast: false,
    };

    this.notificationCenter = new WebSocket(
      `${process.env.REACT_APP_NOTIFICATION_CENTER_URL}plan/${_.get(this.props, 'match.params.plan_id')}/notification/`,
    );

    this.userScopedNotificationCenter = new WebSocket(
      `${process.env.REACT_APP_NOTIFICATION_CENTER_URL}notification/`,
    );

    this.exportPollingCenter = new WebSocket(
      `${process.env.REACT_APP_NOTIFICATION_CENTER_URL}exports/`,
    );

    this.importedDistrictPollingCenter = new WebSocket(
      `${process.env.REACT_APP_NOTIFICATION_CENTER_URL}plan/${_.get(this.props, 'match.params.plan_id')}/imported_district/`,
    );
  }

  componentDidMount = () => {
    const planId = _.get(this.props, 'match.params.plan_id');
    // this.props.getPlanDetails(planId);
    // this.props.getGuideLayers(planId);
    // this.props.getBaseLayers();
    this.props.getNotifications(planId);

    this.notificationCenter.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      this.props.receiveNotification(notification.message);
      toast.success(<EditorNotificationItem {...notification.message} />);
    };

    this.userScopedNotificationCenter.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      this.props.receiveNotification(notification.message);
      toast.success(<EditorNotificationItem {...notification.message} />);
    };

    this.exportPollingCenter.onmessage = (event) => {
      const exportPoll = JSON.parse(event.data);
      this.props.receiveExportPoll(exportPoll.message);
    };
    this.importedDistrictPollingCenter.onmessage = (event) => {
      const importedDistrictPoll = JSON.parse(event.data);
      this.props.receiveImportedDistrictPoll(importedDistrictPoll.message);
      const isComplete = _.get(importedDistrictPoll, 'message.is_complete', false);
      if (isComplete) {
        this.props.getPlanDetails(planId).then(() => {
          this.props.history.push(`/editor/${_.get(this.props, 'match.params.plan_id')}`);
        });
      }
    };
  }

  componentWillUnmount = () => {
    this.notificationCenter.close();
    this.userScopedNotificationCenter.close();
    this.exportPollingCenter.close();
  }

  handleClick = (toolName) => {
    this.props.setChosenTool(toolName);
  }

  // handleSave = (districtId) => {
  //  const planId = _.get(this.props.match, 'params.plan_id');
  //  this.props.saveDistrict(planId, districtId);
  // }

  // handleCancelDistrict = (districtId) => {
  //  const planId = _.get(this.props.match, 'params.plan_id');
  // this.props.revertDistrict(planId, districtId);
  // }

  // handleDistrictSelect = (district) => {
  //   const planId = _.get(this.props.match, 'params.plan_id');
  //   this.props.setChosenDistrict(district);
  //   //this.props.retrieveSandboxDistrictDetail(planId, district.id);
  // }

  handleExport = () => {
    const planId = _.get(this.props.match, 'params.plan_id');
    this.props.exportPlan(planId);
    toast.info('Plan export has started.');
  }

  handleUpdateSettings = (guideLayer, obj) => {
    const planId = this.props.match.params.plan_id;
    const newSettings = _.merge(guideLayer.settings, obj);
    this.props.updateGuideLayerSettings(planId, guideLayer.id, newSettings);
  }

  handleBaseLayerChange = (baseLayer) => {
    const planId = _.get(this.props.match, 'params.plan_id');
    this.props.updatePlanBaseLayer(planId, baseLayer.id);
  }

  handleNotificationClick = () => {
    this.setState((prevState) => ({
      showNotifications: !prevState.showNotifications,
    }));
  }

  handleClickOutsideNotifications = () => {
    this.setState({ showNotifications: false });
  }

  render = () => (
    <>
      <EditorToolbox
        {...this.props}
        {...this.state}
        handleNotificationClick={this.handleNotificationClick}
        handleClickOutsideNotifications={this.handleClickOutsideNotifications}
        handleExport={this.handleExport}
        handleClick={this.handleClick}
        handleUpdateSettings={this.handleUpdateSettings}
        handleBaseLayerChange={this.handleBaseLayerChange}
        planId={_.get(this.props.match, 'params.plan_id')}
        isInteractive={!!this.props.isInteractive}
        unseenNotifications={
        _.filter(this.props.notifications, { is_seen: false })
      }
      />
    </>
  )
}

const mapStateToProps = (state) => ({
  baseLayers: _.get(state, 'editor.baseLayers'),
  chosenTool: _.get(state, 'editor.chosenTool'),
  chosenBrush: _.get(state, 'editor.chosenBrush'),
  chosenPlan: _.get(state, 'plan.chosenPlan'),
  chosenDistrict: _.get(state, 'plan.chosenDistrict'),
  committedChanges: _.get(state, 'editor.committedChanges'),
  districtDetails: _.get(state, 'editor.districtDetails'),
  notifications: _.get(state, 'notification.notifications'),
  exportPolls: _.get(state, 'notification.exportPolls'),
  publishedGuideLayers: _.get(state, 'guideLayer.publishedGuideLayers'),
  userUploads: _.get(state, 'editor.userUploads'),
  unassignedDetails: _.get(state, 'editor.unassignedDetails'),
});

EditorContainer.propTypes = {
  chosenBrush: PropTypes.object,
  chosenDistrict: PropTypes.object,
  chosenPlan: PropTypes.any,
  chosenTool: PropTypes.string,
  committedChanges: PropTypes.number.isRequired,
  districtDetails: PropTypes.array,
  exportPlan: PropTypes.func.isRequired,
  // focusDistrict: PropTypes.func.isRequired,
  getBaseLayers: PropTypes.func.isRequired,
  getGuideLayers: PropTypes.func.isRequired,
  getNotifications: PropTypes.func.isRequired,
  getPlanDetails: PropTypes.func.isRequired,
  isInteractive: PropTypes.bool,
  match: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  publishedGuideLayers: PropTypes.array.isRequired,
  receiveNotification: PropTypes.func.isRequired,
  receiveExportPoll: PropTypes.func.isRequired,
  receiveImportedDistrictPoll: PropTypes.func.isRequired,
  // retrieveSandboxDistrictDetail: PropTypes.func.isRequired,
  // revertDistrict: PropTypes.func.isRequired,
  // saveDistrict: PropTypes.func.isRequired,
  // selectUnassigned: PropTypes.func.isRequired,
  setChosenBrush: PropTypes.func.isRequired,
  // setChosenDistrict: PropTypes.func.isRequired,
  setChosenTool: PropTypes.func.isRequired,
  unassignedDetails: PropTypes.array,
  updateGuideLayerSettings: PropTypes.func.isRequired,
  updatePlanBaseLayer: PropTypes.func.isRequired,
  userUploads: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
};

EditorContainer.defaultProps = {
  chosenTool: {},
  chosenBrush: {},
  chosenPlan: {},
  chosenDistrict: {},
  districtDetails: [],
  isInteractive: true,
  unassignedDetails: null,
};

export default connect(mapStateToProps, {
  // focusDistrict,
  // selectUnassigned,
  getGuideLayers,
  getNotifications,
  getPlanDetails,
  getBaseLayers,
  // retrieveSandboxDistrictDetail,
  // revertDistrict,
  setChosenBrush,
  // setChosenDistrict,
  setChosenTool,
  // saveDistrict,
  updateGuideLayerSettings,
  // commitUnassignedDistrict,
  // revertUnassignedDistrict,
  updatePlanBaseLayer,
  exportPlan,
  receiveNotification,
  receiveExportPoll,
  receiveImportedDistrictPoll,
})(EditorContainer);
