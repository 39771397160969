import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../common/Button';

const ColumnSettingsSearch = (props) => (
  <div className="column-settings-search">
    <div className="css-search-area">
      <span><Icon icon={faSearch} /></span>
      <input
        type="text"
        onChange={(e) => props.handleChange('searchText')(e.target.value)}
        value={props.searchText}
      />
      {props.searchText !== ''
        && (
          <Button onClick={() => props.handleChange('searchText')('')}>
            <Icon icon={faTimes} />
          </Button>
        )}
    </div>
    <div className="css-filter-area">
      <h5>Show:</h5>
      <Button
        className={props.searchFilter === null ? 'selected' : ''}
        onClick={() => props.handleChange('searchFilter')(null)}
      >
        All
      </Button>
      <Button
        className={props.searchFilter === 'active' ? 'selected' : ''}
        onClick={() => props.handleChange('searchFilter')('active')}
      >
        Active
      </Button>
      <Button
        className={props.searchFilter === 'inactive' ? 'selected' : ''}
        onClick={() => props.handleChange('searchFilter')('inactive')}
      >
        Inactive
      </Button>
    </div>

  </div>
);

ColumnSettingsSearch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  searchFilter: PropTypes.string.isRequired,
  searchText: PropTypes.string.isRequired,
};

ColumnSettingsSearch.defaultProps = {

};

export default ColumnSettingsSearch;
