import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMetrics, deleteMetric } from '../../actions/metrics';
import MetricsListView from '../../components/metrics/MetricsListView';

class MetricsMetricListContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
      tobeDeleted: null,
    };
  }

  componentDidMount = () => {
    this.props.getMetrics();
  }

  handleDelete = (id) => {
    this.setState({
      isDeleting: true,
      tobeDeleted: id,
    });
  }

  handleCancelDelete = () => {
    this.setState({
      isDeleting: false,
      tobeDeleted: null,
    });
  }

  handleConfirmDelete = () => {
    this.props.deleteMetric(this.state.tobeDeleted).then(() => {
      this.setState({
        tobeDeleted: null,
        isDeleting: false,
      });
    });
  }

  render = () => (
    <MetricsListView
      {...this.props}
      {...this.state}
      handleConfirmDelete={this.handleConfirmDelete}
      handleCancelDelete={this.handleCancelDelete}
      handleDelete={this.handleDelete}
    />
  );
}

MetricsMetricListContainer.propTypes = {
  deleteMetric: PropTypes.func.isRequired,
  getMetrics: PropTypes.func.isRequired,
  metrics: PropTypes.array.isRequired,
};

MetricsMetricListContainer.defaultProps = {
};

const mapStateToProps = (state) => ({
  metrics: _.get(state, 'metrics.metrics'),
});

export default connect(mapStateToProps, {
  getMetrics,
  deleteMetric,
})(MetricsMetricListContainer);
