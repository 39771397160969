import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { TextField, Grid, Checkbox } from '@material-ui/core';

import Button from '../common/Button';

import './CreateReportDetailsSection.css';

const CreateReportDetailsSection = (props) => (
  <section className="create-report-details-section column-settings-form activity-area">
    <header>
      <h3>
        Report Details Section
      </h3>
    </header>
    <div className="csf-inner-activity-area inner-activity">
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <h4>Report Name</h4>
          <TextField
            className="bsf-text"
            value={_.get(props.form, 'detailsSection.name') || ''}
            placeholder="Title of the Report you're building"
            fullWidth
            onChange={props.handleChange('detailsSection', 'name')}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Description</h4>
          <TextField
            className="bsf-text"
            defaultValue=""
            id="outlined-multiline-static"
            multiline
            rows="4"
            variant="outlined"
            fullWidth
            value={_.get(props.form, 'detailsSection.description') || ''}
            placeholder="Description of the Report you're building"
            onChange={props.handleChange('detailsSection', 'description')}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <h4>Select Test Suites</h4>
          <Grid container spacing={2}>
            {
              props.testSuites.map((testSuite) => (
                <Grid item xs={12} md={4} className="mtc-option">
                  <Checkbox
                    onChange={() => props.handleCheck(testSuite.id)}
                    checked={_.get(
                      props.form,
                      'detailsSection.testSuites',
                      [],
                    ).includes(testSuite.id)}
                  />
                  <div className="mtc-item">
                    <h4>{testSuite.title}</h4>
                  </div>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
      </Grid>
    </div>
    <footer>
      <Button
        primary
        disabled={!props.checkDetailsComplete()}
        onClick={props.handleNext}
      >
        Next
      </Button>
    </footer>
  </section>
);

CreateReportDetailsSection.propTypes = {
  form: PropTypes.object.isRequired,
  testSuites: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCheck: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  checkDetailsComplete: PropTypes.func.isRequired,
};

export default CreateReportDetailsSection;
