import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MetricResultsView from '../../components/metrics/ResultsView';
import {
  getMetricsTestResults,
  generateTestInstance,
  getMetricTests,
  receiveMetricTestResultsPoll,
} from '../../actions/metrics';

class MetricsResultsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.pollingCenter = new WebSocket(
      `${process.env.REACT_APP_NOTIFICATION_CENTER_URL}`
        + `plan/${props.planId}/metrics/${props.testSuiteId}/results/${props.testInstanceId}/`,
    );
  }

  componentDidMount = () => {
    this.props.getMetricsTestResults(
      this.props.planId,
      this.props.testSuiteId,
      this.props.testInstanceId,
    );

    if (this.props.testSuites.length === 0) {
      this.props.getMetricTests(this.props.planId);
    }

    this.initializePolling();
  }

  initializePolling = () => {
    this.pollingCenter.onmessage = (event) => {
      const poll = JSON.parse(event.data);

      this.props.receiveMetricTestResultsPoll(poll.message);
    };
  }

  handleGenerateTest = () => {
    const { planId, testSuiteId } = this.props;

    this.props.generateTestInstance(planId, testSuiteId)
      .then((response) => {
        const testSuiteItemId = _.get(response, 'payload.data.id');
        this.pollingCenter.close();
        this.props.history.push(`/editor/${planId}/metrics/${testSuiteId}/results/${testSuiteItemId}`);
        this.pollingCenter = new WebSocket(
          `${process.env.REACT_APP_NOTIFICATION_CENTER_URL}`
            + `plan/${planId}/metrics/${testSuiteId}/results/${testSuiteItemId}/`,
        );
        this.initializePolling();
      });
  }

  handleDownloadReport = () => {
    window.open(_.get(this.props, 'testResult.filename'), '_blank');
  }

  render = () => (
    <MetricResultsView
      {...this.props}
      handleGenerateTest={this.handleGenerateTest}
      handleDownloadReport={this.handleDownloadReport}
      testSuite={_.filter(this.props.testSuites, { id: this.props.testSuiteId }, [])[0]}
    />
  );
}

MetricsResultsContainer.propTypes = {
  districts: PropTypes.array,
  generateTestInstance: PropTypes.func.isRequired,
  getMetricTests: PropTypes.func.isRequired,
  getMetricsTestResults: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  metrics: PropTypes.array,
  planId: PropTypes.string.isRequired,
  results: PropTypes.object,
  receiveMetricTestResultsPoll: PropTypes.func.isRequired,
  testInstanceId: PropTypes.number.isRequired,
  testSuiteId: PropTypes.number.isRequired,
  testSuites: PropTypes.array.isRequired,
};

MetricsResultsContainer.defaultProps = {
  districts: null,
  metrics: null,
  results: null,

};

const mapStateToProps = (state) => ({
  districts: _.get(state, 'plan.chosenPlan.districts'),
  title: _.get(state, 'metrics.testResults.title'),
  testResult: _.get(state, 'metrics.testResults'),
  metrics: _.get(state, 'metrics.testResults.metrics'),
  results: _.get(state, 'metrics.testResults.results'),
  task: _.get(state, 'metrics.testResults.task'),
  testSuites: _.get(state, 'metrics.testSuites'),
});

export default connect(mapStateToProps, {
  getMetricsTestResults,
  generateTestInstance,
  getMetricTests,
  receiveMetricTestResultsPoll,
})(MetricsResultsContainer);
