import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Grid, LinearProgress } from '@material-ui/core';

import { Link } from 'react-router-dom';
import TestSuiteDetails from './TestSuiteDetails';
import ResultsTable from './ResultsTable';
import MetricsMenu from './MetricsMenu';

import './ResultsView.css';

const computeCompletion = (task) => {
  const done = _.get(task, 'result.done', 0);
  const total = _.get(task, 'result.total', 1);
  return (done * 100) / total;
};
const ResultsView = (props) => (
  <section className="shaded-layer-view modal-page">
    <div className="slv-viewport viewport">
      <header>
        <h1>{props.title}</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="slv-content content">
        <MetricsMenu {...props} />
        <div className="slv-activity modal-activity">
          <div className="activity-area">
            <header>
              <h3>Test Results</h3>
            </header>
            <div className="shaded-layer-form inner-activity">
              <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                  <TestSuiteDetails {...props} />
                </Grid>
                {
                  _.get(props, 'testResult.is_complete', false)
                    ? <ResultsTable {...props} />
                    : (
                      <Grid item xs={12} md={12}>
                        <div className="slf-loading">
                          <p>{_.get(props.task, 'result.message', 'Processing...')}</p>
                          <LinearProgress
                            variant="determinate"
                            value={computeCompletion(props.task)}
                          />
                        </div>
                      </Grid>
                    )
                }

              </Grid>
            </div>
            <footer>
              {/* <Button disabled onClick={()=>{}}>Update</Button> */}
            </footer>
          </div>
        </div>
      </section>
    </div>
  </section>

);

ResultsView.propTypes = {
  districts: PropTypes.array.isRequired,
  metrics: PropTypes.array,
  planId: PropTypes.string.isRequired,
  results: PropTypes.object,
  task: PropTypes.object,
  testSuite: PropTypes.object.isRequired,
  title: PropTypes.string,
};

ResultsView.defaultProps = {
  title: '',
  metrics: [],
  results: {},
  task: {},
};

export default ResultsView;
