import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { TextField, MenuItem } from '@material-ui/core';
import Autosuggest from 'react-autosuggest';

import './Search.css';

const renderInputComponent = (inputProps) => {
  const {
    classes, inputRef = () => {}, ref, ...other
  } = inputProps;

  return (
    <TextField
      fullWidth
      className="ems-autosuggest"
      placeholder="Input Search Query"
      InputProps={{
        inputRef: (node) => {
          ref(node);
          inputRef(node);
        },
      }}
      {...other}
    />
  );
};

const renderSuggestion = (suggestion, { /* query, */ isHighlighted }) => (
  <MenuItem selected={isHighlighted} component="div">
    <div>{suggestion.label}</div>
  </MenuItem>
);

const getSuggestionValue = (suggestion) => suggestion.label;

const Search = (props) => (
  <div className="editor-map-search">
    {/* <DropDown
      className="ems-dropdown"
      label={(
        <span className="ems-dropdown-label">
          {_.get(props.searchProps, 'type') || 'Address'}
        </span>
      )}
      options={SEARCH_TYPE}
      onClose={() => {}}
      onChange={(searchType) => props.handleSearchChange('type')(searchType)}
      renderItem={(searchType) => (<span>{searchType}</span>)}
    /> */}
    <Icon icon={faSearch} color="gray" />
    <div className="ems-autosuggest">
      <Autosuggest
        getSuggestionValue={getSuggestionValue}
        renderInputComponent={renderInputComponent}
        suggestions={props.searchResults}
        onSuggestionsClearRequested={() => {
          props.handleSearchChange('query')('');
        }}
        inputProps={{
          placeholder: `Search ${_.get(props.searchProps, 'type')}`,
          value: _.get(props.searchProps, 'query'),
          onChange: (__, { newValue }) => {
            props.handleSearchChange('query')(newValue);
          },
          InputLabelProps: {
            shrink: true,
          },
        }}
        renderSuggestion={renderSuggestion}
        onSuggestionsFetchRequested={props.handleSuggestionFetchRequested}
        onSuggestionSelected={(__, { suggestion }) => {
          props.setFocusGeom(suggestion.geometry);
        }}
        renderSectionTitle={() => {}}
        getSectionSuggestions={() => {}}
      />
    </div>
  </div>
);

Search.propTypes = {
  handleSearchChange: PropTypes.func.isRequired,
  handleSuggestionFetchRequested: PropTypes.func.isRequired,
  searchProps: PropTypes.object.isRequired,
  searchResults: PropTypes.array.isRequired,
  setFocusGeom: PropTypes.func.isRequired,
};

export default Search;
