import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { censusStates, censusDistrictTypes } from '../../helper/censusData';

import './PlanModal.css';

class PlanModal extends React.Component {
  constructor(props) {
    super(props);

    let states = censusStates;
    let planState = '';
    const pdiStates = _.get(this.props, 'auth.settings', '');
    if (pdiStates !== '') {
      states = censusStates.filter((censusState) => pdiStates.split(',').includes(censusState.id));
    }
    if (planState === '') {
      planState = states[0].id;
    }

    this.state = {
      id: null,
      title: null,
      planState,
      description: null,
      districtCount: null,
      districtType: '',
      folderId: '0000',
      states,
    };
  }

  componentDidUpdate = (prevProps) => {
    const prevStates = _.get(prevProps, 'auth.settings', '');
    const pdiStates = _.get(this.props, 'auth.settings', '');
    let states = censusStates;
    let planState = '';

    if (JSON.stringify(prevStates) !== JSON.stringify(pdiStates)) {
      if (pdiStates !== '') {
        states = censusStates.filter((censusState) => pdiStates.split(',').includes(censusState.id));
      }
      if (planState === '') {
        planState = states[0].id;
      }
      this.setState({
        states,
        planState,
      });
    }
  }

  planSubmit = (plan) => {
    const planToSubmit = { ...plan };
    if (_.get(this.props, 'plan.id') && typeof (planToSubmit.folderId) !== 'undefined') {
      delete planToSubmit.folderId;
    }
    if (planToSubmit.folderId === '0000') {
      planToSubmit.folderId = null;
    }
    this.props.handleSubmit(planToSubmit);
  }

  handleOnRendered = () => {
    let states = censusStates;
    let planState = _.get(this.props, 'plan.state', '');
    const pdiStates = _.get(this.props, 'auth.settings', '');
    if (pdiStates !== '') {
      states = censusStates.filter((censusState) => pdiStates.split(',').includes(censusState.id));
    }
    if (planState === '') {
      planState = states[0].id;
    }

    this.setState({
      id: _.get(this.props, 'plan.id'),
      title: _.get(this.props, 'plan.title'),
      planState,
      description: _.get(this.props, 'plan.description'),
      districtCount: _.get(this.props, 'plan.district_count'),
      districtType: _.get(this.props, 'plan.district_type', 'CD'),
      folderId: _.get(this.props, 'plan.folder', '0000'),
    });
  }

  render = () => {
    const { states, planState } = this.state;
    const editMode = !!this.state.id;
    let stateName = states[0].name;
    if (editMode) {
      stateName = _.get(_.find(censusStates, { id: planState }), 'name', '');
    }
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.handleClose}
          onRendered={this.handleOnRendered}
        >
          <div className="modalCont">
            <div className="modal">
              <div className="header">
                <Typography variant="h6" className="title">
                  { editMode ? 'Edit Plan' : 'Create Plan' }
                </Typography>
              </div>
              <div className="modalContent">
                <div className="modalContentCont">
                  <form>
                    <div>
                      <label>Name</label>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ title: e.target.value })}
                        value={this.state.title || ''}
                      />
                    </div>
                    <div>
                      <label>Description</label>
                      <input
                        type="text"
                        onChange={(e) => this.setState({ description: e.target.value })}
                        value={this.state.description || ''}
                      />
                    </div>
                    <div>
                      <label>State</label>
                      { states.length > 1 && !editMode ? (
                        <select
                          onChange={(e) => this.setState({ planState: e.target.value })}
                          value={this.state.planState}
                        >
                          {
                            states.map((censusState) => (
                              <option
                                key={censusState.id}
                                value={censusState.id}
                              >
                                {censusState.name}
                              </option>
                            ))
                           }
                        </select>
                      ) : <div className="stateName">{stateName}</div>}
                    </div>
                    <div>
                      <label>Number of Districts</label>
                      <input
                        type="number"
                        min="0"
                        value={this.state.districtCount || ''}
                        onChange={(e) => {
                          this.setState({ districtCount: parseInt(e.target.value, 10) });
                        }}
                      />
                    </div>
                    <div>
                      <label>DistrictType</label>
                      <select
                        onChange={(e) => this.setState({ districtType: e.target.value })}
                        value={this.state.districtType}
                      >
                        {
                          censusDistrictTypes.map((censusDistrictType) => (
                            <option key={censusDistrictType.id} value={censusDistrictType.id}>
                              {censusDistrictType.name}
                            </option>
                          ))
                        }
                      </select>
                    </div>
                    { !_.get(this.props, 'plan.id') && (
                      <div>
                        <label>Folder</label>
                        <select
                          onChange={(e) => this.setState({ folderId: e.target.value })}
                          value={this.state.folderId || '0000'}
                        >
                          <option key="0000" value="0000">
                            None
                          </option>
                          {
                            this.props.folders.map((folder) => (
                              <option key={folder.id} value={folder.id}>
                                {folder.name}
                              </option>
                            ))
                          }
                        </select>
                      </div>
                    )}
                  </form>
                </div>
              </div>
              <div className="footer">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this.props.handleClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.planSubmit({ ...this.state })}
                >
                  { this.state.id ? 'Update' : 'Submit' }
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

PlanModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  folders: PropTypes.array.isRequired,
};

export default PlanModal;
