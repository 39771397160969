import _ from 'lodash';

export const districtIdsArrayToMap = (district) => {
  const newDistrict = { ...district };
  const ids = _.get(district, 'settings.ids', {});
  Object.keys(ids).forEach((censusType) => {
    const idArray = _.get(ids, censusType, []);
    if (Array.isArray(idArray)) {
      const idMap = idArray.reduce((map, key) => {
        const newMap = { ...map };
        newMap[key] = true;
        return newMap;
      }, {});
      newDistrict.settings.ids[censusType] = idMap;
    }
  });
  return newDistrict;
};

export const districtsIdsArrayToMap = (districts) => {
  const newDistricts = [...districts];
  const returnDistricts = [];
  newDistricts.forEach((district) => {
    returnDistricts.push(districtIdsArrayToMap(district));
  });
  return returnDistricts;
};

export const getMinArea = (censusAreaType = null, zoom) => {
  let minArea = 0;
  if (censusAreaType === 'block' || censusAreaType === null) {
    if (zoom >= 13) {
      minArea = 500;
    } else if (zoom <= 12) {
      minArea = 10000;
    }
  } else if (censusAreaType === 'county') {
    minArea = 225000;
  } else if (censusAreaType === 'tract') {
    minArea = 52000;
  } else if (censusAreaType === 'group') {
    minArea = 26000;
  }

  return minArea;
};

export const removeGaps = (geom, censusAreaType, zoom) => {
  // get out gaps
  // const t0 = performance.now();
  const polygons = geom.getPolygons();
  const polyCoords = [];
  polygons.forEach((polygon) => {
    const linearRings = polygon.getLinearRings();
    const ringCoords = [];
    linearRings.forEach((linearRing) => {
      const coordinates = linearRing.getCoordinates();
      const area = linearRing.getArea();
      if (Math.abs(area) > getMinArea(censusAreaType, zoom)) {
      // if (coordinates.length > 11) {
        ringCoords.push(coordinates);
      }
      // console.log('** linear rings',
      //  linearRing, linearRing.getCoordinates(), linearRing.getArea(), ringCoords);
      // if (area < 500000 && area >= getMinArea(censusAreaType, zoom)) {
      //         console.log('**', zoom, getMinArea(censusAreaType, zoom), area);
      //       }
    });
    if (ringCoords.length > 0) {
      polyCoords.push(ringCoords);
    }
  });
  // const t1 = performance.now();
  // console.log('*** remove gaps', t1 - t0);
  return polyCoords;
};

// export const getLargerCensusTypes = (censusType) => {
//   if (censusType === 'county') {
//     return ['county'];
//   } else if (censusType === 'tract') {
//     return ['county', 'tract'];
//   } else if (censusType === 'group') {
//     return ['county', 'tract', 'group'];
//   } else if (censusType === 'block') {
//     return ['county', 'tract', 'group', 'block'];
//   }
// }
export const toCamel = (obj) => _.mapKeys(obj, (v, k) => _.camelCase(k));
