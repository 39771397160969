import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Numeral from 'numeral';

import './Legends.css';

const Legends = (props) => (
  <div className="editor-map-legends">
    <h5>Legends</h5>
    {_.get(props.shadedLayer, 'choropleth_progression') !== null
      ? (
        <ul>
          {_.get(props.shadedLayer, 'choropleth_progression', []).map((perc, ind) => {
            const key = `legend_${_.get(props.shadedLayer, `color_range[${ind}]`, 0)}`;
            return (
              <li key={key} style={{ backgroundColor: _.get(props.shadedLayer, `color_range[${ind}]`, 0) }}>
                <span>
                  { Numeral(perc).format('0.0%') }
                </span>
              </li>
            );
          })}
        </ul>
      )
      : null}
  </div>
);

Legends.propTypes = {
  shadedLayer: PropTypes.object,
};

Legends.defaultProps = {
  shadedLayer: {},
};

export default Legends;
