import React from 'react';
import PropTypes from 'prop-types';

import PlanRow from './PlanRow';
import FolderRow from './FolderRow';

const PlanList = (props) => (
  <div className="myPlans">
    <table className="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Owner</th>
          <th>Date</th>
          <th>State</th>
          <th>Type</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {props.folders.map((folder) => (
          <FolderRow
            auth={props.auth}
            folder={folder}
            folders={props.folders}
            key={`folder_${folder.id}`}
            handleUpdatePlan={props.handleUpdatePlan}
            handleDeletePlan={props.handleDeletePlan}
            handlePublishPlan={props.handlePublishPlan}
            handleUpdateFolder={props.handleUpdateFolder}
            handleDeleteFolder={props.handleDeleteFolder}
            handleMovePlanToFolder={props.handleMovePlanToFolder}
            handleClonePlan={props.handleClonePlan}
            arePrivatePlans={props.arePrivatePlans}
          />
        ))}

        {props.plans.map((plan) => (
          <PlanRow
            auth={props.auth}
            plan={plan}
            folders={props.folders}
            key={plan.id}
            isInFolder={false}
            handleUpdatePlan={props.handleUpdatePlan}
            handleDeletePlan={props.handleDeletePlan}
            handlePublishPlan={props.handlePublishPlan}
            handleMovePlanToFolder={props.handleMovePlanToFolder}
            handleClonePlan={props.handleClonePlan}
            arePrivatePlans={props.arePrivatePlans}
          />
        ))}

      </tbody>
    </table>
  </div>
);

PlanList.propTypes = {
  folders: PropTypes.array.isRequired,
  plans: PropTypes.array.isRequired,
  arePrivatePlans: PropTypes.bool.isRequired,
  handleDeletePlan: PropTypes.func.isRequired,
  handlePublishPlan: PropTypes.func.isRequired,
  handleUpdatePlan: PropTypes.func.isRequired,
  handleUpdateFolder: PropTypes.func.isRequired,
  handleDeleteFolder: PropTypes.func.isRequired,
  handleMovePlanToFolder: PropTypes.func.isRequired,
  handleClonePlan: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default PlanList;
