import React from 'react';
import _ from 'lodash';
import { Grid, Switch, Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import PropTypes from 'prop-types';

import SettingsView from './SettingsView';
import Button from '../common/Button';
import DropDown from '../common/DropDown';

import { DISTRICT_ALLOWED_DEVIATION_TYPES } from '../../constants';

import './DistrictSettings.css';

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const AllowedDeviationSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus,&:hover,&$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    display: 'none',
    left: 'calc(-50% + 11px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

const DistrictSettings = (props) => (
  <SettingsView abbrev="ds" className="district-settings" {...props}>
    <section className="activity-area">
      <header>
        <h3>
          District Settings
        </h3>
      </header>
      <div className="inner-activity">
        <Grid container spacing={5}>
          <Grid item xs={5}>
            <h4>District Coloring</h4>
            <div className="ds-color-item">
              <Switch
                checked={props.isDistrictColored}
                color="primary"
                onChange={
                  () => {
                    props.handleChange('isDistrictColored')(!props.isDistrictColored);
                  }
                }
              />
              <span>Turn on color for commited districts</span>
            </div>
          </Grid>
          <Grid item xs={7} />

          <Grid item xs={12} md={5}>
            <h4>Allowed Deviation Type</h4>
            <div className="">
              <DropDown
                top
                className="sl-dropdown default-dropdown"
                label={props.allowedDeviationType ? props.allowedDeviationType.label : 'Choose Deviation Type'}
                options={DISTRICT_ALLOWED_DEVIATION_TYPES}
                onClose={() => {}}
                onChange={(deviationType) => {
                  props.handleChange('allowedDeviationType')(deviationType);
                }}
                renderItem={(item) => (item.label)}
              />
            </div>
          </Grid>
          <Grid item xs={0} md={7} />

          <Grid item xs={12} md={5}>
            <h4>Allowed Deviation</h4>
            <div className={`ds-allowed-dev ${_.get(props.allowedDeviationType, 'value') === 'allowed-deviation-percentage' ? 'ds-perc' : ''}`}>
              <input
                type="number"
                className="ds-allowed"
                value={props.allowedDeviation}
                onChange={(e) => { props.handleChange('allowedDeviation')(e.target.value); }}
              />
              <AllowedDeviationSlider
                aria-label="ios slider"
                value={
                  _.get(props.allowedDeviationType, 'value') === 'allowed-deviation-percentage'
                    ? _.get(props, 'allowedDeviation', 0)
                    : (_.get(props, 'allowedDeviation', 0) * 100) / props.maxDeviation
                }
                marks={[{ value: 0 }, { value: 100 }]}
                step={0.01}
                valueLabelDisplay="on"
                onChange={
                  (__, newValue) => {
                    if (
                      _.get(props.allowedDeviationType, 'value') === 'allowed-deviation-person'
                    ) {
                      props.handleChange('allowedDeviation')(Math.floor(props.maxDeviation * (newValue / 100)));
                    } else {
                      props.handleChange('allowedDeviation')(newValue);
                    }
                  }
                }
              />
            </div>
          </Grid>

        </Grid>
      </div>
      <footer>
        <Button grayed onClick={props.revert}>Revert</Button>
        <Button primary onClick={props.handleSubmit}>Save</Button>
      </footer>
    </section>
  </SettingsView>
);

DistrictSettings.propTypes = {
  isDistrictColored: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  revert: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  allowedDeviationType: PropTypes.string,
  allowedDeviation: PropTypes.number,
  maxDeviation: PropTypes.number,
};

DistrictSettings.defaultProps = {
  allowedDeviationType: null,
  allowedDeviation: 0,
  maxDeviation: 0,

};
export default DistrictSettings;
