import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { TextField, Grid } from '@material-ui/core';

import Button from '../common/Button';

const CreateReportDistrictSection = (props) => (
  <section className="column-settings-form activity-area">
    <header>
      <h3>
        Report District Section (Optional)
      </h3>
    </header>
    <div className="csf-inner-activity-area inner-activity">
      <p>Fill out the name and description of each district</p>
      <Grid container spacing={5}>
        {
          _.get(props, 'districts', []).map((district, index) => (
            <Grid item xs={12} key={district.id}>
              <h5>DISTRICT ALIAS</h5>
              <TextField
                className="bsf-text"
                defaultValue=""
                value={_.get(props.form, `districtSection[${index}].alias`) || ''}
                placeholder="Title of the Report you're building"
                fullWidth
                onChange={props.handleDistrictChange(index, 'alias')}
                InputLabelProps={{ shrink: true }}
                style={{ marginBottom: 10 }}
              />

              <h5>DISTRICT DESCRIPTION</h5>
              <TextField
                className="bsf-text"
                defaultValue=""
                id="outlined-multiline-static"
                multiline
                rows="4"
                variant="outlined"
                fullWidth
                value={_.get(props.form, `districtSection[${index}].description`) || ''}
                placeholder="Description of the District"
                onChange={props.handleDistrictChange(index, 'description')}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          ))
        }
      </Grid>
    </div>
    <footer>
      <Button grayed onClick={props.handleBack}>Back</Button>
      <Button primary onClick={props.handleNext}>Next</Button>
    </footer>
  </section>
);

CreateReportDistrictSection.propTypes = {
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleDistrictChange: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
};

export default CreateReportDistrictSection;
