import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetricsTestSuiteView from '../../components/metrics/TestSuiteView';
import { getMetricTests, generateTestInstance } from '../../actions/metrics';

class MetricsTestSuiteContainer extends React.Component {
  componentDidMount = () => {
    this.props.getMetricTests(this.props.planId);
  }

  handleGenerateTest = () => {
    const { testSuiteId, planId } = this.props;
    this.props.generateTestInstance(planId, testSuiteId)
      .then((response) => {
        const testSuiteItemId = _.get(response, 'payload.data.id');
        this.props.history.push(
          `/editor/${planId}/metrics/${testSuiteId}/results/${testSuiteItemId}`,
        );
      });
  }

  render = () => (
    <MetricsTestSuiteView
      {...this.props}
      testSuite={_.filter(this.props.testSuites, { id: this.props.testSuiteId }, [])[0]}
      handleGenerateTest={this.handleGenerateTest}
    />
  );
}

MetricsTestSuiteContainer.propTypes = {
  getMetricTests: PropTypes.func.isRequired,
  generateTestInstance: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  planId: PropTypes.string.isRequired,
  testSuites: PropTypes.array.isRequired,
  testSuiteId: PropTypes.number.isRequired,
};

MetricsTestSuiteContainer.defaultProps = {

};

const mapStateToProps = (state) => ({
  testSuites: _.get(state, 'metrics.testSuites'),
});
export default connect(mapStateToProps, {
  getMetricTests,
  generateTestInstance,
})(MetricsTestSuiteContainer);
