import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';

import Button from '../common/Button';
import ColumnSettingsSearch from './ColumnSettingsSearch';

import './ColumnSettingsForm.css';

const ColumnSettingsItem = (props) => {
  const pillClass = `pill pill-${props.column.data_source}`;
  return (
    <li>
      <div className="csf-item">
        <h5>{props.column.name}</h5>
        <span>{props.column.description}</span>
        <span className={pillClass}>{props.column.data_source_display_name}</span>
      </div>
      <Switch
        checked={props.checked}
        color="primary"
        onChange={() => (props.checked
          ? props.removeColumn(props.column.id)
          : props.addColumn(props.column.id))}
      />
    </li>
  );
};

ColumnSettingsItem.propTypes = {
  addColumn: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  column: PropTypes.object.isRequired,
  removeColumn: PropTypes.func.isRequired,
};

const ColumnSettingsForm = (props) => (
  <section className="column-settings-form activity-area">
    <header>
      <h3>
        Columns Settings
      </h3>
    </header>
    <div className="csf-inner-activity-area inner-activity">
      <ColumnSettingsSearch {...props} />
      <div className="csf-import-activity">
        <ul>
          {props.columns && props.columns.map((column) => (
            <ColumnSettingsItem
              key={column.id}
              {...props}
              column={column}
              checked={_.includes(props.form.columns, column.id)}
            />
          ))}
        </ul>
      </div>
    </div>
    <footer>
      <Button grayed onClick={props.revert}>Revert</Button>
      <Button primary onClick={props.handleSubmit}>Save</Button>
    </footer>
  </section>
);

ColumnSettingsForm.propTypes = {
  columns: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  revert: PropTypes.func.isRequired,
};

ColumnSettingsForm.defaultProps = {

};

export default ColumnSettingsForm;
