import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import './ReportDetails.css';

const ReportDetails = (props) => (
  <div className="report-details">
    <h1>{_.get(props.chosenReport, 'name')}</h1>
    <p>{_.get(props.chosenReport, 'description')}</p>
  </div>
);

ReportDetails.propTypes = {
  chosenReport: PropTypes.object.isRequired,
};

export default ReportDetails;
