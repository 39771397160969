import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import {
  Grid, Table, TableBody, TableCell, TableHead, TableRow,
} from '@material-ui/core';
import ReportMenu from './ReportMenu';

const ListReportView = (props) => (
  <section className="view-report modal-page">
    <div className={`bs-viewport viewport ${props.isSubmitting ? 'bs-submitting' : ''}`}>
      <header>
        <h1>Reports</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="bs-content content">
        <ReportMenu {...props} />
        <div className="bs-activity modal-activity">
          <div className="activity-area">
            <header>
              <h3>Report Listing</h3>
            </header>
            <div className="vrv-inner-activity inner-activity">
              <Grid container spacing={5}>
                <Grid item xs={12} md={8}>
                  <Table size="small" aria-label="acs-table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Report Name</TableCell>
                        <TableCell align="lefts">Description</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        _.get(props, 'reports', []).map((report) => (
                          <TableRow key={report.id}>
                            <TableCell>{report.name}</TableCell>
                            <TableCell>{report.description}</TableCell>
                            <TableCell align="right">
                              <Link to={`/editor/${props.planId}/report/${report.id}/view`}>View</Link>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
);

ListReportView.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  planId: PropTypes.string.isRequired,
};
export default ListReportView;
