import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { LinearProgress } from '@material-ui/core';
import ImportedDistrictUploadArea from './ImportedDistrictUploadArea';
import ImportedDistrictUploadDetail from './ImportedDistrictUploadDetail';
import Button from '../common/Button';
import './ImportedDistrictUpload.css';

const computeCompletion = (task) => {
  const done = _.get(task, 'result.done', 0);
  const total = _.get(task, 'result.total', 1);
  return (done * 100) / total;
};

const ImportedDistrictUpload = (props) => {
  const taskId = _.get(props, 'importedDistrictPolls[0].task.result.task_id', null);
  const taskMessage = _.get(props, 'importedDistrictPolls[0].task.result.message', 'Processing...');
  const task = _.get(props, 'importedDistrictPolls[0].task', {});
  return (
    <section className="import-district-upload activity-area">
      <header>
        <h3>
          Upload District:
          {!props.importDetails ? 'Upload File' : 'Basic Info & Column Mapping'}
        </h3>
        {
          (taskId !== null)
            ? <div className="slf-loading">
                <p>{taskMessage}</p>
                <LinearProgress
                  variant="determinate"
                  value={computeCompletion(task)}
                />
              </div>
            : null
        }
      </header>
      <div className="idu-inner-activity-area inner-activity">
        <div className="idu-import-activity">
          {props.uploadStep === 'IMPORTED_DISTRICT_UPLOAD' && <ImportedDistrictUploadArea {...props} /> }
          {props.uploadStep === 'IMPORTED_DISTRICT_DETAILS' && <ImportedDistrictUploadDetail {...props} /> }
        </div>
      </div>
      {props.uploadStep === 'IMPORTED_DISTRICT_DETAILS'
        && (
        <footer>
          {
              taskId === null
                ? (
                  <Button
                    primary={props.isValid}
                    disabled={!props.isValid}
                    onClick={props.handleSubmit}
                  >
                    Publish
                  </Button>
                )
                : (<Button disabled onClick={() => {}}>Loading</Button>)
            }
        </footer>
        )}
    </section>
  );
};

ImportedDistrictUpload.defaultProps = {
  importDetails: {},
  importedDistrictPolls: [],
};

ImportedDistrictUpload.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  importDetails: PropTypes.object,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  uploadStep: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  importedDistrictPolls: PropTypes.array,
};

export default ImportedDistrictUpload;
