import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid } from '@material-ui/core';

import Button from '../common/Button';

const CreateReportReviewSection = (props) => (
  <section className="column-settings-form activity-area">
    <header>
      <h3>
        Report Review Section
      </h3>
    </header>
    <div className="csf-inner-activity-area inner-activity">
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <h3>Report Details</h3>
          <p>{_.get(props.form, 'detailsSection.name', '')}</p>
          <h4>Report Description</h4>
          <p>{_.get(props.form, 'detailsSection.description', '')}</p>
          <h4>Tests Included</h4>
          <ul>
            {
              _.get(props.form, 'detailsSection.testSuites', []).map(
                (testSuiteId) => (
                  <li>{_.get(_.filter(props.testSuites, { id: testSuiteId })[0], 'title')}</li>
                ),
              )
            }
          </ul>
        </Grid>

      </Grid>
    </div>
    <footer>
      <Button grayed onClick={props.handleBack}>Back</Button>
      <Button primary onClick={props.handleSubmit}>Finish</Button>
    </footer>
  </section>
);

CreateReportReviewSection.propTypes = {
  form: PropTypes.object.isRequired,
  testSuites: PropTypes.array.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default CreateReportReviewSection;
