import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetricsQueryBuilderView from '../../components/metrics/QueryBuilderView';
import { getMeasurements, updateMetric, getMetrics } from '../../actions/metrics';

class MetricsMetricItemContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: null,
        name: null,
        description: null,
        markup: '',
      },
    };
  }

  componentDidMount = () => {
    this.props.getMeasurements();
    this.props.getMetrics()
      .then(() => {
        const metricId = _.get(this.props, 'metricId', null);
        if (metricId) {
          const metric = _.filter(this.props.metrics, { id: parseInt(metricId, 10) });
          if (metric && metric.length > 0) {
            this.setState({
              form: {
                name: _.get(metric[0], 'name', ''),
                description: _.get(metric[0], 'description', ''),
                markup: _.get(metric[0], 'markup', '') || '',
                id: _.get(metric[0], 'id', ''),
              },
            });
          }
        }
      });
  }

  handleClick = (e) => {
    const { target } = e;
    const range = document.createRange();
    range.selectNode(target);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  handleChange = (name) => (e) => {
    const { value } = e.target;
    this.setState((prevProps) => ({
      form: {
        ...prevProps.form,
        [name]: value,
      },
    }));
  }

  handleSubmit = () => {
    const { name, description, markup } = this.state.form;
    /* TODO
        - After creating metric, go to "Show Metric" page [BAL-302]
    */
    this.props.updateMetric(this.props.metricId, name, description, markup);
    // .then(() => {
    //   this.props.history.push(
    //     `/editor/${this.props.planId}/metrics/metric-list`,
    //   );
    // });
  }

  render = () => (
    <MetricsQueryBuilderView
      {...this.props}
      {...this.state}
      handleChange={this.handleChange}
      handleSubmit={this.handleSubmit}
    />
  );
}

MetricsMetricItemContainer.propTypes = {
  createMetric: PropTypes.func.isRequired,
  getMeasurements: PropTypes.func.isRequired,
  getMetrics: PropTypes.array.isRequired,
  getMetricTests: PropTypes.func.isRequired,
  measurements: PropTypes.array,
  metrics: PropTypes.array.isRequired,
  metricId: PropTypes.number.isRequired,
  planId: PropTypes.string.isRequired,
  updateMetric: PropTypes.func.isRequired,
};

MetricsMetricItemContainer.defaultProps = {
  measurements: [],
};

const mapStateToProps = (state) => ({
  metrics: _.get(state, 'metrics.metrics'),
  measurements: _.get(state, 'metrics.measurements'),
  queryBuilderError: _.get(state, 'metrics.queryBuilderError'),
});
export default connect(mapStateToProps, {
  getMeasurements,
  updateMetric,
  getMetrics,
})(MetricsMetricItemContainer);
