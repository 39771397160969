import _ from 'lodash';

const defaultState = {
  testSuites: [],
  testResults: {},
  isLoading: false,
  metrics: [],
  isSubmitting: false,
  measurements: [],
  queryBuilderError: null,
  spatialReferences: [],
};

const MetricsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'BALLISTA_LOGOUT': {
      return defaultState;
    }
    case 'METRIC_DELETE_METRIC_SUCCESS': {
      const metrics = [...state.metrics];
      const metricId = _.get(action, 'meta.previousAction.metricId');

      _.remove(metrics, { id: metricId });

      return {
        ...state,
        metrics,
      };
    }
    case 'METRIC_UPDATE_METRIC_SUCCESS': {
      const metrics = [...state.metrics];
      const updatedMetric = _.get(action, 'payload.data');
      metrics.splice(
        _.findIndex(metrics, { id: _.get(updatedMetric, 'id') }), 1, updatedMetric,
      );

      return {
        ...state,
        metrics,
      };
    }
    case 'METRIC_CREATE_METRIC': {
      return {
        ...state,
        queryBuilderError: null,
      };
    }
    case 'METRIC_UPDATE_METRIC_FAIL':
    case 'METRIC_CREATE_METRIC_FAIL': {
      return {
        ...state,
        queryBuilderError: _.get(action, 'error.response.data'),
      };
    }
    case 'METRIC_CREATE_METRIC_SUCCESS': {
      return {
        ...state,
        measurements: [...state.measurements, _.get(action, 'payload.data')],
      };
    }
    case 'METRIC_GET_MEASUREMENTS_SUCCESS': {
      return {
        ...state,
        measurements: _.get(action, 'payload.data'),
      };
    }
    case 'METRIC_GET_SPATIAL_REFERENCE_SUCCESS': {
      return {
        ...state,
        spatialReferences: _.get(action, 'payload.data'),
      };
    }
    case 'METRIC_GET_METRICS_SUCCESS': {
      return {
        ...state,
        metrics: _.get(action, 'payload.data'),
      };
    }
    case 'METRIC_GET_TEST_SUITES_SUCCESS':
      return {
        ...state,
        testSuites: _.get(action, 'payload.data'),
      };
    case 'METRIC_GET_TEST_RESULTS':
      return {
        ...state,
        testResults: null,
        isLoading: true,
      };
    case 'METRIC_GET_TEST_RESULTS_SUCCESS':
      return {
        ...state,
        testResults: _.get(action, 'payload.data'),
        isLoading: false,
      };

    case 'METRIC_GENERATE_TEST_SUCCESS':
    case 'METRIC_POLL_TEST_RESULT': {
      return {
        ...state,
        testResults: _.get(action, 'payload.data'),
      };
    }
    case 'METRIC_GET_TEST_RESULTS_FAIL':
      return {
        ...state,
        isLoading: false,
      };
    case 'METRIC_CREATE_METRIC_TESTS':
      return {
        ...state,
        isSubmitting: true,
      };
    case 'METRIC_CREATE_METRIC_TESTS_SUCCESS': {
      const testSuites = [...state.testSuites];
      const newTestSuite = _.get(action, 'payload.data');

      return {
        ...state,
        isSubmitting: false,
        testSuites: [
          ...testSuites,
          newTestSuite,
        ],
      };
    }
    default:
      return state;
  }
};

export default MetricsReducer;
