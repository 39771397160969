import React from 'react';
import PropTypes from 'prop-types';

class Event extends React.Component {
  componentDidMount() {
    this.props.map.on(this.props.event, this.props.handler);
  }

  componentWillUnmount() {
    this.props.map.un(this.props.event, this.props.handler);
  }

  render = () => null;
}

Event.propTypes = {
  event: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  map: PropTypes.object.isRequired,
};

export default Event;
