import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import './FolderModal.css';

class FolderModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
    };
  }

  componentWillUnmount() {
    this.clearForm();
  }

  clearForm = () => {
    this.setState({
      id: '',
      name: '',
    });
  }

  handleCloseModal = () => {
    this.clearForm();
    this.props.handleClose();
  }

  handleOnRendered = () => {
    this.setState({
      id: _.get(this.props.folder, 'id'),
      name: _.get(this.props.folder, 'name'),
    });
  }

  render = () => (
    <>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.props.handleClose}
        onRendered={this.handleOnRendered}
      >
        <div className="modal-cont">
          <div className="modal">
            <div className="header">
              <Typography variant="h6" className="title">
                { this.state.id ? 'Edit Folder' : 'Create Folder' }
              </Typography>
            </div>
            <div className="modal-content">
              <div className="modal-content-cont">
                <form>
                  <div>
                    <label>Name</label>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ name: e.target.value })}
                      value={this.state.name || ''}
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="footer">
              <Button
                variant="contained"
                color="secondary"
                onClick={this.handleCloseModal}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.props.handleSubmit({ ...this.state })}
              >
                { this.state.id ? 'Update' : 'Submit' }
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

FolderModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  folder: PropTypes.object,
};

FolderModal.defaultProps = {
  folder: {},
};

export default FolderModal;
