import _ from 'lodash';

const defaultState = {
  uploadedGuideLayer: null,
  step: 'GUIDE_LAYER_UPLOAD',
  isSubmitting: false,
  publishedGuideLayers: [],
};

const GuideLayerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'BALLISTA_LOGOUT': {
      return defaultState;
    }
    case 'GUIDE_LAYER_UPLOAD_SUCCESS':
      return {
        ...state,
        uploadedGuideLayer: _.get(action, 'payload.data.body'),
        step: 'GUIDE_LAYER_DETAILS',
      };
    case 'GUIDE_LAYER_SUBMIT_DETAILS':
      return {
        ...state,
        isSubmitting: true,
      };
    case 'GUIDE_LAYER_GET_GUIDE_LAYERS_SUCCESS':
      return {
        ...state,
        publishedGuideLayers: _.get(action, 'payload.data'),
      };
    case 'GUIDE_LAYER_SUBMIT_DETAILS_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
        uploadedGuideLayer: {},
        step: 'GUIDE_LAYER_UPLOAD',
        publishedGuideLayers: [
          ...state.publishedGuideLayers,
          _.get(action, 'payload.data'),
        ],
      };
    case 'GUIDE_LAYER_UPDATE_SETTINGS_SUCCESS':
      return {
        ...state,
        publishedGuideLayers: _.get(action, 'payload.data'),
      };
    case 'GUIDE_LAYER_GET_DEFAULT_GUIDE_LAYERS_SUCCESS':
      return {
        ...state,
        defaultGuideLayers: _.get(action, 'payload.data'),
      };
    default:
      return state;
  }
};

export default GuideLayerReducer;
