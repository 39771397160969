import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';

import './ErrorModal.css';

const ErrorModal = (props) => (
  <div className="unauthorized-page">
    <div className="unauthorized-viewport">
      <div className="unauthorized-area">
        <div>
          <Icon icon={faInfoCircle} size="2x" />
          <span>{props.message}</span>
        </div>
        <div>
          <Button primary="true" onClick={props.clearMessage}>Close</Button>
        </div>
      </div>
    </div>
  </div>
);

ErrorModal.propTypes = {
  message: PropTypes.string.isRequired,
  clearMessage: PropTypes.func.isRequired,
};

export default ErrorModal;
