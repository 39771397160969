import React from 'react';
import { Map as _Map, View } from 'ol';
import PropTypes from 'prop-types';
import { fromLonLat } from 'ol/proj';
import { defaults as defaultInteractions } from 'ol/interaction';
import 'ol/ol.css';

/**
 * This class would be the one to pass the map & view elements.
 */
const uniqueId = Math.floor(Math.random() * 20000);

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.view = null;

    this.state = {
      isLoaded: false,
    };
  }

  componentDidMount() {
    this.view = new View({
      center: fromLonLat([-118.243683, 34.052235]),
      zoom: 12,
      projection: 'EPSG:900913',
    });

    this.map = new _Map({
      interactions: defaultInteractions(),
      target: `${uniqueId}-parent-redistricting-map`,
      view: this.view,
    });

    this.map.setProperties({ debug: false });

    this.props.setMap(this.map);

    this.initializeView();

    // Put a slight delay to start listening on the actual first events...
    setTimeout(() => {
      this.initializeEvents();
    }, 500);

    this.setState({ isLoaded: true });

    if (typeof this.props.mouseoutHandler === 'function') {
      this.map.getViewport().addEventListener('mouseout', this.props.mouseoutHandler, false);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.viewport !== this.props.viewport) {
      this.initializeView();
    }
  }

  componentWillUnmount() {
    if (!!this.props.onMoveEnd && typeof this.props.onMoveEnd === 'function') {
      this.map.un('moveend', this.props.onMoveEnd);
    }
  }

  initializeView = () => {
    if (this.props.viewport) {
      this.map.getView().fit(this.props.viewport, { nearest: true });
      this.props.setZoom(this.map.getView().getZoom());
    }
  }

  initializeEvents = () => {
    if (!!this.props.onMoveEnd && typeof this.props.onMoveEnd === 'function') {
      this.map.on('moveend', this.props.onMoveEnd);
    }
  }

  render = () => (
    <div id={`${uniqueId}-parent-redistricting-map`} className={this.props.className || ''}>
      { this.map && this.state.isLoaded
          && React.Children.map(this.props.children, (child) => (child === null ? <></>
            : React.cloneElement(child, { map: this.map, view: this.view })))}
    </div>
  );
}

Map.propTypes = {
  children: PropTypes.array.isRequired,
  className: PropTypes.any,
  onMoveEnd: PropTypes.func,
  setMap: PropTypes.func.isRequired,
  setZoom: PropTypes.func,
  viewport: PropTypes.any,
  mouseoutHandler: PropTypes.func,
};

Map.defaultProps = {
  onMoveEnd: () => {},
  className: '',
  viewport: null,
  setZoom: () => {},
  mouseoutHandler: () => {},
};
export default Map;
