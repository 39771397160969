import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import './ClonePlanModal.css';

class ClonePlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentId: null,
      title: null,
      folderId: '',
    };
  }

  planSubmit = (plan) => {
    const planToSubmit = { ...plan };
    if (planToSubmit.folderId === '0000') {
      planToSubmit.folderId = null;
    }
    this.props.handleSubmit(planToSubmit);
  }

  handleOnRendered = () => {
    this.setState({
      parentId: _.get(this.props.plan, 'id'),
      title: `Copy of ${_.get(this.props.plan, 'title')}`,
      folderId: _.get(this.props.plan, 'folder'),
    });
  }

  render = () => (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.props.handleClose}
        onRendered={this.handleOnRendered}
      >
        <div className="modal-cont">
          <div className="modal">
            <div className="header">
              <Typography variant="h6" className="title">
                Copy Plan
              </Typography>
            </div>
            <div className="modal-content">
              <div className="modal-content-cont">
                <form>
                  <div>
                    <label>Name</label>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ title: e.target.value })}
                      value={this.state.title || ''}
                    />
                  </div>
                  <div>
                    <label>Folder</label>
                    <select
                      onChange={(e) => this.setState({ folderId: e.target.value })}
                      value={this.state.folderId || '0000'}
                    >
                      <option key="0000" value="0000">
                        None
                      </option>
                      {
                        this.props.folders.map((folder) => (
                          <option key={folder.id} value={folder.id}>
                            {folder.name}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </form>
              </div>
            </div>
            <div className="footer">
              <Button
                variant="contained"
                color="secondary"
                onClick={this.props.handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => this.planSubmit({ ...this.state })}
              >
                Copy Plan
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

ClonePlanModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  folders: PropTypes.array.isRequired,
  plan: PropTypes.object.isRequired,
};

export default ClonePlanModal;
