import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import {
  faDownload, faCompass, faChartBar, faDatabase,
} from '@fortawesome/free-solid-svg-icons';
import './EditorNotificationItem.css';
import Button from '../../common/Button';

const getIcon = (type) => {
  switch (type) {
    case 'EXPORT':
      return faDownload;
    case 'SHADED_LAYER':
      return faCompass;
    case 'SCORE':
      return faChartBar;
    case 'UNIVERSE':
      return faDatabase;
    default:
      return null;
  }
};

const buildUrl = (type, metadata) => {
  switch (type) {
    case 'EXPORT': {
      const url = _.get(metadata, 'url');
      return url;
    }
    case 'SHADED_LAYER': {
      const planId = _.get(metadata, 'plan_id');
      const shadedLayerId = _.get(metadata, 'shaded_layer_id');
      return `/editor/${planId}/shaded-layer/${shadedLayerId}/activate`;
    }
    case 'SCORE': {
      const planId = _.get(metadata, 'plan_id');
      const testSuiteId = _.get(metadata, 'test_suite_id');
      const testInstanceId = _.get(metadata, 'test_instance_id');
      const url = `/editor/${planId}/metrics/${testSuiteId}/results/${testInstanceId}`;
      return url;
    }
    default:
      return null;
  }
};

const isExternal = (url) => /^https?:\/\//.test(url);

const EditorNotificationItem = (props) => {
  const url = buildUrl(props.type, props.metadata);
  const isExt = isExternal(url);
  return (
    <li className={'editor-notification-item'
                  + ` ${!_.get(props, 'is_seen', true) ? 'eni-unseen' : ''} `
                  + ` ${!_.get(props, 'is_read', true) ? 'eni-unread' : ''}`}
    >
      <Button
        className="item"
        onClick={
          () => {
            if (isExt) {
              window.open(url, '_blank');
            } else if (url === null) {
              return null;
            } else {
              window.open(url, '_self');
            }
            return null;
          }
        }
      >
        <div className="eni-image">
          <Icon icon={getIcon(props.type)} size="2x" color="lightgray" />
        </div>
        <div className="eni-info">
          <h5>{props.title}</h5>
          <p>
            {props.message}
          </p>
        </div>
      </Button>
    </li>
  );
};

EditorNotificationItem.propTypes = {
  message: PropTypes.string.isRequired,
  metadata: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default EditorNotificationItem;
