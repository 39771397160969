import React from 'react';
import PropTypes from 'prop-types';
import Stamen from 'ol/source/Stamen';
import TileLayer from 'ol/layer/Tile';

class StamenLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseLayer: null,
      labelLayer: null,
    };
  }

  componentDidMount() {
    if (this.props.parent !== 'BASELAYER') {
      throw new Error('This component should be under BaseLayer');
    }

    this.initStamen();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.baseURL !== this.props.baseURL) {
      this.props.map.removeLayer(this.state.baseLayer);
      this.props.map.removeLayer(this.state.labelLayer);
      this.initStamen();
    }
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.state.baseLayer);
    this.props.map.removeLayer(this.state.labelLayer);
  }

  initStamen = () => {
    const { baseURL } = this.props;

    let baseStamenType = baseURL;
    let labelURL;
    let labelLayer = null;

    if (baseURL.indexOf(',') !== -1) {
      [baseStamenType, labelURL] = baseURL.split(',');
    }

    if (labelURL) {
      labelLayer = new TileLayer({
        source: new Stamen({
          layer: labelURL,
        }),
      });
    }

    const baseLayer = new TileLayer({
      source: new Stamen({
        layer: baseStamenType,
      }),
    });

    this.setState({ baseLayer, labelLayer }, () => {
      this.renderMap();
    });
  }

  renderMap = () => {
    this.props.map.getLayers().insertAt(0, this.state.baseLayer);
    if (this.state.labelLayer) {
      this.props.map.getLayers().insertAt(1, this.state.labelLayer);
    }
  }

  render = () => <></>;
}

StamenLayer.propTypes = {
  parent: PropTypes.string,
  map: PropTypes.object,
  baseURL: PropTypes.string.isRequired,
};

StamenLayer.defaultProps = {
  parent: null,
  map: null,
};

export default StamenLayer;
