import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditorACSTable from './ACSTable';
import Button from '../../common/Button';

import './UnassignedInfo.css';

const EditorUnassignedDetails = (props) => (
  <div className="editor-unassigned-details editor-district-details">
    <h3>
      <span>
        Affected Districts
      </span>
      <div className="edd-options">
        <Button
          onClick={() => {
            // props.commitUnassignedDistrict(props.planId)
          }}
        >
          Save
        </Button>
        <Button
          onClick={() => {
            // props.revertUnassignedDistrict(props.planId)
          }}
        >
          Revert
        </Button>
      </div>
    </h3>
    <Table size="small" aria-label="acs-table">
      <TableHead>
        <TableRow>
          <TableCell>District Name</TableCell>
          <TableCell align="right">Orig Pop&apos;l</TableCell>
          <TableCell align="right">Change</TableCell>
          <TableCell align="right">New Pop&apos;l</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { props.unassignedDetails.map((affected) => (
          <EditorACSTable key={affected.district} {...affected} />
        ))}
      </TableBody>
    </Table>
  </div>
);

EditorUnassignedDetails.propTypes = {
  // commitUnassignedDistrict: PropTypes.func.isRequired,
  // revertUnassignedDistrict: PropTypes.func.isRequired,
  planId: PropTypes.any.isRequired,
  unassignedDetails: PropTypes.object,
};

EditorUnassignedDetails.defaultProps = {
  unassignedDetails: null,
};

export default EditorUnassignedDetails;
