import React from 'react';
import PropTypes from 'prop-types';
import ImportedDistrictUploadContainer from '../containers/ImportedDistrictUploadContainer';
import ImportedDistrictLibraryContainer from '../containers/ImportedDistrictLibraryContainer';
import ImportedDistrictPublicContainer from '../containers/ImportedDistrictPublicContainer';

const ImportedDistrictPage = (props) => (
  <>
    { props.tab === 'upload' && <ImportedDistrictUploadContainer {...props} />}
    { props.tab === 'library' && <ImportedDistrictLibraryContainer {...props} />}
    { props.tab === 'public' && <ImportedDistrictPublicContainer {...props} />}
  </>
);

ImportedDistrictPage.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default ImportedDistrictPage;
