import React from 'react';

import './LogoutPage.css';

const LogoutPage = () => (
  <div className="logout">
    You are logged out from the redistricting application.
  </div>
);

export default LogoutPage;
