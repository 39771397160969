import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import DropDown from '../../common/DropDown';
import ShadedLayerItem from './ShadedLayerItem';
// import Switch from '@material-ui/core/Switch';
import { AntSwitch } from '../../common/Switch';

import './ShadedLayers.css';

const ShadedLayers = (props) => (
  <div className="shaded-layers editor-subsection">
    <h3>
      <div className="editor-label">
        <span>Shaded Layers</span>
        <div className="editor-show-section">
          Show
          <AntSwitch
            checked={props.isShadedLayerActive}
            onChange={() => props.handleToggleShadedLayer()}
            value="isShadedLayerShown"
          />
        </div>
      </div>
      {
        props.isInteractive
        && <Link to={`/editor/${_.get(props.chosenPlan, 'id')}/shaded-layer/create`}>Add</Link>
      }

    </h3>

    <div className={`editor-sub-list ${props.isShadedLayerActive ? 'active' : ''}`}>
      <DropDown
        top
        className="sl-dropdown"
        label={props.activeShadedLayer
          ? <ShadedLayerItem {...props.activeShadedLayer} />
          : 'Pick a Shaded Layer'}
        options={props.shadedLayers}
        onClose={() => {}}
        onChange={(shadedLayer) => {
          props.activateShadedLayer(_.get(props.chosenPlan, 'id'), shadedLayer.id);
        }}
        renderItem={(item) => (<ShadedLayerItem {...item} />)}
      />
    </div>
  </div>
);

ShadedLayers.propTypes = {
  activateShadedLayer: PropTypes.func.isRequired,
  activeShadedLayer: PropTypes.object,
  chosenPlan: PropTypes.any,
  handleToggleShadedLayer: PropTypes.func.isRequired,
  isShadedLayerActive: PropTypes.bool.isRequired,
  isInteractive: PropTypes.bool,
  shadedLayers: PropTypes.array.isRequired,
};

ShadedLayers.defaultProps = {
  activeShadedLayer: null,
  isInteractive: true,
  chosenPlan: null,
};

export default ShadedLayers;
