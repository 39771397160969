import _ from 'lodash';

const defaultState = {
  list: [],
  isLoading: false,
  isSubmitting: false,
  importDetails: null,
  uploadStep: 'IMPORTED_DISTRICT_UPLOAD',
};

const ImportedDistrictReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'BALLISTA_LOGOUT': {
      return defaultState;
    }
    case 'IMPORTED_DISTRICT_GET_LIST':
      return {
        ...state,
        isLoading: true,
      };
    case 'IMPORTED_DISTRICT_GET_LIST_SUCCESS':
      return {
        ...state,
        isLoading: false,
        list: _.get(action, 'payload.data'),
      };
    case 'IMPORTED_DISTRICT_SELECT_FOR_PLAN':
      return {
        ...state,
        isSubmitting: false,
      };
    case 'IMPORTED_DISTRICT_SELECT_FOR_PLAN_ERROR':
      return {
        ...state,
        isSubmitting: true,
      };
    case 'IMPORTED_DISTRICT_SELECT_FOR_PLAN_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
        importDetails: action.payload.data,
      };
    case 'IMPORTED_DISTRICT_GET_IMPORTED_DISTRICT_SUCCESS':
      return {
        ...state,
        isLoading: false,
        list: action.payload.data,
      };
    case 'IMPORTED_DISTRICT_GET_IMPORTED_DISTRICT_ERROR':
      return {
        ...state,
        isLoading: false,
      };

    case 'IMPORTED_DISTRICT_SUBMIT_UPLOADED_DISTRICTS':
      return {
        ...state,
        isSubmitting: true,
      };
    case 'IMPORTED_DISTRICT_SUBMIT_UPLOADED_DISTRICTS_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
      };
    case 'IMPORTED_DISTRICT_SELECTED_PLAN':
      return {
        ...state,
        isSubmitting: true,
      };
    case 'IMPORTED_DISTRICT_SELECTED_PLAN_SUCCESS':
      return {
        ...state,
        isSubmitting: false,
      };
    case 'IMPORTED_DISTRICT_SELECTED_PLAN_ERROR':
      return {
        ...state,
        isSubmitting: false,
      };

    case 'IMPORTED_DISTRICT_UPLOAD_FINISHED':
      return {
        ...state,
        importDetails: action.payload.data,
        uploadStep: 'IMPORTED_DISTRICT_DETAILS',
      };

    default: return state;
  }
};

export default ImportedDistrictReducer;
