import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import Button from '../common/Button';

import './RegistrationView.css';

class RegistrationView extends React.Component {
  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.props.handleRegister();
    }
  }

  render = () => (
    <div className="registrationPage">
      <div className="registrationArea form-area">
        <h3>Scorpio Registration</h3>
        <form onSubmit={(e) => { e.preventDefault(); this.props.handleRegister(); }}>
          <div className="form-item">
            <label>Username</label>
            <TextField
              type="text"
              name="username"
              placeholder="Enter your Username"
              onChange={this.props.handleChange('username')}
            />
          </div>
          <div className="form-item">
            <label>Password</label>
            <TextField
              type="password"
              name="password"
              placeholder="Enter your Password"
              onChange={this.props.handleChange('password')}
            />
          </div>
          <div className="form-item">
            <label>Password</label>
            <TextField
              type="password"
              name="retype-password"
              placeholder="Re-Enter your Password"
              onChange={this.props.handleChange('retype_password')}
            />
          </div>
          <div className="form-item submit-button">
            <Button primary="true" onClick={this.props.handleRegister}>Register</Button>
          </div>
        </form>
      </div>
    </div>
  );
}

RegistrationView.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleRegister: PropTypes.func.isRequired,
};

export default RegistrationView;
