/**
 * Retrieves the list of District Sets the person has previously updated.
 *
 * @param {Number} planId
 * ID of plan associated with the imported districts
 */
export const getImportedDistrict = (planId) => (
  {
    type: 'IMPORTED_DISTRICT_GET_LIST',
    payload: {
      request: {
        url: `/plan/${planId}/imported-district/library/`,
        method: 'GET',
      },
    },
  }
);

/**
 * After the user has uploaded a district set, they will be asked to
 * provide a name, description, and an alias property.
 * This method submits the district back to the server.
 *
 * @param {number} planId
 * @param {number} importedDistrictId
 * @param {string} name Name of the district set
 * @param {string} description Short description about the set of districts being uploaded
 * @param {string} alias
 * Name of the field/property in the spatial file corresponding to the district's alias/label
 *
 */
export const submitUploadedDistricts = (planId, importedDistrictId, name, description, alias) => (
  {
    type: 'IMPORTED_DISTRICT_SUBMIT_UPLOADED_DISTRICTS',
    payload: {
      request: {
        url: `/plan/${planId}/imported-district/${importedDistrictId}/`,
        method: 'POST',
        data: {
          name,
          description,
          alias,
        },
      },
    },
  }
);

/**
 * When user selected a district set that they have previously uploaded.
 * @param {number} planId
 * @param {number} importedDistrictId
 */
export const submitSelectedImportedDistrict = (planId, importedDistrictId) => ({
  type: 'IMPORTED_DISTRICT_SELECT_FOR_PLAN',
  payload: {
    request: {
      url: `/plan/${planId}/imported-district/${importedDistrictId}/`,
      method: 'PUT',
    },
  },
});

/**
 * Upload is handled separately by `Uppy` so this is called only
 * after uppy has finished uploading the file to server.
 * This will then tell the app to proceed to details submission.
 *
 * @param {object} data Returned data by server after uploading the spatial file
 */
export const handleUploadImportedDistrict = (data) => (
  {
    type: 'IMPORTED_DISTRICT_UPLOAD_FINISHED',
    payload: {
      data: data.body,
    },
  }
);
