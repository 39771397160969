import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from './Button';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Toast.css';

const CloseButton = ({ closeToast }) => (
  <Button
    onClick={closeToast}
    className={styles.closeButton}
  >
    <Icon icon={faTimes} color="white" size="2x" name="close" />
  </Button>
);

CloseButton.propTypes = {
  closeToast: PropTypes.func.isRequired,
};

const Toast = () => (
  <ToastContainer
    hideProgressBar
    position="top-right"
    className={styles.container}
    toastClassName={styles.toast}
    closeButton={CloseButton}
  />
);

export default Toast;
