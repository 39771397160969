import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { SliderPicker } from 'react-color';
import {
  faUnlock, faLock, faPen, faCheck, faCheckCircle, faTimes, faTrash,
} from '@fortawesome/free-solid-svg-icons';
import Numeral from 'numeral';
import ClickOutside from '../../common/ClickOutside';
import { ConfirmationModal } from '../../common/Modal';

import './DistrictListItem.css';

class DistrictListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alias: props.district.alias,
      color: props.color,
      showPicker: false,
      isLocked: props.isLocked,
      isDeleting: false,
    };
  }

  handleCancel = (e) => {
    this.setState({
      alias: this.props.district.alias,
      color: this.props.color,
    }, () => this.props.cancelEditing());
    e.stopPropagation();
  }

  handleOnChange = (e) => {
    this.setState({
      alias: e.target.value,
    });
  }

  editDistrictAlias = (e) => {
    this.props.setEditingAlias(this.props.district.id);
    e.stopPropagation();
  }

  handleSubmit = (e) => {
    this.props.submitDistrictUpdate(
      this.props.planId,
      this.props.district.id,
      this.state.alias,
      this.state.color,
    ).then(
      () => {
        this.props.setEditingAlias(null);
      },
    );
    e.preventDefault();
    e.stopPropagation();
    return false;
  }

  handleColorChange = (color, e) => {
    this.setState({
      color: color.hex,
    });
    e.stopPropagation();
  }

  handleLockClick = (e) => {
    this.props.toggleDistrictLock(
      this.props.planId,
      this.props.district.id,
      !this.state.isLocked,
    ).then(
      () => {
        this.setState((prevState) => ({ isLocked: !prevState.isLocked }));
      },
    );
    e.stopPropagation();
  }

  handleConfirmDelete = () => {
    this.props.handleDistrictDelete(this.props.district.id);
    this.setState({ isDeleting: false });
  }

  handleCancelDelete = () => {
    this.setState({ isDeleting: false });
  }

  handleOpenDeleteModal = () => {
    this.setState({ isDeleting: true });
  }

  toggleColorPicker = (e) => {
    this.setState((prevState) => ({ showPicker: !prevState.showPicker }));
    e.stopPropagation();
  }

  render = () => {
    const {
      column, district, targetPopulation, minimumPopulation,
    } = this.props;
    const displayColumn = column !== null && column !== 0;
    const totalPopulation = _.get(district, 'total_population') || 0;
    let columnValue = '';
    let columnPercent = '0%';
    if (displayColumn) {
      columnValue = _.get(district, `settings.columns[${column}]`, 0);
      if (columnValue > 0 && totalPopulation > 0) {
        columnPercent = `${((columnValue / totalPopulation) * 100).toFixed(1)}%`;
      }
    }

    return (
      <li className={`district-list-item ${this.props.chosen ? 'dli-chosen-item' : ''} ${this.state.isLocked ? 'dli-lock-item' : ''} ${!this.props.isInteractive ? 'interactive-disabled' : ''}`}>
        <div className="dli-container">
          { this.props.isInteractive
            && this.props.district.id !== -1
            ? (
              <span
                className={`dli-lock ${this.state.isLocked ? 'dli-locked' : ''}`}
                role="button"
                onClick={this.handleLockClick}
                onKeyDown={this.handleLockClick}
                tabIndex={-1}
              >
                {this.state.isLocked
                  ? <Icon icon={faLock} />
                  : <Icon icon={faUnlock} />}
              </span>
            )
            : <span className="dli-lock" />}
          <span className={`dli-alias ${this.props.district.id === -1 ? 'unassigned' : ''}`}>
            {this.props.isInteractive && this.props.isEditing
              ? (
                <form onSubmit={this.handleSubmit}>
                  <ClickOutside
                    handleClickOutside={() => this.setState({ showPicker: false })}
                  >
                    <span
                      className="dli-color"
                      style={{ backgroundColor: this.state.color }}
                      role="button"
                      onClick={this.toggleColorPicker}
                      onKeyDown={this.toggleColorPicker}
                      tabIndex={-1}
                    >
                      {
                        this.state.showPicker
                          && (
                          <SliderPicker
                            onChangeComplete={this.handleColorChange}
                            color={this.state.color}
                            className="dli-colorpicker"
                          />
                          )
                      }
                    </span>
                  </ClickOutside>
                  <input
                    type="text"
                    className="dli-alias-input"
                    value={this.state.alias}
                    onChange={this.handleOnChange}
                    autoFocus
                    onFocus={(e) => { e.target.select(); }}
                  />
                  <button
                    className="dli-action dli-submit"
                    type="submit"
                  >
                    <Icon icon={faCheck} />
                  </button>
                  <button
                    className="dli-action dli-cancel"
                    onClick={this.handleCancel}
                    type="button"
                  >
                    <Icon icon={faTimes} />
                  </button>
                </form>
              )
              : (
                <>
                  { this.props.isInteractive && (
                  <span
                    className={`dli-color ${this.props.chosen ? 'dli-chosen' : ''}`}
                    style={{ backgroundColor: this.props.color }}
                    onClick={(e) => this.props.handleDistrictSelect(this.props.district, e)}
                    onKeyDown={(e) => this.props.handleDistrictSelect(this.props.district, e)}
                    tabIndex={-1}
                    role="button"
                  />
                  )}
                  {this.props.district.alias}
                  {
                    this.props.isInteractive
                    && this.props.district.id !== -1
                      && (
                        <button onClick={this.editDistrictAlias} type="button">
                          <Icon icon={faPen} className="dli-pen" />
                        </button>
                      )
                  }
                </>
              )}
          </span>
          <span className="dli-stat dli-popl">
            { displayColumn
              ? Numeral(columnValue).format(',')
              : Numeral(totalPopulation).format(',') }
            { !displayColumn
              && minimumPopulation
              && _.get(district, 'total_population', 0) > (minimumPopulation || 0)
              && district.id !== -1
              ? <Icon icon={faCheckCircle} color="green" className="dli-fa-checkcircle" />
              : null }
          </span>
          <span className="dli-stat dli-dev">
            { displayColumn
              ? columnPercent
              : Numeral(totalPopulation - targetPopulation).format(',') }
          </span>
          <span className="dli-act">
            {
              this.props.isInteractive
              && district.id !== -1
              && (
              <button onClick={this.handleOpenDeleteModal} type="button">
                <Icon icon={faTrash} className="dli-action" color="lightgray" />
              </button>
              )
            }
          </span>
        </div>
        <ConfirmationModal
          open={this.state.isDeleting}
          title="Delete District"
          onConfirm={this.handleConfirmDelete}
          onCancel={this.handleCancelDelete}
        >
          Are you sure you want to delete
          {' '}
          {this.state.alias}
        </ConfirmationModal>
      </li>
    );
  };
}

DistrictListItem.propTypes = {
  cancelEditing: PropTypes.func,
  color: PropTypes.string.isRequired,
  submitDistrictUpdate: PropTypes.func,
  handleDistrictSelect: PropTypes.func.isRequired,
  handleDistrictDelete: PropTypes.func,
  district: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  isInteractive: PropTypes.bool,
  toggleDistrictLock: PropTypes.func,
  isLocked: PropTypes.bool,
  planId: PropTypes.any,
  setEditingAlias: PropTypes.func,
  chosen: PropTypes.bool.isRequired,
  targetPopulation: PropTypes.number,
  minimumPopulation: PropTypes.number,
  column: PropTypes.number,
};

DistrictListItem.defaultProps = {
  cancelEditing: () => {},
  targetPopulation: 0,
  submitDistrictUpdate: () => {},
  isEditing: false,
  isInteractive: true,
  toggleDistrictLock: () => {},
  isLocked: false,
  setEditingAlias: () => {},
  planId: null,
  handleDistrictDelete: null,
  minimumPopulation: null,
  column: null,
};

export default DistrictListItem;
