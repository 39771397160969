import _ from 'lodash';

const defaultState = {
  shapefileArchive: [],
  metricResultsArchive: [],
};

const ExportArchiveReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'BALLISTA_LOGOUT': {
      return defaultState;
    }
    case 'EXPORT_GET_EXPORT_SHAPEFILE_ARCHIVE_SUCCESS': {
      return {
        ...state,
        shapefileArchive: _.get(action, 'payload.data'),
      };
    }

    case 'EXPORT_GET_METRIC_TEST_ARCHIVES_SUCCESS': {
      return {
        ...state,
        metricResultsArchive: _.get(action, 'payload.data'),
      };
    }

    default:
      return state;
  }
};

export default ExportArchiveReducer;
