import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import CreateReportContainer from '../containers/report/CreateReportContainer';
import ListReportContainer from '../containers/report/ListReportContainer';
import ViewReportContainer from '../containers/report/ViewReportContainer';

const ReportPage = (props) => (
  <>
    { props.action === 'create' && <CreateReportContainer action="create" planId={_.get(props.match, 'params.plan_id')} {...props} />}
    { props.action === 'list' && <ListReportContainer action="list" planId={_.get(props.match, 'params.plan_id')} {...props} />}
    { props.action === 'view' && <ViewReportContainer action="list" planId={_.get(props.match, 'params.plan_id')} {...props} />}
  </>
);

ReportPage.propTypes = {
  action: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default ReportPage;
