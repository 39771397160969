import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid, TextField } from '@material-ui/core';
import { MentionsInput, Mention } from 'react-mentions';
import MetricsMenu from './MetricsMenu';
import Button from '../common/Button';
import { QUERY_BUILDER_MATH_OPERATIONS } from '../../constants';
import './QueryBuilderView.css';

const QueryBuilderView = (props) => (
  <section className="query-builder-view modal-page">
    <div className="viewport">
      <header>
        <h1>Metrics</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="content">
        <MetricsMenu {...props} />
        <div className="modal-activity">
          <div className="activity-area">
            <header>
              <h3>Query Builder</h3>
            </header>
            <div className="inner-activity">
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <h4>1.) Query Name</h4>
                  <TextField
                    value={_.get(props.form, 'name') || ''}
                    placeholder="Name of the metric you're building"
                    fullWidth
                    onChange={props.handleChange('name')}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <h4>2.) Description</h4>
                  <TextField
                    value={_.get(props.form, 'description') || ''}
                    placeholder="Tell us a little bit about this metric"
                    fullWidth
                    onChange={props.handleChange('description')}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <h4>3.) Write the Formula</h4>
                  <MentionsInput
                    className="query-builder-formula"
                    value={_.get(props.form, 'markup')}
                    onChange={props.handleChange('markup')}
                  >
                    <Mention
                      markup="@[__display__](OPERATION:__id__)"
                      trigger="@"
                      data={props.measurements.map((m) => ({ id: m.id, display: m.name }))}
                      // renderSuggestion={this.renderUserSuggestion}
                      className="qb-formula-operation"

                    />

                    <Mention
                      markup="@[__display__](ARITH:__id__)"
                      trigger="#"
                      data={QUERY_BUILDER_MATH_OPERATIONS}
                      className="qb-formula-operation"
                      style={{ backgroundColor: 'rgba(255,0, 255, 0.3)' }}
                    />
                  </MentionsInput>
                  <>
                    {
                      props.queryBuilderError
                      && (
                        <div className="query-builder-error">
                          <span className="error-formula">
                            {_.get(props.queryBuilderError, 'formula')}
                          </span>
                          <span className="error-arrow">
                            {
                              parseInt(_.get(props.queryBuilderError, 'column'), 10)
                                ? _.fill(
                                  Array(
                                    parseInt(_.get(props.queryBuilderError, 'column'), 10) - 1,
                                  ),
                                  '\u00A0',
                                ).join('') : ''
                            }
                          </span>
                          <span className="error-message">
                            {_.get(props.queryBuilderError, 'error')}
                          </span>
                        </div>
                      )
                    }
                  </>
                </Grid>
              </Grid>
            </div>
            <footer>
              <Button primary onClick={props.handleSubmit}>
                { props.form.id ? 'Update Metric' : 'Create Metric'}
              </Button>
            </footer>
          </div>
        </div>
      </section>
    </div>
  </section>
);

QueryBuilderView.propTypes = {
  form: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  measurements: PropTypes.array.isRequired,
  planId: PropTypes.string.isRequired,
  queryBuilderError: PropTypes.object,
};

QueryBuilderView.defaultProps = {
  queryBuilderError: {},
};

export default QueryBuilderView;
