import { client } from '../requestClient';

const loaderFunction = (sourceObject, targetUrl) => {
  const functionToReturn = async (extent, resolution, projection) => {
    const url = targetUrl;
    client.get(url, { params: {}, headers: { Accept: '*/*' } })
      .then((res) => {
        const source = res.data;
        if (source) {
          sourceObject.addFeatures(sourceObject.getFormat().readFeatures(source,
            { featureProjection: projection }));
        }
      }).catch(() => sourceObject.removeLoadedExtent(extent));
  };
  return functionToReturn;
};

export default loaderFunction;
