import React from 'react';
// import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
// import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const MetricsMenu = (props) => (
  <section className="metrics-menu modal-menu">
    <nav>
      <ul>
        <li
          className={props.tab === 'create' ? 'active' : ''}
        >
          <Link to={`/editor/${props.planId}/metrics/create`}>
            Create Tests
          </Link>
        </li>

        <li className={props.tab === 'builder' ? 'active' : ''}>
          <Link to={`/editor/${props.planId}/metrics/builder`}>
            Query Builder
          </Link>
        </li>

        <li className={['list', 'results', 'suite'].includes(props.tab) ? 'active' : ''}>
          <Link to={`/editor/${props.planId}/metrics/list`}>
            Test Suite Results
          </Link>
        </li>

        <li className={['metric-edit', 'metric-list'].includes(props.tab) ? 'active' : ''}>
          <Link to={`/editor/${props.planId}/metrics/metric-list`}>
            Metrics
          </Link>
        </li>
      </ul>
    </nav>
  </section>
);

MetricsMenu.propTypes = {
  planId: PropTypes.any.isRequired,
  tab: PropTypes.string.isRequired,
};

export default MetricsMenu;
