import React from 'react';

import './GuideLayerPreview.css';

class GuideLayerPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => (
    <div>THIS IS A PREVIEW MAP</div>
  )
}

GuideLayerPreview.propTypes = {
};

export default GuideLayerPreview;
