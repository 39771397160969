import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ShadedLayerContainer from '../containers/ShadedLayerContainer';

const ShadedLayerPage = (props) => (
  <ShadedLayerContainer
    {...props}
    planId={_.get(props.match, 'params.plan_id')}
    shadedLayerId={_.get(props.match, 'params.shaded_layer_id', '-1')}
    action={_.get(props.match, 'params.shaded_layer_id') ? 'edit' : 'create'}
  />
);

ShadedLayerPage.propTypes = {
  match: PropTypes.object.isRequired,
};

export default ShadedLayerPage;
