/** *
 *  -------------------
 *  EditorContainer.js
 *  -------------------
 *
 *  EditorContainer is where the actual editing of districts happen.
 *  This page contains the heaviest load of activitiest and will
 *  show a lot of features specified by the client. This will also
 *  manage all the interactions and changes to all the layers.
 * */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import randomColor from 'randomcolor';
import EditorDetailsView from '../components/editor/details/EditorDetailsView';
import {
  focusDistrict,
  gotoIncompleteArea,
  saveDistrictDetails,
  setChosenTool,
  setChosenBrush,
  // retrieveSandboxDistrictDetail,
  // revertDistrict,
  // commitUnassignedDistrict,
  // revertUnassignedDistrict,
  submitDistrictUpdate,
  toggleDistrictLock,
  deleteDistrict,
  clearDistrictDetails,
  undoLastAction,
} from '../actions/editor';
import {
  activateShadedLayer,
  updateShadedLayerStatus,
} from '../actions/shadedLayer';
import {
  updateGuideLayerSettings,
} from '../actions/guideLayer';
import {
  addDistrict,
  setChosenDistrict,
  // selectUnassigned,
  updatePlanSettings,
} from '../actions/plan';
import { BRUSH_STROKES } from '../constants';

/**
 * Editor Container provides information to mapping and editor
 * toolbox.
 */
class EditorDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.planId = _.get(props, 'match.params.plan_id');

    this.state = {
      isShadedLayerActive: false,
      isGuideLayerActive: false,
    };

    this.pollingCenter = new WebSocket(
      `${process.env.REACT_APP_NOTIFICATION_CENTER_URL}`
        + `plan/${this.planId}/shaded_layer/`,
    );
  }

  componentDidMount = () => {
    if (_.get(this.props.chosenPlan, 'id', null) !== null) {
      this.activateOverlays();
    }

    this.initializePolling();

    // reset tools
    this.props.setChosenTool('Pointer');
    this.props.setChosenBrush(BRUSH_STROKES[0]);
  }

  componentDidUpdate = (prevProps) => {
    if (_.get(prevProps.chosenPlan, 'id', null) === null && _.get(this.props.chosenPlan, 'id', null) !== null) {
      this.activateOverlays();
    }
  }

  initializePolling = () => {
    this.pollingCenter.onmessage = (event) => {
      const poll = JSON.parse(event.data);
      this.props.updateShadedLayerStatus(poll.message);
    };
  }

  activateOverlays = () => {
    this.setState({
      isShadedLayerActive: _.get(this.props.chosenPlan, 'settings.isShadedLayerActive', false),
      isGuideLayerActive: _.get(this.props.chosenPlan, 'settings.isGuideLayerActive', false),
    });
  }

  handleClick = (toolName) => {
    this.props.setChosenTool(toolName);
  }

  handleSave = (districtId, districtDetails) => {
    this.props.saveDistrictDetails(this.planId, districtId, districtDetails);
  }

  handleUndo = () => {
    this.props.undoLastAction();
  }

  handleCancelDistrict = () => {
    // this.props.revertDistrict(this.planId, districtId);
    this.props.clearDistrictDetails();
  }

  handleDistrictSelect = (district) => {
    this.props.setChosenDistrict(district);
    // this.props.retrieveSandboxDistrictDetail(this.planId, district.id);
  }

  handleAddDistrict = () => {
    const len = _.get(this.props, 'districts', []).length;
    const district = len + 1;
    const color = randomColor({ luminosity: 'light' });
    this.props.addDistrict(this.planId, `District ${district}`, color);
  }

  handleUpdateSettings = (guideLayer, obj) => {
    const newSettings = _.merge(guideLayer.settings, obj);
    this.props.updateGuideLayerSettings(this.planId, guideLayer.id, newSettings);
  }

  handleEditDistrictAlias = () => {
  }

  handleDistrictDelete = (districtId) => {
    this.props.deleteDistrict(this.planId, districtId);
  }

  handleToggleShadedLayer = () => {
    this.setState((prevState) => ({
      isShadedLayerActive: !prevState.isShadedLayerActive,
    }), () => {
      this.props.updatePlanSettings(this.planId, {
        ..._.get(this.props.chosenPlan, 'settings', {}),
        isShadedLayerActive: this.state.isShadedLayerActive,
      });
    });
  }

  handleToggleGuideLayer = () => {
    this.setState((prevState) => ({
      isGuideLayerActive: !prevState.isGuideLayerActive,
    }), () => {
      this.props.updatePlanSettings(this.planId, {
        ..._.get(this.props.chosenPlan, 'settings', {}),
        isGuideLayerActive: this.state.isGuideLayerActive,
      });
    });
  }

  getMinimumPopulation = () => {
    const targetPopulation = _.get(this.props.chosenPlan, 'target_population', 0);
    const allowedDeviationType = _.get(this.props.chosenPlan, 'settings.allowedDeviationType', null);
    const allowedDeviation = _.get(this.props.chosenPlan, 'settings.allowedDeviation', null);

    if (_.get(allowedDeviationType, 'value') === 'allowed-deviation-percentage') {
      return Math.floor(targetPopulation - (targetPopulation * (allowedDeviation / 100)));
    } if (_.get(allowedDeviationType, 'value') === 'allowed-deviation-person') {
      return targetPopulation - allowedDeviation;
    }

    return 0;
  }

  render = () => (
    <EditorDetailsView
      {...this.props}
      {...this.state}
      handleAddDistrict={this.handleAddDistrict}
      handleCancelDistrict={this.handleCancelDistrict}
      handleClick={this.handleClick}
      handleEditDistrictAlias={this.handleEditDistrictAlias}
      handleDistrictSelect={this.handleDistrictSelect}
      handleDistrictDelete={this.handleDistrictDelete}
      handleSave={this.handleSave}
      handleToggleGuideLayer={this.handleToggleGuideLayer}
      handleToggleShadedLayer={this.handleToggleShadedLayer}
      handleUpdateSettings={this.handleUpdateSettings}
      handleUndo={this.handleUndo}
      minimumPopulation={this.getMinimumPopulation()}
      planId={this.planId}
      isInteractive={!!this.props.isInteractive}
    />
  );
}

const mapStateToProps = (state) => ({
  chosenTool: _.get(state, 'editor.chosenTool'),
  chosenBrush: _.get(state, 'editor.chosenBrush'),
  chosenPlan: _.get(state, 'plan.chosenPlan'),
  chosenDistrict: _.get(state, 'plan.chosenDistrict'),
  committedChanges: _.get(state, 'editor.committedChanges'),
  districts: _.get(state, 'editor.districts'),
  districtDetails: _.get(state, 'editor.districtDetails'),
  percentageComplete: _.get(state, 'editor.percentageComplete'),
  publishedGuideLayers: _.get(state, 'guideLayer.publishedGuideLayers'),
  defaultGuideLayers: _.get(state, 'guideLayer.defaultGuideLayers', []),
  shadedLayers: _.filter(_.get(state, 'shadedLayer.shadedLayers'), { is_complete: true }),
  incompleteShadedLayers: _.filter(_.get(state, 'shadedLayer.shadedLayers'), { is_complete: false }),
  userUploads: _.get(state, 'editor.userUploads'),
  unassignedDetails: _.get(state, 'editor.unassignedDetails'),
  // hasPendingChanges: _.get(state, 'editor.hasPendingChanges'),
});

EditorDetailsContainer.propTypes = {
  addDistrict: PropTypes.func.isRequired,
  chosenBrush: PropTypes.object,
  chosenDistrict: PropTypes.object,
  chosenPlan: PropTypes.any,
  chosenTool: PropTypes.string,
  committedChanges: PropTypes.number.isRequired,
  deleteDistrict: PropTypes.func.isRequired,
  districtDetails: PropTypes.array,
  districts: PropTypes.array.isRequired,
  focusDistrict: PropTypes.func.isRequired,
  isInteractive: PropTypes.bool,
  match: PropTypes.object.isRequired,
  publishedGuideLayers: PropTypes.array.isRequired,
  // retrieveSandboxDistrictDetail: PropTypes.func.isRequired,
  // revertDistrict: PropTypes.func.isRequired,
  saveDistrictDetails: PropTypes.func.isRequired,
  undoLastAction: PropTypes.func.isRequired,
  // selectUnassigned: PropTypes.func.isRequired,
  clearDistrictDetails: PropTypes.func.isRequired,
  setChosenBrush: PropTypes.func.isRequired,
  setChosenDistrict: PropTypes.func.isRequired,
  setChosenTool: PropTypes.func.isRequired,
  submitDistrictUpdate: PropTypes.func.isRequired,
  toggleDistrictLock: PropTypes.func.isRequired,
  unassignedDetails: PropTypes.array,
  updateGuideLayerSettings: PropTypes.func.isRequired,
  updatePlanSettings: PropTypes.func.isRequired,
  userUploads: PropTypes.array.isRequired,
  updateShadedLayerStatus: PropTypes.func.isRequired,
};

EditorDetailsContainer.defaultProps = {
  chosenTool: {},
  chosenBrush: {},
  chosenPlan: {},
  chosenDistrict: {},
  districtDetails: [],
  isInteractive: true,
  unassignedDetails: null,
};

export default connect(mapStateToProps, {
  activateShadedLayer,
  addDistrict,
  // commitUnassignedDistrict,
  deleteDistrict,
  focusDistrict,
  gotoIncompleteArea,
  // retrieveSandboxDistrictDetail,
  // revertDistrict,
  // revertUnassignedDistrict,
  saveDistrictDetails,
  clearDistrictDetails,
  // selectUnassigned,
  setChosenBrush,
  setChosenDistrict,
  setChosenTool,
  submitDistrictUpdate,
  toggleDistrictLock,
  updateGuideLayerSettings,
  updateShadedLayerStatus,
  updatePlanSettings,
  undoLastAction,
})(EditorDetailsContainer);
