import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

import './Modal.css';

const ConfirmationModal = (props) => (
  <Modal
    open={props.open}
    onBackdropClick={props.onCancel}
    style={{
      zIndex: 20000,
    }}
  >
    <div className="confirmation-modal">
      <div className="cm-title">
        <h5>{props.title || 'Confirmation'}</h5>
        <button type="button" onClick={props.onCancel}>
          <Icon icon={faTimes} className="cm-close" size="2x" />
        </button>
      </div>
      <div className="cm-content">
        <span className="icon">
          <Icon icon={faInfoCircle} size="3x" color="lightgray" />
        </span>
        <div className="cm-text">
          {props.children}
        </div>
      </div>
      <div className="cm-buttons">
        <button type="button" className="cancel" onClick={props.onCancel}>
          Cancel
        </button>
        <button type="button" className="confirm" onClick={props.onConfirm}>
          Confirm
        </button>
      </div>
    </div>

  </Modal>
);

ConfirmationModal.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.any.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export {
  ConfirmationModal,
};

export default Modal;
