import React from 'react';
import PropTypes from 'prop-types';
import OLVectorTileSource from 'ol/source/VectorTile';
// import {transformExtent} from 'ol/proj';

/**
 * For our intents and purposes, this component are vectors being served
 * by Ballista server
 */
class VectorTileSource extends React.Component {
  constructor(props) {
    super(props);
    let vectorTileSource;
    if (props.source) {
      vectorTileSource = props.source;
    } else {
      vectorTileSource = new OLVectorTileSource({
        format: props.format,
        tileGrid: props.tileGrid,
        tileUrlFunction: props.tileUrlFunction,
        overlaps: false,
      });
      if (props.tileLoadFunction) {
        vectorTileSource.setTileLoadFunction(props.tileLoadFunction);
      }
    }
    this.state = {
      vectorTileSource,
    };
  }

  componentDidMount() {
    this.renderSource();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shadedLayerId !== this.props.shadedLayerId) {
      this.state.vectorTileSource.refresh();
      this.state.vectorTileSource.clear(true);
    }
    if (prevProps.dataLayer !== this.props.dataLayer) {
      this.state.vectorTileSource.refresh();
      this.state.vectorTileSource.clear(true);
    }
  }

  getFeaturesInExtent = (extent) => this.state.vectorTileSource.getFeaturesInExtent(extent)

  renderSource = () => {
    this.props.layer.setSource(this.state.vectorTileSource);
  }

  render = () => null;
}

VectorTileSource.propTypes = {
  layer: PropTypes.object,
  tileUrlFunction: PropTypes.func,
  tileLoadFunction: PropTypes.func,
  tileGrid: PropTypes.object,
  format: PropTypes.object,
  shadedLayerId: PropTypes.number,
  dataLayer: PropTypes.string,
  source: PropTypes.object,
};

VectorTileSource.defaultProps = {
  layer: null,
  shadedLayerId: null,
  dataLayer: null,
  tileUrlFunction: null,
  tileGrid: null,
  format: null,
  source: null,
  tileLoadFunction: null,
};

export default VectorTileSource;
