import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faHome } from '@fortawesome/free-solid-svg-icons';
import Button from '../../common/Button';
import DropDown from '../../common/DropDown';
import EditorNotifications from './EditorNotifications';

import './EditorToolbox.css';

import { BRUSH_STROKES, TOOLBOX } from '../../../constants';

const EditorToolbox = ({
  chosenBrush,
  chosenPlan,
  chosenTool,
  setChosenBrush,
  handleClick,
  ...props
}) => (
  <div className={`editor-toolbox et-section ${!props.isInteractive ? 'disabled-interactive' : ''}`}>

    {chosenPlan
      && (
      <div className="et-title">
        <Link to="/"><FontAwesomeIcon className="homeButton" icon={faHome} size="1x" color="gray" /></Link>
        <h4>{`${chosenPlan.title} | ${chosenPlan.state} - ${chosenPlan.district_type}`}</h4>
      </div>
      )}
    {props.isInteractive
        && (
        <div className="et-section et-tools-section">
          <div className="et-subsection">
            <EditorNotifications {...props} />
          </div>
          <div className="et-subsection">
            <h5>Tool Type</h5>
            <ul className="et-tool-type et-options">
              {
                TOOLBOX.map((tool) => (
                  <li key={tool.name} className={`et-tool-type-item ${tool.name === chosenTool ? ' chosen-item' : ''}`}>
                    <Button onClick={() => handleClick(tool.name)}>
                      {tool.name}
                    </Button>
                  </li>
                ))
              }
            </ul>
          </div>

          <div className="et-subsection">
            <h5>Building Unit</h5>
            <ul className="et-brush-type et-options">
              {
            BRUSH_STROKES.map((brush) => (
              <li key={brush.id} className={`et-brush-type-item ${chosenBrush && brush.id === chosenBrush.id ? ' chosen-item' : ''}`}>
                <Button onClick={() => { setChosenBrush(brush); }}>
                  <span>{brush.name}</span>
                </Button>
              </li>
            ))
          }
            </ul>
          </div>

          <div className="et-subsection">
            <h5>Actions</h5>
            <ul className="et-options">
              <li>
                <Link to={`/editor/${_.get(chosenPlan, 'id')}/import-district`}>Import Districts</Link>
              </li>
              <li>
                <Link to={`/editor/${_.get(chosenPlan, 'id')}/settings`}>Plan Settings</Link>
              </li>
              <li>
                <Link to={`/editor/${_.get(chosenPlan, 'id')}/exports/shapefile`}>Exports Archive</Link>
              </li>
              <li>
                <Button onClick={props.handleExport}>Export Plan</Button>
              </li>
              <li>
                <Link to={`/editor/${_.get(chosenPlan, 'id')}/metrics/create`}>Generate Tests</Link>
              </li>
              <li>
                <Link to={`/editor/${_.get(chosenPlan, 'id')}/report/create`}>Generate Report</Link>
              </li>
            </ul>
          </div>

          <div className="et-subsection">
            <h5>Base Layer</h5>
            <DropDown
              className="et-dropdown"
              label={(
                <span className="et-dropdown-label">
                  {_.get(chosenPlan, 'base_layer.title') || 'Choose a Base Layer'}
                </span>
          )}
              options={props.baseLayers}
              onClose={() => {}}
              onChange={(baseLayer) => props.handleBaseLayerChange(baseLayer)}
              renderItem={(baseLayer) => (<span>{baseLayer.title}</span>)}
            />
          </div>

        </div>
        )}

  </div>
);

EditorToolbox.propTypes = {
  baseLayers: PropTypes.array,
  chosenTool: PropTypes.string.isRequired,
  chosenPlan: PropTypes.any,
  chosenBrush: PropTypes.object,
  handleBaseLayerChange: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  isInteractive: PropTypes.bool,
  setChosenBrush: PropTypes.func.isRequired,
  handleExport: PropTypes.func.isRequired,
};

EditorToolbox.defaultProps = {
  chosenPlan: {},
  chosenBrush: {},
  isInteractive: true,
  baseLayers: [],
};

export default EditorToolbox;
