import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DistrictInfo from './DistrictInfo';
import DistrictList from './DistrictList';
import GuideLayers from './GuideLayers';
import ShadedLayers from './ShadedLayers';
import UnassignedInfo from './UnassignedInfo';

import './EditorDetailsView.css';

const EditorDetailsView = (props) => (
  <div className="eda-section">
    <div className="eda-container">
      { props.districts
          && <DistrictList {...props} />}
      { props.districtDetails && props.districtDetails instanceof Array
          && <DistrictInfo {...props} />}
      {
        props.unassignedDetails
        && props.chosenDistrict.id === -1 && (<UnassignedInfo {...props} />)
      }

      <ShadedLayers
        {...props}
        activeShadedLayer={
          _.filter(
            props.shadedLayers, { is_active: true }, [],
          )[0]
        }
      />

      <GuideLayers
        {...props}
        activeDefaultGuideLayer={
          _.filter(
            props.defaultGuideLayers, { short_name: _.get(props, 'chosenPlan.settings.defaultGuideLayer') }, [],
          )[0]
        }
      />
    </div>
  </div>
);

EditorDetailsView.propTypes = {
  chosenDistrict: PropTypes.object,
  chosenPlan: PropTypes.any,
  districts: PropTypes.array,
  districtDetails: PropTypes.array,
  unassignedDetails: PropTypes.array,
  shadedLayers: PropTypes.array.isRequired,
  defaultGuideLayers: PropTypes.array.isRequired,
  handleAddDistrict: PropTypes.func,
  handleCancelDistrict: PropTypes.func,
  handleClick: PropTypes.func,
  handleEditDistrictAlias: PropTypes.func,
  handleDistrictSelect: PropTypes.func,
  handleDistrictDelete: PropTypes.func,
  handleSave: PropTypes.func,
  handleToggleGuideLayer: PropTypes.func,
  handleToggleShadedLayer: PropTypes.func,
  handleUpdateSettings: PropTypes.func,
  handleUndo: PropTypes.func,
  minimumPopulation: PropTypes.number,
  planId: PropTypes.string.isRequired,
  isInteractive: PropTypes.bool,
};

EditorDetailsView.defaultProps = {
  chosenDistrict: {},
  chosenPlan: {},
  districts: [],
  districtDetails: [],
  unassignedDetails: null,
  handleAddDistrict: null,
  handleCancelDistrict: null,
  handleClick: null,
  handleEditDistrictAlias: null,
  handleDistrictSelect: null,
  handleDistrictDelete: null,
  handleSave: null,
  handleToggleGuideLayer: null,
  handleToggleShadedLayer: null,
  handleUpdateSettings: null,
  handleUndo: null,
  minimumPopulation: 0,
  isInteractive: true,
};

export default EditorDetailsView;
