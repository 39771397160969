import _ from 'lodash';

const defaultState = {
  notifications: [],
  exportPolls: [],
  importedDistrictPolls: [],
};

const NotificationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'NOTIFICATION_UPDATE_NOTIFICATIONS': {
      return state;
    }

    case 'NOTIFICATION_UPDATE_NOTIFICATIONS_SUCCESS': {
      const notifications = _.get(state, 'notifications');
      const newNotification = _.get(action, 'payload.data');
      notifications.splice(
        _.findIndex(notifications, { id: _.get(newNotification, 'id') }), 1, newNotification,
      );

      return {
        ...state,
        notifications,
      };
    }

    case 'NOTIFICATION_RECEIVE_PUSH_NOTIFICATION': {
      const notifications = _.get(state, 'notifications');
      const newNotification = _.get(action, 'payload.data');

      return {
        ...state,
        notifications: [
          newNotification,
          ...notifications,
        ],
      };
    }

    case 'NOTIFICATION_GET_NOTIFICATIONS': {
      return state;
    }
    case 'NOTIFICATION_GET_NOTIFICATIONS_SUCCESS': {
      return {
        ...state,
        notifications: _.get(action, 'payload.data'),
      };
    }

    case 'NOTIFICATION_RECEIVE_EXPORT_POLL': {
      let exportPolls = [...state.exportPolls];
      const exportPoll = _.get(action, 'payload.data.exportPoll');
      const index = _.findIndex(exportPolls, { id: _.get(exportPoll, 'id') });

      // If export doesn't exist, it will be added to exportPolls. Otherwise it will be replaced.
      if (index < 0) {
        exportPolls = [...exportPolls, exportPoll];
      } else {
        exportPolls.splice(index, 1, exportPoll);
      }

      return {
        ...state,
        exportPolls,
      };
    }

    case 'NOTIFICATION_RECEIVE_IMPORTED_DISTRICT_POLL': {
      let importedDistrictPolls = [...state.importedDistrictPolls];
      const importedDistrictPoll = _.get(action, 'payload.data.importedDistrictPoll');
      const index = _.findIndex(importedDistrictPolls, { id: _.get(importedDistrictPoll, 'id') });

      // If export doesn't exist, it will be added to exportPolls. Otherwise it will be replaced.
      if (index < 0) {
        importedDistrictPolls = [...importedDistrictPolls, importedDistrictPoll];
      } else {
        importedDistrictPolls.splice(index, 1, importedDistrictPoll);
      }

      return {
        ...state,
        importedDistrictPolls,
      };
    }

    default:
      return state;
  }
};

export default NotificationReducer;
