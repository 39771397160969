import { combineReducers } from 'redux';

import editorReducer from './editor';
import planReducer from './plan';
import organizationPlanReducer from './organizationPlan';
import GlobalPlanReducer from './globalPlan';
import folderReducer from './folder';
import importedDistrictReducer from './importedDistrict';
import guideLayerReducer from './guideLayer';
import shadedLayerReducer from './shadedLayer';
import authReducer from './auth';
import exportArchiveReducer from './exportArchive';
import metricsReducer from './metrics';
import notificationReducer from './notification';
import reportReducer from './report';
import errorReducer from './error';

const rootReducer = combineReducers({
  editor: editorReducer,
  plan: planReducer,
  organizationPlan: organizationPlanReducer,
  globalPlan: GlobalPlanReducer,
  folder: folderReducer,
  importedDistrict: importedDistrictReducer,
  guideLayer: guideLayerReducer,
  shadedLayer: shadedLayerReducer,
  exportArchive: exportArchiveReducer,
  metrics: metricsReducer,
  auth: authReducer,
  notification: notificationReducer,
  report: reportReducer,
  error: errorReducer,
});

export default rootReducer;
