import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  faPlus, faBinoculars, faCaretLeft, faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import DistrictListItem from './DistrictListItem';
import PercentageComplete from './PercentageComplete';
import './DistrictList.css';
import Button from '../../common/Button';

class DistrictList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editingAlias: null,
      columns: [{
        id: 0, name: 'Total Population',
      }],
      columnIndex: 0,
    };
  }

  setEditingAlias = (id) => {
    this.setState({ editingAlias: id });
  }

  componentDidUpdate = (prevProps) => {
    const columnIds = _.get(this.props, 'chosenPlan.settings.columns', []);
    const columns = _.get(this.props, 'shadedLayer.columns', []);
    const prevColumnIds = _.get(prevProps, 'chosenPlan.settings.columns', []);
    const prevColumns = _.get(prevProps, 'shadedLayer.columns', []);
    // a one-time condition where one of the previous arrays was empty,
    //  but now both are greater than 0, or if the lists have changed
    if (((prevColumnIds.length === 0 || prevColumns.length === 0)
       && (columnIds.length > 0 && columns.length > 0))
       || (_.xor(prevColumnIds, columnIds).length !== 0)) {
      this.initializeColumns(columnIds, columns);
    }
  }

  changeColumns = (direction) => {
    let { columnIndex } = this.state;
    const { columns } = this.state;
    if (direction === 'right') {
      columnIndex += 1;
      if (columnIndex === columns.length) {
        columnIndex = 0;
      }
    } else {
      columnIndex -= 1;
      if (columnIndex === -1) {
        columnIndex = columns.length - 1;
      }
    }
    this.setState({
      columnIndex,
    });
  }

  cancelEditing = () => {
    this.setState({ editingAlias: null });
  }

  handleDistrictSelect = (district, e) => {
    if (_.get(this.props, 'chosenDistrict.id') !== _.get(district, 'id')) {
      this.props.handleCancelDistrict();
    }
    this.props.setChosenDistrict(district);
    // this.props.retrieveSandboxDistrictDetail(this.props.planId, district.id);
    if (district.is_initialized) {
      this.props.focusDistrict(district.id);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  // handleUnassignedSelect = (__, e) => {
  //  this.props.selectUnassigned(this.props.planId);
  //  e.preventDefault();
  // }

  initializeColumns(columnIds, columns) {
    const newColumns = [{ id: 0, name: 'Total Population' }];
    for (let i = 0; i < columns.length; i++) {
      if (columnIds.includes(columns[i].id)) {
        newColumns.push(columns[i]);
      }
    }
    this.setState({
      columnIndex: 0,
      columns: newColumns,
    });
  }

  render = () => {
    const districts = _.get(this.props, 'chosenPlan.districts', []);

    return (
      <section className="district-list">
        <h3>
          <div>
            <span>Districts</span>
            {
              this.props.isInteractive
              && (
              <button
                type="button"
                className="dl-add-button"
                onClick={() => this.props.handleAddDistrict()}
              >
                <Icon icon={faPlus} className="dl-add-district" />
              </button>
              )
            }
          </div>
          <div className="districts-completion">
            <PercentageComplete percentageComplete={this.props.percentageComplete} />
          </div>
          {
            this.props.isInteractive
            && (
            <div className="districts-incomplete">
              <Button onClick={() => this.props.gotoIncompleteArea(this.props.planId)}>
                <Icon icon={faBinoculars} size="2x" />
              </Button>
            </div>
            )
          }
        </h3>
        {
          this.props.isInteractive
          && (
          <div className="districts-columns">
            <Button onClick={() => this.changeColumns('left')} className="left">
              <Icon icon={faCaretLeft} size="2x" />
            </Button>
            <span className="column-name">{this.state.columns[this.state.columnIndex].name}</span>
            <Button onClick={() => this.changeColumns('right')} className="right">
              <Icon icon={faCaretRight} size="2x" />
            </Button>
          </div>
          )
        }
        <div className="dl-list-container">
          <ul>
            <DistrictListItem
              key="UNASSIGNED"
              color="white"
              district={{
                ..._.get(this.props.chosenPlan, 'unassigned'),
                id: -1,
                alias: 'Unassigned',
                total_population: _.get(this.props.chosenPlan, 'unassigned.total_population')
                  - _.sumBy(districts, 'total_population'),
              }}
              targetPopulation={0}
              handleDistrictSelect={() => {
                // this.handleUnassignedSelect
              }}
              chosen={_.get(this.props.chosenDistrict, 'id') === -1}
              isInteractive={false}
            />
            {districts.map((district) => (
              <DistrictListItem
                key={district.id}
                isEditing={this.state.editingAlias === district.id}
                setEditingAlias={this.setEditingAlias}
                cancelEditing={this.cancelEditing}
                color={_.get(district, 'district_sandbox.color')}
                isLocked={_.get(district, 'is_locked')}
                district={district}
                column={this.state.columns[this.state.columnIndex].id}
                minimumPopulation={this.props.minimumPopulation}
                submitDistrictUpdate={this.props.submitDistrictUpdate}
                toggleDistrictLock={this.props.toggleDistrictLock}
                handleDistrictSelect={this.handleDistrictSelect}
                handleDistrictDelete={this.props.handleDistrictDelete}
                chosen={_.get(this.props.chosenDistrict, 'id') === district.id}
                planId={this.props.planId}
                isInteractive={this.props.isInteractive}
                targetPopulation={
                  _.get(this.props.chosenPlan, 'target_population') || 0
                }
              />
            ))}
          </ul>
        </div>
      </section>
    );
  }
}

DistrictList.propTypes = {
  chosenDistrict: PropTypes.object,
  chosenPlan: PropTypes.any,
  focusDistrict: PropTypes.func.isRequired,
  gotoIncompleteArea: PropTypes.func.isRequired,
  handleAddDistrict: PropTypes.func.isRequired,
  handleDistrictDelete: PropTypes.func.isRequired,
  percentageComplete: PropTypes.number,
  planId: PropTypes.any,
  // retrieveSandboxDistrictDetail: PropTypes.func.isRequired,
  // selectUnassigned: PropTypes.func.isRequired,
  setChosenDistrict: PropTypes.func.isRequired,
  submitDistrictUpdate: PropTypes.func.isRequired,
  toggleDistrictLock: PropTypes.func.isRequired,
  minimumPopulation: PropTypes.number,
  isInteractive: PropTypes.bool,
  handleCancelDistrict: PropTypes.func,
};

DistrictList.defaultProps = {
  planId: null,
  chosenDistrict: null,
  chosenPlan: null,
  isInteractive: true,
  percentageComplete: 0,
  minimumPopulation: 0,
  handleCancelDistrict: null,
};

export default DistrictList;
