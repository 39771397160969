import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditorToolboxContainer from '../containers/EditorToolboxContainer';
import EditorDetailsContainer from '../containers/EditorDetailsContainer';
import EditorMapContainer from '../containers/EditorMapContainer';
import ErrorModal from '../components/common/ErrorModal';

import { getPlanDetails } from '../actions/plan';
import { getGuideLayers } from '../actions/guideLayer';
import { getBaseLayers } from '../actions/editor';
import { clearError } from '../actions/error';

import './EditorPage.css';

class ViewerPage extends React.Component {
  constructor(props) {
    super(props);
    this.planId = _.get(this.props, 'match.params.plan_id');
  }

  componentDidMount() {
    this.props.getPlanDetails(this.planId);
    this.props.getGuideLayers(this.planId);
    this.props.getBaseLayers();
  }

  render = () => (
    <>
      <div className="editor-view editor-view-readonly">
        { this.props.error.errorMessage
          ? (
            <ErrorModal
              message={this.props.error.errorMessage}
              clearMessage={this.props.clearError}
            />
          )
          : null }
        <EditorToolboxContainer
          {...this.props}
          isInteractive={false}
        />
        <div className="editor-activity-area">
          {
          !!this.planId && (
            <div className="editor-area">
              <EditorDetailsContainer
                {...this.props}
                isInteractive={false}
              />
              <EditorMapContainer
                planId={this.planId}
                {...this.props}
                isInteractive={false}
              />
            </div>
          )
        }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  error: _.get(state, 'error'),
});

ViewerPage.propTypes = {
  match: PropTypes.object.isRequired,
  getPlanDetails: PropTypes.func.isRequired,
  getGuideLayers: PropTypes.func.isRequired,
  getBaseLayers: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getPlanDetails,
  getGuideLayers,
  getBaseLayers,
  clearError,
})(ViewerPage);
