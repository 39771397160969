import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import SettingsMenu from './SettingsMenu';

const SettingsView = (props) => (
  <section className={`${props.className} modal-page`}>
    <div className={`${props.abbrev}-viewport viewport ${props.isSubmitting ? `${props.abbrev}-submitting` : ''}`}>
      <header>
        <h1>Plan Settings</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className={`${props.abbrev}-content content`}>
        <SettingsMenu {...props} />
        <div className={`${props.abbrev}-activity modal-activity`}>
          {props.children}
        </div>
      </section>
    </div>
  </section>
);

SettingsView.propTypes = {
  className: PropTypes.string.isRequired,
  abbrev: PropTypes.string,
  isSubmitting: PropTypes.bool,
  children: PropTypes.any.isRequired,
  planId: PropTypes.any.isRequired,
};

SettingsView.defaultProps = {
  abbrev: 'sv',
  isSubmitting: false,
};

export default SettingsView;
