import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';
import _ from 'lodash';

import './PercentageComplete.css';

const PercentageComplete = (props) => (
  <div className="percentage-complete">
    <h4 className="pc-label">
      {Numeral(_.round(_.get(props, 'percentageComplete', 0), 4)).format('2.2%')}
      {' '}
      Complete
    </h4>
    <div className="pc-scale">
      <div className="pc-complete" style={{ width: `${props.percentageComplete * 100}%` }} />
    </div>
  </div>
);

PercentageComplete.propTypes = {
  percentageComplete: PropTypes.number,
};

PercentageComplete.defaultProps = {
  percentageComplete: 0.0,
};

export default PercentageComplete;
