import React from 'react';
import PropTypes from 'prop-types';

import ShapefileDownloadContainer from '../containers/exportArchive/ShapefileDownloadContainer';
import MetricsDownloadContainer from '../containers/exportArchive/MetricsDownloadContainer';

const ExportArchivePage = (props) => (
  <>
    { props.action === 'shapefile' && <ShapefileDownloadContainer {...props} />}
    { props.action === 'metrics' && <MetricsDownloadContainer {...props} />}
  </>
);

ExportArchivePage.propTypes = {
  action: PropTypes.string.isRequired,
};

export default ExportArchivePage;
