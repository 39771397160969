import _ from 'lodash';

const defaultState = {
  errorMessage: null,
};

const ErrorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'BALLISTA_LOGIN_FAIL':
    case 'PLAN_CREATE_PLAN_FAIL':
    case 'PLAN_GET_PLANS_FAIL':
    case 'PLAN_GET_PLAN_DETAILS_FAIL':
    case 'PLAN_UPDATE_PLAN_FAIL':
    case 'PLAN_UPDATE_PLAN_BASELAYER_FAIL':
    case 'EDITOR_DISTRICT_UPDATE_ALIAS_FAIL':
    case 'CREATE_SHADED_LAYER_FAIL': {
      return {
        ...state,
        errorMessage: _.get(action, 'error.response.data.detail'),
      };
    }
    case 'BALLISTA_REGISTER_FAIL': {
      let errorMessage = '';
      const errors = _.get(action, 'error.response.data.errors', {});
      const fields = Object.keys(errors);
      if (fields.length > 0) {
        errorMessage = errors[fields[0]][0].message;
      }
      return {
        ...state,
        errorMessage,
      };
    }
    case 'BALLISTA_LOGOUT':
    case 'CLEAR_ERROR': {
      return {
        ...state,
        errorMessage: null,
      };
    }
    default:
      return state;
  }
};

export default ErrorReducer;
