/**
 * PlansView.js — contains all the components used by the PlansContainer
 */

import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';

import FolderModal from './FolderModal';

import PlanModal from './PlanModal';

import PlanList from './PlanList';

import Header from './Header';

import './PlansView.css';

const VerticalTabs = withStyles(() => ({
  flexContainer: {
    flexDirection: 'column',
  },
  indicator: {
    display: 'none',
  },
}))(Tabs);

const MyTab = withStyles(() => ({
  root: {
    border: '2px solid transparent',
  },
  selected: {
    color: '#333',
    border: '2px solid #5EBCF4',
    background: '#EEE',
    borderRadius: '10px',
  },
}))(Tab);

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 24 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.array.isRequired,
};

class PlansView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createPlanModalShown: false,
      createFolderModalShown: false,
    };
  }

  // componentDidUpdate() {
  // }

  handleChange = (event, activeIndex) => {
    if (this.props.displayedPlanIndex === 0) {
      this.props.changePlanTableContents(activeIndex);
    } else if (this.props.displayedPlanIndex === 1) {
      this.props.changePlanTableContents(activeIndex);
    } else if (this.props.displayedPlanIndex === 2) {
      this.props.changePlanTableContents(activeIndex);
    }
  }

  openCreateFolderModal = () => {
    this.setState({ createFolderModalShown: true });
  }

  closeCreateFolderModal = () => {
    this.setState({ createFolderModalShown: false });
  }

  submitCreateFolder = (folder) => {
    this.closeCreateFolderModal();
    this.props.handleCreateFolder(folder);
  }

  openCreatePlanModal = () => {
    this.setState({ createPlanModalShown: true });
  }

  closeCreatePlanModal = () => {
    this.setState({ createPlanModalShown: false });
  }

  submitCreatePlan = (plan) => {
    this.closeCreatePlanModal();
    this.props.handleCreatePlan(plan);
  }

  render = () => {
    const globalName = `${process.env.REACT_APP_GLOBAL_NAME || 'Global'} Plans`;
    return (
      <div className="plansView">
        <CssBaseline />
        <Container maxWidth="xl">
          <Header
            handleLogout={this.props.handleLogout}
            auth={this.props.auth}
          />
          <Typography variant="h4" className="heading">
            Plans
          </Typography>

          <div className="main">
            <div className="sidebar">
              <VerticalTabs
                value={this.props.displayedPlanIndex}
                onChange={this.handleChange}
              >
                <MyTab label="My Plans" />
                <MyTab label="Organization Plans" />
                <MyTab label={globalName} />
              </VerticalTabs>
            </div>
            <div className="plansContent">
              <TabContainer>
                { this.props.displayedPlanIndex === 0 && (
                <div className="controls">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.openCreateFolderModal()}
                  >
                    + Add Folder
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.openCreatePlanModal()}
                  >
                    + Add Plan
                  </Button>
                </div>
                )}
                <PlanList
                  auth={this.props.auth}
                  folders={this.props.folders}
                  plans={this.props.plans}
                  handleDeletePlan={this.props.handleDeletePlan}
                  handleUpdatePlan={this.props.handleUpdatePlan}
                  handlePublishPlan={this.props.handlePublishPlan}
                  handleUpdateFolder={this.props.handleUpdateFolder}
                  handleDeleteFolder={this.props.handleDeleteFolder}
                  handleMovePlanToFolder={this.props.handleMovePlanToFolder}
                  handleClonePlan={this.props.handleClonePlan}
                  arePrivatePlans={this.props.displayedPlanIndex === 0}
                />
              </TabContainer>
            </div>
          </div>
        </Container>
        <PlanModal
          open={this.state.createPlanModalShown}
          handleSubmit={(plan) => this.submitCreatePlan(plan)}
          handleClose={() => this.closeCreatePlanModal()}
          folders={this.props.folders}
          auth={this.props.auth}
        />
        { this.state.createFolderModalShown
          && (
            <FolderModal
              open={this.state.createFolderModalShown}
              handleSubmit={(folder) => this.submitCreateFolder(folder)}
              handleClose={() => this.closeCreateFolderModal()}
            />
          )}
      </div>
    );
  }
}

PlansView.propTypes = {
  folders: PropTypes.array.isRequired,
  plans: PropTypes.array.isRequired,
  handleCreatePlan: PropTypes.func.isRequired,
  handleDeletePlan: PropTypes.func.isRequired,
  handleDeleteFolder: PropTypes.func.isRequired,
  handleUpdatePlan: PropTypes.func.isRequired,
  handlePublishPlan: PropTypes.func.isRequired,
  handleCreateFolder: PropTypes.func.isRequired,
  handleUpdateFolder: PropTypes.func.isRequired,
  handleMovePlanToFolder: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleClonePlan: PropTypes.func.isRequired,
  changePlanTableContents: PropTypes.func.isRequired,
  displayedPlanIndex: PropTypes.number.isRequired,
  auth: PropTypes.object.isRequired,
};

export default PlansView;
