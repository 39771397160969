import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SettingsMenu from './SettingsMenu';

import ColumnSettingsForm from './ColumnSettingsForm';
import './ColumnSettings.css';

const ColumnSettings = (props) => (
  <section className="column-settings modal-page">
    <div className={`cs-viewport viewport ${props.isSubmitting ? 'cs-submitting' : ''}`}>
      <header>
        <h1>Plan Settings</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="cs-content content">
        <SettingsMenu {...props} />
        <div className="cs-activity modal-activity">
          <ColumnSettingsForm {...props} />
        </div>
      </section>
    </div>
  </section>
);

ColumnSettings.propTypes = {
  action: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  planId: PropTypes.any.isRequired,
};

ColumnSettings.defaultProps = {
  action: 'basic',
};

export default ColumnSettings;
