import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import {
  Table, TableRow, TableCell, TableHead, TableBody,
} from '@material-ui/core';

import ExportArchiveMenu from './ExportArchiveMenu';

const MetricsDownloadView = (props) => (
  <section className="shapefile-download-view modal-page">
    <div className="viewport">
      <header>
        <h1>Exports Archive</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="content">
        <ExportArchiveMenu {...props} />
        <div className="modal-activity">
          <div className="activity-area">
            <header>
              <h3>Metrics Tests Downloads Archive</h3>
            </header>
            <div className="inner-activity">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Test Suite</TableCell>
                    <TableCell>Projection</TableCell>
                    <TableCell>Date Created</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    props.metricResultsArchive
                    && props.metricResultsArchive.map(
                      (fileItem) => (
                        <TableRow>
                          <TableCell>
                            {_.get(fileItem, 'metric_test_suite.title')}
                          </TableCell>
                          <TableCell>
                            {_.get(fileItem, 'metric_test_suite.projection')}
                          </TableCell>
                          <TableCell>
                            <Moment date={_.get(fileItem, 'created_at')} format="DD MMM YYYY // hh:mm a" />
                          </TableCell>
                          <TableCell>
                            <a href={fileItem.filename} rel="noopener noreferrer" target="_blank">Download</a>
                          </TableCell>
                        </TableRow>
                      ),
                    )
                  }
                </TableBody>
              </Table>
            </div>
            <footer />
          </div>
        </div>
      </section>
    </div>
  </section>
);

MetricsDownloadView.propTypes = {
  planId: PropTypes.string.isRequired,
  metricResultsArchive: PropTypes.array.isRequired,

};
export default MetricsDownloadView;
