import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Table, TableCell, TableHead, TableRow, TableBody,
} from '@material-ui/core';

import MetricsMenu from './MetricsMenu';
import { ConfirmationModal } from '../common/Modal';
import './QueryBuilderView.css';

const MetricsListView = (props) => (

  <>
    <ConfirmationModal
      open={props.isDeleting}
      title="Delete Metric"
      onConfirm={props.handleConfirmDelete}
      onCancel={props.handleCancelDelete}
    >
      <span>
        Are you sure you want to delete
      </span>
      <br />
      <strong>
        {_.get(_.filter(props.metrics, { id: props.tobeDeleted }), '[0].name', 'this metric')}
      </strong>
      <span>?</span>
    </ConfirmationModal>
    <section className="metrics-list-view modal-page">
      <div className="viewport">
        <header>
          <h1>Metrics</h1>
          <Link to={`/editor/${props.planId}`}>Close</Link>
        </header>
        <section className="content">
          <MetricsMenu {...props} />
          <div className="modal-activity">
            <div className="activity-area">
              <header>
                <h3>Metrics List</h3>
              </header>
              <div className="inner-activity">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Metric Name</TableCell>
                      <TableCell>Formula</TableCell>
                      <TableCell>Variables</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { props.metrics.map((metric, i) => (
                      <TableRow key={`metrics_formula_${i}`}>
                        <TableCell>{metric.name}</TableCell>
                        <TableCell className="formula-text">{metric.formula}</TableCell>
                        <TableCell className="formula-text">
                          <ul>
                            {Object.keys(metric.variable_mapping).map((key, j) => (
                              <li key={`metrics_formula_${i}_${j}`}>
                                {key}
                                :
                                {' '}
                                {metric.variable_mapping[key]}
                              </li>
                            ))}
                          </ul>
                        </TableCell>
                        <TableCell>{metric.description}</TableCell>
                        <TableCell>
                          <Link to={`/editor/${props.planId}/metrics/metric-edit/${metric.id}`}>Edit</Link>
                          &nbsp;•&nbsp;
                          <Link to="#" onClick={() => props.handleDelete(metric.id)}>Delete</Link>
                        </TableCell>
                      </TableRow>
                    ))}

                  </TableBody>
                </Table>
              </div>
              <footer />
            </div>
          </div>
        </section>
      </div>
    </section>
  </>
);

MetricsListView.propTypes = {
  isDeleting: PropTypes.bool.isRequired,
  handleCancelDelete: PropTypes.func.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  metrics: PropTypes.array.isRequired,
  planId: PropTypes.string.isRequired,
  tobeDeleted: PropTypes.number,
};

MetricsListView.defaultProps = {
  tobeDeleted: null,
};

export default MetricsListView;
