import React from 'react';
import { Provider } from 'react-redux';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Scorpio from './pages/Scorpio';
import store from './store';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3E97CB',
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Scorpio />
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
