import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types';

import CreateReportView from '../../components/report/CreateReportView';

import { submitCreateReport } from '../../actions/report';
import { getMetricTests } from '../../actions/metrics';

class CreateReportContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      form: {
        detailsSection: {
          name: null,
          description: null,
          testSuites: [],
        },
        districtSection: [],
      },
    };
  }

  componentDidMount() {
    this.props.getMetricTests(this.props.planId);
    this.initializeDistricts();
  }

  componentDidUpdate(prevProps) {
    if ((!prevProps.districts || prevProps.districts === undefined)
      && this.props.districts !== undefined) {
      this.initializeDistricts();
      this.initializeForm();
    }
  }

  initializeDistricts = () => {
    if (!!this.props.districts && this.props.districts !== undefined) {
      const districts = this.props.districts.map((d) => ({
        id: d.id,
        alias: d.alias,
        description: d.description,
      }));

      this.setState((prevProps) => ({
        form: {
          ...prevProps.form,
          districtSection: [...districts],
        },
      }));
    }
  }

  handleNext = () => {
    this.setState((prevProps) => ({ activeStep: prevProps.activeStep + 1 }));
  }

  handleBack = () => {
    this.setState((prevProps) => ({ activeStep: prevProps.activeStep - 1 }));
  }

  handleDistrictChange = (index, property) => (e) => {
    const { value } = e.target;

    let districtSection = _.get(this.state, 'form.districtSection', []);

    districtSection = _.set(districtSection, `[${index}].${property}`, value);

    this.setState((prevProps) => ({
      form: {
        ...prevProps.form,
        districtSection,
      },
    }));
  }

  handleCheck = (id) => {
    const testSuites = _.get(this.state.form, 'detailsSection.testSuites');
    if (testSuites.includes(id)) {
      testSuites.splice(testSuites.indexOf(id), 1);
    } else {
      testSuites.push(id);
    }

    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        detailsSection: {
          ...prevState.form.detailsSection,
          testSuites,
        },
      },
    }));
  }

  handleChange = (section, name) => (e) => {
    const { value } = e.target;

    this.setState((prevProps) => ({
      form: {
        ...prevProps.form,
        [section]: {
          ...prevProps.form[section],
          [name]: value,
        },
      },
    }));
  }

  handleSubmit = () => {
    this.props.submitCreateReport(
      this.props.planId,
      this.state.form.detailsSection,
      this.state.form.districtSection,
    ).then((response) => {
      const reportId = _.get(response, 'payload.data.id');
      this.props.history.push(`/editor/${this.props.planId}/report/${reportId}/view`);
    });
  }

  checkDetailsComplete = () => !_.isEmpty(_.get(this.state.form, 'detailsSection.name'))
    && !_.isEmpty(_.get(this.state.form, 'detailsSection.description'))
    && !_.isEmpty(_.get(this.state.form, 'detailsSection.testSuites'))

  initializeForm() {
    this.setState((prevProps) => ({
      form: {
        ...prevProps.form,
        detailsSection: {
          ...prevProps.form.detailsSection,
          name: this.props.chosenPlan.title,
        },
      },
    }));
  }

  render = () => (
    <CreateReportView
      {...this.props}
      {...this.state}
      handleNext={this.handleNext}
      handleBack={this.handleBack}
      handleChange={this.handleChange}
      handleCheck={this.handleCheck}
      handleDistrictChange={this.handleDistrictChange}
      handleSubmit={this.handleSubmit}
      checkDetailsComplete={this.checkDetailsComplete}
    />
  );
}

const mapStateToProps = (state) => ({
  testSuites: _.get(state.metrics, 'testSuites'),
  districts: _.get(state.plan, 'chosenPlan.districts'),
  chosenPlan: _.get(state.plan, 'chosenPlan'),
});

CreateReportContainer.propTypes = {
  getMetricTests: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  submitCreateReport: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  chosenPlan: PropTypes.object.isRequired,
  districts: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, {
  getMetricTests,
  submitCreateReport,
})(CreateReportContainer);
