import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Uppy from '@uppy/core';
import Cookies from 'universal-cookie';
import XHRUpload from '@uppy/xhr-upload';
import { DragDrop } from '@uppy/react';

import './GuideLayerUpload.css';

class GuideLayerUpload extends React.Component {
  constructor(props) {
    super(props);

    this.uppy = Uppy({
      meta: { type: ['geojson', 'shp', 'zip'] },
      restrictions: { maxNumberOfFiles: 1 },
      autoProceed: true,
      allowMultipleUploads: false,
      logger: Uppy.debugLogger,
    });
  }

  componentDidMount() {
    const planId = _.get(this.props, 'planId');

    const cookies = new Cookies();

    const headers = {};
    if (cookies.get('ballista_token') && !cookies.get('pdi_token')) {
      headers.authorization = `Bearer ${cookies.get('ballista_token')}`;
    }

    this.uppy.use(XHRUpload, {
      endpoint: `${process.env.REACT_APP_API_URL}api/v1/plan/${planId}/guide-layer/`,
      formData: true,
      fieldName: 'files',
      headers,
      withCredentials: true,
      logger: Uppy.debugLogger,
      limit: 10,
    });

    this.uppy.on('upload-success', (__, response) => {
      this.props.handleUploadGuideLayer(response);
    });
  }

  render = () => (
    <div className="upload-drop-area">
      <DragDrop
        uppy={this.uppy}
        locale={{
          strings: {
            // Text to show on the droppable area.
            // `%{browse}` is replaced with a link that opens the system file selection dialog.
            dropHereOr: 'Drop shapefile or geojson here or click to %{browse}',
            // Used as the label for the link that opens the system file selection dialog.
            browse: 'browse',
          },
        }}
      />
    </div>
  );
}

GuideLayerUpload.propTypes = {
  handleUploadGuideLayer: PropTypes.func.isRequired,
};

export default GuideLayerUpload;
