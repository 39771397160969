import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import ImportedDistrictView from '../components/importedDistrict/ImportedDistrictView';
import {
  getImportedDistrict,
  submitSelectedImportedDistrict,
} from '../actions/importedDistrict';

import {
  getPlanDetails,
} from '../actions/plan';

class ImportedDistrictLibraryContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedLibraryItem: null,
    };
  }

  componentDidMount() {
    const planId = _.get(this.props.match, 'params.plan_id');
    this.props.getImportedDistrict(planId);
  }

  handleSelect = () => {
    const planId = _.get(this.props, 'match.params.plan_id');
    const importedDistrictId = _.get(this.state, 'selectedLibraryItem.id');

    this.props
      .submitSelectedImportedDistrict(planId, importedDistrictId)
      .then(() => this.props.getPlanDetails(planId))
      .then(() => {
        this.props.history.push(`/editor/${planId}`);
      });
  }

  selectLibraryItem = (importedDistrict) => {
    if (importedDistrict.id === _.get(this.state.selectedLibraryItem, 'id')) {
      this.setState({ selectedLibraryItem: null });
    } else {
      this.setState({ selectedLibraryItem: importedDistrict });
    }
  }

  render = () => (
    <ImportedDistrictView
      {...this.props}
      {...this.state}
      action="library"
      handleSelect={this.handleSelect}
      planId={_.get(this.props, 'match.params.plan_id')}
      selectLibraryItem={this.selectLibraryItem}
    />
  )
}

const mapStateToProps = (state) => ({
  importedDistricts: _.get(state, 'importedDistrict.list'),
  isLoading: _.get(state, 'importedDistrict.isLoading'),
  isSubmitting: _.get(state, 'importedDistrict.isSubmitting'),
});

ImportedDistrictLibraryContainer.propTypes = {
  getImportedDistrict: PropTypes.func.isRequired,
  getPlanDetails: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  importedDistricts: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  submitSelectedImportedDistrict: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getImportedDistrict,
  getPlanDetails,
  submitSelectedImportedDistrict,
})(ImportedDistrictLibraryContainer);
