/**
 * Retrieves the list of exported shapefi;e for this item
 * @param {string} planId
 */
export const getExportedShapefileArchive = (planId) => ({
  type: 'EXPORT_GET_EXPORT_SHAPEFILE_ARCHIVE',
  payload: {
    request: {
      url: `/plan/${planId}/export/`,
      method: 'GET',
    },
  },
});

export const getMetricTestsArchive = (planId) => ({
  type: 'EXPORT_GET_METRIC_TEST_ARCHIVES',
  payload: {
    request: {
      url: `/plan/${planId}/metrics/exports/`,
    },
  },
});
