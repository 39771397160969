import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';

import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { DragDrop } from '@uppy/react';

import './ImportedDistrictUploadArea.css';

class ImportedDistrictUploadArea extends React.Component {
  constructor(props) {
    super(props);

    this.uppy = Uppy({
      meta: { type: ['geojson', 'shp', 'zip'] },
      restrictions: { maxNumberOfFiles: 1 },
      autoProceed: true,
      allowMultipleUploads: false,
      logger: Uppy.debugLogger,
    });
  }

  componentDidMount() {
    const headers = {};
    const cookies = new Cookies();

    if (cookies.get('ballista_token') && !cookies.get('pdi_token')) {
      headers.Authorization = `Bearer ${cookies.get('ballista_token')}`;
    }
    this.uppy.use(XHRUpload, {
      endpoint: `${process.env.REACT_APP_API_URL}api/v1/plan/${this.props.planId}/imported-district/upload/`,
      formData: true,
      headers,
      withCredentials: true,
      fieldName: 'files',
      logger: Uppy.debugLogger,
      limit: 10,
    });

    this.uppy.on('upload-success', (_, response) => {
      this.props.handleUploadImportedDistrict(response);
    });
  }

  render = () => (
    <div className="upload-drop-area">
      <DragDrop
        uppy={this.uppy}
        locale={{
          strings: {
            // Text to show on the droppable area.
            // `%{browse}` is replaced with a link that opens the system file selection dialog.
            dropHereOr: 'Drop shapefile or geojson here or click to %{browse}',
            // Used as the label for the link that opens the system file selection dialog.
            browse: 'browse',
          },
        }}
      />
    </div>
  )
}

ImportedDistrictUploadArea.propTypes = {
  handleUploadImportedDistrict: PropTypes.func.isRequired,
  planId: PropTypes.any.isRequired,
};

export default ImportedDistrictUploadArea;
