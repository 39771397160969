import React from 'react';
import PropTypes from 'prop-types';
import OLVectorTileSource from 'ol/source/VectorTile';
import { GeoJSON } from 'ol/format';

/**
 * For our intents and purposes, this component are vectors being served
 * by Ballista server
 */
class VectorTileLoaderSource extends React.Component {
  constructor(props) {
    super(props);

    const format = new GeoJSON({});

    const vectorTileSource = new OLVectorTileSource({
      format,
      tileGrid: props.tileGrid,
      tileUrlFunction: props.tileUrlFunction,
      overlaps: false,
    });

    if (props.tileLoadFunction) {
      vectorTileSource.setTileLoadFunction(props.tileLoadFunction);
    }

    this.state = {
      vectorTileSource,
    };

    this.loading = {};
    this.loaded = {};
    this.loadedTiles = {};
  }

  componentDidMount() {
    this.renderSource();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.shadedLayerId !== this.props.shadedLayerId) {
      this.state.vectorTileSource.refresh();
      this.state.vectorTileSource.clear(true);
    }
    if (prevProps.dataLayer !== this.props.dataLayer) {
      this.state.vectorTileSource.refresh();
      this.state.vectorTileSource.clear(true);
    }
  }

  getFeaturesInExtent = (extent) => this.state.vectorTileSource.getFeaturesInExtent(extent)

  getTileGrid = () => this.state.vectorTileSource.getTileGrid()

  renderSource = () => {
    this.props.layer.setSource(this.state.vectorTileSource);
  }

  refresh = () => this.state.vectorTileSource.refresh()

  render = () => null;
}

VectorTileLoaderSource.propTypes = {
  layer: PropTypes.object,
  tileUrlFunction: PropTypes.func.isRequired,
  tileLoadFunction: PropTypes.func,
  tileGrid: PropTypes.object.isRequired,
  shadedLayerId: PropTypes.number,
  dataLayer: PropTypes.string,
};

VectorTileLoaderSource.defaultProps = {
  layer: null,
  shadedLayerId: null,
  dataLayer: null,
  tileLoadFunction: null,
};

export default VectorTileLoaderSource;
