import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import Button from '../common/Button';
import { censusStates, censusDistrictTypes } from '../../helper/censusData';

import DropDown from '../common/DropDown';

const BasicSettingsForm = (props) => (
  <section className="basic-settings-form activity-area">
    <header>
      <h3>
        Basic Settings
      </h3>
    </header>
    <div className="bsf-inner-activity-area inner-activity">
      <div className="bsf-import-activity">
        <Grid container spacing={5}>
          {/* Plan Title */}
          <Grid item xs={12} md={12}>
            <TextField
              label="Plan Title"
              className="bsf-text"
              defaultValue={props.form.title}
              value={props.form.title || ''}
              placeholder="Title of your Plan"
              fullWidth
              onChange={(event) => {
                props.handleChange('title')(event.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          {/* Plan Description */}
          <Grid item xs={12} md={12}>
            <TextField
              label="Description"
              className="bsf-text"
              defaultValue={props.form.description}
              value={props.form.description}
              InputLabelProps={{ shrink: true }}
              placeholder="Function that this shaded layer does."
              fullWidth
              multiline
              onChange={(event) => props.handleChange('description')(event.target.value)}
            />
          </Grid>

          {/* State Dropdown */}
          <Grid item md={6} xs={12}>
            <h5>State</h5>
            <DropDown
              className="bsf-dropdown dropdown"
              disabled
              label={
                (
                  <span>
                    {_.get(_.filter(censusStates, { id: props.form.planState }, [])[0], 'name', 'Choose State')}
                  </span>
                )
              }
              options={censusStates}
              onChange={(item) => {
                props.handleChange('planState')(item.id);
              }}
              renderItem={(item) => (<span>{item.name}</span>)}
            />
          </Grid>

          {/* District Type */}
          <Grid item md={6} xs={12}>
            <h5>District Type</h5>
            <DropDown
              className="bsf-dropdown dropdown"
              label={
                (
                  <span>
                    {_.get(
                      _.filter(censusDistrictTypes, { id: props.form.districtType }, [])[0],
                      'name',
                      'Choose District Type',
                    )}
                  </span>
                )
              }
              options={censusDistrictTypes}
              onChange={(item) => {
                props.handleChange('districtType')(item.id);
              }}
              renderItem={(item) => (<span>{item.name}</span>)}
            />
          </Grid>

          {/* # of Districts */}
          <Grid item md={6} xs={12}>
            <TextField
              label="# of Districts"
              className="bsf-text"
              value={props.form.districtCount}
              InputLabelProps={{ shrink: true }}
              placeholder="# of districts"
              type="number"
              onChange={(event) => props.handleChange('districtCount')(event.target.value)}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Autocomplete
              id="combo-box-demo"
              options={props.spatialReferences}
              getOptionLabel={(option) => `${option.auth_name}:${option.srid} - ${option.name}`}
              style={{ width: '100%' }}
              value={_.get(props.form, 'defaultProjection')}
              controlled
              onChange={(__, value) => {
                props.handleChange('defaultProjection')(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Default Projection"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />

          </Grid>
        </Grid>
      </div>
    </div>
    <footer>
      <Button grayed onClick={props.revert}>Revert</Button>
      <Button primary onClick={props.handleSubmit}>Save</Button>
    </footer>
  </section>
);

BasicSettingsForm.propTypes = {

  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  revert: PropTypes.func.isRequired,
  spatialReferences: PropTypes.object.isRequired,
};

BasicSettingsForm.defaultProps = {
};

export default BasicSettingsForm;
