/**
 * Creates a folder
 * @param {string} name
 * @param {array} plans
 */
export const createFolder = (name, plans = []) => ({
  type: 'FOLDER_CREATE_FOLDER',
  payload: {
    request: {
      url: '/folder/',
      method: 'POST',
      data: {
        name,
        plans,
      },
    },
  },
});

/**
 * Updates a folder
 * @param {number} folderId
 * @param {string} name
 * @param {array} plans
 */
export const updateFolder = (folderId, name, plans = []) => ({
  type: 'FOLDER_UPDATE_FOLDER',
  payload: {
    request: {
      url: `/folder/${folderId}/`,
      method: 'PUT',
      data: {
        name,
        plans,
      },
    },
  },
});

/**
 * calls DELETE method of `/folder/` to soft-delete a folder
 * @param {number} folderId
 */
export const deleteFolder = (folderId) => ({
  type: 'FOLDER_DELETE_FOLDER',
  folderId,
  payload: {
    request: {
      url: `/folder/${folderId}/`,
      method: 'DELETE',
    },
  },
});
