import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetricsTestListView from '../../components/metrics/TestListView';
import {
  getMetricTests,
} from '../../actions/metrics';

class MetricsTestListContainer extends React.Component {
  componentDidMount = () => {
    this.props.getMetricTests(this.props.planId);
  }

  render = () => (
    <MetricsTestListView
      {...this.props}
    />
  );
}

MetricsTestListContainer.propTypes = {
  getMetricTests: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
};

MetricsTestListContainer.defaultProps = {

};

const mapStateToProps = (state) => ({
  testSuites: _.get(state, 'metrics.testSuites'),
});
export default connect(mapStateToProps, {
  getMetricTests,
})(MetricsTestListContainer);
