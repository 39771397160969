import React from 'react';
import PropTypes from 'prop-types';
import {
  Fill, Stroke, Style as OLStyle,
} from 'ol/style';

/**
 * For our intents and purposes, this component are vectors being served
 * by Ballista server
 */
class Style extends React.Component {
  // This would have the feature stile
  // has this.props.layer
  componentDidMount() {
    // this.props.layer
    this.renderStyle();
  }

  componentDidUpdate() {
    this.renderStyle();
  }

  renderStyle = () => {
    if (!!this.props.featureStyle && typeof this.props.featureStyle === 'function') {
      this.props.layer.setStyle(this.props.featureStyle);
    } else {
      this.props.layer.setStyle((feature) => {
        const fill = new Fill(
          this.props.fill(feature),
        );

        const { fillOpacity } = this.props;
        const stroke = this.props.stroke !== null ? new Stroke(this.props.stroke(feature)) : null;

        return new OLStyle({
          fillOpacity,
          fill,
          stroke,
        });
      });
    }
  }

  render = () => null;
}

Style.propTypes = {
  featureStyle: PropTypes.func,
  layer: PropTypes.object,
  fillOpacity: PropTypes.any,
  fill: PropTypes.any,
  stroke: PropTypes.any,
};

Style.defaultProps = {
  featureStyle: null,
  layer: null,
  fillOpacity: null,
  fill: null,
  stroke: null,
};

export default Style;
