import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { LinearProgress } from '@material-ui/core';
import ShadedLayerMenu from './ShadedLayerMenu';
import ShadedLayerForm from './ShadedLayerForm';
import Button from '../common/Button';
import { ConfirmationModal } from '../common/Modal';

import './ShadedLayerView.css';

const ShadedLayerView = (props) => (
  <>
    <ConfirmationModal
      open={props.isDeleting}
      title="Delete Shaded Layer"
      onConfirm={props.handleConfirmDelete}
      onCancel={props.handleCancelDelete}
    >
      Are you sure you want to delete the Shade Layer
      {' '}
      {_.get(props.form, 'name', '...')}
    </ConfirmationModal>
    <section className="shaded-layer-view modal-page">
      <div className={`slv-viewport viewport ${props.isSubmitting ? 'slv-submitting' : ''}`}>
        <header>
          <h1>Shaded Layers</h1>
          <Link to={`/editor/${props.planId}`}>Close</Link>
        </header>
        <section className="slv-content content">
          <ShadedLayerMenu {...props} />
          <div className="slv-activity modal-activity">
            <div className="activity-area  ">
              <header>
                <h3>
                  {!props.isComplete ? '[Building] ' : null}
                  {props.action === 'create'
                    ? 'Create Shaded Layer'
                    : `${_.get(props.form, 'name', '...')}`}
                </h3>
                {
                  !props.isComplete
                    ? <LinearProgress />
                    : null
                }
              </header>
              <ShadedLayerForm
                {...props}
              />
              <footer>
                { _.get(props.form, 'id', null) !== null
                  ? (
                    <Button grayed onClick={() => { props.handleDelete(); }}>
                      Delete
                    </Button>
                  )
                  : (<div />)}
                <div className="slv-editing">

                  {
                    props.isComplete && (
                      <>
                        <Button grayed onClick={() => {}}>
                          Revert
                        </Button>

                        {
                        _.get(props.form, 'id', null) === null
                          ? (
                            <Button
                              primary
                              onClick={props.handleSubmit}
                            >
                              Create
                            </Button>
                          )
                          : (
                            <Button
                              primary
                              onClick={props.handleUpdate}
                            >
                              Update
                            </Button>
                          )
                      }
                      </>
                    )
                  }

                </div>

              </footer>
            </div>
          </div>
        </section>
      </div>
    </section>
  </>
);

ShadedLayerView.propTypes = {
  action: PropTypes.string,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  planId: PropTypes.any.isRequired,
  form: PropTypes.object.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleCancelDelete: PropTypes.func.isRequired,
  handleConfirmDelete: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isComplete: PropTypes.bool.isRequired,
};

ShadedLayerView.defaultProps = {
  action: 'upload',
  isSubmitting: false,
};

export default ShadedLayerView;
