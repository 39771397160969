import React from 'react';
import PropTypes from 'prop-types';

import OSM from 'ol/source/OSM';

import { get as getProjection } from 'ol/proj';
import { createXYZ } from 'ol/tilegrid';
import TileLayer from 'ol/layer/Tile';

class OpenStreetMap extends React.Component {
  constructor(props) {
    super(props);
    this.openStreetMap = null;
    this.baseLayer = null;
  }

  componentDidMount() {
    if (this.props.parent !== 'BASELAYER') {
      throw new Error('This component should be under BaseLayer');
    }

    this.openStreetMap = new OSM();
    this.openStreetMap.setTileGridForProjection(getProjection('EPSG:900913'), createXYZ({
      extent: [-180, -90, 180, 90],
      tileSize: 512,
    }));

    this.baseLayer = new TileLayer({
      source: this.openStreetMap,
    });

    this.props.map.getLayers().insertAt(0, this.baseLayer);
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.baseLayer);
  }

  render = () => <></>;
}

OpenStreetMap.propTypes = {
  parent: PropTypes.string,
  map: PropTypes.object,
};

OpenStreetMap.defaultProps = {
  parent: null,
  map: null,
};

export default OpenStreetMap;
