/** Retrieves all available/allowed metrics for the user..
 *
 */
export const getMetrics = () => ({
  type: 'METRIC_GET_METRICS',
  payload: {
    request: {
      url: '/metrics/',
      method: 'GET',
    },
  },
});

/**
 * Retrieves the test results for a test instance
 * @param {string} planId
 * @param {number} testSuiteId
 * @param {number} instanceId
 */
export const getMetricsTestResults = (planId, testSuiteId, instanceId) => ({
  type: 'METRIC_GET_TEST_RESULTS',
  payload: {
    request: {
      url: `/plan/${planId}/metrics/${testSuiteId}/results/${instanceId}/`,
      method: 'GET',
    },
  },
});

/**
 * metric get tests from said plan
 * @param {string} planId
 */
export const getMetricTests = (planId) => ({
  type: 'METRIC_GET_TEST_SUITES',
  payload: {
    request: {
      url: `/plan/${planId}/metrics/`,
      method: 'GET',
      params: {
        ordering: '-created_at',
      },
    },
  },
});

/**
 * Creates a test suite for the plan. Includes
 * all the necessary parameters to create a test
 * @param {string} planId
 * @param {string} title
 * @param {id} projection
 * @param {array} metrics
 */
export const createTestSuite = (planId, title, projection, metrics) => ({
  type: 'METRIC_CREATE_METRIC_TESTS',
  payload: {
    request: {
      url: `/plan/${planId}/metrics/`,
      method: 'POST',
      data: {
        title,
        metrics,
        projection,
      },
    },
  },
});

/**
 * Get measurements that are available for the user (eg. AREA, PERIMETER, etc)
 */
export const getMeasurements = () => ({
  type: 'METRIC_GET_MEASUREMENTS',
  payload: {
    request: {
      url: '/measurements/',
      method: 'GET',
    },
  },
});

/**
 * Submits a user created metric
 * @param {string} name
 * @param {string} description
 * @param {string} markup
 */
export const createMetric = (name, description, markup) => ({
  type: 'METRIC_CREATE_METRIC',
  payload: {
    request: {
      url: '/metrics/',
      method: 'POST',
      data: {
        name,
        description,
        markup,
      },
    },
  },
});

/**
 * Updates the information within a metric
 * @param {number} id
 * @param {string} name
 * @param {string} description
 * @param {string} markup
 */
export const updateMetric = (id, name, description, markup) => ({
  type: 'METRIC_UPDATE_METRIC',
  payload: {
    request: {
      url: `/metrics/${id}/`,
      method: 'PUT',
      data: {
        name,
        description,
        markup,
      },
    },
  },
});

/**
 * Returns all the available projections to
 * the user
 */
export const getSpatialReferences = () => ({
  type: 'METRIC_GET_SPATIAL_REFERENCE',
  payload: {
    request: {
      url: '/spatial-reference/',
      method: 'GET',
    },
  },
});

/**
 * Deletes a metric created by the user
 * @param {number} metricId
 */
export const deleteMetric = (metricId) => ({
  type: 'METRIC_DELETE_METRIC',
  metricId,
  payload: {
    request: {
      url: `/metrics/${metricId}/`,
      method: 'DELETE',
    },
  },
});

/**
 * Starts a test for the userr.
 * @param {string} planId - public ID of the plan
 * @param {number} testSuiteId
 */
export const generateTestInstance = (planId, testSuiteId) => ({
  type: 'METRIC_GENERATE_TEST',
  payload: {
    request: {
      url: `/plan/${planId}/metrics/${testSuiteId}/results/`,
      method: 'POST',
    },
  },
});

export const receiveMetricTestResultsPoll = (poll) => ({
  type: 'METRIC_POLL_TEST_RESULT',
  payload: {
    data: {
      ...poll,
    },
  },
});
