import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import EditorToolboxContainer from '../containers/EditorToolboxContainer';
import EditorDetailsContainer from '../containers/EditorDetailsContainer';
import EditorMapContainer from '../containers/EditorMapContainer';
import ErrorModal from '../components/common/ErrorModal';

import { getPlanDetails } from '../actions/plan';
import { getGuideLayers, getDefaultGuideLayers, updateGuideLayerSettings } from '../actions/guideLayer';
import { getBaseLayers } from '../actions/editor';
import { clearError } from '../actions/error';
import { activateShadedLayer, getDataColumns } from '../actions/shadedLayer';
import { logout } from '../actions/auth';

import './EditorPage.css';

class EditorPage extends React.Component {
  constructor(props) {
    super(props);
    this.planId = props.match.params.plan_id;
  }

  componentDidMount() {
    const planId = _.get(this.props, 'match.params.plan_id');
    this.props.getDataColumns(planId);
    this.props.getPlanDetails(planId);
    this.props.getGuideLayers(planId);
    this.props.getDefaultGuideLayers(planId);
    this.props.getBaseLayers();

    if (this.props.shadedLayerId
      && this.props.planId
      && this.props.shouldActivateShadedLayer) {
      this.props.activateShadedLayer(this.props.planId, this.props.shadedLayerId);
      this.props.history.replace(`/editor/${planId}`);
    }
  }

  componentDidUpdate(prevProps) {
    if (_.get(this.props, 'error.errorMessage') === 'Invalid token.' || _.get(this.props, 'error.errorMessage') === 'User inactive or deleted.') {
      this.props.logout();
    }
    if (this.props.shadedLayerId
      && this.props.planId
      && !prevProps.shouldActivateShadedLayer && this.props.shouldActivateShadedLayer) {
      this.props.activateShadedLayer(this.props.planId, this.props.shadedLayerId);
      this.props.history.replace(`/editor/${this.props.planId}`);
    }
  }

  render = () => (
    <>
      <div className="editor-view">
        { this.props.error.errorMessage
          ? (
            <ErrorModal
              message={this.props.error.errorMessage}
              clearMessage={this.props.clearError}
            />
          )
          : null }
        <EditorToolboxContainer
          {...this.props}
        />
        <div className="editor-activity-area">
          {
            !!this.planId && (
              <div className="editor-area">
                <EditorDetailsContainer
                  {...this.props}
                />
                <EditorMapContainer
                  planId={this.planId}
                  {...this.props}
                />
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

EditorPage.propTypes = {
  match: PropTypes.object.isRequired,
  getPlanDetails: PropTypes.func.isRequired,
  getGuideLayers: PropTypes.func.isRequired,
  getDefaultGuideLayers: PropTypes.func.isRequired,
  getBaseLayers: PropTypes.func.isRequired,
  getDataColumns: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
  activateShadedLayer: PropTypes.func.isRequired,
  updateGuideLayerSettings: PropTypes.func.isRequired,
  shouldActivateShadedLayer: PropTypes.bool,
  shadedLayerId: PropTypes.number,
  planId: PropTypes.any,
  history: PropTypes.object.isRequired,
  shadedLayer: PropTypes.object,
  logout: PropTypes.func.isRequired,
};

EditorPage.defaultProps = {
  shouldActivateShadedLayer: false,
  shadedLayerId: null,
  planId: null,
  shadedLayer: {},
};

const mapStateToProps = (state) => ({
  error: _.get(state, 'error'),
  shadedLayer: _.get(state, 'shadedLayer'),
});

export default connect(mapStateToProps, {
  getPlanDetails,
  getGuideLayers,
  getDefaultGuideLayers,
  getBaseLayers,
  clearError,
  activateShadedLayer,
  updateGuideLayerSettings,
  getDataColumns,
  logout,
})(EditorPage);
