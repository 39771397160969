import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { TextField } from '@material-ui/core';
import Button from '../common/Button';

import './LoginView.css';

class LoginView extends React.Component {
  keyPress = (e) => {
    if (e.keyCode === 13) {
      this.props.handleLogin();
    }
  }

  render = () => {
    let hideRegister = false;
    if (process.env.REACT_APP_HIDE_REGISTRATION) {
      hideRegister = true;
    }
    return (
      <div className="login-page">
        <div className="login-area form-area">
          <h3>Scorpio Login</h3>
          <form onSubmit={(e) => { e.preventDefault(); this.props.handleLogin(); }}>
            <div className="form-item">
              <label>Username</label>
              <TextField
                type="text"
                name="username"
                placeholder="Enter your Username"
                onChange={this.props.handleChange('username')}
                onKeyDown={this.keyPress}
              />
            </div>
            <div className="form-item">
              <label>Password</label>
              <TextField
                type="password"
                name="password"
                placeholder="Enter your Password"
                onChange={this.props.handleChange('password')}
                onKeyDown={this.keyPress}
              />
            </div>
            <div className="form-item submit-button">
              <Button primary="true" onClick={this.props.handleLogin} type="submit">Login</Button>
            </div>
            { !hideRegister
          && (
          <div className="register-user">
            New User?
            <Link to="/register"> Register Here</Link>
          </div>
          )}
          </form>
        </div>
      </div>
    );
  }
}

LoginView.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
};

export default LoginView;
