import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import ClickOutside from './ClickOutside';

import './DropDown.css';

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
    };
  }

  hideDropDown = () => {
    this.setState({ showDropDown: false });
  }

  showDropDown = () => {
    this.setState({ showDropDown: true });
  }

  handleClick = (item) => {
    this.props.onChange(item);
    this.hideDropDown();
  }

  render = () => (
    <ClickOutside
      handleClickOutside={this.hideDropDown}
      className={`${this.props.className} ${this.state.showDropDown ? 'active' : ''}`}
    >
      <div className="dropdown-container">
        <div className="dropdown-label">
          <Button
            className="dropdown-button"
            disabled={this.props.disabled}
            onClick={() => (this.state.showDropDown ? this.hideDropDown() : this.showDropDown())}
          >
            {this.props.label}
          </Button>
        </div>
        { this.state.showDropDown && (
        <div className={`dropdown-list-container ${this.props.top ? 'dropdown-top' : ''}`}>
          <ul className="dropdown-list">
            {this.props.options.map((item, index) => (
              <li
                className="dropdown-list-item"
                key={item.id || item.value || JSON.stringify(item)}
              >
                <Button
                  onClick={() => this.handleClick(item)}
                  style={{ display: 'block' }}
                  data={{ item }}
                >
                  {this.props.renderItem(item, index)}
                </Button>
              </li>
            ))}
          </ul>
        </div>
        )}
      </div>
    </ClickOutside>
  )
}

DropDown.propTypes = {
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  top: PropTypes.bool,
};

DropDown.defaultProps = {
  onChange: () => {},
  top: false,
  disabled: false,
};

export default DropDown;
