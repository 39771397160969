export const ORGANIZATION = 'ORGANIZATION';
export const GLOBAL = 'GLOBAL';
export const PRIVATE = 'PRIVATE';

export function getPlans() {
  return {
    type: 'PLAN_GET_PLANS',
    payload: {
      request: {
        url: '/plan/',
        method: 'GET',
      },
    },
  };
}

export function getPlanDetails(planId) {
  return {
    type: 'PLAN_GET_PLAN_DETAILS',
    payload: {
      request: {
        url: `/plan/${planId}/`,
        method: 'GET',
      },
    },
    data: {
      planId,
    },
  };
}

export const setChosenDistrict = (chosenDistrict) => ({
  type: 'PLAN_SET_CHOSEN_DISTRICT',
  data: {
    chosenDistrict,
  },
});

/**
 * Sets the chosen district to unassigned
 */
// export const selectUnassigned = (planId) => ({
//   type: 'PLAN_SELECT_UNASSIGNED',
//   payload: {
//     request: {
//       url: `/plan/${planId}/unassigned/`,
//       method: 'GET',
//     },
//   },
// });

/**
 * Creates a new district for the map
 * @param {uuid} planId
 * @param {object} district
 * @param {string} color
 */
export const addDistrict = (planId, district, color) => ({
  type: 'PLAN_ADD_DISTRICT',
  payload: {
    request: {
      url: `/plan/${planId}/district/`,
      method: 'POST',
      data: {
        district,
        color,
      },
    },
  },
});

/**
 * Creates a plan
 * @param {string} title
 * @param {string} description
 * @param {string} state
 * @param {number} districtCount
 * @param {string} districtType
 */
export const createPlan = (
  title,
  description,
  state,
  districtCount,
  districtType,
  folderId,
) => ({
  type: 'PLAN_CREATE_PLAN',
  payload: {
    request: {
      url: '/plan/',
      method: 'POST',
      data: {
        title,
        description,
        state,
        district_count: districtCount,
        district_type: districtType,
        districts: [],
        folder: folderId,
      },
    },
  },
});

/**
 * Updates a plan with `planId`
 * @param {uuid} planId
 * @param {string} title
 * @param {string} description
 * @param {string} districtType
 * @param {number} districtCount
 * @param {object} defaultProjection
 */
export const updatePlan = (
  planId,
  title,
  description,
  districtType,
  districtCount,
  defaultProjection,
) => ({
  type: 'PLAN_UPDATE_PLAN',
  payload: {
    request: {
      url: `/plan/${planId}/`,
      method: 'PUT',
      data: {
        title,
        description,
        district_type: districtType,
        district_count: districtCount,
        default_projection_id: defaultProjection,
      },
    },
  },
});

/**
 * Updates a plan with `planId`
 * @param {uuid} planId
 * @param {number} folderId
 */
export const updatePlanFolder = (
  planId,
  folderId,
) => ({
  type: 'PLAN_UPDATE_FOLDER_PLAN',
  payload: {
    request: {
      url: `/plan/${planId}/`,
      method: 'PUT',
      data: {
        folder: folderId,
      },
    },
  },
});

export const updatePlanSettings = (
  planId,
  settings,
) => ({
  type: 'PLAN_UPDATE_SETTINGS_PLAN',
  payload: {
    request: {
      url: `/plan/${planId}/`,
      method: 'PUT',
      data: {
        settings,
      },
    },
  },
});

export const updatePlanColumns = (
  planId,
  columns,
) => ({
  type: 'PLAN_UPDATE_COLUMNS_PLAN',
  payload: {
    request: {
      url: `/plan/${planId}/columns/`,
      method: 'PUT',
      data: {
        columns,
      },
    },
  },
});

// export const clearPlanSandboxes = (planId) => ({
//  type: 'PLAN_CLEAR_PLAN_SANDBOXES',
//  payload: {
//    request: {
//      url: `/plan/${planId}/clear/`,
//      method: 'POST',
//      data: {
//        id: planId,
//      },
//    },
//  },
// });

/**
 * Updates the base layer of a plan
 * @param {uuid} planId
 * @param {Number} baseLayerId
 */
export const updatePlanBaseLayer = (planId, baseLayerId) => ({
  type: 'PLAN_UPDATE_PLAN_BASELAYER',
  payload: {
    request: {
      url: `/plan/${planId}/`,
      method: 'PUT',
      data: {
        base_layer_id: baseLayerId,
      },
    },
  },
});

/**
 * Updates the viewport of a plan to save
 * @param {uuid} planId
 * @param {Array} viewport
 */
export const updatePlanViewport = (
  planId,
  viewport,
) => ({
  type: 'PLAN_UPDATE_VIEWPORT_PLAN',
  payload: {
    request: {
      url: `/plan/${planId}/`,
      method: 'PUT',
      data: {
        settings: { viewport },
      },
    },
  },
});

/**
 * calls DELETE method of `/plan/` to soft-delete a plan
 * @param {uuid} planId
 */
export const deletePlan = (planId) => ({
  type: 'PLAN_DELETE_PLAN',
  planId,
  payload: {
    request: {
      url: `/plan/${planId}/`,
      method: 'DELETE',
    },
  },
});

/**
 * exports plan to an ESRI Shapefile format
 * @param {number} planId
 */
export const exportPlan = (planId) => ({
  type: 'PLAN_EXPORT_PLAN',
  planId,
  payload: {
    request: {
      url: `/plan/${planId}/export/shp/`,
      method: 'POST',
    },
  },
});

/**
 * Creates a new plan from existing plan
 * and allows title and folder of the new plan
 * to be changed during the clone
 * @param {number} parentPlanId
 * @param {string} title
 * @param {number} folder
 */
export const copyPlan = (parentPlanId, title, folder) => ({
  type: 'PLAN_COPY_PLAN',
  payload: {
    request: {
      url: `/plan/${parentPlanId}/copy/`,
      method: 'POST',
      data: {
        title,
        folder,
      },
    },
  },
});

/**
 * Publishes a plan to either the global, curated plan set
 * or to a specific organization plan set
 * @param {number} planId
 * @param {string} target
 */
export const publishPlan = (planId, target) => {
  const scope = target === ORGANIZATION ? 'organization' : 'global';
  const url = `/plan/${planId}/${scope}/`;
  const type = target === ORGANIZATION ? 'PLAN_PUBLISH_ORGANIZATION_PLAN' : 'PLAN_PUBLISH_GLOBAL_PLAN';
  return ({
    type,
    payload: {
      request: {
        url,
        method: 'PUT',
      },
    },
  });
};
