import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const ResultsTable = (props) => (

  <Grid item xs={12} md={12} className="test-results-list">
    <h3>Results</h3>
    <Table size="small" aria-label="acs-table">
      <TableHead>
        <TableRow>
          <TableCell>District Name</TableCell>
          {props.metrics
            && props.metrics.map((metric, i) => (
              <TableCell align="left" key={`metric_districtname_${i}`}>{metric.name}</TableCell>
            )) }

        </TableRow>
      </TableHead>
      <TableBody>

        {
            props.districts
            && props.districts.map((district, i) => (
              <TableRow key={`metricresults_${i}`}>
                <TableCell component="th" scope="row">
                  {district.alias}
                </TableCell>
                {props.metrics
                  && props.metrics.map((metric, j) => (
                    <TableCell key={`metricresults_${i}_${j}`} align="left" className="numeric">{_.get(props.results, `[${metric.id}][${district.id}]`, 'N/A')}</TableCell>
                  )) }
              </TableRow>
            ))
          }

      </TableBody>
    </Table>
  </Grid>
);

ResultsTable.propTypes = {
  metrics: PropTypes.array.isRequired,
  districts: PropTypes.array.isRequired,
  results: PropTypes.object.isRequired,
};

export default ResultsTable;
