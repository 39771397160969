import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetricsQueryBuilderView from '../../components/metrics/QueryBuilderView';
import { getMeasurements, createMetric } from '../../actions/metrics';

class MetricsQueryBuilderContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        name: null,
        description: null,
        markup: '',
      },
    };
  }

  componentDidMount = () => {
    this.props.getMeasurements();
  }

  handleClick = (e) => {
    const { target } = e;
    const range = document.createRange();
    range.selectNode(target);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  }

  handleChange = (name) => (e) => {
    const { value } = e.target;
    this.setState((prevProps) => ({
      form: {
        ...prevProps.form,
        [name]: value,
      },
    }));
  }

  handleSubmit = () => {
    const { name, description, markup } = this.state.form;
    /* TODO
        - After creating metric, go to "Show Metric" page [BAL-302]
    */
    this.props.createMetric(name, description, markup)
      .then(() => {
        this.props.history.push(
          `/editor/${this.props.planId}/metrics/create`,
        );
      });
  }

  render = () => (
    <MetricsQueryBuilderView
      {...this.props}
      {...this.state}
      handleChange={this.handleChange}
      handleSubmit={this.handleSubmit}
    />
  );
}

MetricsQueryBuilderContainer.propTypes = {
  createMetric: PropTypes.func.isRequired,
  getMetricTests: PropTypes.func,
  getMeasurements: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  measurements: PropTypes.array,
  planId: PropTypes.string.isRequired,
};

MetricsQueryBuilderContainer.defaultProps = {
  measurements: [],
  getMetricTests: () => {},
};

const mapStateToProps = (state) => ({
  measurements: _.get(state, 'metrics.measurements'),
  queryBuilderError: _.get(state, 'metrics.queryBuilderError'),
});
export default connect(mapStateToProps, {
  getMeasurements,
  createMetric,
})(MetricsQueryBuilderContainer);
