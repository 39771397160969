import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import ImportedDistrictView from '../components/importedDistrict/ImportedDistrictView';
import {
  submitUploadedDistricts,
  handleUploadImportedDistrict,
} from '../actions/importedDistrict';

import {
  getPlanDetails,
} from '../actions/plan';

class ImportedDistrictContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPlan: null,
      stateFIP: props.match.params.state_fip,
      planId: props.match.params.plan_id,
      isValid: false,
      uploadForm: {
        name: null,
        description: null,
        aliasProperty: null,
      },
    };
  }

  componentDidMount() {}

  setValue = (key, value) => {
    this.setState((prevState) => ({ uploadForm: { ...prevState.uploadForm, [key]: value } }));
  }

  checkValid = () => {
    const keys = Object.keys(this.state.uploadForm);
    const { uploadForm } = this.state;
    for (let i = 0; i < keys.length; i += 1) {
      if (!uploadForm[keys[i]]) {
        return false;
      }
    }
    return true;
  }

  handleSubmit = () => {
    // const planId = _.get(this.props.match, 'params.plan_id');
    const name = _.get(this.state, 'uploadForm.name');
    const description = _.get(this.state, 'uploadForm.description');
    const aliasProperty = _.get(this.state, 'uploadForm.aliasProperty');
    const importDetailsID = _.get(this.props.importDetails, 'id');
    this.props.submitUploadedDistricts(
      this.state.planId,
      importDetailsID,
      name,
      description,
      aliasProperty,
    );
    //  .then(() => this.props.getPlanDetails(planId))
    //  .then(() => {
    //    this.props.history.push(`/editor/${this.state.planId}`);
    //  });
  }

  render = () => (
    <ImportedDistrictView
      {...this.props}
      action="upload"
      handleSelectPlan={this.handleSelectPlan}
      handleSubmit={this.handleSubmit}
      isValid={this.checkValid()}
      planId={_.get(this.props, 'match.params.plan_id')}
      selectedPlan={this.state.selectedPlan}
      setValue={this.setValue}
      uploadForm={this.state.uploadForm}
      isComplete={_.get(this.props.importDetails, 'is_complete', true)}
    />
  )
}

const mapStateToProps = (state) => ({
  importDetails: _.get(state, 'importedDistrict.importDetails'),
  importedDistricts: _.get(state, 'importedDistrict.list'),
  isLoading: _.get(state, 'importedDistrict.isLoading'),
  isSubmitting: _.get(state, 'importedDistrict.isSubmitting'),
  uploadStep: _.get(state, 'importedDistrict.uploadStep'),
  importedDistrictPolls: _.get(state, 'notification.importedDistrictPolls'),
});

ImportedDistrictContainer.defaultProps = {
  importDetails: {},
};

ImportedDistrictContainer.propTypes = {
  getPlanDetails: PropTypes.func.isRequired,
  handleUploadImportedDistrict: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  submitUploadedDistricts: PropTypes.func.isRequired,
  importDetails: PropTypes.object,
  importedDistricts: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  uploadStep: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {
  getPlanDetails,
  handleUploadImportedDistrict,
  submitUploadedDistricts,
})(ImportedDistrictContainer);
