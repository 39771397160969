import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getPdiAuthState } from '../helper/auth';
import { getPdiToken, loadAuth } from '../actions/auth';

class TransparentLoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pdiTokenSuccessFlag: false,
    };
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    const appKey = _.get(values, 'appKey', null);
    const requestToken = _.get(values, 'request_token', null);

    if (appKey !== null && requestToken !== null) {
      this.props.getPdiToken(appKey, requestToken).then(() => {
        const auth = getPdiAuthState();
        // console.log('*** TransparentLoginPage auth', auth);
        this.props.loadAuth(auth);
        this.setState({
          pdiTokenSuccessFlag: true,
        });
      });
    }
  }

  getLoginPage = () => {
    const { pdiTokenSuccessFlag } = this.state;
    const isAuthenticated = _.get(this.props.auth, 'id', null) !== null;
    // console.log('*** isAuthenticated', isAuthenticated);
    if (isAuthenticated && pdiTokenSuccessFlag) {
      return <Redirect to="/" />;
    }
    return (
      <div>
        Redirecting to Redistricting Software...
      </div>
    );
  }

  render = () => {
    const loginPage = this.getLoginPage();
    return loginPage;
  }
}

TransparentLoginPage.propTypes = {
  location: PropTypes.object.isRequired,
  getPdiToken: PropTypes.func.isRequired,
  loadAuth: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPdiToken,
  loadAuth,
})(TransparentLoginPage);
