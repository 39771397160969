import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { handleUploadGuideLayer, submitDetails } from '../actions/guideLayer';

import GuideLayerView from '../components/guideLayer/GuideLayerView';

class GuideLayerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      strField: '',
      intField: '',
    };
  }

  handleSubmit = () => {
    const {
      name,
      description,
      strField,
      intField,
    } = this.state;
    const guideLayerId = _.get(this.props, 'uploadedGuideLayer.id');
    const planId = _.get(this.props, 'match.params.plan_id');
    this.props.submitDetails(
      planId,
      guideLayerId,
      name,
      description,
      strField,
      intField,
    ).then(() => {
      this.props.history.push(`/editor/${planId}`);
    });
  }

  isValid = () => {
    const {
      name, description, strField,
    } = this.state;
    return _.every([name, description, strField]);
  }

  setValue = (key, val) => {
    this.setState({ [key]: val });
  }

  render = () => (
    <GuideLayerView
      {...this.props}
      {...this.state}
      handleSubmit={this.handleSubmit}
      isValid={this.isValid()}
      setValue={this.setValue}
      planId={_.get(this.props, 'match.params.plan_id')}
    />
  );
}

GuideLayerContainer.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  uploadedGuideLayer: PropTypes.object,
  submitDetails: PropTypes.func.isRequired,
};

GuideLayerContainer.defaultProps = {
  uploadedGuideLayer: null,
};

const mapStateToProps = (state) => ({
  uploadedGuideLayer: _.get(state, 'guideLayer.uploadedGuideLayer'),
  step: _.get(state, 'guideLayer.step'),
  isSubmitting: _.get(state, 'guideLayer.isSubmitting'),
});

export default connect(mapStateToProps, {
  handleUploadGuideLayer,
  submitDetails,
})(GuideLayerContainer);
