import _ from 'lodash';

const defaultState = {
  globalPlans: [],
  loadingPlan: false,
};

const GlobalPlanReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'BALLISTA_LOGOUT': {
      return defaultState;
    }
    case 'PLAN_DELETE_PLAN_SUCCESS': {
      const planId = _.get(action, 'meta.previousAction.planId');
      const plans = _.get(state, 'globalPlans');
      _.remove(plans, { id: planId });

      return {
        ...state,
      };
    }
    case 'PLAN_PUBLISH_GLOBAL_PLAN_SUCCESS': {
      const newPlan = _.get(action, 'payload.data');
      const plans = [...state.globalPlans];
      const existingPlanIndex = _.findIndex(plans, { id: _.get(newPlan, 'id') });
      if (existingPlanIndex < 0) {
        plans.push(newPlan);
      }
      return {
        ...state,
        globalPlans: plans,
        loadingPlan: false,
      };
    }
    case 'PLAN_GET_PLANS_SUCCESS': {
      const returnVal = { ...state };
      const globalPlans = _.get(action, 'payload.data.global_plans', []);
      const newGlobalPlans = [...globalPlans];
      returnVal.globalPlans = newGlobalPlans;
      return returnVal;
    }
    default: {
      return state;
    }
  }
};

export default GlobalPlanReducer;
