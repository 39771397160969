import React from 'react';
import Event from './Event';

export const Click = (props) => <Event event="singleclick" {...props} />;
export const MoveEnd = (props) => <Event event="moveend" {...props} />;
export const Paint = (props) => <Event event="pointerdrag" {...props} />;
export const Erase = (props) => <Event event="pointerdrag" {...props} />;
export const Hover = (props) => <Event event="pointermove" {...props} />;

export default Event;
