import React from 'react';
// import _ from 'lodash';
import PropTypes from 'prop-types';

const ShadedLayerItem = (props) => (
  <div>
    { props.name }
  </div>
);

ShadedLayerItem.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ShadedLayerItem;
