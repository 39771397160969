import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Moment from 'react-moment';
import Button from '../common/Button';

const TestSuiteDetails = ({ testSuite, testResult, ...props }) => (
  <Table>
    <TableBody>
      <TableRow>
        <TableCell>
          <div className="test-list-info">
            <h4>Metrics</h4>
            <div className="metric-list">
              <ul className="metric-list">
                {
                  _.get(testSuite, 'metrics', []).map((metric, i) => (
                    <li className="metric-test" key={`metric_${i}`}>{_.get(metric, 'name')}</li>
                  ))
                }
              </ul>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <h4>Projection</h4>
          {`${_.get(testSuite, 'projection.auth_name')}:${_.get(testSuite, 'projection.srid')}`}
        </TableCell>
        <TableCell>
          <h4>Date Created</h4>
          <Moment date={_.get(testSuite, 'created_at')} format="D MMM YYYY  //  h:mm a" />
        </TableCell>
        <TableCell>
          {
            _.get(testResult, 'is_complete', false)
              ? (
                <>
                  <Button primary onClick={props.handleDownloadReport}>
                    View PDF
                  </Button>
                  <Button primary onClick={props.handleGenerateTest}>
                    Rerun Test
                  </Button>
                </>
              )
              : (<></>)
          }

        </TableCell>
      </TableRow>
    </TableBody>
  </Table>
);

TestSuiteDetails.propTypes = {
  testSuite: PropTypes.object.isRequired,
  testResult: PropTypes.object,
  handleDownloadReport: PropTypes.func.isRequired,
  handleGenerateTest: PropTypes.func.isRequired,
};

TestSuiteDetails.defaultProps = {
  testResult: null,
};

export default TestSuiteDetails;
