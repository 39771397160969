import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

const ReportFileView = (props) => (
  <div className="report-file-view">
    <iframe
      title={_.get(props.chosenReport, 'title', 'title')}
      src={_.get(props.chosenReport, 'filename', '')}
    />
  </div>
);

ReportFileView.propTypes = {
  chosenReport: PropTypes.object.isRequired,
};
export default ReportFileView;
