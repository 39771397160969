import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import axiosMiddleware from 'redux-axios-middleware';
import reducers from './reducers';
import { client } from './requestClient.js';


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const error = ({ getSourceAction }, error) =>
  Promise.reject(error);
const success = ({ getSourceAction }, res) =>
  Promise.resolve(res);
export default createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware,
      axiosMiddleware(client, {
        interceptors: { response: [{ error, success }] },
        returnRejectedPromiseOnError: true,
      }),
    ),
  ),
);
