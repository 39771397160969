/** *
 *  -------------------
 *  PlansContainer.js
 *  -------------------
 *
 *  Plans container is a page that will manage plan creation /
 *  organization and will launch into the EditorContainer
 * */

import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ConfirmationModal } from '../components/common/Modal';
import PlansView from '../components/plans/PlansView';
import ErrorModal from '../components/common/ErrorModal';
import {
  createPlan, getPlans, deletePlan, updatePlan, updatePlanFolder,
  copyPlan, publishPlan,
} from '../actions/plan';
import { createFolder, updateFolder, deleteFolder } from '../actions/folder';
import selectFolderStructure from './selectors/selectFolderStructure';
import { logout, getPdiGeo } from '../actions/auth';
import { clearError } from '../actions/error';

class PlansContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmingDeletion: false,
      idToDelete: null,
      deletionType: null,
      displayedPlanIndex: 0,
    };
  }

  componentDidMount = () => {
    const { auth } = this.props;
    if ((_.get(auth, 'id', null) !== null)) {
      this.props.getPlans();
      if (_.get(auth, 'isPdiUser', false)) {
        this.props.getPdiGeo();
        // this.props.getPdiGeo().then((data) => {
        // });
      }
    }
  }

  componentDidUpdate = () => {
    if (_.get(this.props, 'error.errorMessage') === 'Invalid token.'
    || _.get(this.props, 'error.errorMessage') === 'User inactive or deleted.') {
      this.props.logout();
    }
  }

  handleCreatePlan = ({
    title,
    description,
    planState,
    districtCount,
    districtType,
    folderId,
  }) => {
    this.props.createPlan(
      title,
      description,
      planState,
      districtCount,
      districtType,
      folderId,
    );
  };

  handleUpdatePlan = ({
    id, title, description, districtType, districtCount,
  }) => {
    this.props.updatePlan(
      id,
      title,
      description,
      districtType,
      districtCount,
    );
  }

  handleDeletePlan = (planId) => {
    this.setState({
      idToDelete: planId,
      isConfirmingDeletion: true,
      deletionType: 'plan',
    });
  }

  handleClonePlan = (parentPlanId, title, folderId) => {
    this.props.copyPlan(parentPlanId, title, folderId);
  }

  handlePublishPlan = (planId, shareTarget) => {
    this.props.publishPlan(planId, shareTarget);
  }

  handleCreateFolder = ({ name }) => {
    this.props.createFolder(name);
  }

  handleUpdateFolder = ({ id, name }) => {
    this.props.updateFolder(id, name);
  }

  handleUpdatePlanFolder = ({ planId, folderId }) => {
    this.props.updatePlanFolder(planId, folderId);
  }

  handleDeleteFolder = (folderId) => {
    this.setState({
      idToDelete: folderId,
      isConfirmingDeletion: true,
      deletionType: 'folder',
    });
  }

  handleConfirmPlanDelete = () => {
    this.props.deletePlan(this.state.idToDelete);
    this.setState({
      isConfirmingDeletion: false,
      deletionType: null,
      idToDelete: null,
    });
  }

  handleConfirmFolderDelete = () => {
    this.props.deleteFolder(this.state.idToDelete);
    this.setState({
      isConfirmingDeletion: false,
      deletionType: null,
      idToDelete: null,
    });
  }

  handleConfirmDelete = () => {
    if (this.state.deletionType === 'plan') {
      this.handleConfirmPlanDelete();
    } else if (this.state.deletionType === 'folder') {
      this.handleConfirmFolderDelete();
    }
  }

  handleCancelPlanDelete = () => {
    this.setState({
      isConfirmingDeletion: false,
      deletionType: null,
      idToDelete: null,
    });
  }

  handleCancelFolderDelete = () => {
    this.setState({
      isConfirmingDeletion: false,
      deletionType: null,
      idToDelete: null,
    });
  }

  handleCancelDelete = () => {
    if (this.state.deletionType === 'plan') {
      this.handleCancelPlanDelete();
    } else if (this.state.deletionType === 'folder') {
      this.handleCancelFolderDelete();
    }
  }

  getConfirmationModalTitle = () => {
    let title = '';
    if (this.state.deletionType === 'plan') {
      title = 'Confirm plan deletion';
    } else if (this.state.deletionType === 'folder') {
      title = 'Confirm folder deletion';
    }
    return title;
  }

  getConfirmationModalContent = () => {
    let content = '';
    if (this.state.deletionType === 'plan') {
      content = 'Are you sure you want to delete this plan?';
    } else if (this.state.deletionType === 'folder') {
      content = 'Are you sure you want to delete this folder?';
    }
    return content;
  }

  handleLogout = () => {
    this.props.logout();
  }

  changePlanTableContents = (activeIndex) => {
    this.setState({ displayedPlanIndex: activeIndex });
  }

  render = () => {
    if (!(_.get(this.props.auth, 'id', null) !== null)) {
      if (process.env.REACT_APP_HIDE_LOGIN_REGISTRATION === 'true') {
        return <Redirect to="/logout" />;
      }
      return <Redirect to="/login" />;
    }
    let displayedPlans = [];
    let displayedFolders = [];
    if (this.state.displayedPlanIndex === 0) {
      displayedPlans = this.props.plans;
      displayedFolders = this.props.folders;
    } else if (this.state.displayedPlanIndex === 1) {
      displayedPlans = this.props.organizationPlans;
    } else if (this.state.displayedPlanIndex === 2) {
      displayedPlans = this.props.globalPlans;
    }

    return (
      <>
        { this.props.error.errorMessage
          ? (
            <ErrorModal
              message={this.props.error.errorMessage}
              clearMessage={this.props.clearError}
            />
          )
          : null }
        <PlansView
          folders={displayedFolders}
          auth={this.props.auth}
          plans={displayedPlans}
          handleCreatePlan={this.handleCreatePlan}
          handleDeletePlan={this.handleDeletePlan}
          handleDeleteFolder={this.handleDeleteFolder}
          handleUpdatePlan={this.handleUpdatePlan}
          handleCreateFolder={this.handleCreateFolder}
          handleUpdateFolder={this.handleUpdateFolder}
          handleMovePlanToFolder={this.handleUpdatePlanFolder}
          handleLogout={this.handleLogout}
          handleClonePlan={this.handleClonePlan}
          handlePublishPlan={this.handlePublishPlan}
          displayedPlanIndex={this.state.displayedPlanIndex}
          changePlanTableContents={this.changePlanTableContents}
        />
        <ConfirmationModal
          open={this.state.isConfirmingDeletion}
          onConfirm={this.handleConfirmDelete}
          onCancel={this.handleCancelDelete}
          title={this.getConfirmationModalTitle()}
        >
          <div>
            {this.getConfirmationModalContent()}
          </div>
        </ConfirmationModal>
      </>
    );
  }
}

PlansContainer.propTypes = {
  folders: PropTypes.array,
  plans: PropTypes.array,
  organizationPlans: PropTypes.array,
  globalPlans: PropTypes.array,
  createPlan: PropTypes.func.isRequired,
  getPlans: PropTypes.func.isRequired,
  getPdiGeo: PropTypes.func.isRequired,
  deletePlan: PropTypes.func.isRequired,
  updatePlan: PropTypes.func.isRequired,
  createFolder: PropTypes.func.isRequired,
  updateFolder: PropTypes.func.isRequired,
  deleteFolder: PropTypes.func.isRequired,
  publishPlan: PropTypes.func.isRequired,
  updatePlanFolder: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  copyPlan: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
};

PlansContainer.defaultProps = {
  folders: [],
  plans: [],
  organizationPlans: [],
  globalPlans: [],
};

const mapStateToProps = (state) => {
  const {
    folders, orphanedPlans, orphanedOrgPlans, orphanedPdiPlans,
  } = selectFolderStructure(state);
  return ({
    folders,
    plans: orphanedPlans,
    organizationPlans: orphanedOrgPlans,
    globalPlans: orphanedPdiPlans,
    auth: state.auth,
    error: state.error,
  });
};

export default connect(mapStateToProps, {
  getPlans,
  getPdiGeo,
  createPlan,
  deletePlan,
  updatePlan,
  createFolder,
  updateFolder,
  deleteFolder,
  publishPlan,
  updatePlanFolder,
  logout,
  copyPlan,
  clearError,
})(PlansContainer);
