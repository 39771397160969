import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import Button from '../../common/Button';
import './GuideLayers.css';
import DropDown from '../../common/DropDown';
import GuideLayerItem from './GuideLayerItem';

const EditorGuideLayers = (props) => {
  const options = [...props.defaultGuideLayers];
  if (props.activeDefaultGuideLayer) {
    options.unshift({ name: '- None -', short_name: null });
  }
  return (
    <section className="editor-guide-layers editor-subsection">
      <h3>
        <div className="editor-label">
          <span>Guide Layers</span>
        </div>
        {
          props.isInteractive
          && <Link to={`/editor/${_.get(props, 'chosenPlan.id')}/guide-layer/create`}>Add</Link>
        }
      </h3>
      <div className="editor-sub-list active">
        <DropDown
          top
          className="gl-dropdown"
          label={props.activeDefaultGuideLayer
            ? <GuideLayerItem {...props.activeDefaultGuideLayer} />
            : 'Pick a Guide Layer'}
          options={options}
          onClose={() => {}}
          onChange={(defaultGuideLayer) => {
            props.updatePlanSettings(_.get(props.chosenPlan, 'id'), {
              ..._.get(props.chosenPlan, 'settings', {}),
              defaultGuideLayer: defaultGuideLayer.short_name,
            });
          }}
          renderItem={(item) => (<GuideLayerItem {...item} />)}
        />
      </div>
      <ul className="egl-list editor-sub-list active">
        {
            _.get(props, 'publishedGuideLayers', []).map((item) => (
              <li key={JSON.stringify(item)}>
                <span>{item.guide_layer.name}</span>
                <Button onClick={() => {
                  props.handleUpdateSettings(item.guide_layer, { visible: !item.settings.visible });
                }}
                >
                  {item.settings.visible ? 'Hide' : 'Show'}
                </Button>
              </li>
            ))
          }
      </ul>
    </section>
  );
};

EditorGuideLayers.propTypes = {
  handleUpdateSettings: PropTypes.func.isRequired,
  updatePlanSettings: PropTypes.func.isRequired,
  defaultGuideLayers: PropTypes.array.isRequired,
  activeDefaultGuideLayer: PropTypes.object,
  isInteractive: PropTypes.bool,
  chosenPlan: PropTypes.any,
};

EditorGuideLayers.defaultProps = {
  isInteractive: true,
  activeDefaultGuideLayer: null,
  chosenPlan: null,
};

export default EditorGuideLayers;
/**
 * <div className='editor-show-section'>
          Show
          <AntSwitch
            checked={props.isGuideLayerActive}
            onChange={()=>props.handleToggleGuideLayer()}
            value="isShadedLayerShown"
          />
        </div>
 */
