import React from 'react';
import PropTypes from 'prop-types';
import MetricsTestCreateContainer from '../containers/metrics/CreateTestContainer';
import MetricsTestListContainer from '../containers/metrics/TestListContainer';
import MetricsResultsContainer from '../containers/metrics/ResultsContainer';
import MetricsQueryBuilderContainer from '../containers/metrics/QueryBuilderContainer';
import MetricsMetricListContainer from '../containers/metrics/MetricListContainer';
import MetricsMetricItemContainer from '../containers/metrics/MetricItemContainer';
import MetricsTestSuiteContainer from '../containers/metrics/TestSuiteContainer';

const ImportedDistrictPage = (props) => (
  <>
    { props.tab === 'create' && <MetricsTestCreateContainer {...props} />}
    { props.tab === 'list' && <MetricsTestListContainer {...props} />}
    { props.tab === 'results' && <MetricsResultsContainer {...props} />}
    { props.tab === 'builder' && <MetricsQueryBuilderContainer {...props} />}
    { props.tab === 'metric-list' && <MetricsMetricListContainer {...props} />}
    { props.tab === 'metric-edit' && <MetricsMetricItemContainer {...props} />}
    { props.tab === 'suite' && <MetricsTestSuiteContainer {...props} /> }
  </>
);

ImportedDistrictPage.propTypes = {
  tab: PropTypes.string.isRequired,
};

export default ImportedDistrictPage;
