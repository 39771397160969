import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import RegistrationView from '../components/login/RegistrationView';
import ErrorModal from '../components/common/ErrorModal';
import { register } from '../actions/auth';
import { clearError } from '../actions/error';

class RegistrationContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        username: null,
        password: null,
        retype_password: null,
      },
    };
  }

  handleChange = (target) => (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({ form: { ...prevState.form, [target]: value } }));
  }

  handleRegister = () => {
    this.props.register(
      this.state.form.username,
      this.state.form.password,
      this.state.form.retype_password,
    );
  }

  render = () => {
    if (_.get(this.props, 'auth.id', null) && !_.get(this.props, 'auth.isLoading')) {
      return <Redirect to="/" />;
    }
    if (process.env.REACT_APP_HIDE_LOGIN_REGISTRATION === 'true' || process.env.REACT_APP_HIDE_REGISTRATION) {
      return <Redirect to="/logout" />;
    }
    return (
      <>
        { this.props.error.errorMessage
          ? (
            <ErrorModal
              message={this.props.error.errorMessage}
              clearMessage={this.props.clearError}
            />
          )
          : null }
        <RegistrationView
          {...this.props}
          {...this.state.form}
          handleChange={this.handleChange}
          handleRegister={this.handleRegister}
        />
      </>
    );
  }
}

RegistrationContainer.propTypes = {
  register: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  error: PropTypes.object.isRequired,
  clearError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  error: state.error,
});

export default connect(mapStateToProps, {
  register,
  clearError,
})(RegistrationContainer);
