import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import EditorMap from '../components/editor/map/EditorMap';
import {
  submitSelection,
  submitIDs,
  focusDistrict,
  // updateUnassignedDistrict,
  // retrieveSandboxDistrictDetail,
  searchMap,
  clearSearch,
  setFocusGeom,
  clearIncompleteAreaExtent,
  setDistrictDetails,
  clearDistrictDetails,
  clearUndoLastActionFlag,
  clearCalculateDistrictDetailsSuccessToken,
  clearChangedDistrictId,
  calculateDistrictDetails,
} from '../actions/editor';
import {
  setChosenDistrict,
  updatePlanSettings,
  updatePlanViewport,
} from '../actions/plan';

class EditorMapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: {
        type: 'Address',
        query: '',
      },
      searchTimeout: null,
    };
  }

  handleDistrictSelect = (district) => {
    // const planId = _.get(this.props.match, 'params.plan_id');
    this.props.setChosenDistrict(district);
    // this.props.retrieveSandboxDistrictDetail(planId, district.id);
  }

  handleSearchChange = (val) => (newValue) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [val]: newValue,
      },
    }));
  }

  handleSuggestionFetchRequested = ({ value }) => {
    clearTimeout(this.state.searchTimeout);
    this.setState({
      searchTimeout: setTimeout(() => {
        const { type } = this.state.search;
        if (value.length > 3) {
          this.props.searchMap(type, value);
        } else {
          this.props.clearSearch();
        }
      }, 500),
    });
  }

  render = () => (
    _.get(this.props.chosenPlan, 'id')
      ? (
        <EditorMap
          {...this.props}
          handleDistrictSelect={this.handleDistrictSelect}
          planId={_.get(this.props.chosenPlan, 'id')}
          activeShadedLayer={
            _.filter(
              this.props.shadedLayers, { is_active: true }, [],
            )[0]
          }
          searchProps={this.state.search}
          searchSuggestions={this.props.searchResults}
          handleSearchChange={this.handleSearchChange}
          handleChangeSearchType={this.handleChangeSearchType}
          handleSuggestionFetchRequested={this.handleSuggestionFetchRequested}
          isInteractive={!!this.props.isInteractive}
        />
      )
      : <></>
  )
}

const mapStateToProps = (state) => ({
  chosenTool: _.get(state, 'editor.chosenTool'),
  chosenBrush: _.get(state, 'editor.chosenBrush'),
  chosenPlan: _.get(state, 'plan.chosenPlan'),
  chosenDistrict: _.get(state, 'plan.chosenDistrict'),
  districts: _.get(state, 'editor.districts'),
  committedChanges: _.get(state, 'editor.committedChanges'),
  districtDetails: _.get(state, 'editor.districtDetails'),
  districtDetailsSuccessFlag: _.get(state, 'editor.districtDetailsSuccessFlag'),
  calculateDistrictDetailsSuccessToken: _.get(state, 'editor.calculateDistrictDetailsSuccessToken'),
  undoLastActionFlag: _.get(state, 'editor.undoLastActionFlag'),
  changedDistrictId: _.get(state, 'editor.changedDistrictId'),
  focusedGeometry: _.get(state, 'editor.focusedGeometry'),
  incompleteArea: _.get(state, 'editor.incompleteArea'),
  publishedGuideLayers: _.get(state, 'guideLayer.publishedGuideLayers'),
  userUploads: _.get(state, 'editor.userUploads'),
  unassignedDetails: _.get(state, 'editor.unassignedDetails'),
  activeDistrict: _.get(state, 'editor.activeDistrict'),
  focusedDistrict: _.get(state, 'editor.focusedDistrict'),
  searchResults: _.get(state, 'editor.searchResults'),
  shadedLayers: _.get(state, 'shadedLayer.shadedLayers'),
  dataColumns: _.get(state.shadedLayer, 'columns'),
  accessGeography: _.get(state.plan, 'accessGeography'),
});

EditorMapContainer.propTypes = {
  activeDistrict: PropTypes.number,
  chosenBrush: PropTypes.object,
  chosenDistrict: PropTypes.object,
  districts: PropTypes.array,
  chosenPlan: PropTypes.any,
  chosenTool: PropTypes.string.isRequired,
  clearSearch: PropTypes.func.isRequired,
  focusedDistrict: PropTypes.number,
  handleCancelDistrict: PropTypes.func,
  incompleteArea: PropTypes.array,
  isInteractive: PropTypes.bool,
  match: PropTypes.object.isRequired,
  planId: PropTypes.any,
  districtDetails: PropTypes.array,
  districtDetailsSuccessFlag: PropTypes.bool,
  calculateDistrictDetailsSuccessToken: PropTypes.string,
  // retrieveSandboxDistrictDetail: PropTypes.func.isRequired,
  searchMap: PropTypes.func.isRequired,
  searchResults: PropTypes.array.isRequired,
  setChosenDistrict: PropTypes.func.isRequired,
  shadedLayers: PropTypes.array.isRequired,
  submitIDs: PropTypes.func.isRequired,
  submitSelection: PropTypes.func.isRequired,
  // updateUnassignedDistrict: PropTypes.func.isRequired,
  userUploads: PropTypes.array,
  setDistrictDetails: PropTypes.func.isRequired,
  calculateDistrictDetails: PropTypes.func.isRequired,
  clearDistrictDetails: PropTypes.func.isRequired,
  clearChangedDistrictId: PropTypes.func.isRequired,
  clearUndoLastActionFlag: PropTypes.func.isRequired,
  clearCalculateDistrictDetailsSuccessToken: PropTypes.func.isRequired,
  accessGeography: PropTypes.object,
};

EditorMapContainer.defaultProps = {
  activeDistrict: null,
  chosenBrush: {},
  chosenDistrict: {},
  chosenPlan: {},
  districts: [],
  focusedDistrict: null,
  handleCancelDistrict: () => {},
  incompleteArea: null,
  isInteractive: true,
  planId: null,
  userUploads: [],
  calculateDistrictDetailsSuccessToken: null,
  districtDetails: null,
  districtDetailsSuccessFlag: false,
  accessGeography: null,
};

export default connect(mapStateToProps, {
  clearSearch,
  clearIncompleteAreaExtent,
  submitSelection,
  submitIDs,
  focusDistrict,
  setChosenDistrict,
  // retrieveSandboxDistrictDetail,
  // updateUnassignedDistrict,
  searchMap,
  setFocusGeom,
  updatePlanSettings,
  updatePlanViewport,
  setDistrictDetails,
  calculateDistrictDetails,
  clearDistrictDetails,
  clearUndoLastActionFlag,
  clearChangedDistrictId,
  clearCalculateDistrictDetailsSuccessToken,
})(EditorMapContainer);
