import React from 'react';
import PropTypes from 'prop-types';

const ActiveLayer = (props) => (
  <>
    {
    React.Children.map(props.children, (child) => (child === null ? <></>
      : React.cloneElement(child, { map: props.map, view: props.view, parent: 'ACTIVELAYER' })))
  }
  </>
);

ActiveLayer.propTypes = {
  children: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
  view: PropTypes.object.isRequired,
};

export default ActiveLayer;
