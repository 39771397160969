import React from 'react';
import PropTypes from 'prop-types';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';

import DrawTool, { createBox } from 'ol/interaction/Draw';

class Square extends React.Component {
  constructor(props) {
    super(props);

    this.drawingSource = new VectorSource();
    this.drawingLayer = new VectorLayer({
      source: this.drawingSource,
    });
  }

  componentDidMount() {
    this.draw = new DrawTool({
      source: this.drawingSource,
      type: 'Circle',
      geometryFunction: createBox(),
    });
    this.props.map.addLayer(this.drawingLayer);
    this.props.map.addInteraction(this.draw);
    this.draw.on('drawend', this.handleDrawEnd);
  }

  componentWillUnmount() {
    this.draw.un('drawend', this.handleDrawEnd);
    this.props.map.removeInteraction(this.draw);
    this.props.map.removeLayer(this.drawingLayer);
  }

  handleDrawEnd = (e) => {
    const feature = e.feature.clone();
    feature.getGeometry().transform('EPSG:900913', 'EPSG:4326');
    const writer = new GeoJSON();
    const geojsonText = writer.writeFeatures([feature]);
    const geojson = JSON.parse(geojsonText);

    if (!!this.props.onDrawEnd
      && typeof this.props.onDrawEnd === 'function') {
      this.props.onDrawEnd(geojson);
    }

    setTimeout(() => {
      this.drawingSource.clear();
    }, 100);

    e.preventDefault();
  }

  render = () => null;
}

Square.propTypes = {
  onDrawEnd: PropTypes.func.isRequired,
  map: PropTypes.object,
};

Square.defaultProps = {
  map: null,
};

export default Square;
