import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updatePlanColumns } from '../../actions/plan';
import { getDataColumns } from '../../actions/shadedLayer';

import ColumnSettings from '../../components/settings/ColumnSettings';

class ColumnSettingsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        columns: _.get(props.chosenPlan, 'columns', []),
      },
      searchFilter: null,
      searchText: '',
    };
  }

  componentDidMount() {
    if (this.props.columns === null) {
      this.props.getDataColumns(this.props.planId);
    }

    this.initializeColumns();
  }

  componentDidUpdate(prevProps) {
    if (_.get(prevProps.chosenPlan, 'id', null) === null
      && _.get(this.props.chosenPlan, 'id', null) !== null) {
      this.initializeColumns();
    }
  }

  initializeColumns = () => {
    this.setState({
      form: {
        ..._.get(this.props.chosenPlan, 'settings'),
        columns: _.get(this.props.chosenPlan, 'settings.columns', []),
      },
    });
  }

  removeColumn = (columnId) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        columns: _.filter(prevState.form.columns, (id) => columnId !== id),
      },
    }));
  }

  addColumn = (columnId) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        columns: [...prevState.form.columns, columnId],
      },
    }));
  }

  handleChange = (col) => (val) => {
    this.setState({
      [col]: val,
    });
  }

  handleSubmit = () => {
    this.props.updatePlanColumns(this.props.planId, this.state.form.columns);
  }

  revert = () => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        columns: _.get(this.props.chosenPlan, 'settings.columns', []),
      },
    }));
  }

  getColumns = () => {
    let { columns } = this.props;
    if (this.state.searchFilter) {
      if (this.state.searchFilter === 'active') {
        columns = _(columns).keyBy('id').at(this.state.form.columns).value();
      } else if (this.state.searchFilter === 'inactive') {
        columns = _.differenceWith(
          columns,
          this.state.form.columns,
          ({ id }, value) => id === value,
        );
      }
    }

    if (this.state.searchText) {
      columns = _.filter(
        columns,
        (col) => _.includes(
          `${col.name} ${col.description}`.toLowerCase(),
          this.state.searchText.toLowerCase(),
        ),
      );
    }

    return columns;
  }

  render = () => (
    <ColumnSettings
      {...this.props}
      form={this.state.form}
      searchFilter={this.state.searchFilter}
      searchText={this.state.searchText}
      columns={this.getColumns()}
      addColumn={this.addColumn}
      removeColumn={this.removeColumn}
      handleChange={this.handleChange}
      handleSubmit={this.handleSubmit}
      revert={this.revert}
    />
  )
}

const mapStateToProps = (state) => ({
  chosenPlan: _.get(state.plan, 'chosenPlan'),
  columns: _.get(state.shadedLayer, 'columns'),
});

ColumnSettingsContainer.propTypes = {
  chosenPlan: PropTypes.object.isRequired,
  columns: PropTypes.array,
  getDataColumns: PropTypes.func.isRequired,
  planId: PropTypes.any.isRequired,
  updatePlanColumns: PropTypes.func.isRequired,
};

ColumnSettingsContainer.defaultProps = {
  columns: null,
};

export default connect(mapStateToProps, {
  getDataColumns,
  updatePlanColumns,
})(ColumnSettingsContainer);
