import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import PlanRow from './PlanRow';
import FolderModal from './FolderModal';

import './FolderRow.css';

class FolderRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditModal: false,
    };
  }

  handleOpenEditModal = () => {
    this.setState({ openEditModal: true });
  }

  handleCloseEditModal = () => {
    this.setState({ openEditModal: false });
  }

  handleDelete = () => {
    this.props.handleDeleteFolder(_.get(this.props.folder, 'id'));
  }

  handleUpdate = (form) => {
    const updateForm = form;
    updateForm.id = this.props.folder.id;
    this.props.handleUpdateFolder(updateForm);
    this.setState({ openEditModal: false });
  }

  render() {
    return (
      <>
        <tr
          className="folder-container"
          key={`folder_${this.props.folder.id}`}
        >
          <td>
            <div className="folder-container">
              <FontAwesomeIcon
                icon={faFolder}
                size="lg"
                className="folderIcon"
                color="#5EBCF4"
              />
              <span className="folder-name">{this.props.folder.name}</span>
            </div>
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>
            <button
              onClick={this.handleOpenEditModal}
              type="button"
              className="pv-action-icon"
              title="Edit Folder"
            >
              <FontAwesomeIcon icon={faEdit} size="2x" color="lightgray" />
            </button>
            <button
              type="button"
              className="pv-action-icon"
              onClick={this.handleDelete}
              title="Delete Folder"
            >
              <FontAwesomeIcon icon={faTrash} size="2x" color="lightgray" />
            </button>
          </td>
        </tr>

        {
          this.props.folder.plans.map((plan) => (
            <PlanRow
              auth={this.props.auth}
              plan={plan}
              folders={this.props.folders}
              key={plan.id}
              isInFolder
              handleUpdatePlan={this.props.handleUpdatePlan}
              handleDeletePlan={this.props.handleDeletePlan}
              handlePublishPlan={this.props.handlePublishPlan}
              handleMovePlanToFolder={this.props.handleMovePlanToFolder}
              handleClonePlan={this.props.handleClonePlan}
              arePrivatePlans={this.props.arePrivatePlans}
            />
          ))
        }
        { this.state.openEditModal && this.props.folder.id
          && (
            <FolderModal
              folder={this.props.folder}
              open={this.state.openEditModal}
              handleSubmit={(folder) => this.handleUpdate(folder)}
              handleClose={() => this.handleCloseEditModal()}
            />
          )}
      </>
    );
  }
}

FolderRow.propTypes = {
  folder: PropTypes.object.isRequired,
  folders: PropTypes.array.isRequired,
  handleDeletePlan: PropTypes.func.isRequired,
  handleUpdatePlan: PropTypes.func.isRequired,
  handleUpdateFolder: PropTypes.func.isRequired,
  handleDeleteFolder: PropTypes.func.isRequired,
  handlePublishPlan: PropTypes.func.isRequired,
  handleMovePlanToFolder: PropTypes.func.isRequired,
  handleClonePlan: PropTypes.func.isRequired,
  arePrivatePlans: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
};

export default FolderRow;
