/**
 * Gets the list of guide layers for the editor
 *
 * @param {number} planId
 */
export const getGuideLayers = (planId) => ({
  type: 'GUIDE_LAYER_GET_GUIDE_LAYERS',
  payload: {
    request: {
      url: `/plan/${planId}/guide-layer/`,
      method: 'GET',
    },
  },
});

/**
 * Gets the list of default guide layers for the editor
 *
 * @param {number} planId
 */
export const getDefaultGuideLayers = (planId) => ({
  type: 'GUIDE_LAYER_GET_DEFAULT_GUIDE_LAYERS',
  payload: {
    request: {
      url: `/plan/${planId}/guide-layer/default/`,
      method: 'GET',
    },
  },
});

/**
 * Upload is handled separately by `Uppy` so this is called only
 * after uppy has finished uploading the file to server.
 * This will then tell the app to proceed to details submission.
 *
 * @param {object} response
 */
export const handleUploadGuideLayer = (response) => ({
  type: 'GUIDE_LAYER_UPLOAD_SUCCESS',
  payload: {
    data: response,
  },
});

/**
 * After uploading a guide layer, app will ask user to provide several properties.
 * This function submits those properties to the server
 *
 * @param {number} planId
 * @param {number} guideLayerId
 * @param {string} name
 * @param {string} description
 * @param {string} strField
 * For normalization. Field property within the feature
 * that the server will use as the string feature
 * @param {string} intField
 * For normalization. Field property within the feature
 * that the server will use as the int feature
 */
export const submitDetails = (
  planId, guideLayerId, name, description, strField, intField,
) => ({
  type: 'GUIDE_LAYER_SUBMIT_DETAILS',
  payload: {
    request: {
      url: `/plan/${planId}/guide-layer/${guideLayerId}/geom/`,
      method: 'POST',
      data: {
        planId,
        guideLayerId,
        name,
        description,
        strField,
        intField,
      },
    },
  },

});

/**
 * User can change the design/settings of each guide layer. This method will
 * update database so that it's persistent
 *
 * @param {number} planId
 * @param {number} guideLayerId
 * @param {JSON} settings
 */
export const updateGuideLayerSettings = (planId, guideLayerId, settings) => ({
  type: 'GUIDE_LAYER_UPDATE_SETTINGS',
  payload: {
    request: {
      url: `/plan/${planId}/guide-layer/${guideLayerId}/settings/`,
      method: 'POST',
      data: {
        plan_id: planId,
        settings,
      },
    },
  },
});
