import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import DropDown from '../common/DropDown';
import './ImportedDistrictUploadDetail.css';

const ImportedDistrictUploadDetail = (props) => (
  <section className="import-district-upload">
    <div className="idu-activity-area">
      <div className="idu-details">
        <div className="idu-form idu-info form-area">
          <h3>Basic Information</h3>
          <form>
            <div className="idu-formitem form-item fi-text">
              <label>Name</label>
              <input
                type="text"
                value={_.get(props.uploadForm, 'name') || ''}
                onChange={(e) => { props.setValue('name', e.target.value); }}
                placeholder="Enter Districts Set Title"
                maxLength={200}
              />
            </div>
            <div className="idu-formitem form-item">
              <label>Description</label>
              <textarea
                placeholder="Enter Districts Set Description"
                onChange={(e) => { props.setValue('description', e.target.value); }}
              />
            </div>
          </form>
        </div>

        <div className="idu-form idu-info form-area">
          <h3>Column Mapping</h3>
          <form>
            <div className="idu-formitem form-item">
              <label>Alias Property</label>
              <span className="help">Choose the alias for property</span>
              <DropDown
                className="idu-dropdown"
                label={props.uploadForm.aliasProperty || 'Alias Field'}
                options={_.filter(
                  _.get(props.importDetails, 'metadata.fields'),
                  { field_type: 'String' },
                )}
                onClose={() => {}}
                onChange={(item) => { props.setValue('aliasProperty', item.field_name); }}
                renderItem={(item) => (<span>{item.field_name}</span>)}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="idu-preview">
        MAP PREVIEW
      </div>
    </div>
  </section>
);

ImportedDistrictUploadDetail.propTypes = {
  importDetails: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  uploadForm: PropTypes.object.isRequired,
};

export default ImportedDistrictUploadDetail;
