import React from 'react';
import PropTypes from 'prop-types';
import TileLayer from 'ol/layer/Tile';
import XYZSource from 'ol/source/XYZ';
import apply from 'ol-mapbox-style';
import _ from 'lodash';

class MapBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      baseLayer: null,
    };
  }

  componentDidMount() {
    this.initMapBox();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.baseURL !== this.props.baseURL) {
      this.props.map.removeLayer(this.state.baseLayer);
      this.initMapBox();
    }
  }

  componentWillUnmount() {
    if (this.state.baseLayer) {
      this.props.map.removeLayer(this.state.baseLayer);
    }
  }

  initMapBox = () => {
    let baseLayer;
    const { baseURL, accessToken } = this.props;
    const self = this;

    if (typeof baseURL !== 'undefined' && baseURL.indexOf('styles/v1') !== -1) {
      // mapbox-specific
      apply(
        this.props.map,
        `${baseURL}?access_token=${accessToken}`,
      ).then((map) => {
        const layers = map.getLayers();
        let layerIndex;
        layers.forEach((layer, i) => {
          const properties = layer.getProperties();
          if (_.get(properties, 'mapbox-source')) {
            layerIndex = i;
          }
        });
        const mapboxLayer = layers.item(layerIndex);
        self.setState({ baseLayer: mapboxLayer }, () => {
          layers.remove(mapboxLayer);
          layers.insertAt(0, mapboxLayer);
        });
      });
    } else {
      baseLayer = new TileLayer({
        source: new XYZSource({
          url: `${baseURL}/{z}/{x}/{y}.png?`
               + `access_token=${accessToken}`,
        }),
      });
      this.setState({ baseLayer }, () => {
        this.renderMap();
      });
    }
  }

  renderMap = () => {
    this.props.map.getLayers().insertAt(0, this.state.baseLayer);
  }

  render = () => null;
}

MapBox.defaultProps = {
  map: null,
};

MapBox.propTypes = {
  baseURL: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  map: PropTypes.any,
};

export default MapBox;
