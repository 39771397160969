import _ from 'lodash';

const selectFolderStructure = (state) => {
  const folders = _.get(state, 'folder.folders', []);
  const plans = _.get(state, 'plan.plans', []);
  const plansByFolder = {};
  _.forEach(plans, (plan) => {
    const { folder } = plan;
    const folderCategory = folder !== null ? folder : 'no-folder';
    if (plansByFolder[folderCategory]) {
      plansByFolder[folderCategory].push(plan);
    } else {
      plansByFolder[folderCategory] = [plan];
    }
  });

  const foldersWithPlans = folders.map((folder) => {
    const newFolder = { ...folder };
    const folderPlans = plansByFolder[folder.id] ? plansByFolder[folder.id] : [];
    newFolder.plans = folderPlans;
    return newFolder;
  });

  const orphanedPlans = plansByFolder['no-folder'] ? plansByFolder['no-folder'] : [];

  const orgPlans = _.get(state, 'organizationPlan.organizationPlans', []);

  const globalPlans = _.get(state, 'globalPlan.globalPlans', []);

  return {
    folders: foldersWithPlans,
    orphanedPlans,
    orphanedOrgPlans: orgPlans,
    orphanedPdiPlans: globalPlans,
  };
};

export default selectFolderStructure;
