export const login = (username, password) => ({
  type: 'BALLISTA_LOGIN',
  payload: {
    request: {
      url: '/auth/',
      method: 'POST',
      data: {
        username,
        password,
      },
    },
  },
});

export const register = (username, password1, password2) => ({
  type: 'BALLISTA_REGISTER',
  payload: {
    request: {
      url: '/auth/register/',
      method: 'POST',
      data: {
        username,
        password1,
        password2,
      },
    },
  },
});

export const logout = () => ({
  type: 'BALLISTA_LOGOUT',
});

export const getPdiToken = (appKey, requestToken) => ({
  type: 'PDI_LOGIN',
  payload: {
    request: {
      url: '/pdiauth/',
      method: 'POST',
      data: {
        appKey,
        requestToken,
        timestamp: new Date().getTime(),
      },
    },
  },
});

export const getPdiGeo = () => ({
  type: 'PDI_GEO',
  payload: {
    request: {
      url: `/pdigeo/?timestamp=${new Date().getTime()}`,
      method: 'GET',
    },
  },
});

export const loadAuth = (auth) => ({
  type: 'AUTH_SET_AUTH',
  auth,
});
