import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ImportedDistrictMenu from './ImportedDistrictMenu';
import ImportedDistrictUpload from './ImportedDistrictUpload';
import ImportedDistrictLibrary from './ImportedDistrictLibrary';
import ImportedDistrictPublic from './ImportedDistrictPublic';

import './ImportedDistrictView.css';

const ImportedDistrictView = (props) => (
  <section className="import-district-view modal-page">
    <div className={`idv-viewport viewport ${props.isSubmitting ? 'idv-submitting' : ''}`}>
      <header>
        <h1>Import Districts</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="idv-content content">
        <ImportedDistrictMenu {...props} />
        <div className="idv-activity modal-activity">
          { props.action === 'upload' && <ImportedDistrictUpload {...props} /> }
          { props.action === 'library' && <ImportedDistrictLibrary {...props} /> }
          { props.action === 'public' && <ImportedDistrictPublic {...props} /> }
        </div>
      </section>
    </div>
  </section>
);

ImportedDistrictView.propTypes = {
  action: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  planId: PropTypes.any.isRequired,
};

ImportedDistrictView.defaultProps = {
  action: 'upload',
};

export default ImportedDistrictView;
