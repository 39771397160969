import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrash,
  faFolder,
  faClone,
  faShare,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import PlanModal from './PlanModal';
import PlanFolderModal from './PlanFolderModal';
import ClonePlanModal from './ClonePlanModal';
import SharePlanModal from './SharePlanModal';

import './PlanRow.css';

class PlanRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditModal: false,
      openMovePlanToFolderModal: false,
      openCloneModal: false,
      openShareModal: false,
    };
  }

  handleDelete = () => {
    this.props.handleDeletePlan(_.get(this.props.plan, 'id'));
  }

  handleOpenEditModal = () => {
    this.setState({ openEditModal: true });
  }

  handleCloseEditModal = () => {
    this.setState({ openEditModal: false });
  }

  handleEditSubmit = (form) => {
    this.props.handleUpdatePlan(form);
    this.setState({ openEditModal: false });
  }

  handleOpenMovePlanFolderModal = () => {
    this.setState({ openMovePlanToFolderModal: true });
  }

  handleCloseMovePlanFolderModal = () => {
    this.setState({ openMovePlanToFolderModal: false });
  }

  handleMoveToFolder = (form) => {
    const { folderId } = form;
    const planId = this.props.plan.id;
    this.props.handleMovePlanToFolder({ planId, folderId });
    this.handleCloseMovePlanFolderModal();
  }

  handleCloseClonePlanModal = () => {
    this.setState({ openCloneModal: false });
  }

  handleOpenClonePlanModal = () => {
    this.setState({ openCloneModal: true });
  }

  handleCloseSharePlanModal = () => {
    this.setState({ openShareModal: false });
  }

  handleOpenSharePlanModal = () => {
    this.setState({ openShareModal: true });
  }

  handleClonePlan = ({ title, folderId }) => {
    this.props.handleClonePlan(this.props.plan.id, title, folderId);
    this.handleCloseClonePlanModal();
  }

  handleSharePlan = (form) => {
    const { planId, target } = form;
    this.props.handlePublishPlan(planId, target);
    this.handleCloseSharePlanModal();
  }

  getRowClassName = (isInFolder, disabled) => {
    let className = '';
    if (isInFolder) {
      className = 'folder-plan';
    } else {
      className = 'orphan-plan';
    }
    if (disabled) {
      className += ' disabled';
    }
    return className;
  }

  render = () => {
    const {
      auth, plan, isInFolder, arePrivatePlans, folders,
    } = this.props;
    const authSettings = _.get(auth, 'settings', '');
    const planState = _.get(plan, 'state', '');
    let disabled = false;
    if (authSettings !== '') {
      if (!authSettings.split(',').includes(planState)) {
        disabled = true;
      }
    }

    return (
      <>
        <tr className={this.getRowClassName(isInFolder, disabled)}>
          <td>
            <span className="plan-name">{ _.get(plan, 'title') }</span>
          </td>
          <td>&nbsp;</td>
          <td>&nbsp;</td>
          <td>{ planState }</td>
          <td>{ _.get(plan, 'district_type') }</td>
          <td>
            <Link className={disabled ? 'disabledLink' : ''} to={arePrivatePlans ? `editor/${_.get(plan, 'id')}` : `viewer/${_.get(plan, 'id')}`}>View Map</Link>
            {arePrivatePlans
              && (
              <button
                onClick={this.handleOpenEditModal}
                type="button"
                className="pv-action-icon"
                title="Edit Plan"
              >
                <FontAwesomeIcon icon={faEdit} size="2x" color="lightgray" />
              </button>
              )}
            {arePrivatePlans && (
            <button
              onClick={this.handleOpenSharePlanModal}
              type="button"
              className="pv-action-icon"
              title="Share Plan"
            >
              <FontAwesomeIcon icon={faShare} size="2x" color="lightgray" />
            </button>
            )}
            <button
              onClick={this.handleOpenClonePlanModal}
              type="button"
              className="pv-action-icon"
              title="Clone Plan"
            >
              <FontAwesomeIcon icon={faClone} size="2x" color="lightgray" />
            </button>
            {arePrivatePlans && (
            <button
              onClick={this.handleOpenMovePlanFolderModal}
              type="button"
              className="pv-action-icon"
              title="Move Plan To Folder"
            >
              <FontAwesomeIcon icon={faFolder} size="2x" color="lightgray" />
            </button>
            )}
            {arePrivatePlans && (
            <button
              onClick={this.handleDelete}
              type="button"
              className="pv-action-icon"
              title="Delete Plan"
            >
              <FontAwesomeIcon icon={faTrash} size="2x" color="lightgray" />
            </button>
            )}
            { this.state.openEditModal
              && (
                <PlanModal
                  open={this.state.openEditModal}
                  handleClose={this.handleCloseEditModal}
                  {...this.props}
                  handleSubmit={this.handleEditSubmit}
                  folders={folders}
                />
              )}
            { this.state.openMovePlanToFolderModal
              && (
                <PlanFolderModal
                  open={this.state.openMovePlanToFolderModal}
                  handleClose={this.handleCloseMovePlanFolderModal}
                  {...this.props}
                  handleSubmit={this.handleMoveToFolder}
                  folders={folders}
                />
              )}
            { this.state.openCloneModal
              && (
                <ClonePlanModal
                  open={this.state.openCloneModal}
                  handleClose={this.handleCloseClonePlanModal}
                  handleSubmit={this.handleClonePlan}
                  folders={folders}
                  plan={plan}
                />
              )}
            { this.state.openShareModal
              && (
                <SharePlanModal
                  open={this.state.openShareModal}
                  handleClose={this.handleCloseSharePlanModal}
                  plan={plan}
                  handleSubmit={this.handleSharePlan}
                />
              )}
          </td>
        </tr>
      </>
    );
  }
}

PlanRow.propTypes = {
  plan: PropTypes.object.isRequired,
  handleDeletePlan: PropTypes.func.isRequired,
  handleUpdatePlan: PropTypes.func.isRequired,
  handlePublishPlan: PropTypes.func.isRequired,
  folders: PropTypes.array.isRequired,
  isInFolder: PropTypes.bool.isRequired,
  handleMovePlanToFolder: PropTypes.func.isRequired,
  handleClonePlan: PropTypes.func.isRequired,
  arePrivatePlans: PropTypes.bool.isRequired,
  auth: PropTypes.object.isRequired,
};

export default PlanRow;
