import React from 'react';
import PropTypes from 'prop-types';

const BaseLayer = (props) => (
  <>
    {
    React.Children.map(props.children, (child) => React.cloneElement(child, { map: props.map, view: props.view, parent: 'BASELAYER' }))
  }
  </>
);

BaseLayer.propTypes = {
  children: PropTypes.object,
  map: PropTypes.object,
  view: PropTypes.object,
};

BaseLayer.defaultProps = {
  children: null,
  map: null,
  view: null,
};

export default BaseLayer;
