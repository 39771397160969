import React from 'react';
import PropTypes from 'prop-types';

class DrawLayer extends React.Component {
  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render = () => (
    <>
      { this.props.map && this.props.children
      && React.Children.map(this.props.children, (child) => (child === null ? <></>
        : React.cloneElement(child, { map: this.props.map, view: this.props.view, parent: 'DRAWLAYER' })))}
    </>
  );
}

DrawLayer.propTypes = {
  children: PropTypes.array,
  map: PropTypes.object,
  view: PropTypes.object,
};

DrawLayer.defaultProps = {
  children: null,
  map: null,
  view: null,
};

export default DrawLayer;
