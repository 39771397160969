import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Moment from 'react-moment';
import MetricsMenu from './MetricsMenu';
import TestSuiteDetails from './TestSuiteDetails';
import './TestSuiteView.css';

const TestSuiteView = ({ testSuite, ...props }) => (
  <section className="test-list-view modal-page">
    <div className="viewport">
      <header>
        <h1>Metrics Test Results</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="content">
        <MetricsMenu {...props} />
        <div className="modal-activity">
          <div className="activity-area">
            <header>
              <h3>
                Test Suite:
                { _.get(testSuite, 'title') }
              </h3>
            </header>
            <div className="inner-activity">
              <Grid container spacing={5}>
                <Grid xs={12} md={12}>
                  <TestSuiteDetails testSuite={testSuite} {...props} />
                </Grid>
                <Grid xs={12} md={12} className="tests-list">
                  <h3>Tests</h3>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Test Date</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.get(testSuite, 'metric_test_suite_tests', [])
                        .map((testItem) => (
                          <TableRow>
                            <TableCell>
                              <Moment date={_.get(testItem, 'created_at')} format="D MMM YYYY  //  h:mm a" />
                            </TableCell>
                            <TableCell>
                              <Link to={`/editor/${props.planId}/metrics/${testSuite.id}/results/${testItem.id}`}>View</Link>
                            </TableCell>
                          </TableRow>
                        ))}

                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </div>
            <footer>
              {/* <Button disabled onClick={()=>{}}>Update</Button> */}
            </footer>
          </div>
        </div>
      </section>
    </div>
  </section>
);

TestSuiteView.propTypes = {
  planId: PropTypes.string.isRequired,
  testSuite: PropTypes.array.isRequired,
};

export default TestSuiteView;
