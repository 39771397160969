import React from 'react';
import PropTypes from 'prop-types';

import BasicSettingsContainer from '../containers/settings/BasicSettingsContainer';
import ColumnSettingsContainer from '../containers/settings/ColumnSettingsContainer';
import DistrictSettingsContainer from '../containers/settings/DistrictSettingsContainer';

const SettingsPage = (props) => (
  <>
    { props.action === 'basic' && <BasicSettingsContainer {...props} />}
    { props.action === 'columns' && <ColumnSettingsContainer {...props} />}
    { props.action === 'district' && <DistrictSettingsContainer {...props} /> }
  </>
);

SettingsPage.propTypes = {
  action: PropTypes.string.isRequired,
};

export default SettingsPage;
