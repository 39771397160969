import React from 'react';

const ImportedDistrictPublic = () => (
  <section className="import-district-public activity-area">
    <header>
      <h3>Public Library</h3>
    </header>
  </section>
);

ImportedDistrictPublic.propTypes = {

};

export default ImportedDistrictPublic;
