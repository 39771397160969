import _ from 'lodash';

const defaultState = {
  shadedLayers: [],
  isSubmitting: false,
  isPageLoaded: false,
};

const ShadedLayerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'BALLISTA_LOGOUT': {
      return defaultState;
    }
    case 'DELETE_SHADED_LAYER_SUCCESS': {
      const shadedLayerId = _.get(action, 'meta.previousAction.shadedLayerId');
      const shadedLayers = [...state.shadedLayers];
      _.remove(shadedLayers, { id: shadedLayerId });

      return {
        ...state,
        shadedLayers,
      };
    }
    case 'CREATE_SHADED_LAYER_SUCCESS': {
      return {
        ...state,
        shadedLayers: [...state.shadedLayers, _.get(action.payload, 'data')],
      };
    }
    case 'RETRIEVE_DATA_COLUMNS_SUCCESS':
      return {
        ...state,
        columns: _.get(action, 'payload.data'),
      };

    case 'PLAN_GET_PLAN_DETAILS_SUCCESS':
      return {
        ...state,
        shadedLayers: _.get(action, 'payload.data.shaded_layers'),
        isPageLoaded: true,
      };

    case 'ACTIVATE_SHADED_LAYER_SUCCESS': {
      // Turn activated item off...
      const layers = [...state.shadedLayers];
      const ind = _.findIndex(layers, { is_active: true });
      if (layers[ind]) {
        layers[ind].is_active = false;
      }

      const newId = _.findIndex(layers, { id: _.get(action.payload, 'data.id') });
      layers.splice(newId, 1, _.get(action.payload, 'data'));

      return {
        ...state,
        shadedLayers: [...layers],
      };
    }

    case 'UPDATE_SHADED_LAYER': {
      return {
        ...state,
      };
    }
    case 'UPDATE_SHADED_LAYER_SUCCESS': {
      const layers = [...state.shadedLayers];
      const updatedId = _.findIndex(layers, { id: _.get(action.payload, 'data.id') });
      layers.splice(updatedId, 1, _.get(action.payload, 'data'));

      return {
        ...state,
        shadedLayers: [...layers],
      };
    }

    case 'SHADED_LAYER_UPDATE_STATUS': {
      const shadedLayers = [...state.shadedLayers];
      const updatedId = _.findIndex(shadedLayers, { id: _.get(action.payload, 'data.id') });

      _.set(shadedLayers, `[${updatedId}].is_complete`, _.get(action.payload, 'data.is_complete'));

      return {
        ...state,
        shadedLayers,
      };
    }

    default:
      return state;
  }
};

export default ShadedLayerReducer;
