import OpenStreetMap from './OpenStreetMap';
import MapBox from './MapBox';
import StamenLayer from './Stamen';
import BaseLayer from './BaseLayer';

export {
  OpenStreetMap,
  MapBox,
  StamenLayer,
};

export default BaseLayer;
