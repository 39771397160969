import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import GuideLayerUpload from './GuideLayerUpload';
import GuideLayerDetails from './GuideLayerDetails';

import Button from '../common/Button';
import './GuideLayerView.css';

const GuideLayerView = (props) => (
  <div className="guide-layers-view modal-page">
    <div className={`viewport gl-viewport ${props.isSubmitting && 'gl-submitting'}`}>
      <header>
        <h1>Upload Guide Layers</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="glv-content content">
        <div className="glv-activity">
          {
            props.step === 'GUIDE_LAYER_UPLOAD'
            && (
              <GuideLayerUpload
                handleUploadGuideLayer={props.handleUploadGuideLayer}
                planId={props.planId}
              />
            )
          }
          {
            props.step === 'GUIDE_LAYER_DETAILS'
            && (
              <GuideLayerDetails
                {...props}
              />
            )
          }
        </div>
      </section>
      {props.step === 'GUIDE_LAYER_DETAILS'
        && (
        <footer>
          {
            !props.isSubmitting
              ? (
                <Button
                  primary={props.isValid}
                  disabled={!props.isValid}
                  onClick={props.handleSubmit}
                >
                  Publish
                </Button>
              )
              : (<Button disabled onClick={() => {}}>Loading</Button>)
          }
        </footer>
        )}
    </div>
  </div>
);

GuideLayerView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleUploadGuideLayer: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  planId: PropTypes.any.isRequired,
  step: PropTypes.string.isRequired,
};

export default GuideLayerView;
