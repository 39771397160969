import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import GuideLayerPreview from './GuideLayerPreview';
import DropDown from '../common/DropDown';

import './GuideLayerDetails.css';

const GuideLayerDetails = ({
  description,
  intField,
  name,
  setValue,
  strField,
  uploadedGuideLayer,
}) => (
  <section className="guide-layer-details">
    <div className="gld-details">
      <div className="gld-form gld-info form-area">
        <h3>Basic Information</h3>
        <form>
          <div className="gld-formitem gld-filesection">
            <label>Filename</label>
            <span>{uploadedGuideLayer.source_file.replace(/^.*[\\/]/, '')}</span>
          </div>
          <div className="gld-formitem form-item fi-text">
            <label>Name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => { setValue('name', e.target.value); }}
              placeholder="Enter layer title"
              maxLength={200}
            />
          </div>
          <div className="gld-formitem form-item">
            <label>Description</label>
            <textarea onChange={(e) => { setValue('description', e.target.value); }} />
          </div>
        </form>
      </div>
      <div className="gld-form gld-mapping form-area">
        <h3>Field Mapping</h3>
        <form>
          <div className="gld-formitem form-item">
            <label>Feature Name Property</label>
            <span className="help">Choose the property to become feature label</span>
            <DropDown
              className="gld-dropdown"
              label={strField || 'Field Label'}
              options={_.filter(
                _.get(uploadedGuideLayer, 'metadata.fields'),
                { field_type: 'String' },
              )}
              onClose={() => {}}
              onChange={(item) => { setValue('strField', item.field_name); }}
              renderItem={(item) => (<span>{item.field_name}</span>)}
            />
          </div>
          <div className="gld-formitem form-item">
            <label>Integer Property</label>
            <span className="help">Choose the property to become the comparison value</span>
            <DropDown
              className="gld-dropdown dropdown"
              label={intField || 'Field Label'}
              options={_.filter(
                _.get(uploadedGuideLayer, 'metadata.fields'),
                { field_type: 'Integer' },
              )}
              onChange={(item) => {
                setValue('intField', item.field_name);
              }}
              renderItem={(item) => (<span>{item.field_name}</span>)}
            />
          </div>

          <div className="gld-formitem form-item">
            <label>Geometry Type</label>
            <div className="gld-value">
              {_.get(uploadedGuideLayer, 'metadata.geom_type')}
            </div>
          </div>
        </form>
      </div>
    </div>
    <div className="gld-preview">
      <GuideLayerPreview
        description={description}
        intField={intField}
        name={name}
        strField={strField}
        uploadedGuideLayer={uploadedGuideLayer}
      />
    </div>
  </section>
);

GuideLayerDetails.propTypes = {
  description: PropTypes.string,
  intField: PropTypes.string,
  name: PropTypes.string,
  setValue: PropTypes.func.isRequired,
  strField: PropTypes.string,
  uploadedGuideLayer: PropTypes.object,
};

GuideLayerDetails.defaultProps = {
  description: '',
  intField: '',
  name: '',
  strField: '',
  uploadedGuideLayer: {},
};

export default GuideLayerDetails;
