import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { connect } from 'react-redux';

import ViewReportView from '../../components/report/ViewReportView';

import {
  receiveReportStatusPoll,
  getReport,
} from '../../actions/report';

class ViewReportContainer extends React.Component {
  constructor(props) {
    super(props);

    this.pollingCenter = new WebSocket(
      `${process.env.REACT_APP_NOTIFICATION_CENTER_URL}`
        + `plan/${props.planId}/report/${props.reportId}`,
    );
  }

  componentDidMount() {
    this.initializePolling();

    this.props.getReport(
      this.props.planId,
      this.props.reportId,
    );
  }

  componentWillUnmount = () => {
    this.pollingCenter.close();
  }

  initializePolling = () => {
    this.pollingCenter.onmessage = (event) => {
      const poll = JSON.parse(event.data);
      this.props.receiveReportStatusPoll(poll.message);
    };
  }

  render = () => (
    <ViewReportView
      {...this.props}
    />
  );
}

const mapStateToProps = (state) => ({
  chosenReport: _.get(state.report, 'chosenReport'),
  task: _.get(state.report, 'chosenReport.task'),
});

ViewReportContainer.propTypes = {
  planId: PropTypes.string.isRequired,
  reportId: PropTypes.number.isRequired,
  getReport: PropTypes.func.isRequired,
  receiveReportStatusPoll: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  receiveReportStatusPoll,
  getReport,
})(ViewReportContainer);
