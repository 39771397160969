import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ReportMenu from './ReportMenu';

import DetailsSection from './CreateReportDetailsSection';
import DistrictSection from './CreateReportDistrictSection';
import ReviewSection from './CreateReportReviewSection';

const getStepContent = (stepIndex) => {
  switch (stepIndex) {
    case 0:
      return DetailsSection;
    case 1:
      return DistrictSection;
    case 2:
      return ReviewSection;
    default:
      return DetailsSection;
  }
};

const STEPS = [
  'Report Information',
  'District Details',
  'Review Report',
];

const CreateReportView = (props) => {
  const ActiveSection = getStepContent(props.activeStep);

  return (
    <section className="view-report modal-page">
      <div className={`bs-viewport viewport ${props.isSubmitting ? 'bs-submitting' : ''}`}>
        <header>
          <h1>Reports | Create Report</h1>
          <Link to={`/editor/${props.planId}`}>Close</Link>
        </header>
        <section className="bs-content content">
          <ReportMenu {...props} />
          <div className="bs-activity modal-activity">
            <Stepper activeStep={props.activeStep}>
              {STEPS.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <ActiveSection {...props} />
          </div>
        </section>
      </div>
    </section>
  );
};

CreateReportView.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  planId: PropTypes.string.isRequired,
  activeStep: PropTypes.number.isRequired,
};

export default CreateReportView;
