import React from 'react';
import PropTypes from 'prop-types';
import OLVectorLayer from 'ol/layer/Vector';

/**
 * For our intents and purposes, this component are vectors being served
 * by Ballista server
 */
class VectorLayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vectorLayer: new OLVectorLayer({
        visible: props.visible,
      }),
    };
  }

  componentDidMount() {
    if (this.props.pos) {
      this.props.map.getLayers().insertAt(this.props.pos, this.state.vectorLayer);
    } else {
      this.props.map.addLayer(this.state.vectorLayer);
    }
  }

  shouldComponentUpdate(nextProps) {
    return this.props.committedChanges !== nextProps.committedChanges;
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.state.vectorLayer);
  }

  render = () => (
    <>
      {
    React.Children.map(this.props.children, (child) => (child === null ? <></>
      : React.cloneElement(child, {
        map: this.props.map,
        view: this.props.view,
        layer: this.state.vectorLayer,
        parent: 'VECTORLAYER',
      })))
  }
    </>
  );
}

VectorLayer.propTypes = {
  pos: PropTypes.number,
  committedChanges: PropTypes.any,
  map: PropTypes.object,
  children: PropTypes.any,
  view: PropTypes.object,
  visible: PropTypes.bool,
};

VectorLayer.defaultProps = {
  pos: null,
  committedChanges: null,
  map: null,
  children: null,
  view: null,
  visible: true,
};

export default VectorLayer;
