import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  getDataColumns,
  createShadedLayer,
  updateShadedLayer,
  deleteShadedLayer,
} from '../actions/shadedLayer';

import ShadedLayerView from '../components/shadedLayer/ShadedLayerView';

class ShadedLayerContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        id: null,
        name: null,
        description: null,
        breakoffPoint: null,
        colorMin: null,
        colorMax: null,
        colorVia: null,
        steps: null,
        stops: null,
        brushType: null,
        planId: null,
        isActive: null,
        denominator: null,
        numerator: null,
      },
      isDeleting: false,
    };
  }

  componentDidMount() {
    this.props.getDataColumns(this.props.planId);
    if (this.props.shadedLayerId > 0) {
      const chosen = this.getShadedLayer(parseInt(this.props.shadedLayerId, 10));
      this.setShadedLayerForm(chosen);
    } else {
      this.setShadedLayerForm(null);
    }
  }

  setShadedLayerForm = (shadeLayer) => {
    this.setState({
      form: {
        id: _.get(shadeLayer, 'id'),
        name: _.get(shadeLayer, 'name'),
        description: _.get(shadeLayer, 'description'),
        breakoffPoint: _.get(shadeLayer, 'breakoff_point'),
        colorMin: _.get(shadeLayer, 'color_min'),
        colorMax: _.get(shadeLayer, 'color_max'),
        colorVia: _.get(shadeLayer, 'color_via'),
        steps: _.get(shadeLayer, 'steps'),
        stops: _.get(shadeLayer, 'stops'),
        brushType: _.get(shadeLayer, 'brush_type'),
        planId: _.get(shadeLayer, 'plan_id'),
        isActive: _.get(shadeLayer, 'is_active'),
        denominator: _.get(shadeLayer, 'denominator'),
        numerator: _.get(shadeLayer, 'numerator'),
      },
    });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.shadedLayerId !== prevProps.shadedLayerId
      || (!prevProps.isPageLoaded && this.props.isPageLoaded)
    ) {
      if (this.props.shadedLayerId > 0) {
        const chosen = this.getShadedLayer(parseInt(this.props.shadedLayerId, 10));
        this.setShadedLayerForm(chosen);
      } else {
        this.setShadedLayerForm(null);
      }
    }
  }

  getShadedLayer = (id) => _.filter(
    this.props.shadedLayers,
    { id: parseInt(id, 10) }, [],
  )[0]

  handleChange = (name) => (value) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [name]: value,
      },
    }));
  }

  handleSubmit = () => {
    this.props.createShadedLayer(this.props.planId, this.state.form)
      .then(() => {
        this.props.history.push(`/editor/${this.props.planId}/shaded-layer/${this.props.shadedLayers[this.props.shadedLayers.length - 1].id}/edit/`);
      });
  }

  handleUpdate = () => {
    this.props.updateShadedLayer(
      this.props.planId,
      this.props.shadedLayerId,
      this.state.form,
    );
  }

  handleCancelDelete = () => {
    this.setState({ isDeleting: false });
  }

  handleConfirmDelete = () => {
    this.props.deleteShadedLayer(this.props.planId, parseInt(this.props.shadedLayerId, 10))
      .then(() => {
        this.setState({ isDeleting: false });
        if (this.props.shadedLayers.length > 0) {
          this.props.history.replace(`/editor/${this.props.planId}/shaded-layer/${this.props.shadedLayers[0].id}/edit`);
        } else {
          this.props.history.replace(`/editor/${this.props.planId}/shaded-layer/create/`);
        }
      });
  }

  handleDelete = () => {
    this.setState({ isDeleting: true });
  }

  render = () => (
    <ShadedLayerView
      {...this.props}
      form={this.state.form}
      handleChange={this.handleChange}
      handleSubmit={this.handleSubmit}
      handleUpdate={this.handleUpdate}
      isDeleting={this.state.isDeleting}
      handleDelete={this.handleDelete}
      handleCancelDelete={this.handleCancelDelete}
      handleConfirmDelete={this.handleConfirmDelete}
      isComplete={_.get(this.getShadedLayer(this.props.shadedLayerId), 'is_complete', true)}
    />
  )
}

ShadedLayerContainer.propTypes = {
  planId: PropTypes.any.isRequired,
  shadedLayerId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  shadedLayers: PropTypes.array.isRequired,
  createShadedLayer: PropTypes.func.isRequired,
  updateShadedLayer: PropTypes.func.isRequired,
  deleteShadedLayer: PropTypes.func.isRequired,
  isPageLoaded: PropTypes.bool.isRequired,
  getDataColumns: PropTypes.func.isRequired,
};

ShadedLayerContainer.defaultProps = {

};

const mapStateToProps = (state) => ({
  shadedLayers: _.get(state.shadedLayer, 'shadedLayers'),
  isPageLoaded: _.get(state.shadedLayer, 'isPageLoaded'),
  columns: _.get(state.shadedLayer, 'columns'),
});

export default connect(mapStateToProps, {
  getDataColumns,
  createShadedLayer,
  updateShadedLayer,
  deleteShadedLayer,
})(ShadedLayerContainer);
