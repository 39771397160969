import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './ImportedDistrictLibraryItem.css';

const ImportedDistrictLibraryItem = (props) => (
  <li
    className="imported-district-library-item"
    data-is-selected={
      _.get(props.selectedLibraryItem, 'id') === props.importedDistrict.id
    }
  >
    <a
      href="javacript: void(null)"
      onClick={() => props.selectLibraryItem(props.importedDistrict)}
    >
      <span className="idlt-thumb" />
      <span className="idlt-details">
        <h3>
          {_.get(props.importedDistrict, 'id')}
          {' '}
          {_.get(props.importedDistrict, 'name')}
        </h3>
        <p>{_.get(props.importedDistrict, 'description')}</p>
      </span>
    </a>
  </li>
);

ImportedDistrictLibraryItem.propTypes = {
  importedDistrict: PropTypes.object.isRequired,
  selectedLibraryItem: PropTypes.object,
  selectLibraryItem: PropTypes.func.isRequired,
};

ImportedDistrictLibraryItem.defaultProps = {
  selectedLibraryItem: null,
};

export default ImportedDistrictLibraryItem;
