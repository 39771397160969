import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { SETTINGS_MENU_LIST } from '../../constants';

const SettingsMenu = (props) => (
  <section className="settings-menu modal-menu">
    <nav>
      <ul>
        {
          SETTINGS_MENU_LIST.map((menuItem) => (
            <li
              key={menuItem.action}
              className={menuItem.action === props.action ? 'active' : ''}
            >
              <Link to={`/editor/${props.planId}/settings/${menuItem.action}`}>
                {menuItem.label}
              </Link>
            </li>
          ))
        }
      </ul>
    </nav>
  </section>
);

SettingsMenu.propTypes = {
  action: PropTypes.string.isRequired,
  planId: PropTypes.any.isRequired,
};

export default SettingsMenu;
