import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ShapefileDownloadView from '../../components/exportArchive/ShapefileDownloadView';

import { getExportedShapefileArchive } from '../../actions/exportArchive';

class ShapefileDownloadContainer extends React.Component {
  componentDidMount = () => {
    this.props.getExportedShapefileArchive(this.props.planId);
  }

  render = () => (
    <ShapefileDownloadView
      {...this.props}
    />
  )
}

const mapStateToProps = (state) => ({
  shapefileArchive: _.get(state, 'exportArchive.shapefileArchive'),
});

ShapefileDownloadContainer.propTypes = {
  getExportedShapefileArchive: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {
  getExportedShapefileArchive,
})(ShapefileDownloadContainer);
