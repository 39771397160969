import _ from 'lodash';
import jwtDecode from 'jwt-decode';
import Cookies from 'universal-cookie';

/* eslint camelcase: 0 */
export const getPdiAuthState = () => {
  const cookies = new Cookies();

  const pdi_token = cookies.get('pdi_token');
  const pdi_settings_token = cookies.get('pdi_settings');
  // console.log('** pdi_token', pdi_token, 'pdi_settings_token', pdi_settings_token);
  let pdi_token_decoded;
  let pdi_token_expired = false;
  if (pdi_token !== null && typeof pdi_token !== 'undefined') {
    pdi_token_decoded = jwtDecode(pdi_token);
    // check expires
    const epochNow = Math.round(Date.now() / 1000);
    const expiration = _.get(pdi_token_decoded, 'exp', 0);
    if (epochNow > expiration) {
      pdi_token_expired = true;
    }
    // console.log('** pdi_token_decoded', pdi_token_decoded,_ .get(pdi_token_decoded, 'exp'),
    // 'expired', pdi_token_expired);
  }

  let pdi_settings_decoded;
  if (pdi_settings_token !== null && typeof pdi_settings_token !== 'undefined') {
    pdi_settings_decoded = pdi_settings_token.replace(/\\054/g, ',');
    // console.log('** pdi_settings_decoded', pdi_settings_decoded);
  }
  let auth = {};

  if (pdi_token_decoded && !pdi_token_expired) {
    auth = {
      isPdiUser: true,
      id: pdi_token_decoded.UserId,
      username: pdi_token_decoded.unique_name,
    };
    if (pdi_settings_decoded) {
      auth.settings = pdi_settings_decoded;
    }
  }

  return auth;
};

export const getBallistaAuthState = () => {
  const cookies = new Cookies();

  const ballista_token = cookies.get('ballista_token');

  let auth = {};

  if (ballista_token) {
    auth = {
      isPdiUser: false,
      id: ballista_token,
    };
  }

  return auth;
};
