import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MENU = [
  { label: 'Create Report', action: 'create' },
  { label: 'Show Reports', action: ['list', 'view'] },
];

const ReportMenu = (props) => (
  <section className="report-menu modal-menu">
    <nav>
      <ul>
        {
          MENU.map((menuItem) => (
            <li
              key={menuItem.action}
              className={menuItem.action.includes(props.action) ? 'active' : ''}
            >
              <Link to={`/editor/${props.planId}/report/${menuItem.action.includes('list') ? 'list' : menuItem.action}`}>
                {menuItem.label}
              </Link>
            </li>
          ))
        }
      </ul>
    </nav>
  </section>
);

ReportMenu.propTypes = {
  action: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
};

export default ReportMenu;
