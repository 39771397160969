import React from 'react';
import PropTypes from 'prop-types';
import OLVectorTileLayer from 'ol/layer/VectorTile';
import _ from 'lodash';

/**
 * For our intents and purposes, this component are vectors being served
 * by Ballista server
 */
class VectorTileLayer extends React.Component {
  constructor(props) {
    super(props);

    let opacity = 1;
    if (!props.show) {
      opacity = 0;
    }

    this.state = {
      vectorTileLayer: new OLVectorTileLayer({
        declutter: props.declutter,
        renderMode: props.renderMode,
        style: props.style,
        opacity,
      }),
    };
    this.state.vectorTileLayer.set('layerName', props.title);
  }

  componentDidMount() {
    if (this.props.pos) {
      this.props.map.getLayers().insertAt(this.props.pos, this.state.vectorTileLayer);
    } else {
      this.props.map.addLayer(this.state.vectorTileLayer);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (typeof this.props.committedChanges === 'object') {
      return !_.isEqual(this.props.committedChanges, nextProps.committedChanges);
    }
    return this.props.committedChanges !== nextProps.committedChanges;
  }

  componentDidUpdate(prevProps) {
    if (
      (this.state.vectorTileLayer.getSource() === null || this.props.source !== prevProps.source)
      && this.props.source !== null
    ) {
      this.state.vectorTileLayer.setSource(this.props.source);
    }
    if (this.props.show !== prevProps.show) {
      let opacity = 1;
      if (!this.props.show) {
        opacity = 0;
      }
      this.state.vectorTileLayer.setOpacity(opacity);
    }
  }

  componentWillUnmount() {
    this.props.map.removeLayer(this.state.vectorTileLayer);
  }

  changed = () => {
    this.state.vectorTileLayer.changed();
  }

  getSource = () => this.state.vectorTileLayer.getSource();

  getFeatures = (pixel) => this.state.vectorTileLayer.getFeatures(pixel);

  getProperties = () => this.state.vectorTileLayer.getProperties();

  get = (key) => this.state.vectorTileLayer.get(key);

  render = () => (
    <>
      {React.Children.map(this.props.children, (child) => (child === null ? <></>
        : React.cloneElement(child, {
          map: this.props.map,
          view: this.props.view,
          layer: this.state.vectorTileLayer,
          parent: 'VECTORTILELAYER',
        })))}
    </>
  );
}

VectorTileLayer.propTypes = {
  pos: PropTypes.number,
  map: PropTypes.any,
  children: PropTypes.any,
  view: PropTypes.object,
  committedChanges: PropTypes.any,
  title: PropTypes.string,
  source: PropTypes.any,
  renderMode: PropTypes.string,
  declutter: PropTypes.bool,
  style: PropTypes.any,
  show: PropTypes.bool,
};

VectorTileLayer.defaultProps = {
  pos: null,
  committedChanges: null,
  map: null,
  children: null,
  view: null,
  title: '',
  source: null,
  renderMode: 'hybrid',
  declutter: false,
  style: null,
  show: true,
};

export default VectorTileLayer;
