import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getReports } from '../../actions/report';

import ListReportView from '../../components/report/ListReportView';

class ListReportContainer extends React.Component {
  componentDidMount() {
    this.props.getReports(this.props.planId);
  }

  render = () => (
    <ListReportView
      {...this.props}
    />
  );
}

const mapStateToProps = (state) => ({
  reports: _.get(state.report, 'reports'),
});

ListReportContainer.propTypes = {
  getReports: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, {
  getReports,
})(ListReportContainer);
