import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import Button from '../../common/Button';
import ACSTable from './ACSTable';

import './DistrictInfo.css';

const DistrictInfo = (props) => {
  // <Button onClick={() => props.focusDistrict(props.chosenDistrict.id)}>Focus</Button>
  const firstDistrictName = _.get(props, 'districtDetails[0].district.alias', '');
  return (
    <div className="editor-district-details">
      <h3>
        <span>Pending Changes</span>
        <div className="edd-options">
          <Button onClick={() => props.handleUndo()}>
            <Icon icon={faUndo} color="gray" />
            {' '}
            Undo
          </Button>
          <Button onClick={() => props.handleSave(props.chosenDistrict.id, props.districtDetails)}>
            Save
          </Button>
          <Button onClick={() => props.handleCancelDistrict()}>Cancel</Button>
        </div>
      </h3>
      <Table size="small" aria-label="acs-table">
        <TableHead>
          <TableRow>
            <TableCell>
              {firstDistrictName}
              {' '}
            </TableCell>
            <TableCell align="right">Orig Pop&apos;l</TableCell>
            <TableCell align="right">Change</TableCell>
            <TableCell align="right">New Pop&apos;l</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { props.districtDetails
        && props.districtDetails.map((affected) => (
          <ACSTable {...affected} key={`acstable_${affected.district.id}`} />
        ))}
        </TableBody>
      </Table>

    </div>
  );
};

DistrictInfo.propTypes = {
  chosenDistrict: PropTypes.object.isRequired,
  districtDetails: PropTypes.array.isRequired,
  focusDistrict: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleCancelDistrict: PropTypes.func.isRequired,
  handleUndo: PropTypes.func.isRequired,
};

export default DistrictInfo;
