import _ from 'lodash';

const defaultState = {
  reports: [],
  chosenReport: null,
};

const ReportReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'REPORT_GET_REPORTS_SUCCESS': {
      return {
        ...state,
        reports: _.get(action, 'payload.data'),
      };
    }

    case 'REPORT_GET_REPORT_SUCCESS': {
      return {
        ...state,
        chosenReport: _.get(action, 'payload.data'),
      };
    }

    case 'REPORT_SUBMIT_CREATE_REPORT_SUCCESS': {
      return {
        ...state,
        chosenReport: _.get(action, 'payload.data'),
      };
    }

    case 'REPORT_CREATE_POLL_REPORT':
      return {
        ...state,
        chosenReport: _.get(action, 'payload'),
      };

    default:
      return state;
  }
};

export default ReportReducer;
