import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DistrictSettings from '../../components/settings/DistrictSettings';
import { updatePlanSettings } from '../../actions/plan';

class DistrictSettingsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDistrictColored: _.get(props.settings, 'isDistrictColored', false),
      allowedDeviationType: null,
      allowedDeviation: 0,
    };
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps) {
    if (_.get(prevProps.chosenPlan, 'id', null) === null
      && _.get(this.props.chosenPlan, 'id', null) !== null) {
      this.initialize();
    }

    if (_.get(prevProps.chosenPlan, 'id') === undefined && this.props.chosenPlan.id) {
      this.initialize();
    }
  }

  initialize = () => {
    this.setState({
      allowedDeviation: _.get(this.props.settings, 'allowedDeviation', 0),
      allowedDeviationType: _.get(this.props.settings, 'allowedDeviationType', null),
      isDistrictColored: _.get(this.props.settings, 'isDistrictColored', false),
    });
  }

  handleChange = (stateName) => (val) => {
    this.setState({
      [stateName]: val,
    });
  }

  handleSubmit = () => {
    this.props.updatePlanSettings(
      this.props.planId,
      {
        ...this.props.settings,
        isDistrictColored: this.state.isDistrictColored,
        allowedDeviationType: this.state.allowedDeviationType,
        allowedDeviation: this.state.allowedDeviation,
      },
    );
  }

  revert = () => {
    this.setState({ isDistrictColored: _.get(this.props.settings, 'isDistrictColored', false) });
  }

  render = () => (
    <DistrictSettings
      {...this.props}
      {...this.state}
      handleChange={this.handleChange}
      handleSubmit={this.handleSubmit}
      maxDeviation={Math.floor(_.get(this.props.chosenPlan, 'total_populations.population') / _.get(this.props.chosenPlan, 'district_count', 1))}
      revert={this.revert}
    />
  )
}

const mapStateToProps = (state) => ({
  chosenPlan: _.get(state.plan, 'chosenPlan'),
  settings: _.get(state.plan, 'chosenPlan.settings'),
});

DistrictSettingsContainer.propTypes = {
  updatePlanSettings: PropTypes.func.isRequired,
  chosenPlan: PropTypes.any,
  planId: PropTypes.any.isRequired,
  settings: PropTypes.object,
};

DistrictSettingsContainer.defaultProps = {
  chosenPlan: {},
  settings: {},
};

export default connect(mapStateToProps, { updatePlanSettings })(DistrictSettingsContainer);
