import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api/v1/`,
  responseType: 'json',
  withCredentials: true,
});

export const tileClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}api/v1/`,
  responseType: 'arraybuffer',
  withCredentials: true,
});

export const setAuthHeader = (token) => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
  tileClient.defaults.headers.common.Authorization = `Bearer ${token}`;
};

if (cookies.get('ballista_token') && !cookies.get('pdi_token')) {
  setAuthHeader(cookies.get('ballista_token'));
}
