export const submitCreateReport = (planId, details, districts) => ({
  type: 'REPORT_SUBMIT_CREATE_REPORT',
  payload: {
    request: {
      url: `/plan/${planId}/report/`,
      method: 'POST',
      data: {
        name: details.name,
        description: details.description,
        metric_test_suites: details.testSuites,
        districts,
      },
    },
  },
});

export const getReports = (planId) => ({
  type: 'REPORT_GET_REPORTS',
  payload: {
    request: {
      url: `/plan/${planId}/report/`,
      method: 'GET',
    },
  },
});

export const getReport = (planId, reportId) => ({
  type: 'REPORT_GET_REPORT',
  payload: {
    request: {
      url: `/plan/${planId}/report/${reportId}/`,
      method: 'GET',
    },
  },
});

export const receiveReportStatusPoll = (reportPoll) => ({
  type: 'REPORT_CREATE_POLL_REPORT',
  payload: reportPoll,
});
