import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TextField, Grid } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import './ShadedLayerForm.css';
import { CompactPicker } from 'react-color';
import DropDown from '../common/DropDown';
import ClickOutside from '../common/ClickOutside';

import { STOPS_TYPE } from '../../constants';

const ShadedLayerForm = (props) => {
  const [values, setValues] = React.useState({
    showMinColor: false,
    showMaxColor: false,
    showViaColor: false,
  });

  return (
    <div className={`shaded-layer-form inner-activity ${!props.isComplete ? 'slv-building' : ''}`}>
      <div className="slf-form-area">
        <Grid container spacing={5}>
          <Grid item xs={8}>
            <TextField
              label="Name"
              className="slf-text"
              defaultValue={props.form.name}
              value={props.form.name || ''}
              placeholder="Label for your Shaded Layer"
              fullWidth
              onChange={(event) => {
                props.handleChange('name')(event.target.value);
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4} />

          <Grid item xs={8}>
            <TextField
              label="Description"
              className="slf-text"
              defaultValue={props.form.description}
              value={props.form.description}
              InputLabelProps={{ shrink: true }}
              placeholder="Function that this shaded layer does."
              fullWidth
              multiline
              onChange={(event) => props.handleChange('description')(event.target.value)}
            />
          </Grid>
          <Grid item xs={4} />

          <Grid item xs={12} sm={6}>
            <h5>Numerator</h5>
            <Autocomplete
              className="gld-dropdown dropdown"
              options={props.columns}
              getOptionLabel={(option) => (`${option.name} (${option.data_source_display_name})`)}
              style={{ width: '100%' }}
              renderInput={(params) => (<TextField {...params} fullWidth placeholder="Choose Numerator" variant="outlined" />)}
              renderOption={(option) => {
                const pillClass = `pill pill-${option.data_source}`;
                return (
                  <div className="column-container">
                    <span className="column-name">{option.name}</span>
                    <span className={pillClass}>{option.data_source_display_name}</span>
                  </div>
                );
              }}
              onChange={(event, value) => {
                props.handleChange('numerator')(value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <h5>Denominator</h5>
            <Autocomplete
              className="gld-dropdown dropdown"
              options={props.columns}
              getOptionLabel={(option) => (`${option.name} (${option.data_source_display_name})`)}
              style={{ width: '100%' }}
              renderInput={(params) => (<TextField {...params} fullWidth placeholder="Choose Denominator" variant="outlined" />)}
              renderOption={(option) => {
                const pillClass = `pill pill-${option.data_source}`;
                return (
                  <div className="column-container">
                    <span className="column-name">{option.name}</span>
                    <span className={pillClass}>{option.data_source_display_name}</span>
                  </div>
                );
              }}
              onChange={(event, value) => {
                props.handleChange('denominator')(value);
              }}
            />
          </Grid>

          <ClickOutside
            handleClickOutside={() => {
              setValues({ showMinColor: false, showMaxColor: false, showViaColor: false });
            }}
          >
            <Grid item container xs={12} sm={12} md={9}>

              <Grid item xs={12} sm={3} md={4} className={`slf-color-pick-container ${values.showMinColor ? 'focused' : ''}`}>
                <div className="slf-color-preview" style={{ backgroundColor: props.form.colorMin }} />
                <TextField
                  label="Min Color"
                  className="slf-text"
                  value={props.form.colorMin || ''}
                  placeholder="#000000"
                  InputLabelProps={{ shrink: true }}
                  onFocus={() => setValues({ showMinColor: true })}
                />
                <div className="slf-color-pick">
                  <CompactPicker
                    color={props.form.colorMin || ''}
                    onChange={(color) => {
                      props.handleChange('colorMin')(color.hex);
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={3} md={4} className={`slf-color-pick-container ${values.showMaxColor ? 'focused' : ''}`}>
                <div className="slf-color-preview" style={{ backgroundColor: props.form.colorMax }} />
                <TextField
                  label="Max Color"
                  className="slf-text"
                  value={props.form.colorMax || ''}
                  placeholder="#0000FF"
                  InputLabelProps={{ shrink: true }}
                  onFocus={() => setValues({ showMaxColor: true })}
                />
                <div className="slf-color-pick">
                  <CompactPicker
                    color={props.form.colorMax || ''}
                    onChange={(color) => {
                      props.handleChange('colorMax')(color.hex);
                    }}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={3} md={4} className={`slf-color-pick-container ${values.showViaColor ? 'focused' : ''}`}>
                <div className="slf-color-preview" style={{ backgroundColor: props.form.colorVia }} />
                <TextField
                  label="Via Color"
                  className="slf-text"
                  value={props.form.colorVia || ''}
                  placeholder="#0000FF"
                  InputLabelProps={{ shrink: true }}
                  onFocus={() => setValues({ showViaColor: true })}
                />
                <div className="slf-color-pick">
                  <CompactPicker
                    color={props.form.colorVia || ''}
                    onChange={(color) => {
                      props.handleChange('colorVia')(color.hex);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </ClickOutside>

          <Grid item container xs={12} sm={12} md={6} spacing={5}>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Steps"
                className="slf-text"
                value={props.form.steps}
                InputLabelProps={{ shrink: true }}
                placeholder="# of steps"
                type="number"
                onChange={(event) => props.handleChange('steps')(event.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <h5>Stop Type</h5>
              <DropDown
                className="gld-dropdown dropdown"
                top
                label={
                  (
                    <span>
                      {_.get(_.filter(STOPS_TYPE, { value: props.form.stops }, [])[0], 'label', 'Choose Stop Type')}
                    </span>
)
}
                options={STOPS_TYPE}
                onChange={(item) => {
                  props.handleChange('stops')(item.value);
                }}
                renderItem={(item) => (<span>{item.label}</span>)}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                label="Breakoff point"
                className="slf-text"
                value={props.form.breakoffPoint}
                InputLabelProps={{ shrink: true }}
                placeholder="Ex. 0.1 or 1.0"
                type="number"
                onChange={(event) => props.handleChange('breakoffPoint')(event.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="slf-form-submit" />
    </div>
  );
};

ShadedLayerForm.propTypes = {
  form: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  isComplete: PropTypes.bool.isRequired,
};

export default ShadedLayerForm;
