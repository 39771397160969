import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Moment from 'react-moment';
import MetricsMenu from './MetricsMenu';
import './TestListView.css';

const TestListView = (props) => (
  <section className="test-list-view modal-page">
    <div className="viewport">
      <header>
        <h1>Metrics</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="content">
        <MetricsMenu {...props} />
        <div className="modal-activity">
          <div className="activity-area">
            <header>
              <h3>Test List View</h3>
            </header>
            <div className="inner-activity">
              <Grid container spacing={5}>
                <Grid item xs={12} md={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Test Suite</TableCell>
                        <TableCell>Projection</TableCell>
                        <TableCell>Date Created</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { props.testSuites.map((testSuite, i) => (
                        <TableRow key={`metrics_testlistview_${i}`}>
                          <TableCell>
                            <div className="test-list-info">
                              <h4>{ testSuite.title }</h4>
                              <div className="metric-list">
                                <ul className="metric-list">
                                  {
                                      _.get(testSuite, 'metrics', []).map((metric, j) => (
                                        <li key={`metrics_testlistview_${i}_${j}`} className="metric-test">{_.get(metric, 'name')}</li>
                                      ))
                                    }
                                </ul>
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            {`${_.get(testSuite, 'projection.auth_name')}:${_.get(testSuite, 'projection.srid')}`}
                          </TableCell>
                          <TableCell>
                            <Moment date={testSuite.created_at} format="D MMM YYYY  //  h:mm a" />
                          </TableCell>
                          <TableCell>
                            <Link to={`/editor/${props.planId}/metrics/${testSuite.id}/results`}>View</Link>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </Grid>
            </div>
            <footer>
              {/* <Button disabled onClick={()=>{}}>Update</Button> */}
            </footer>
          </div>
        </div>
      </section>
    </div>
  </section>
);

TestListView.propTypes = {
  planId: PropTypes.string.isRequired,
  testSuites: PropTypes.array.isRequired,
};

export default TestListView;
