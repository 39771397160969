import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';

import 'ol/ol.css';
import './EditorMap.css';

import Legends from './Legends';
import Search from './Search';
import MapArea from './MapArea';

const EditorMap = (props) => (
  <div className="editor-map">
    <MapArea {...props} />

    <Search {...props} />

    {
        _.get(props.chosenPlan, 'settings.isShadedLayerActive', false)
        && props.activeShadedLayer
        && (
          <Legends shadedLayer={props.activeShadedLayer} />
        )
      }
  </div>
);

EditorMap.propTypes = {
  activeShadedLayer: PropTypes.object,
  chosenPlan: PropTypes.any,
  chosenBrush: PropTypes.object.isRequired,
  chosenDistrict: PropTypes.object,
  chosenTool: PropTypes.string.isRequired,
  clearIncompleteAreaExtent: PropTypes.func.isRequired,
  committedChanges: PropTypes.number,
  focusDistrict: PropTypes.func.isRequired,
  focusedDistrict: PropTypes.number,
  focusedGeometry: PropTypes.object,
  planId: PropTypes.any,
  incompleteArea: PropTypes.array,
  dataColumns: PropTypes.array,
  submitIDs: PropTypes.func.isRequired,
  submitSelection: PropTypes.func.isRequired,
  handleCancelDistrict: PropTypes.func,
  handleDistrictSelect: PropTypes.func.isRequired,
  updatePlanSettings: PropTypes.func.isRequired,
  // updateUnassignedDistrict: PropTypes.func.isRequired,
};

EditorMap.defaultProps = {
  activeShadedLayer: null,
  committedChanges: 0,
  chosenDistrict: undefined,
  handleCancelDistrict: () => {},
  focusedDistrict: null,
  focusedGeometry: null,
  incompleteArea: null,
  planId: null,
  dataColumns: [],
  chosenPlan: null,
};

export default EditorMap;
