import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SettingsMenu from './SettingsMenu';

import BasicSettingsForm from './BasicSettingsForm';
import './BasicSettings.css';

const BasicSettings = (props) => (
  <section className="basic-settings modal-page">
    <div className={`bs-viewport viewport ${props.isSubmitting ? 'bs-submitting' : ''}`}>
      <header>
        <h1>Plan Settings</h1>
        <Link to={`/editor/${props.planId}`}>Close</Link>
      </header>
      <section className="bs-content content">
        <SettingsMenu {...props} />
        <div className="bs-activity modal-activity">
          <BasicSettingsForm {...props} />
        </div>
      </section>
    </div>
  </section>
);

BasicSettings.propTypes = {
  isSubmitting: PropTypes.bool,
  planId: PropTypes.any.isRequired,
};

BasicSettings.defaultProps = {
  isSubmitting: false,
};

export default BasicSettings;
