import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MetricsCreateTestView from '../../components/metrics/CreateTestView';
import {
  getMetrics,
  createTestSuite,
  getSpatialReferences,
} from '../../actions/metrics';
import { getPlanDetails } from '../../actions/plan';

class MetricsCreateTestContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = ({
      form: {
        title: null,
        metricTests: [],
        spatialReference: _.get(this.props.chosenPlan, 'default_projection', null),
      },
    });
  }

  componentDidMount = () => {
    this.props.getMetrics();
    this.props.getSpatialReferences();

    if (this.props.chosenPlan === null) {
      this.props.getPlanDetails(this.props.planId);
    }
  }

  componentDidUpdate = (prevProps) => {
    if (_.get(prevProps, 'chosenPlan.id', null) === null
          && _.get(this.props, 'chosenPlan.id', null) !== null) {
      this.initialize();
    }
  }

  initialize = () => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        spatialReference: _.get(this.props.chosenPlan, 'default_projection'),
      },
    }));
  }

  handleChange = (name) => (e) => {
    const { value } = e.target;
    this.setState((prevProps) => ({
      form: {
        ...prevProps.form,
        [name]: value,
      },
    }));
  }

  handleCheck = (id) => {
    let { metricTests } = this.state.form;
    if (metricTests.includes(id)) {
      // Remove
      metricTests = _.remove(metricTests, id);
    } else {
      metricTests.push(id);
    }

    this.setState((prevProps) => ({
      form: {
        ...prevProps.form,
        metricTests,
      },
    }));
  }

  handleSubmit = () => {
    const metricTests = this.state.form.metricTests.map((metricId) => ({ metric_id: metricId }));
    this.props.createTestSuite(
      this.props.planId,
      this.state.form.title,
      _.get(this.state, 'form.spatialReference.srid'),
      metricTests,
    ).then((response) => {
      const testSuiteId = _.get(response, 'payload.data.id');
      const testInstanceId = _.get(response, 'payload.data.metric_test_suite_tests[0].id');
      this.props.history.push(`/editor/${this.props.planId}/metrics/${testSuiteId}/results/${testInstanceId}`);
    });
  }

  render = () => (
    <MetricsCreateTestView
      {...this.props}
      {...this.state}
      handleChange={this.handleChange}
      handleCheck={this.handleCheck}
      handleSubmit={this.handleSubmit}
    />
  );
}

MetricsCreateTestContainer.propTypes = {
  chosenPlan: PropTypes.object.isRequired,
  createTestSuite: PropTypes.func.isRequired,
  getSpatialReferences: PropTypes.func.isRequired,
  planId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  getPlanDetails: PropTypes.func.isRequired,
  getMetrics: PropTypes.func.isRequired,
};

MetricsCreateTestContainer.defaultProps = {

};

const mapStateToProps = (state) => ({
  metrics: _.get(state, 'metrics.metrics'),
  chosenPlan: _.get(state, 'plan.chosenPlan'),
  spatialReferences: _.get(state, 'metrics.spatialReferences'),
  isSubmitting: _.get(state, 'metrics.isSubmitting'),
});

export default connect(mapStateToProps, {
  getMetrics,
  createTestSuite,
  getSpatialReferences,
  getPlanDetails,
})(MetricsCreateTestContainer);
