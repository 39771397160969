import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { ORGANIZATION, GLOBAL } from '../../actions/plan';

import './SharePlanModal.css';

class SharePlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      planId: null,
      target: null,
    };
  }

  handleOnRendered = () => {
    this.setState({
      planId: _.get(this.props.plan, 'id'),
    });
  }

  handleShare = () => {
    this.props.handleSubmit({ planId: this.state.planId, target: this.state.target });
  }

  render = () => (
    <div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={this.props.open}
        onClose={this.props.handleClose}
        onRendered={this.handleOnRendered}
      >
        <div className="modalCont">
          <div className="modal">
            <div className="header">
              <Typography variant="h6" className="title">
                Share Plan
              </Typography>
            </div>
            <div className="modalContent">
              <div className="modalContentCont">
                <form>
                  <div>
                    <label>Share to:</label>
                    <select
                      onChange={(e) => this.setState({ target: e.target.value })}
                      value={this.state.target || '0000'}
                    >
                      <option key="0000" value="0000">
                        None
                      </option>
                      <option key="1111" value={ORGANIZATION}>
                        Organization
                      </option>
                      <option key="2222" value={GLOBAL}>
                        Global
                      </option>
                    </select>
                  </div>
                </form>
              </div>
            </div>
            <div className="footer">
              <Button
                variant="contained"
                color="secondary"
                onClick={this.props.handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.handleShare}
              >
                Share Plan
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

SharePlanModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  plan: PropTypes.object.isRequired,
};

export default SharePlanModal;
