import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MetricsDownloadView from '../../components/exportArchive/MetricsDownloadView';
import { getMetricTestsArchive } from '../../actions/exportArchive';

class MetricsDownloadContainer extends React.Component {
  componentDidMount = () => {
    this.props.getMetricTestsArchive(this.props.planId);
  };

  render = () => (
    <MetricsDownloadView
      {...this.props}
    />
  )
}

const mapStateToProps = (state) => ({
  metricResultsArchive: _.get(state, 'exportArchive.metricResultsArchive', []),
});

MetricsDownloadContainer.propTypes = {
  planId: PropTypes.string.isRequired,
  getMetricTestsArchive: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getMetricTestsArchive,
})(MetricsDownloadContainer);
