import _ from 'lodash';
import { BRUSH_STROKES } from '../constants';
import { districtsIdsArrayToMap, districtIdsArrayToMap } from '../helper/district';

const defaultState = {
  activeDistrict: null,
  baseLayers: [{
    id: 5, title: 'MapBox Dark', map_source: 'MapType.MAPBOX', configuration: { url: 'https://api.mapbox.com/styles/v1/mapbox/dark-v10?access_token=pk.eyJ1IjoicmNzY2FzdGlsbG8iLCJhIjoiY2pseDZ2bmp0MDcwYzNwcGp1bjBqNHo4aSJ9.3bD8gQrMAIEqV6yyS-__vg', base_url: 'https://api.mapbox.com/v4/mapbox.dark', access_token: 'pk.eyJ1IjoicmNzY2FzdGlsbG8iLCJhIjoiY2pseDZ2bmp0MDcwYzNwcGp1bjBqNHo4aSJ9.3bD8gQrMAIEqV6yyS-__vg' },
  }],
  chosenBrush: BRUSH_STROKES[0],
  chosenTool: 'Pointer',
  committedChanges: 0,
  districtDetails: null,
  districtDetailsSuccessFlag: false,
  calculateDistrictDetailsSuccessToken: null,
  districtDetailsTransactionToken: null,
  undoLastActionFlag: false,
  districts: [],
  changedDistrictId: null,
  focusedDistrict: null,
  searchResults: [],
  shadedLayers: [],
  submittingBlocks: false,
  userUploads: [],
  focusedGeometry: null,
  percentageComplete: 0,
  incompleteArea: null,
  notifications: [],
};

const EditorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'BALLISTA_LOGOUT': {
      return defaultState;
    }
    case 'EDITOR_GET_BASE_LAYERS_SUCCESS':
      return {
        ...state,
        baseLayers: _.get(action, 'payload.data'),
      };
    case 'EDITOR_SET_FOCUS_GEOMETRY':
      return {
        ...state,
        focusedGeometry: _.get(action, 'payload.data.focusedGeometry'),
      };
    case 'EDITOR_RETRIEVE_USER_UPLOADS_SUCCESS':
      return {
        ...state,
        userUploads: _.get(action, 'payload.data'),
      };
    case 'EDITOR_SET_CHOSEN_TOOL':
      return {
        ...state,
        chosenTool: _.get(action, 'data.chosenTool'),
      };
    case 'EDITOR_SET_CHOSEN_BRUSH':
      return {
        ...state,
        chosenBrush: _.get(action, 'data.brush'),
      };
    case 'EDITOR_UNDO_LAST_ACTION':
      return {
        ...state,
        undoLastActionFlag: true,
      };
    case 'EDITOR_CLEAR_UNDO_LAST_ACTION_FLAG':
      return {
        ...state,
        undoLastActionFlag: false,
      };
    case 'EDITOR_CLEAR_CALCULATE_DISTRICT_DETAILS_SUCCESS_TOKEN':
      return {
        ...state,
        calculateDistrictDetailsSuccessToken: null,
      };
    case 'EDITOR_CLEAR_CHANGED_DISTRICT_ID':
      return {
        ...state,
        changedDistrictId: null,
      };
    //    case 'EDITOR_SET_HAS_PENDING_CHANGES':
    //      return {
    //        ...state,
    //        hasPendingChanges: _.get(action, 'data.hasPendingChanges'),
    //      };
    case 'EDITOR_SET_DISTRICT_DETAILS': {
      let newDistrictDetails = [...(_.get(state, 'districtDetails') || [])];
      const actionDistrictDetails = _.get(action, 'data.districtDetails', []);
      if (actionDistrictDetails !== null) {
        actionDistrictDetails.forEach((actionDistrict) => {
          const newDistrictDetailIndex = _.findIndex(
            newDistrictDetails,
            (districtDetail) => _.get(districtDetail, 'district.id') === _.get(actionDistrict, 'district.id'),
          );
          if (newDistrictDetailIndex === -1) {
            newDistrictDetails.push(actionDistrict);
          } else {
            newDistrictDetails.splice(newDistrictDetailIndex, 1, actionDistrict);
          }
        });
        if (newDistrictDetails.length === 0) {
          newDistrictDetails = null;
        }
        return {
          ...state,
          districtDetails: newDistrictDetails,
          districtDetailsTransactionToken: _.get(action, 'data.transactionToken'),
        };
      }
      return {
        ...state,
        districtDetails: null,
        districtDetailsTransactionToken: null,
      };
    }
    case 'EDITOR_CLEAR_DISTRICT_DETAILS':
      return {
        ...state,
        districtDetails: null,
        districtDetailsSuccessFlag: false,
      };
    case 'EDITOR_SAVE_DISTRICT_DETAILS_SUCCESS': {
      const editor = { ...state };
      const newDistricts = districtsIdsArrayToMap(_.get(action, 'payload.data.districts', []));
      // merge editor districts with new Districts
      editor.districts = editor.districts.map((district) => {
        const thisDistrictIndex = _.findIndex(newDistricts, { id: district.id });
        if (thisDistrictIndex !== -1) {
          return {
            ...district,
            ...newDistricts[thisDistrictIndex],
          };
        }
        return district;
      });
      return {
        ...editor,
        districtDetailsSuccessFlag: true,
      };
    }
    case 'EDITOR_CALCULATE_DISTRICT_DETAILS_SUCCESS': {
      const transactionToken = _.get(action, 'meta.previousAction.transactionToken');
      const actionDistricts = _.get(action, 'payload.data.districts') || [];
      if (transactionToken === state.districtDetailsTransactionToken && transactionToken !== null) {
        const newDistrictDetails = [...(_.get(state, 'districtDetails') || [])];
        actionDistricts.forEach((actionDistrict) => {
          const newDistrictDetail = _.find(
            newDistrictDetails,
            (districtDetail) => _.get(districtDetail, 'district.id') === _.get(actionDistrict, 'id'),
          );
          if (typeof newDistrictDetail !== 'undefined') {
            newDistrictDetail.final.counts = _.get(actionDistrict, 'settings.counts', {
              total_population: 0,
              total_voters: 0,
              cvap_asian_2017: 0,
              cvap_black_2017: 0,
              cvap_latino_2017: 0,
            });
          }
        });
        return {
          ...state,
          districtDetails: newDistrictDetails,
          districtDetailsTransactionToken: null,
          calculateDistrictDetailsSuccessToken: transactionToken,
        };
      }
      return {
        ...state,
      };
    }
    case 'EDITOR_SUBMIT_IDS_SUCCESS':
      return {
        ...state,
        districtDetails: _.get(action, 'payload.data.totals'),
      };
    // case 'EDITOR_REVERT_DISTRICT_SUCCESS':
    //
    //   return {
    //     ...state,
    //     districtDetails: _.get(action, 'payload.data.totals'),
    //     committedChanges: state.committedChanges + 1,
    //   };
    case 'EDITOR_SUBMIT_SELECTION':
      return {
        ...state,
      };
    case 'EDITOR_SUBMIT_SELECTION_SUCCESS':
      return {
        ...state,
        districtDetails: _.get(action, 'payload.data.totals'),
        committedChanges: state.committedChanges + 1,
      };
    case 'EDITOR_RETRIEVE_SANDBOX_DISTRICT_DETAIL':
      return {
        ...state,
      };

      // case 'EDITOR_RETRIEVE_SANDBOX_DISTRICT_DETAIL_SUCCESS':
      // return {
      //  ...state,
      //  districtDetails: _.get(action, 'payload.data.totals'),
      // };

    case 'EDITOR_GOTO_INCOMPLETE_AREA_SUCCESS':
      return {
        ...state,
        incompleteArea: _.get(action, 'payload.data'),
      };
    case 'EDITOR_CLEAR_INCOMPLETE_AREA_EXTENT':
      return {
        ...state,
        incompleteArea: null,
      };
    // case 'EDITOR_SAVE_DISTRICT_SUCCESS':
    //
    //   return {
    //     ...state,
    //     districtDetails: null,
    //     districts: _.get(action, 'payload.data.districts'),
    //     percentageComplete: _.get(action, 'payload.data.percentage_complete'),
    //     committedChanges: state.committedChanges + 1,
    //   };
    case 'EDITOR_FOCUS_DISTRICT':
      return {
        ...state,
        focusedDistrict: _.get(action, 'data.focusedDistrict'),
      };
    // case 'EDITOR_UPDATE_UNASSIGNED_DISTRICT_SUCCESS':
    //   return {
    //     ...state,
    //     committedChanges: state.committedChanges + 1,
    //     unassignedDetails: _.get(action, 'payload.data.details'),
    //   };
    case 'EDITOR_SEARCH_SUCCESS':
      return {
        ...state,
        searchResults: _.get(action, 'payload.data'),
      };
    case 'EDITOR_CLEAR_SEARCH':
      return {
        ...state,
        searchResults: [],
      };
    case 'PLAN_GET_PLAN_DETAILS_SUCCESS': {
      // Listening to when plan gets the details...
      const districts = districtsIdsArrayToMap(_.get(action, 'payload.data.districts'));

      // convert district ids to hash map
      return {
        ...state,
        districts,
        shadedLayers: _.get(action, 'payload.data.shaded_layers'),
        percentageComplete: _.get(action, 'payload.data.percentage_complete'),
      };
    }
    case 'PLAN_UPDATE_PLAN_SUCCESS':
      return {
        ...state,
        districts: districtsIdsArrayToMap(_.get(action, 'payload.data.districts')),
      };
    case 'EDITOR_DISTRICT_UPDATE_LOCK_SUCCESS': {
      const districts = _.get(state, 'districts');
      const newDistrict = districtIdsArrayToMap(_.get(action, 'payload.data'));
      districts.splice(
        _.findIndex(districts, { id: _.get(newDistrict, 'id') }), 1, newDistrict,
      );
      return {
        ...state,
        districts,
        changedDistrictId: newDistrict.id,
      };
    }
    case 'EDITOR_DISTRICT_UPDATE_ALIAS_SUCCESS': {
      // TODO:: NORMALIZR
      const districts = _.get(state, 'districts');
      const newDistrict = districtIdsArrayToMap(_.get(action, 'payload.data'));
      districts.splice(
        _.findIndex(districts, { id: _.get(newDistrict, 'id') }), 1, newDistrict,
      );
      return {
        ...state,
        districts,
        committedChanges: state.committedChanges + 1,
      };
    }

    case 'EDITOR_DISTRICT_DELETE_SUCCESS': {
      const districtId = _.get(action, 'meta.previousAction.districtId');
      const districts = [...state.districts];

      _.remove(districts, { id: districtId });

      return {
        ...state,
        districts,
        committedChanges: state.committedChanges + 1,
      };
    }

    case 'PLAN_ADD_DISTRICT_SUCCESS': {
      const districts = [..._.get(state, 'districts'), _.get(action, 'payload.data')];
      return {
        ...state,
        districts,
      };
    }
    // case 'PLAN_SELECT_UNASSIGNED_SUCCESS':
    //   return {
    //     ...state,
    //     unassignedDetails: _.get(action, 'payload.data.details'),
    //   };
    // case 'EDITOR_REVERT_UNASSIGNED_DISTRICT_SUCCESS':
    //   return {
    //     ...state,
    //     committedChanges: state.committedChanges + 1,
    //     unassignedDetails: null,
    //   };
    // case 'EDITOR_COMMIT_UNASSIGNED_DISTRICT_SUCCESS':
    //   return {
    //     ...state,
    //     committedChanges: state.committedChanges + 1,
    //     unassignedDetails: null,
    //     districts: _.get(action, 'payload.data.districts'),
    //     percentageComplete: _.get(action, 'payload.data.percentage_complete'),
    //   };
    // Listening for other signals:
    case 'GUIDE_LAYER_UPDATE_SETTINGS':
    case 'PLAN_UPDATE_SETTINGS_PLAN_SUCCESS':
    case 'IMPORTED_DISTRICT_SELECT_FOR_PLAN_SUCCESS':
    case 'IMPORTED_DISTRICT_SUBMIT_UPLOADED_DISTRICTS_SUCCESS':
    case 'PLAN_UPDATE_PLAN_BASELAYER_SUCCESS':
      return {
        ...state,
        committedChanges: state.committedChanges + 1,
      };
    default: return state;
  }
};

export default EditorReducer;
